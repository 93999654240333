
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {EinweisungGegenstandDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import GegenstandComponent from "@/views/einweisung/components/GegenstandComponent.vue";

@Component({
  components: {GegenstandComponent, Multiselect}
})
export default class EinweisungGegenstandComponent extends Vue {
  @Prop() private id: string;
  @Prop() private value: EinweisungGegenstandDTO;
  @Prop() private error: any;
  userList = []

  data: EinweisungGegenstandDTO = null

  async created() {
    this.init(this.value)
    this.userList = await this.$store.dispatch('user/fetchUser')
  }

  init(newValue) {
    this.data = structuredClone(newValue)
  }

  changed() {
    this.$emit("input", this.data);
    this.$emit("change", this.data);
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

}
