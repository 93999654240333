import { render, staticRenderFns } from "./FileLexTreeDocs.vue?vue&type=template&id=d3c55554&"
import script from "./FileLexTreeDocs.vue?vue&type=script&lang=ts&"
export * from "./FileLexTreeDocs.vue?vue&type=script&lang=ts&"
import style0 from "./FileLexTreeDocs.vue?vue&type=style&index=0&id=d3c55554&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports