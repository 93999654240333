

import {Component, Vue} from "vue-property-decorator";
import {UserFileDTO} from "@/model/dto";
import {FileApi} from "@/services/FileApi";
import UserFileEditModal from "@/views/user/modal/UserFileEditModal.vue";
import jquery from "jquery";

@Component({
  components: {UserFileEditModal}
})
export default class UserDashboardFileCard extends Vue {
  data: UserFileDTO | null = null
  files: UserFileDTO[] = []
  donePromise;

  init() {
    this.donePromise = new Promise((resolve, reject) => {
      let userId = this.$store.getters.getUser.id
      FileApi.getUserFileByUser(userId, false).then(data => {
        this.files = data

        this.$nextTick(() => {
          jquery('#filecontent').children().appendTo("#myCards");
          resolve(this.files.length)
        });
      })
    })
  }

  getPromise() {
    return this.donePromise
  }

  async created() {
    this.init()
  }

  get getFileData() {
    return this.files.filter((file) => file.statusNumeric == 2).sort((a, b) => {
      return b.statusNumeric - a.statusNumeric
    })
  }

  get filteredFileData() {
    const seen = new Set();
    return this.getFileData.filter(file => {
      if (!file.downloadFile) {
        return true;
      }
      const key = file.downloadFile.id;
      if (seen.has(key)) {
        return false;
      } else {
        seen.add(key);
        return true;
      }
    });
  }
}
