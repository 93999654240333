
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {EinweisungUserDTO, UserResponseDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {EinweisungUser} from "@/model/Einweisung";

@Component({
  components: {Multiselect}
})
export default class EinweisungUserSimpleComponent extends Vue {
  @Prop() private id: string;
  @Prop() private value: EinweisungUserDTO[];
  @Prop() private error: any;
  userList = []

  data: UserResponseDTO[] = []

  async created() {
    this.init(this.value)
    this.userList = await this.$store.dispatch('user/fetchUser')
  }

  init(newValue) {
    const clone = structuredClone(newValue)
    this.data = clone.map(ewUser => ewUser.user)
  }

  changed() {
    const transformed = this.data.map(user => {
      let ewUser = new EinweisungUser();
      ewUser.user = user;
      return ewUser
    });

    this.$emit("input", transformed);
    this.$emit("change", transformed);
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

}
