
import {Component, Prop} from 'vue-property-decorator';
import {DivaApi} from "@/services/DivaApi";
import {GenericError, SuchenPersonDTO, SuchenPersonTokenDTO} from "@/model/dto";
import {SuchenPerson, SuchenPersonToken} from "@/model/Diva";
import {mixins} from "vue-class-component";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {ApiResponse} from "@/model/AbstractClasses";
import store from "@/store";
import {DateHelper} from "../../model/Date";
import DivaSuche from "@/views/diva/components/DivaSuche.vue";

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    },
    store() {
      return store
    },
    SuchenPerson() {
      return SuchenPerson
    }
  },
  components: {DivaSuche},
})


export default class TokenView extends mixins<SimpleViewMixin<SuchenPersonTokenDTO, GenericError>>(SimpleViewMixin) {
  @Prop({default: ''}) token!: string
  modus = 0 //modus 0 = init, modus 1 = anfrage, modus = 2 = fehler, modus 3 = antwort
  suchenPerson: SuchenPersonDTO = null

  data: SuchenPersonTokenDTO = new SuchenPersonToken()
  error: GenericError = new GenericError()

  created() {
    this.$root.$on('alert', this.showAlert)

    DivaApi.getByToken(this.token)
        .then((response) => {
          if (response) {
            this.suchenPerson = response
            this.modus = (response.status == SuchenPerson.STATUS_NA
                || response.status == SuchenPerson.STATUS_Angefragt) ? 1 : 3
          } else {
            this.modus = 2
          }
        })
        .catch(e => {
          this.modus = 2
        })
  }

  destroyed() {
    this.$root.$off('alert', this.showAlert)
  }

  onSubmit() {
    this.data.token = this.token
    const p = DivaApi.answer(this.data);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data !== undefined) {
        this.suchenPerson = data.data
        this.modus = 3
      } else if (!data.fieldErrors || data.fieldErrors.length === 0) {
        this.modus = 2
      }
    })
  }

  showAlert(text, variant = 'success', dismissCountDown = 4) {
    const isUnmounted = !document.body.contains(this.$el)
    if (isUnmounted) return;
    this.$toast[variant](text, {timeout: dismissCountDown * 1000})
  }

  get loggedIn() {
    return localStorage.getItem('token')
  }
}
