
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {EinweisungConfigGegenstandDTO, EinweisungGegenstandDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import GegenstandListItemComponent from "@/views/shared/components/GegenstandListItemComponent.vue";
import {GegenstandListItem} from "@/model/Shared";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

@Component({
  components: {ListItemComponent, GegenstandListItemComponent, Multiselect}
})
export default class GegenstandComponent extends Vue {
  @Prop() private id: string;
  @Prop() private value: EinweisungGegenstandDTO | EinweisungConfigGegenstandDTO;
  @Prop() private error: any;

  gegenstandList = []
  data: GegenstandListItem = null
  parsedError = ""

  async created() {
    const ewos = await this.$store.dispatch('medprodukt/fetchEwos')
    ewos.forEach(m => {
      this.gegenstandList.push(new GegenstandListItem(m))
    })

    const kfzs = await this.$store.dispatch('kfz/fetchKfz')
    kfzs.forEach(m => {
      this.gegenstandList.push(new GegenstandListItem(m))
    })

    const materials = await this.$store.dispatch('material/fetchMaterial')
    materials.forEach(m => {
      if (!m.einweisungPflicht) {
        return;
      }
      this.gegenstandList.push(new GegenstandListItem(m))
    })

    const materialArts = await this.$store.dispatch('material/fetchMaterialArt')
    materialArts.forEach(m => {
      if (!m.inEinweisung || !m.klasse) {
        return;
      }
      this.gegenstandList.push(new GegenstandListItem(m))
    })

    const kfzCategories = await this.$store.dispatch('kfz/fetchKategorien')
    kfzCategories.forEach(m => {
      if (m.abstract) {
        return;
      }
      this.gegenstandList.push(new GegenstandListItem(m))
    })


    this.init(this.value)
  }

  init(newValue) {
    if (!this.value) {
      this.data = null
      return;
    }
    if (this.value.kfz) {
      this.data = new GegenstandListItem(this.value.kfz)
    } else if (this.value.medProdukt) {
      this.data = new GegenstandListItem(this.value.medProdukt)
    } else if (this.value.material) {
      this.data = new GegenstandListItem(this.value.material)
    } else if (this.value.materialKlasse) {
      this.data = new GegenstandListItem(this.value.materialKlasse)
    } else if (this.value.kfzCategory) {
      this.data = new GegenstandListItem(this.value.kfzCategory)
    } else {
      this.data = null
    }
  }

  changed() {
    let mappedData: EinweisungGegenstandDTO | EinweisungConfigGegenstandDTO = this.value
    mappedData.kfz = null
    mappedData.medProdukt = null
    mappedData.material = null
    mappedData.materialKlasse = null
    mappedData.kfzCategory = null

    if (this.data) {
      if (this.data.type == "kfz") {
        mappedData.kfz = this.data.dto
      } else if (this.data.type == "ewo") {
        mappedData.medProdukt = this.data.dto
      } else if (this.data.type == "material") {
        mappedData.material = this.data.dto
      } else if (this.data.type == "materialArt") {
        mappedData.materialKlasse = this.data.dto
      } else if (this.data.type == "kfzCategory") {
        mappedData.kfzCategory = this.data.dto
      }
    }
    //  this.data.forEach()
    this.$emit("input", mappedData);
    this.$emit("change", mappedData);
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

  @Watch('error')
  public watchError(error) {
    if (error['medProdukt']) {
      this.parsedError = error['medProdukt']
    } else if (error['kfz']) {
      this.parsedError = error['kfz']
    } else if (error['material']) {
      this.parsedError = error['material']
    } else {
      this.parsedError = ""
    }
  }
}
