import {apiClient, javaClient} from './AbstractHTTPService'
import store from '../store'

import Axios from "axios";
import User, {UserDTO, UserEdit, UserProfile} from "@/model/User";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {
    FileDTO,
    OrgUnitDTO,
    QualificationDTO,
    SkillDTO,
    UserDressSizeDTO,
    UserOrgUnitDTO,
    UserPasswordDTO,
    UserPinDTO,
    UserProfileDTO,
    UserQualificationDTO,
    UserResponseDTO,
    UserSkillDTO
} from "@/model/dto";
import {Event} from "@/model/Constants";

interface RequestInterface {
    page: number,
    per_page: number,
    total: number,
    total_pages: number,
    data: UserDTO[]
}

export abstract class UsersApi {
    private static usersAxios = Axios.create();

    static async getAllUsers(): Promise<User[]> {
        const response = await this.usersAxios.get('https://reqres.in/api/users');
        return response.data.data.map(userDto => new User(userDto));
    }
    static async getLoggedInUser(): Promise<UserResponseDTO> {
        const response = await javaClient.get('/user');
        console.log(response)
        return response.data;
    }

    static async getUserList(): Promise<UserResponseDTO[]> {
        const response = await apiClient.get('/user/userlist/list');
        return response.data.data;
    }

    static async getOrgUnitUserList(): Promise<UserResponseDTO[]> {
        const response = await apiClient.get('/user/userlist/list/oulist');
        return response.data.data;
    }


    static async getUserClothesList(): Promise<UserDressSizeDTO[]> {
        const response = await apiClient.get('/user/userlist/list/clothes');
        return response.data.data;
    }

    static async getUserQualificationList(): Promise<UserQualificationDTO[]> {
        const response = await apiClient.get('/user/userlist/list/qualifications');
        return response.data.data;
    }

    static async getUserSkillList(): Promise<UserSkillDTO[]> {
        const response = await apiClient.get('/user/userlist/list/skills');
        return response.data.data;
    }


    static async updateProfile(user: UserProfile): Promise<ApiResponse<UserProfileDTO>> {
        const response = await apiClient.put('/user/userprofile/' + store.getters.getUser.id + '/', user);
        return new ApiResponse(response);
    }
    static async updatePin(user: UserPinDTO): Promise<ApiResponse<UserProfileDTO>> {
        const response = await apiClient.put('/user/userpin/' + store.getters.getUser.id + '/', user);
        return new ApiResponse(response);
    }
    static async updatePassword(user: UserPasswordDTO): Promise<ApiResponse<UserProfileDTO>> {
        const response = await apiClient.put('/user/userpassword/' + store.getters.getUser.id + '/', user);
        return new ApiResponse(response);
    }
    static async updateUser(user: UserEdit): Promise<ApiResponse<UserProfileDTO>> {
        const response = await apiClient.put('/user/userdata/' + user.id + '/', user);
        store.dispatch("crudEvent", new CrudEvent(Event.userChanged, response.data.data));
        return new ApiResponse(response);
    }

    static async updateUserPhoto(userId: number, dto): Promise<FileDTO> {
        const response = await apiClient.put('/user/userphoto/' + userId + '/', dto);
        return response.data.data;
    }

    static async removeUserPhoto(userId: number): Promise<any> {
        const response = await apiClient.delete('/user/userphoto/' + userId + '/');
        return response.data;
    }

    static async getProfile(): Promise<ApiResponse<UserProfileDTO>> {
        const response = await apiClient.get('/user/userprofile');
        return new ApiResponse(response);
    }

    static async getUser(id): Promise<ApiResponse<UserProfileDTO>> {
        const response = await apiClient.get('/user/userdata/' + id);
        return new ApiResponse(response);
    }

    static async getOrgUnitsOfUser(id): Promise<OrgUnitDTO[]> {
        const response = await apiClient.get('/user/orgunits/' + id);
        return response.data.data;
    }

    static async removeUserOrgUnit(perm: UserOrgUnitDTO): Promise<UserOrgUnitDTO> {
        const response = await apiClient.delete('/user/orgunits/' + perm.userId, {data: perm});
        return response.data.data;
    }

    static async addUserOrgUnit(perm: UserOrgUnitDTO): Promise<UserOrgUnitDTO> {
        const response = await apiClient.put('/user/orgunits/' + perm.userId, perm);
        return response.data.data;
    }


    static async getQualifications(): Promise<QualificationDTO[]> {
        const response = await apiClient.get('/user/qualification/chain');
        return response.data.data;
    }
    static async putQualification(q: QualificationDTO): Promise<ApiResponse<QualificationDTO>> {
        const response = await apiClient.put('/user/qualification', q);
        store.dispatch("crudEvent", new CrudEvent(Event.qualiUpdate, response.data.data));
        return response.data;
    }

    static async removeQualification(id): Promise<any> {
        const response = await apiClient.delete('/user/qualification/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.qualiUpdate, response.data.data), id);
        return response.data;
    }


    static async getMaxQualificationOfUser(id): Promise<UserQualificationDTO|null> {
        const response = await apiClient.get('/user/userqualification/' + id + '/max');
        return response.data.data;
    }
    static async getQualificationOfUser(id): Promise<UserQualificationDTO[]> {
        const response = await apiClient.get('/user/userqualification/' + id);
        return response.data.data;
    }

    static async putUserQualification(userId: number, q: UserQualificationDTO): Promise<UserQualificationDTO> {
        const response = await apiClient.put('/user/userqualification/' + userId, q);
        return response.data;
    }

    static async removeUserQualification(userQualiId): Promise<any> {
        const response = await apiClient.delete('/user/userqualification/' + userQualiId);
        return response.data;
    }


    static async getSkills(): Promise<SkillDTO[]> {
        const response = await apiClient.get('/user/skill/chain');
        return response.data.data;
    }

    static async putSkill(q: SkillDTO): Promise<ApiResponse<SkillDTO>> {
        const response = await apiClient.put('/user/skill', q);
        store.dispatch("crudEvent", new CrudEvent(Event.skillUpdate, response.data.data));
        return response.data;
    }

    static async removeSkill(id): Promise<any> {
        const response = await apiClient.delete('/user/skill/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.skillUpdate, response.data.data), id);
        return response.data;
    }


    static async getSkillsOfUser(id): Promise<UserSkillDTO[]> {
        const response = await apiClient.get('/user/userskill/' + id);
        return response.data.data;
    }

    static async addUserSkill(userId: number, q: UserSkillDTO): Promise<UserSkillDTO> {
        const response = await apiClient.put('/user/userskill/' + userId, q);
        return response.data;
    }

    static async removeUserSkill(id): Promise<any> {
        const response = await apiClient.delete('/user/userskill/' + id);
        return response.data;
    }

    static async archivUser(id): Promise<any> {
        const response = await apiClient.delete('/user/userdata/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.userChanged, response.data.data), id);

        return response.data;
    }


    static async getImportData(id): Promise<any> {
        const response = await apiClient.get('/user/import/' + encodeURIComponent(id));
        return response.data.data;
    }

    static async processImportData(id): Promise<any> {
        const response = await apiClient.post('/user/import/' + encodeURIComponent(id));
        return response.data.data;
    }
}