import OutsourcingCreateView from "@/views/outsourcing/OutsourcingCreateView.vue";
import OutsourcingEditView from "@/views/outsourcing/OutsourcingEditView.vue";
import OutsourcingTables from "@/views/outsourcing/OutsourcingTables.vue";
import OutsourcingView from "@/views/outsourcing/OutsourcingView.vue";
import OutsourcingServiceView from "@/views/outsourcing/OutsourcingServiceView.vue";
import {Permission, PermissionModul} from "@/model/dto";

export const outsourcingRoutes = [
    {
        path: 'outsourcing',
        name: 'outsourcing',
        component: OutsourcingView,
        children: [
            {
                path: 'services',
                name: 'outsourcingservices',
                component: OutsourcingServiceView,
                meta: {
                    loading: true,
                    title: 'Servicearten',
                    permission: [PermissionModul.Outsourcing_Settings, Permission.Servicearten]
                }
            },
            {
              path: 'overview',
              name: 'outsourcinglist',
              component: OutsourcingTables,
              meta: {
                  loading: true,
                  datatable: true,
                  title: 'Übersicht',
                  permission: [PermissionModul.Outsourcing, Permission.Overview],
                  activeRoutes: ['outsourcinglist', 'outsourcingcreate', 'outsourcingedit']
              }
            },
            {
                path: 'create',
                name: 'outsourcingcreate',
                components: {
                    default: OutsourcingTables,
                    CRUD: OutsourcingCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Outsourcing, Permission.Create]
                }
            },
            {
                path: 'edit/:id',
                name: 'outsourcingedit',
                components: {
                    default: OutsourcingTables,
                    CRUD: OutsourcingEditView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Outsourcing, Permission.Overview]
                }
            }
        ],
    }
]
