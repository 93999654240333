import {apiClient} from './AbstractHTTPService'
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {EwTragweiteBewertungDTO, LevelDTO, LevelklasseDTO, LevelOuDTO, MeldewegDTO, OrgUnitTreeDTO, ReportDTO, ReportNotizDTO, ReportRequestDTO, StatusDTO, TaskReportMapDTO} from "@/model/dto";
import store from "@/store";
import {Event} from "@/model/Constants";
import {Actions} from "@/model/Actions";


export abstract class EmeldApi {

    static async putLevel(level: LevelDTO): Promise<ApiResponse<LevelDTO>> {
        const response = await apiClient.put('/emeld/level/' + level.id + '/', level);
        store.dispatch("crudEvent", new CrudEvent(Event.emeldLevelChanged, response.data.data));
        return new ApiResponse(response);
    }

    static async putLevelOu(level: LevelOuDTO): Promise<ApiResponse<LevelOuDTO>> {
        const response = await apiClient.put('/emeld/levelou/' + level.id, level);
        store.dispatch("crudEvent", new CrudEvent(Event.emeldLevelChanged, response.data.data));
        return new ApiResponse(response);
    }

    static async putReport(report: ReportDTO, action = Actions.SAVE): Promise<ApiResponse<ReportDTO>> {
        const response = await apiClient.put('/emeld/report/' + report.id + '/', report, {
            headers: {
                'X-Action': action
            }
        });
        return new ApiResponse(response);
    }

    static async addLevelToOu(levelId, dto: LevelOuDTO): Promise<ApiResponse<LevelOuDTO>> {
        const response = await apiClient.put('/emeld/levelou/' + levelId, dto);
        store.dispatch("crudEvent", new CrudEvent(Event.emeldLevelChanged, response.data.data));
        return new ApiResponse(response);
    }

    static async getLevel(id): Promise<ApiResponse<LevelDTO>> {
        const response = await apiClient.get('/emeld/level/' + id);
        return new ApiResponse(response);
    }

    static async getLevelOuByLevelId(id): Promise<ApiResponse<LevelOuDTO>> {
        const response = await apiClient.get('/emeld/levelou/' + id);
        return new ApiResponse(response);
    }


    static async getLevelTree(): Promise<OrgUnitTreeDTO[]> {
        const response = await apiClient.get('/emeld/level/tree');
        return response.data.data;
    }

    static async getLevelOuTree(): Promise<OrgUnitTreeDTO[]> {
        const response = await apiClient.get('/emeld/levelou/tree');
        return response.data.data;
    }

    static async getLevelList(): Promise<LevelDTO[]> {
        const response = await apiClient.get('/emeld/level/list');
        return response.data.data;
    }


    static async removeLevel(id): Promise<any> {
        const response = await apiClient.delete('/emeld/level/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.emeldLevelChanged, response.data.data));
        return response.data;
    }

    static async removeLevelOu(id): Promise<any> {
        const response = await apiClient.delete('/emeld/levelou/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.emeldLevelChanged, response.data.data));
        return response.data;
    }

    static async getStatus(): Promise<StatusDTO[]> {
        const response = await apiClient.get('/emeld/status');
        return response.data.data;
    }

    static async getLevelKlassen(): Promise<LevelklasseDTO[]> {
        const response = await apiClient.get('/emeld/levelklasse');
        return response.data.data;
    }

    static async getUserReports(): Promise<ReportDTO[]> {
        const response = await apiClient.get('/emeld/report/user');
        return response.data.data;
    }

    static async getReport(id: number): Promise<ReportDTO> {
        const response = await apiClient.get('/emeld/report/' + id);
        return response.data;
    }

    static async getReports(request: ReportRequestDTO): Promise<ReportDTO[]> {
        const response = await apiClient.post('/emeld/report/', request);
        return response.data;
    }


    static async getNotizen(refId: number): Promise<ReportNotizDTO[]> {
        const response = await apiClient.get('/emeld/reportnotiz/' + refId);
        return response.data.data;
    }

    static async putNotiz(refId: number, q: ReportNotizDTO): Promise<ReportNotizDTO> {
        q.reportId = refId;

        const response = await apiClient.put('/emeld/reportnotiz', q);
        return response.data;
    }

    static async getEwTragweiteBewertung(): Promise<EwTragweiteBewertungDTO> {
        const response = await apiClient.get('/emeld/bewertung');
        return response.data.data;
    }

    static async getTasks(reportId: number): Promise<TaskReportMapDTO[]> {
        const response = await apiClient.get('/task/taskreportmap/report/' + reportId);
        return response.data.data;
    }

    static async checkUpdateAccess(reportId: number): Promise<any> {
        const response = await apiClient.get('/emeld/report/updateaccess/' + reportId);
        return response.data;
    }

    static async getMeldewegeByLevelId(levelId: number): Promise<MeldewegDTO[]> {
        const response = await apiClient.get('/emeld/levelou/levelmeldewege/' + levelId);
        return response.data.data;
    }
    static async getMeldewegeByTagId(tagId: number): Promise<MeldewegDTO[]> {
        const response = await apiClient.get('/emeld/levelou/tagmeldewege/' + tagId);
        return response.data.data;
    }


}