

import {Component, Vue} from "vue-property-decorator";
import {MaterialApi} from "@/services/MaterialApi";
import {DateHelper} from "@/model/Date";
import jquery from "jquery";

@Component
export default class UserDashboardMaterialCard extends Vue {
  data = null
  dateHelper = DateHelper
  showChildModalVisible = false;
  selectedMaterial = null;
  childMaterials = [];

  donePromise;

  async init() {
    this.donePromise = new Promise((resolve, reject) => {
      let userId = this.$store.getters.getUser.id
      MaterialApi.getMaterialAusgabenByUser(userId, true).then(data => {
        this.data = data

        this.$nextTick(() => {
          jquery('#materialContent').children().appendTo("#myCards");
          resolve(this.data.length)
        });
      })
    })
  }

  getPromise() {
    return this.donePromise
  }

  async created() {
    await this.init();
  }

  get filteredData() {
    return this.data ? this.data.filter(material => material.material && material.parent === null) : [];
  }

  async confirmMaterial(material) {
    this.$confirm('Wollen Sie den Erhalt der Ausgabe wirklich bestätigen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(async () => {
      MaterialApi.setMaterialAusgabeConfirmed(material.id)
          .then(() => {
            material.empfangConfirmed = true;
            this.showChildModalVisible = false;
          })
          .catch(() => {
            this.$alert("Zugriff verweigert!", "Fehler", "error");
          });
    }).catch(() => {
      //no nothing
    });
  }

  async showChildModal(material) {
    this.selectedMaterial = material;
    this.childMaterials = await MaterialApi.getMaterialAusgabenByParent(material.id);
    this.showChildModalVisible = true;
  }
}
