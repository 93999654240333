
import {Component, Prop, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import EmeldNavComponent from "@/views/emeld/components/EmeldNavComponent.vue";
import ReportListServersideView from "@/views/emeld/ReportListServersideView.vue";
import Multiselect from "@/libs/multiselect";
import FormListView from "@/views/form/FormListView.vue";
import ReportListPublicServersideView from "@/views/emeld/ReportListPublicServersideView.vue";


@Component(
    {
      components: {
        ReportListPublicServersideView,
        FormListView,
        ReportListServersideView,
        EmeldNavComponent,
        DatatableButtonHeader,
        Multiselect
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class EmeldTables extends Vue {
  @Prop() id: number;

  showTable1 = false
  showVersionTable = false
  showTable3 = false
  showTablePublic = false
  showTableOwn = false

  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'reportlist') {
      this.showTable1 = true
      this.showVersionTable = false
      this.showTable3 = false
      this.showTablePublic = false
      this.showTableOwn = false
    } else if (route.name == 'reportlistown') {
      this.showTable1 = false
      this.showVersionTable = false
      this.showTable3 = false
      this.showTablePublic = false
      this.showTableOwn = true
    } else if (route.name == 'emeldformlist') {
      this.showTable1 = false
      this.showVersionTable = false
      this.showTable3 = true
      this.showTablePublic = false
      this.showTableOwn = false
    } else if (route.name == 'reportlistpublic') {
      this.showTable1 = false
      this.showVersionTable = false
      this.showTable3 = false
      this.showTablePublic = true
      this.showTableOwn = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
