
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {EinweisungDTO, Permission, PermissionModul} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import AdditionalFieldForm from "@/components/AdditionalFieldForm.vue";
import DateForm from "@/components/DateForm.vue";
import {EinweisungFile, EinweisungGegenstand} from "@/model/Einweisung";
import EinweisungGegenstandComponent from "@/views/einweisung/components/EinweisungGegenstandComponent.vue";
import EinweisungUserComponent from "@/views/einweisung/components/EinweisungUserComponent.vue";
import EinweisungUserSimpleComponent from "@/views/einweisung/components/EinweisungUserSimpleComponent.vue";
import FileUpload from "@/components/FileUpload.vue";
import {ExistingFiles, RewisFile} from "@/model/AbstractClasses";
import {bus} from "@/main";
import {Event, Module} from "@/model/Constants";


@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    FileUpload,
    EinweisungUserSimpleComponent,
    EinweisungUserComponent,
    EinweisungGegenstandComponent,
    EinweisungGegenstand, DateForm, AdditionalFieldForm, NumberForm, Multiselect
  }
})
export default class EinweisungDataView extends mixins<GenericMixin<EinweisungDTO, EinweisungDTO>>(GenericMixin) {
  @Prop() value: EinweisungDTO;
  @Prop() error: EinweisungDTO;
  data: EinweisungDTO = this.$props.value;
  existingFiles = []
  uploadKey = 0

  async created() {
    window.scrollTo(0, 0);
    this.init()
  }


  init() {
    this.existingFiles = new ExistingFiles(this.data.nachweise.map(n => {
      n.file.foreignId = n.id;
      return n.file
    }));
  }

  changeFile(id) {
    const idx = this.data.nachweise.findIndex(nachweis => nachweis.file.identifier == id || (nachweis.file.id == id))
    this.$prompt("Beschreibung", this.data.nachweise[idx].comment).then((r: string) => {
      this.data.nachweise[idx].comment = r
      this.data.nachweise[idx].file.comment = r
      bus.$emit(Event.uploadDescription, this.data.nachweise.map(f => f.file))
    });


    this.$emit('input', this.data)
  }

  fileUploadSuccess(d) {
    let file = new RewisFile(d);


    let nachweis = new EinweisungFile()
    nachweis.file = file
    this.data.nachweise.push(nachweis)

    this.$emit('input', this.data)
  }

  fileDeleted(d) {
    this.data.nachweise = this.data.nachweise.filter(nachweis => (nachweis.id != d.file.foreignId))
    //  this.existingFile = null
    this.$emit('input', this.data)
  }

  fileRemoved(d) {
    this.data.nachweise = this.data.nachweise.filter(nachweis => !nachweis.file.identifier || (nachweis.file.identifier != d.id))
    //  this.existingFile = null
    this.$emit('input', this.data)
  }

  removeGegenstand(idx) {
    this.data.gegenstaende.splice(idx, 1)
  }

  addGegenstand() {
    this.data.gegenstaende.push(new EinweisungGegenstand());
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
    this.uploadKey++
  }

  get editPermission() {
    if (!this.data.id) {
      return (this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.Create))
    }
    return (this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.Update))
  }

}
