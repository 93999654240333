import { render, staticRenderFns } from "./FehlerAnregung.vue?vue&type=template&id=44644384&scoped=true&"
import script from "./FehlerAnregung.vue?vue&type=script&lang=ts&"
export * from "./FehlerAnregung.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44644384",
  null
  
)

export default component.exports