

import {Route} from "vue-router";
import {Component, Vue} from "vue-property-decorator";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {HistoryEvent} from "@/model/Shared";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";

@Component(
    {
      components: {},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)

export default class EwmpgNavComponent extends mixins(NavMixin) {
  navBar = []
  routeName = null

  created() {
    this.fillNavbar(
        ['ewolist', 'ewohersteller', 'ewogeraeteart', 'ewosoftwareversion', 'ewoobjektbezeichnung', 'ewofilecategory'],
        this.navBar)
    this.handleRoute()
  }
  handleRoute() {
    this.routeName = this.$route.name
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/ewmpg/ewohistory'))
  }
}
