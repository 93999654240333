
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {OutsourcingCategoryDTO, OutsourcingDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";


@Component({components: {Multiselect}})
export default class OutsourcingDataView extends mixins<GenericMixin<OutsourcingDTO, OutsourcingDTO>>(GenericMixin) {
  @Prop() value: OutsourcingDTO;
  @Prop() error: OutsourcingDTO;
  data: OutsourcingDTO = this.$props.value;

  categoryOpts: OutsourcingCategoryDTO[] = []

  availableServices: [] = []
  serviceOpts: any[] = []

  async created() {
    this.categoryOpts = await this.$store.dispatch('outsourcing/fetchKategorien')
    this.availableServices = await this.$store.getters['selectize/getAvailableServices']
    this.initServiceOpts()
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.initServiceOpts();
  }

  serviceOptsChanged(value) {
    const options = Array.isArray(value) ? value : [value];

    this.data.kfzStandort = options.some(option => option.value === 'kfzStandort');
    this.data.medProduktStandort = options.some(option => option.value === 'medProdukteStandort');
    this.data.materialStandort = options.some(option => option.value === 'materialStandort');
    this.data.sonstigesService = options.some(option => option.value === 'sonstigesService');
    this.$emit('input', this.data)

  }

  initServiceOpts() {
    this.serviceOpts = []
    if(this.data.kfzStandort) {
      this.serviceOpts.push({name: 'Fahrzeuge', value: 'kfzStandort'})
    }
    if(this.data.medProduktStandort) {
      this.serviceOpts.push({name: 'Medizinprodukte', value: 'medProdukteStandort'})
    }
    if(this.data.materialStandort) {
      this.serviceOpts.push({name: 'Material', value: 'materialStandort'})
    }
    if(this.data.sonstigesService) {
      this.serviceOpts.push({name: 'Sonstiges', value: 'sonstigesService'})
    }
}
  get categoryOptsByTyp() {
    return this.categoryOpts
  }

}
