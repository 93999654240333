import {AbstractClass} from "@/model/AbstractClasses";
import {AuktionDTO, DienstDTO, OrgUnitDTO, SuchenDTO, SuchenPersonDTO, SuchenPersonTokenDTO, UserResponseDTO, VivendiDienstDTO, VivendiDienstOuMapDTO} from "@/model/dto";

export class Auktion extends AbstractClass implements AuktionDTO {
    static readonly MODUS_LINEAR = 0;
    static readonly MODUS_DIREKT = 1;

    static readonly INFORM_CREATOR = 0;
    static readonly INFORM_EVD = 1;

    additionalOus: OrgUnitDTO[] = [];
    archive: boolean;
    datumEnde: string | null;
    datumStart: string | null;
    diva = true;
    inform: number | null = Auktion.INFORM_CREATOR;
    kommentar: string | null;
    modus: number | null = Auktion.MODUS_LINEAR;
    orgUnit: OrgUnitDTO | null;
    portal = true;
    suchen: SuchenDTO[] = [new Suche()];
    warten: number | null = 15;
    zeitEnde: string | null;
    zeitStart: string | null;
    dienst: DienstDTO | null;
    lock: boolean | null;
    statusText: string | null;
}

export class Suche extends AbstractClass implements SuchenDTO {
    lock: boolean;
    static readonly STATUS_INACTIVE = 0;
    static readonly STATUS_ACTIVE = 1;
    static readonly STATUS_END = 2;

    anzahl: number | null = 1;
    funde: number | null;
    personenkreis: [] = [];
    qualiJump: boolean | null = false;
    status: number | null = Suche.STATUS_ACTIVE;
}

export class SuchenPerson extends AbstractClass implements SuchenPersonDTO {
    static readonly STATUS_NA = 0;
    static readonly STATUS_Angefragt = 1;
    static readonly STATUS_Zugesagt = 2;
    static readonly STATUS_Abgelehnt = 3;
    static readonly STATUS_Ignoriert = 4;

    static readonly STATUS_Kommentar = 5;
    static readonly STATUS_OTHER = 6;


    anfrageTs: string | null;
    antwortTs: string | null;
    ignoreReason: string | null;
    status: number | null;
    suche: SuchenDTO | null;
    auktion: AuktionDTO | null;

    token: string | null;
    user: UserResponseDTO | null;
    rang: number | null;
    statusText: string | null;
    antwortKommentar: string | null;
    link: string | null;
}

export class SuchenPersonToken extends  AbstractClass implements  SuchenPersonTokenDTO {
    kommentar: string | null;
    status: number | null = SuchenPerson.STATUS_Zugesagt;
    token: string | null;
}

export class VivendiDienstOuMap extends AbstractClass implements VivendiDienstOuMapDTO {
    static readonly BLACKLIST = 1;
    static readonly WHITELIST = 2;

    dienst: VivendiDienstDTO | null;
    orgUnit: OrgUnitDTO | null;
    typ: number | null = VivendiDienstOuMap.BLACKLIST;
}