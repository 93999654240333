import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {CrudEvent} from "@/model/AbstractClasses";
import {Event, Module} from "@/model/Constants";
import {FormApi} from "@/services/FormApi";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        requestFilter: {statusId: '', typId: ''},
        forms: [],
        fieldIdentifier: [],
        typOptions: [
            {text: 'Aufgaben', value: Module.TASK},
            {text: 'Ereignisse', value: Module.REPORT},
            {text: 'Einsatzerfassung', value: Module.EINSATZ},
            {text: 'Fahrzeug-Aufgaben', value: Module.KFZ},
            {text: 'Medizinprodukt-Aufgaben', value: Module.MEDPRODUKT},
            {text: 'Material-Aufgaben', value: Module.MATERIAL}
        ],
        typFilter: null
    },
    mutations: {
        SET_REQUEST_FILTER(state: any, data: any) {
            state.requestFilter = data
        },
        SET_FORMS(state: any, data: any) {
            state.forms = data
        },
        SET_FIELD_IDENTIFER(state: any, data: any) {
            state.fieldIdentifier = data
        },
        resetLocationState(state) {
            state.forms = []
        }
    },
    actions: {
        emeldRequestFilterUpdate({commit, state}, value: any) {
            commit('SET_REQUEST_FILTER', value)
        },
        async fetchForms({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.forms && state.forms.length > 0) {
                    resolve(state.forms)
                } else {
                    FormApi.getFormOuChain()
                        .then(data => {
                            commit('SET_FORMS', data)
                            resolve(state.forms)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchFieldIdentifer({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.fieldIdentifier && state.fieldIdentifier.length > 0) {
                    resolve(state.fieldIdentifier)
                } else {
                    FormApi.getFieldIdentifiers()
                        .then(data => {
                            commit('SET_FIELD_IDENTIFER', data)
                            resolve(state.fieldIdentifier)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.formChanged:
                    commit('SET_FORMS', [])
                    break;
            }
        },
    },
    getters: {
        getTypOptions: (state: any) => {
            return state.typOptions;
        },
    },
    modules: {}
}
