
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import UserEditView from "@/views/user/UserEditView.vue";
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {UsersApi} from "@/services/UserApi";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {HistoryEvent} from "@/model/Shared";
import {MaterialRequest} from "@/model/Material";
import {Permission, PermissionModul} from "@/model/dto";
import {FileApi} from "@/services/FileApi";


@Component(
    {
      components: {DatatableButtonHeader, UserEditView},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("userlist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class UserListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  includeChilds = false //childOrgUnits

  getUrl() {
    return '/user/userlist/';
  }

  created() {
    this.archive = this.$route.query.archive !== undefined
  }


  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)


    const request = new MaterialRequest();
    request.archive = this.archive
    request.includeChildOus = this.includeChilds //child orgUnits

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    this.registerEvents([Event.userCreated, Event.userChanged, Event.userArchived]);

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(request);
        },
      }),
      responsive: false,
      rowId: "id",
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      columns: [
        {
          title: 'Nachname',
          class: '',
          data: 'lastname'
        },
        {
          title: 'Vorname',
          class: '',
          data: 'firstname'
        },
        {
          title: 'Dateien',
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return func.getDashboardStatusIcon(data.statusFile, 'file');
          }
        },
        {
          title: 'Termine',
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return func.getDashboardStatusIcon(data.statusDate, 'date');
          }
        },
        {
          title: 'Benutzername',
          class: 'center context-menu',
          data: 'username',
          visible: false
        },
        {
          title: 'Personalnummer',
          class: '',
          data: 'personalNo',
          visible: false
        },
        {
          title: 'Mobil',
          class: '',
          data: 'mobile',
          visible: false
        },
        {
          title: 'Festnetz',
          class: '',
          data: 'phone',
          visible: false
        },
        {
          title: 'E-Mail dienstlich',
          class: '',
          data: 'emailBusiness',
          visible: false
        },
        {
          title: 'E-Mail privat',
          class: '',
          data: 'email',
          visible: false
        },
        {
          title: 'Straße',
          class: '',
          data: 'street',
          visible: false
        },
        {
          title: 'Haus Nr.',
          class: '',
          data: 'houseNo',
          visible: false
        },
        {
          title: 'PLZ',
          class: '',
          data: 'plz',
          visible: false
        },
        {
          title: 'Stadt',
          class: '',
          data: 'city',
          visible: false
        },
        {
          title: 'Geburtsdatum',
          class: '',
          data: 'dateOfBirth',
          render: function (data, type) {
            return data ? moment(data).format('DD.MM.YYYY') : "";
          },
          visible: false
        },
        {
          title: 'Geburtsort',
          class: '',
          data: 'placeOfBirth',
          visible: false
        },
        {
          title: 'Führerscheinnummer',
          class: '',
          data: 'licenseNo',
          visible: false
        },
        {
          title: 'Führerscheinklasse',
          class: '',
          data: 'licenseClass',
          visible: false
        },
        {
          title: 'Austellung',
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.licenseIssuingDate ? moment(data.licenseIssuingDate).format('DD.MM.YYYY') : "";
          },
          visible: false
        },
        {
          title: 'Ausstellende Behörde',
          class: '',
          data: 'licenseIssuingAuthority',
          visible: false
        },
        {
          title: 'kein Fahrzeugführer',
          class: 'text-center',
          data: 'keinFahrzeugfuehrer',
          render: function (data, type) {
            return data ? "X" : "";
          },
          visible: false
        },
        {
          title: 'Li.oN-RFID-Code',
          class: '',
          data: 'rfid.publicId',
          visible: false
        },
        {
          title: 'Datum Eintritt',
          class: '',
          data: 'dateEntry',
          render: function (data, type) {
            return data ? moment(data).format('DD.MM.YYYY') : "";
          },
          visible: false
        },
        {
          title: 'Datum Austritt',
          class: '',
          data: 'dateExit',
          render: function (data, type) {
            return data ? moment(data).format('DD.MM.YYYY') : "";
          },
          visible: false
        },
        {
          title: 'Stellenumfang (%)',
          class: '',
          data: 'jobScope',
          visible: false
        },
        {
          title: 'Externe Nummer',
          class: '',
          data: 'externNo',
          visible: false
        },
        {
          title: 'Vivendi Nummer',
          class: '',
          data: 'vivendiNo',
          visible: false
        },
        {
          title: 'Abrechnungsstandort',
          class: '',
          data: 'invoiceOrgUnit.name',
          visible: false
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons)
    })
    self.table = table
    func.datatableInitComplete(table)

    self.initContextMenu(contextMenuButtons)
    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      if (!self.$store.getters.hasPermission(PermissionModul.User, Permission.Read)) {
        return;
      }
      let rowId = jquery(this).attr('id')
      self.editUser(rowId)
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.User, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.createUser()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.User, Permission.Read,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.editUser(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.editUser(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.User, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/user/userdata', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/user/userdata', tr.attr('id')))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.User, Permission.Archive,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }, contextMenuButtons
    )

    this.addButton(
        PermissionModul.User, Permission.Overview_Chain,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.includeChilds ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.includeChilds ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.includeChilds = !self.includeChilds
            self.init()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.User, Permission.Read,
        {
          titleAttr: 'Standort QR-Codes',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-qrcode" />',
          action: function (e, dt, node, config) {
            FileApi.download('/user/userlist/qr', true, false, true)
          },
        }, myButtons)


  }

  createUser() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/user/create/", query: queryParam})
  }

  editUser(id) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/user/edit/" + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "userlist", query: queryParam})
  }

  destroyed() {
    this.unregisterEvents()
    jquery.contextMenu('destroy')
    this.table.fixedHeader.disable()
  }

  initContextMenu(contextMenuButtons) {
    if (contextMenuButtons.length == 0) return
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr';
    jquery.contextMenu('destroy');
    jquery.contextMenu({selector: selector, items: contextMenuButtons});
  }

  archiv(data) {
    this.$confirm('Sind sie Sicher ?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      UsersApi.archivUser(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.userArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

}
