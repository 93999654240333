
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import {OrgUnitTreeDTO} from "@/model/dto";
import {EmeldApi} from "@/services/EmeldApi";
import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import {Route} from "vue-router";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component(
    {
      components: {OrgUnitSelectorLiquor},
      watch: {
        '$route'(val: Route) {
         this.handleRoute()
        }
      }
    }
)
export default class EmeldLevelTree extends mixins<GenericMixin<null, null>>(GenericMixin, DataProps) {
  selectedId = null
  tree: OrgUnitTreeDTO[] = []
  levelChangedCallback = null


  async created() {
    this.init()
    this.levelChangedCallback = (data) => {
      this.init()
    }

    bus.$on(Event.emeldLevelChanged, this.levelChangedCallback)
  }

  destroyed() {
    bus.$off(Event.emeldLevelChanged, this.levelChangedCallback)
  }

  async init() {
    this.tree = await EmeldApi.getLevelTree()
    this.handleRoute()
  }

  handleRoute() {
    if (this.$props.id) {
      this.selectedId = this.$props.id
    } else {
      this.selectedId = null
    }
  }

  treeClicked(dto) {
    this.selectedId = dto.id
    this.edit(dto.id)
  }

  create() {
    this.selectedId = null
    this.$router.push({path: "/emeld/level/create"})
  }

  edit(levelId) {
    this.$router.push({path: "/emeld/level/edit/" + levelId})
  }

  archiv(data) {
    bus.$emit(Event.emeldLevelRemoveRequest, new ObjectEvent(data.id, data));
  }

}
