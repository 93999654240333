import {
    EntityStandortDTO,
    EwoDTO,
    EwoSoftwareversionDTO,
    FileDTO,
    KfzDTO,
    MedProduktDTO,
    MedProduktEwmpgDateTypDTO,
    MedProduktEwmpgFileCategoryDTO,
    MedProduktQrCodeDTO,
    MedProduktStandortDTO,
    OrgUnitDTO,
    OutsourcingDTO
} from "@/model/dto";
import {AbstractClass, AbstractListItem} from "@/model/AbstractClasses";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {Vue} from "vue-property-decorator";


export class MedProduktEdit extends AbstractListItem implements MedProduktDTO {
    produktionsDatum: string;
    locationText: string;
    additionalInfos: string;

    location: EntityStandortDTO | null;
    outsourcingLocation: OutsourcingDTO | null;
    profilePhoto: FileDTO | null;
    anschaffungsJahr: string | null;
    archive: boolean;
    ausserbetriebnahmeDatum: string | null;
    ewo: EwoDTO | null;
    inbetriebnahmeDatum: string | null;
    inventarNummer: string | null;
    kommentar: string | null;
    name: string | null;
    orgUnit: OrgUnitDTO;
    primStandort: EntityStandortDTO | null;
    qrCode: MedProduktQrCodeDTO | null;
    serienNummer: string | null;
    temporary: boolean;
    udi: string | null;
    softwareVersion: EwoSoftwareversionDTO | null;
    ewoGeraeteart: string;
    ewoHersteller: string;
    ewoObjektbezeichnung: string;
    summary: string;
    childs: MedProduktDTO[];
    holder: boolean;
    differentChilds: string[];
    frontendRef: string;
    deleteChilds: MedProduktDTO[] = [];
    independentChilds: number[];
    existingChilds: MedProduktDTO[];
    parent: MedProduktDTO | null;
    investNummer: string | null;
    createChilds = true;
    archiveReason: string | null;
}


export class MedProduktStandort extends AbstractClass implements MedProduktStandortDTO {
   
    kfz: KfzDTO | null;
    name: string;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;
    temporary: boolean;
}

export class MedProduktHelper {
    static parseTitle(data: MedProduktDTO, separator = "<br>") {
        const arr = []
        if (data.ewoHersteller) arr.push(data.ewoHersteller)
        if (data.ewoGeraeteart) arr.push(data.ewoGeraeteart)
        if (data.ewoObjektbezeichnung) arr.push(data.ewoObjektbezeichnung)
        return arr.join(separator);
    }



    static getTagFromFileCategory(fileCategoryDTO: MedProduktEwmpgFileCategoryDTO) {
        const arr = []

        fileCategoryDTO.medProdukt.forEach((medprodukt) => {
            arr.push([medprodukt, fileCategoryDTO.orgUnit])
        })

        fileCategoryDTO.geraeteart.forEach((geraeteart) => {
            arr.push([geraeteart, fileCategoryDTO.orgUnit])
        })

        if (fileCategoryDTO.ewo) {
            fileCategoryDTO.ewo.forEach((ewo) => {
                arr.push([ewo, null])
            })
        }

        return arr
    }


    static getTagFromDateType(dateTypDTO: MedProduktEwmpgDateTypDTO) {
        const arr = []

        dateTypDTO.medProdukt.forEach((medprodukt) => {
            arr.push([medprodukt, dateTypDTO.orgUnit])
        })

        dateTypDTO.geraeteart.forEach((geraeteart) => {
            arr.push([geraeteart, dateTypDTO.orgUnit])
        })

        if (dateTypDTO.ewo) {
            arr.push([dateTypDTO.ewo, null])
        }

        return arr
    }

    static childOrParentFilter(reference: MedProduktDTO, list: MedProduktDTO[]) {
        return list
            .filter(prod => (prod.parent && reference.parent && prod.parent.id == reference.parent.id) //gleicher parent
                || (prod.parent && (prod.parent.id == reference.id)) //option ist kind von auswahl
                || (reference.parent && prod.id == reference.parent.id) //auswahl ist kind von option
                || (prod.id == reference.id)) //auswahl gleich option
    }

    static getMedProduktTagHtml(medProdukt: MedProduktDTO) {
        const listItem = new Vue({
            // eslint-disable-next-line vue/no-unused-components
            components: { ListItemComponent },
            template: `<list-item-component :dto="dto" :box="true" />`,
            data: () => ({ dto: medProdukt })
        }).$mount()

        return listItem.$el.outerHTML
    }
}

export const notAllowedText = "Keine Zulassung für gewerblichen Einsatz";
