
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {FileCategoryDTO, MedProduktFileDTO, Permission, PermissionModul} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import {MedProduktFile} from "@/model/File";
import MedProduktFileEditModal from "@/views/medprodukt/modal/MedProduktFileEditModal.vue";
import {MedProduktHelper} from "@/model/MedProdukt";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {Module} from "@/model/Constants";


@Component(
    {
      components: {DatatableButtonHeader, MedProduktFileEditModal},
    }
)
export default class MedProduktFileView extends Vue {
  @Prop() private id: string;
  @Prop() private category: FileCategoryDTO | null;

  tableId = 'dTable_' + uuid();
  data: MedProduktFileDTO | null = null
  modalId = 'medprodukt-file-edit-modal'
  helper = MedProduktHelper

  newFile() {
    this.data = new MedProduktFile()
    this.data.fileCategories = this.$props.category ? [this.$props.category] : null;
    this.$bvModal.show(this.modalId)
  }


  edit(id) {
    if (!this.editPermission) {
      return;
    }
    if (id.includes('_')) {
      let tmpArr = id.split("_")
      id = tmpArr[0]
      let rowId = "#" + id;
      let table = jquery('#' + this.tableId).DataTable();
      let rowdata: MedProduktFileDTO = table.row(rowId).data();
      this.data = rowdata.childs.find(child => child.id == tmpArr[1])
    } else {
      let rowId = "#" + id;
      let table = jquery('#' + this.tableId).DataTable();
      let rowdata = table.row(rowId).data();
      this.data = rowdata
    }


    this.$bvModal.show(this.modalId)
    this.modalId = 'medprodukt-file-edit-modal' + uuid()
  }

  updateTable(data) {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
    /*
        let rowId = "#" + data.frontendRef;
        let table = jquery('#' + this.tableId).DataTable();
        if (table.row(rowId).data()) {
          table.row(rowId).data(data).draw();
        }
        */
  }


  mounted() {
    this.$root.$emit("loadingStart")
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    const buttons = (this.editPermission) ? [
      {
        className: 'text-secondary bg-primary border-transparent',
        titleAttr: 'Hinzufügen',
        text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
        action: function (e, dt, node, config) {
          self.newFile()
        },
      },
      {
        className: 'text-secondary bg-white',
        titleAttr: 'Betrifft',
        text: '<i class="fas fa-angle-double-right" />',
        action: function (e, dt, node, config) {
          self.openModal()
        }
      }] : []

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/medprodukt/medproduktfile/filecategory/' + self.id,
        type: 'GET'
      }),
      rowId: "id",
      order: [3],
      columnDefs: [
        {
          targets: 0,
          "createdCell": function (td, cellData, rowData, row, col) {
            if (rowData.childs.length === 0) {
              jquery(td).removeClass('dt-control')
            }
          }
        },
        {'orderData': [3], 'targets': [2]},
        {'sortable': true, 'searchable': false, 'visible': false, 'type': 'num', 'targets': [4]}
      ],
      select: false,
      columns: [
        {
          className: 'dt-control not-export-col',
          orderable: false,
          data: null,
          defaultContent: ''
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return self.helper.getMedProduktTagHtml(data.medProdukt)
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return data.icon + "<span class='d-none'>" +data.status + "</span>"
          }
        },
        {
          class: 'center',
          data: 'skipReason'
        },
        {
          class: '',
          data: 'statusNumeric'
        },
        {
          class: 'text-center cw-100',
          data: null,
          render: function (data, type, row) {
            if (!self.downloadPermission) return ""

            return getFileLink(data)
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return getValidUntil(data)
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return getUploadTs(data)
          }
        },
        {
          class: '',
          data: 'comment'
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return [...new Set(data.categoryMap.map(c => c.orgUnit ? c.orgUnit.name : "EWO"))].join(", ")
          }
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(buttons, true)
    })

    func.datatableInitComplete(table)

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function (e) {
      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }
      let rowId = jquery(this).attr('id')
      if (!rowId) return;
      self.edit(rowId)
    })

    jquery('#' + this.tableId + ' tbody').on('click', '.download', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      let myId = jquery(this).attr('file')

      self.download(myId, Module.MEDPRODUKT)
    });

    // Add event listener for opening and closing details
    table.on('click', 'td.dt-control', async function (e) {
      let tr = e.target.closest('tr');
      let row = table.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row

        const childRow = openChildContent(row.data());
        row.child(childRow).show();
      }
    });
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Files, this.category.confidential ? Permission.Update_Vertrauliche : Permission.Update)
  }

  get downloadPermission() {
    if (this.category.audit && !this.$store.getters.hasPermission(PermissionModul.Audit, Permission.Access)) {
      return true
    }

    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Files, this.category.confidential
        ? Permission.Access_Vertraulich : Permission.Access)
  }

  openModal() {
    this.$emit('openModal')
  }
}

function openChildContent(d: MedProduktFileDTO) {
  let str = '<table id="myChildTable" class="table-sm my-3 ml-5" style="width: 90%">'
  d.childs.sort((a,b) => a.statusNumeric > b.statusNumeric ? -1 : 1).forEach(child => str += getChildRow(child, d));
  str += '</table>';

  return str;
}

function getChildRow(d: MedProduktFileDTO, parent: MedProduktFileDTO) {
  let medProdukt = d.medProdukt


  return (
      '<tr  id="' + parent.id + '_' + d.id + '">' +
      '<td class="cw-400">' + MedProduktHelper.getMedProduktTagHtml(medProdukt) + '</td>' +
      '<td class="text-center">' + (d.icon) + '</td>' +
      '<td class="cw-100 text-center">' + getFileLink(d) + '</td>' +
      '<td>' + getValidUntil(d) + '</td>' +
      '<td>' + getUploadTs(d) + '</td>' +

      '</tr>'
  );

}

function getFileLink(data) {
  if (data.childs && !data.file) {
   // return "<span class='font-italic'>siehe Teilprodukte</span>"
  }
  return data.downloadFile != null ? '<button file="' + data.downloadFile.id + '" class="btn btn-outline-info download" type="button"><i class="fas fa-download" /></button>' : ''
}


function getValidUntil(data) {
  return data.validUntil ? moment(data.validUntil).format("DD.MM.YYYY") : ""
}

function getUploadTs(data) {
  return data.uploadDate ? moment(data.uploadDate).format("DD.MM.YYYY") : "";
}

