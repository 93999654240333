import { render, staticRenderFns } from "./EwoFileCategoryEwoView.vue?vue&type=template&id=dadf5872&scoped=true&"
import script from "./EwoFileCategoryEwoView.vue?vue&type=script&lang=ts&"
export * from "./EwoFileCategoryEwoView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dadf5872",
  null
  
)

export default component.exports