
import {Component, Prop} from 'vue-property-decorator';
import Multiselect from "@/libs/multiselect";
import Vue from "vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

@Component({
  components: {ListItemComponent, Multiselect},
})
export default class HygieneDctTriple extends Vue {
  @Prop() private id: string;
  @Prop() value
  @Prop() private error: any;


  disinfecantOpts = []
  concentrationOpts = []
  einwirkZeitOpts = []

  async created() {
    this.disinfecantOpts = await this.$store.dispatch("hygiene/fetchDisinfectant")
    this.concentrationOpts = await this.$store.dispatch("hygiene/fetchConcentration")
    this.einwirkZeitOpts = await this.$store.dispatch("hygiene/fetchEinwirkZeit")
  }


  get workValue() {
    return this.value
  }

  set workValue(val) {
    this.$emit("input", val)
  }

}
