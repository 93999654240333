import ManualView from "@/views/manual/ManualView.vue";
import ManualTables from "@/views/manual/ManualTables.vue";
import {Permission, PermissionModul} from "@/model/dto";

export const manualRoutes = [
    {
        path: 'manual',
        name: 'manual',
        redirect: '/manual/manualoverview',
        component: ManualView,
        children: [
            {
                path: 'manualoverview',
                name: 'manuallist',
                component: ManualTables,
                meta: {
                    loading: true,
                    datatable: true,
                    title: 'Handbücher',
                    permission: [PermissionModul.Manual, Permission.CRUD]
                }
            }
        ],
        meta : {

        }
    }
]
