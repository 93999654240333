
import PincodeInput from 'vue-pincode-input';
import UserService from '@/services/UserService';
import {Component, Vue} from 'vue-property-decorator';
import {func} from '@/scripts/scripts'
import QrCodeReader from '@/components/QrCodeReader.vue';

@Component({
  components: {
    PincodeInput, QrCodeReader
  },
})


export default class QrLoginView extends Vue {
  qrcode = ''
  pincode = ''
  sError = ''
  modus = 0


  onScan() {
    UserService.checkQrLogin(this.qrcode).then(response => {
      this.modus = 1
    })
        .catch(e => {
          e = e.raw
          if (e.response.status === 404) {
            this.pincode = ''
            this.$alert('Der QR-Code konnte nicht zugeordnet werden!')
          } else if (e.response.status === 400) {
            this.pincode = ''
            this.$alert('Bitte loggen Sie sich mit Ihren normalen Zugangsdaten ein und legen Sie dann durch einen Klick auf das Benutzersymbol oben rechts einen Pin fest.',
                'Kein Pin festgelegt!', 'info')
          } else {
            this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
          }
        })
  }


  onSubmit() {
    if (this.pincode.length !== 4) {
      return true
    }
    console.log(this.pincode)

    this.sError = ''

    var location: any
    if (func.isLocalStorage()) {
      location = localStorage.getItem('location')
    } else {
      location = 0
    }


    var payload = {
      qrCode: this.qrcode,
      pin: this.pincode,
      location: location,
    }
    UserService.login(payload)
        .then(response => {
          if (response.data && response.data.token && response.data.ou && response.data.user) {
            this.$store.dispatch('login', response.data)

            if (this.$store.getters['menu/getCalledRoute']) {
              this.$router.push(this.$store.getters['menu/getCalledRoute'])
            } else {
              if (response.data.forcePasswordChange) {
                this.$router.push('/userpassword/1')
              } else if (response.data.forceProfileCheck) {
                this.$router.push('/profile/1')
              } else {
                this.$router.push('/')
              }
            }
          } else {
            this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
          }
        })
        .catch(e => {
          console.log('error')
          if (e.raw.response.status === 401) {
            this.pincode = ''
            this.sError = 'Zugangsdaten ungültig!'
          } else {
            this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
          }
        })
  }
}
