
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {CheckinApi} from "@/services/CheckinApi";
import {CheckinRequestDTO} from "@/model/dto";

@Component
export default class AdminDashboardCheckinCard extends Vue {
  @Prop() chain!: boolean
  openCheckins = 0
  data = null
  loading = true

  created() {
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    const params: CheckinRequestDTO = {
      userId: null,
      ouId: this.$store.getters.getLocation.id,
      dateFrom: null,
      dateTo: null,
      onlyUnconfirmed: true,
      archive: false,
      ouChain: this.chain,
      countOnly: true
    };

    this.data = await CheckinApi.getCheckins(params);
    this.openCheckins = this.data.count;
    this.loading = false
  }

  routeCheckin() {
    this.$router.push("/einsatz/checkinoverview")

  }
}
