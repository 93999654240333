import {LoginDTO, OrgUnitMerkmalDTO, PermissionDTO, PermissionGroupDTO, UserPermissionDTO} from "@/model/dto";
import {AbstractClass} from "@/model/AbstractClasses";

export class Login extends AbstractClass implements LoginDTO  {
    location: number | null;
    password: string | null;
    pin: string | null;
    qrCode: string | null;
    username: string | null;
}

export class UserPermission implements UserPermissionDTO {
    ouId: number;
    permissionGroup: number;
    userId: number;
    passOnChilds: boolean;


    constructor(permissionGroup: number, userId: number, ouId: number, passOnChilds = false) {
        this.ouId = ouId;
        this.permissionGroup = permissionGroup;
        this.userId = userId;
        this.passOnChilds = passOnChilds;
    }
}

export class PermissionGroupCheckbox {
    public state: boolean;
    public pgId: number;
    public forcedPassOnChilds: boolean;
    public passOnChilds: boolean;


    constructor(state: boolean, pgId: number, forcedPassOnChilds: boolean, passOnChilds: boolean) {
        this.state = state;
        this.pgId = pgId;
        this.forcedPassOnChilds = forcedPassOnChilds;
        this.passOnChilds = passOnChilds;
    }
}

export class PermissionGroup extends  AbstractClass implements PermissionGroupDTO {
    name: string;
    passOnChilds: boolean | null;
    permissions: PermissionDTO[] = [];
    ouMerkmale: OrgUnitMerkmalDTO[];
    sortOrder: number | null;
    additionalText: string | null;
    foreignPermissions: PermissionDTO[] = [];
}