
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import Multiselect from "@/libs/multiselect";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import DateForm from "@/components/DateForm.vue";
import {_} from 'vue-underscore';
import {HistoryEvent} from "@/model/Shared";
import {Permission, PermissionModul} from "@/model/dto";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";


@Component(
    {
      computed: {
        bus() {
          return bus
        }
      },
      components: {DateForm, Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class HygieneNavComponent extends mixins(NavMixin) {
  routeName = null
  onChangeDebounced = null

  navBar = []
  navBarSettings = []

  async created() {
    this.fillNavbar(
        ['desilist'],
        this.navBar)

    this.fillNavbar(
        ['desiadvice', 'desiroutine', 'ampel', 'concentration', 'scopes',
          'protection', 'einwirkzeit', 'disinfectant', 'disease', 'inheritance'], this.navBarSettings)

    this.handleRoute()
    this.onChangeDebounced = _.debounce(() => {
      bus.$emit(Event.hygieneFilterChanged)
    }, 1500)
  }

  handleRoute() {
    this.routeName = this.$route.name
  }
  get doneUndone() {
    return this.$store.state.hygiene.requestFilter.onlyUnconfirmed
  }
  set doneUndone(val) {
    console.log(val);
    this.$store.state.hygiene.requestFilter.onlyUnconfirmed = val;
    this.onChangeDebounced()
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/hygiene/history'))
  }


  get datefrom() {
    return this.$store.state.hygiene.requestFilter.dateFrom
  }

  set datefrom(val) {
    this.$store.state.hygiene.requestFilter.dateFrom = val
    this.onChangeDebounced()
  }

  get dateto() {
    return this.$store.state.hygiene.requestFilter.dateTo
  }

  set dateto(val) {
    this.$store.state.hygiene.requestFilter.dateTo = val
    this.onChangeDebounced()
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Hygiene_Settings, Permission.History)
  }
}
