
import UserService from '@/services/UserService'
import PincodeInput from 'vue-pincode-input';
import {Component, Vue} from "vue-property-decorator";
import {func} from '@/scripts/scripts'
import {bus} from "@/main";
import {Event} from "@/model/Constants";

const GreetingProps = Vue.extend({
  props: {
    user: null
  }
})

@Component({
  components: {
    PincodeInput
  },
})
export default class SwitchUserByPinView extends GreetingProps {
  pincode = ''
  sError = ''
  oUser = null

  async mounted() {
    this.oUser = this.$props.user

  }

  onSubmit() {
    if (this.pincode.length !== 4) {
      return true
    }

    this.sError = ''

    var location: any
    if (func.isLocalStorage()) {
      location = localStorage.getItem('location')
    } else {
      location = 0
    }

    var payload = {
      id: this.oUser.id,
      pin: this.pincode,
      location: location
    }


    UserService.login(payload).then(response => {
      if (response.data && response.data.token && response.data.ou && response.data.user) {
        this.$store.dispatch('login', response.data)
        bus.$emit(Event.chatRead)

        this.$router.push('/checkinlist')
      } else {
        this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
      }
    }).catch(e => {
      if (e.raw.response.status === 401) {
        this.pincode = ''
        this.sError = 'Zugangsdaten ungültig!'
      } else {
        this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
      }
    })
  }
}
