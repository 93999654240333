
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {Permission, PermissionModul, TagDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {Tag} from "@/model/Emeld";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import FormCreateModal from "@/views/form/components/FormCreateModal.vue";
import {Event, Module} from "@/model/Constants";
import {bus} from "@/main";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    FormCreateModal,
    PersonenkreisChooser,
    Multiselect
  }
})
export default class EmeldTagDataView extends mixins<GenericMixin<TagDTO, TagDTO>>(GenericMixin) {
  @Prop() value: Tag;
  @Prop() error: Tag;
  data: Tag = this.$props.value;

  loadingComplete = false

  formOptions = []
  personenkreisOptions = []

  showFormCreateModal = false

  async created() {
    window.scrollTo(0, 0);
    this.init()
  }


  async init() {
    this.formOptions = await this.$store.dispatch("form/fetchForms")
    this.formOptions = this.formOptions.filter(form => !form.typ || form.typ == 2)

    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")

    this.loadingComplete = true
  }


  newForm() {
    bus.$off(Event.formTakeOver)
    bus.$on(Event.formTakeOver, (formEvent) => {
      this.data.form = formEvent.data.form
    })

    this.showFormCreateModal = true
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }

  get formReleasePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Emeld_Settings, Permission.Formulare_Release)
  }

}
