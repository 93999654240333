
import {Component, Vue} from "vue-property-decorator";
import Multiselect from "@/libs/multiselect";
import EinweisungOverviewTable from "@/views/einweisung/EinweisungOverviewTable.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";


@Component(
    {
      components: {
        ListItemComponent,
        EinweisungOverviewTable,
        Multiselect

      }
    }
)
export default class EinweisungOverview extends Vue {

}
