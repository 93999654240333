import {CheckinDTO, DienstDTO, KfzKennungDTO, KfzResponseDTO, OrgUnitDTO, PermissionGroupDTO, PersonenkreisDataDTO, QualificationDTO, SkillDTO, UserResponseDTO} from "@/model/dto";
import {AbstractListItem} from "@/model/AbstractClasses";


export class PersonenkreisData extends AbstractListItem implements PersonenkreisDataDTO {
    frontendRef: number | null;
    group: string | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    ouText: string | null;
    qualification: QualificationDTO;
    skill: SkillDTO;
    specialTyp: number | null;
    temporary: boolean;
    user: UserResponseDTO | null;
    qualis: QualificationDTO[];
    skills: SkillDTO[];
    users: UserResponseDTO[];
    archive: boolean;
    permissionGroup: PermissionGroupDTO;
    passOnChilds: boolean;
    dienst: DienstDTO | null;
    checkin: CheckinDTO | null;
    kennung: KfzKennungDTO | null;
    kfz: KfzResponseDTO | null;
}