
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {LevelDTO, OrgUnitTreeDTO} from "@/model/dto";
import {EmeldApi} from "@/services/EmeldApi";
import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import {Route} from "vue-router";
import Multiselect from "@/libs/multiselect";
import {LevelOu} from "@/model/Emeld";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component(
    {
      components: {PersonenkreisChooser, Multiselect, OrgUnitSelectorLiquor},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
          this.$root.$emit('loadingDone')
        }
      }
    }
)
export default class EmeldLevelOuTree extends mixins<GenericMixin<null, null>>(GenericMixin, DataProps) {
  selectedId = null
  tree: OrgUnitTreeDTO[] = []
  levelChangedCallback = null


  //Für Dialog
  levels: LevelDTO[] = []
  levelToImport = null
  zustaendigkeitToImport = null
  showImportLevelModal = false
  personenkreisOptions = []

  async created() {
    this.init()
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")

    this.levelChangedCallback = (data) => {
      this.init()
    }

    bus.$on(Event.emeldLevelOuChanged, this.levelChangedCallback)
  }

  destroyed() {
    bus.$off(Event.emeldLevelOuChanged, this.levelChangedCallback)
  }

  async init() {
    this.tree = await EmeldApi.getLevelOuTree()
    this.levels = await this.$store.dispatch("emeld/fetchLevelList")


    this.handleRoute()
    this.$root.$emit('loadingDone')
  }

  handleRoute() {
    if (this.$props.id) {
      this.selectedId = this.$props.id
    } else {
      this.selectedId = null
    }
  }

  treeClicked(dto) {
    this.selectedId = dto.level.id
    this.edit(dto.level.id)
  }

  create() {
    this.selectedId = null
    this.$router.push({path: "/emeld/levelou/create"})
  }

  edit(levelId) {
    this.$router.push({path: "/emeld/levelou/edit/" + levelId})
  }

  archiv(data) {
    bus.$emit(Event.emeldLevelRemoveRequest, new ObjectEvent(data.id, data));
  }

  addExistingLevel() {
    let dto = new LevelOu()
    dto.level = this.levelToImport
    dto.own = false
    dto.zustaendig = this.zustaendigkeitToImport
    EmeldApi.addLevelToOu(this.levelToImport.id, dto)
        .then(data => {
          if (data instanceof ApiResponse && data.data != undefined) {
            bus.$emit(Event.emeldLevelOuChanged, new ObjectEvent(data.data.id, data.data));
            this.showImportLevelModal = false;
            this.levelToImport = null;

            this.$router.push({path: "/emeld/levelou/edit/" + data.data.level.id})
          } else {
            this.$alert("Die Kategorie konnte nicht hinzugefügt werden", "Fehler", "error")
          }
        })
        .catch((e) => {
          this.$alert("Die Kategorie konnte nicht hinzugefügt werden", "Fehler", "error")
        })
  }

  get importableLevelList() {
    return this.levels.filter(level => !this.tree.map(node => node.dto.level.id).includes(level.id))
  }

  get treeOptions() {
    if (this.$store.state.emeld.requestFilter.klasse) {
      return this.tree.filter(data => data.dto.level.klasse && data.dto.level.klasse.id == this.$store.state.emeld.requestFilter.klasse.id)
    }

    return this.tree
  }

}
