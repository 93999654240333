
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {TagOuDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import EmeldNotificationDataComponent from "@/views/emeld/components/EmeldNotificationDataComponent.vue";
import EmeldTagDataView from "@/views/emeld/data/EmeldTagDataView.vue";
import {Tag} from "@/model/Emeld";

@Component({
  computed: {
    Tag() {
      return Tag
    }
  },
  components: {
    EmeldTagDataView,
    EmeldNotificationDataComponent,
    PersonenkreisChooser,
    Multiselect
  }
})
export default class EmeldTagOuDataView extends mixins<GenericMixin<TagOuDTO, TagOuDTO>>(GenericMixin) {
  @Prop() value: TagOuDTO;
  @Prop() error: TagOuDTO;
  @Prop() own: boolean;

  data: TagOuDTO = this.$props.value;

  loadingComplete = false

  formOptions = []
  personenkreisOptions = []

  async created() {
    window.scrollTo(0, 0);
    this.init()
  }


  async init() {
    this.loadingComplete = true
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }

}
