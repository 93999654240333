

import {Component, Vue} from "vue-property-decorator";
import {DateHelper} from "@/model/Date";
import {FobiApi} from "@/services/FobiApi";
import jquery from "jquery";

@Component({
  components: {}
})
export default class UserDashboardCampusCard extends Vue {
  data = null
  dateHelper = DateHelper
  donePromise;

  filteredData = [];

  async created() {
    this.donePromise = new Promise((resolve, reject) => {
      let userId = this.$store.getters.getUser.id;
      FobiApi.getCampusOverview(userId)
          .then(data => {
            this.data = data.overview;

            this.filteredData = Object.values(this.data)
                .map(overview => {
                  const user = Object.values(overview.users)[0];
                  if (user.missing || user.expired) {
                    return {
                      course: overview.course,
                      expired: user.expired,
                      expiryDate: user.expiryDate,
                      status: user.expired ? "Offen" : "Offen",
                    };
                  }
                })
                .filter(entry => entry !== undefined);

            this.$nextTick(() => {
              jquery('#campuscontent').children().appendTo("#myCards");
              resolve(this.filteredData.length);
            });
          })
          .catch(error => {
            reject(error);
          });
    });
  }

  getPromise() {
    return this.donePromise
  }
}
