
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event, UserId} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {CheckinRequest} from "@/model/Checkin";
import {CheckinApi} from "@/services/CheckinApi";
import {HistoryEvent} from "@/model/Shared";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("checkinoverviewlist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit('loadingDone')
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()

        }
      }
    }
)
export default class CheckinListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false
  requestDTO = null
  reloadFunction = (dto) => {
    this.$root.$emit("loadingStart")
    this.reloadTable()
  }


  getUrl() {
    this.requestDTO = this.$store.state.einsatz.requestFilter.formDataToRequest(new CheckinRequest())
    this.requestDTO.ouId = this.$store.getters.getLocation.id
    this.requestDTO.archive = this.archive

    return '/checkin/checkin/';
  }

  created() {
    bus.$on(Event.einsatzFilterChanged, this.reloadFunction)
  }

  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    this.registerEvents([Event.checkinCreated, Event.checkinArchived, Event.checkinChanged]);

    var p = jquery(".dt-button-bar").last();
    const dateHelper = DateHelper


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(self.requestDTO);
        },
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      stateSave: false,
      responsive: false,
      rowId: "id",
      columns: [
        {
          title: 'ID',
          class: '',
          data: 'id',
          visible: true
        },
        {
          title: 'Prüfung',
          data: null,
          class: 'text-center',
          render: function (data, type, row) {
            if (!data.reviewed) {
              if (!self.$store.getters.hasPermission(PermissionModul.Checkin,
                  Permission.Validate)) {
                return "ausstehend";
              }
            }
            return data.reviewed ? data.reviewer.formatted + "<i class='fas fa-2x fa-hexagon-check text-success text-center mr-3' title='Bestätigt'></i>" : " <i class='far fa-2x fa-hexagon text-danger dt-check text-center mr-3' title='Bestätigen'></i>"
          },
          visible: true
        },
        {
          title: 'Schichtbeginn',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.datumStart)
          },
          visible: true
        },
        {
          title: 'Uhrzeit Beginn',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseTime(data.zeitStart)
          },
          visible: true
        },
        {
          title: 'Km Start',
          class: '',
          data: 'kmStart',
          visible: true
        },
        {
          title: 'Schichtende',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.datumEnde)
          },
          visible: true
        },
        {
          title: 'Uhrzeit Ende',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseTime(data.zeitEnde)
          },
          visible: true
        },
        {
          title: 'Km Ende',
          class: '',
          data: 'kmEnde',
          visible: true
        },
        {
          title: 'Fahrzeug',
          class: '',
          data: 'kfz.text',
          visible: false
        },
        {
          title: 'Dienst',
          class: '',
          data: 'dienst.name',
          visible: true
        },
        {
          title: 'Besatzung',
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.checkinUsers
                .filter(u => u.user)
                .map(u => (u.user.id == UserId.FREITEXT ? u.userText : u.user.formattedShort) + " (" + u.position + ")")
                .join(", ")
          },
          visible: true
        },
        {
          title: 'Checkout',
          data: null,
          class: 'text-center',
          render: function (data, type, row) {
            return data.checkout ? "X" : ""
          },
          visible: false
        },
        {
          title: 'Fahrer ohne Erlaubnis',
          data: null,
          class: 'text-center',
          render: function (data, type, row) {
            return data.ohneFahrerlaubnis ? "X" : ""
          },
          visible: false
        },
        {
          title: 'Aufgaben offen',
          class: 'text-center',
          data: 'undoneTaskCount',
          visible: true
        },
        {
          title: 'Aufgaben gesamt',
          class: 'text-center',
          data: 'totaltaskCount',
          visible: false
        },
        {
          title: 'Kommentar Beginn',
          class: 'cw-100',
          data: 'textStart',
          visible: false
        },
        {
          title: 'Kommentar Ende',
          class: 'cw-100',
          data: 'textEnde',
          visible: false
        },
        {
          title: 'Kommentar Prüfung',
          class: 'cw-100',
          data: 'textReview',
          visible: false
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons)
    })
    self.table = table
    func.datatableInitComplete(table)
    self.initContextMenu(contextMenuButtons)

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })

    jquery('#' + self.tableId).on('click', '.dt-check', function (e) {
      e.preventDefault()
      let tr = jquery(this).closest('tr')
      let row = jquery('#' + self.tableId).DataTable().row(tr)

      let checkinId = row.data().id

      const payload = row.data()
      payload.reviewed = true

      const p = CheckinApi.putCheckin(payload);
      self.handleApiRequest(p, true).then((data) => {
        bus.$emit(Event.checkinChanged, new ObjectEvent(checkinId, data.data));
      }).catch(() => {
        self.$alert("Zugriff verweigert!", "Fehler", "error");
      })
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Checkin, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Checkin, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/checkin/checkin', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/checkin/checkin', tr.attr('id')))
          }
        }, contextMenuButtons)

  }


  edit(id) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }

    if (!this.$store.getters.hasPermission(PermissionModul.Checkin,
        Permission.Overview)) {
      return;
    }

    this.table.fixedHeader.disable()
    this.$router.push({path: "/einsatz/checkin/edit/" + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "checkinlist", query: queryParam})
  }

  destroyed() {
    bus.$off(Event.einsatzFilterChanged, this.reloadFunction)
    this.table.fixedHeader.disable()
    this.unregisterEvents()
  }


  archiv(data) {
    bus.$emit(Event.checkinArchiveRequest, new ObjectEvent(data.id, data));
  }

}
