import FileLexView from "@/views/file/FileLexView.vue";
import FileLexCategoryView from "@/views/file/FileLexCategoryView.vue";
import EmeldEmpty from "@/views/emeld/components/EmeldEmpty.vue";
import FileLexTreeView from "@/views/file/FileLexTreeView.vue";
import FileLexTreeConfig from "@/views/file/FileLexTreeConfig.vue";
import FileLexTreeCreate from "@/views/file/data/FileLexTreeCreate.vue";
import FileLexTreeEdit from "@/views/file/data/FileLexTreeEdit.vue";
import FileLexTreeDocs from "@/views/file/FileLexTreeDocs.vue";
import FileLexList from "@/views/file/FileLexList.vue";
import {Permission, PermissionModul} from "@/model/dto";
import CloudSettings from "@/views/file/CloudSettings.vue";
import CloudView from "@/views/file/CloudView.vue";
import FileView from "@/views/file/FileView.vue";

export const fileLexRoutes = [
    {
        path: 'doc/:id',
        name: 'doc',
        component: FileView,
        meta: {},
        props: true
    },
    {
        path: 'docs',
        name: 'docs',
        component: FileLexView,
        children: [
            {
                path: 'cloudsettings',
                name: 'cloudsettings',
                component: CloudSettings,
                meta: {
                    loading: true,
                    title: 'Dateieingang-Konfiguration',
                    permission: [PermissionModul.Cloud, Permission.Config]
                }
            },
            {
                path: 'cloud',
                name: 'filecloud',
                component: CloudView,
                meta: {
                    loading: true,
                    title: 'Dateieingang',
                    permission: [PermissionModul.Cloud, Permission.Access]
                }
            },
            {
                path: 'tree',
                name: 'filelextree',
                component: FileLexTreeView,
                children: [
                    {
                        path: 'docs',
                        name: 'filelexdocs',
                        components: {
                            tree: FileLexTreeDocs,
                            treeContent: EmeldEmpty
                        },
                        props: {
                            default: false
                        },
                        meta: {
                            loading: false,
                            title: 'Übersicht',
                            permission: [PermissionModul.FILELEX, Permission.Overview]
                        }
                    },
                    {
                        path: 'docs/:id',
                        name: 'filelexdocs',
                        components: {
                            tree: FileLexTreeDocs,
                            treeContent: FileLexList
                        },
                        props: {
                            default: false, treeContent: true, tree: true,
                        },
                        meta: {
                            loading: false,
                            title: 'Übersicht',
                            permission: [PermissionModul.FILELEX, Permission.Overview]
                        }
                    },
                    {
                        path: 'config',
                        name: 'filelextreeconfig',
                        components: {
                            tree: FileLexTreeConfig,
                            treeContent: EmeldEmpty
                        },
                        props: {
                            default: false,
                        },
                        meta: {
                            title: 'Baumstruktur',
                            permission: [PermissionModul.FILELEX, Permission.Config]
                        }
                    },
                    {
                        path: ':id/create',
                        name: 'filelextreecreatechild',
                        components: {
                            tree: FileLexTreeConfig,
                            treeContent: FileLexTreeCreate
                        },
                        props: {
                            default: false, treeContent: true, tree: true
                        },
                        meta: {
                            permission: [PermissionModul.FILELEX, Permission.Config]
                        }
                    },
                    {
                        path: 'create',
                        name: 'filelextreecreate',
                        components: {
                            tree: FileLexTreeConfig,
                            treeContent: FileLexTreeCreate
                        },
                        props: {
                            default: false, treeContent: true, tree: false
                        },
                        meta: {
                            permission: [PermissionModul.FILELEX, Permission.Config]
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'filelextreeedit',
                        components: {
                            tree: FileLexTreeConfig,
                            treeContent: FileLexTreeEdit
                        },
                        props: {
                            default: false, treeContent: true, tree: true
                        },
                        meta: {
                            permission: [PermissionModul.FILELEX, Permission.Config]
                        }
                    },
                ]
            },
            {
                path: 'filelexcategorycatalog',
                name: 'filelexcategorycatalog',
                component: FileLexCategoryView,
                meta: {
                    loading: true,
                    title: 'Kategorie-Katalog',
                    permission: [PermissionModul.FILELEX, Permission.Config]
                }
            },
        ],
        meta: {}
    }
]
