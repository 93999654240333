import {CountryDTO, EwmpgDateTypDTO, EwoDTO, EwoGeraeteartDTO, EwoHerstellerDTO, EwoObjektbezeichnungDTO, EwoSoftwareversionDTO, FileDTO} from "@/model/dto";
import {AbstractClass, AbstractListItem} from "@/model/AbstractClasses";


export class EwoHersteller extends AbstractClass implements EwoHerstellerDTO {
    archive: boolean;
    city: string | null;
    email: string | null;
    houseNo: string | null;

    name: string;
    phone: string | null;
    plz: string | null;
    street: string | null;
    hersteller: boolean;
    lieferant: boolean;
    website: string | null;
    country: CountryDTO | null;
}

export class EwoBaseDataGeneric extends AbstractClass implements EwoGeraeteartDTO, EwoSoftwareversionDTO, EwoObjektbezeichnungDTO {
    archive: boolean;

    name: string;
    icon: string | null;
    searchText: string | null;
    text: string | null;
    type: string | null;
    typeText: string | null;
    iconPlain: string | null;
}

export class EwoEdit extends AbstractListItem implements EwoDTO {
    dates: EwmpgDateTypDTO[];
    archive = false;
    ceNummer: string | null;
    einweisungsPflicht: number;
    geraeteart: EwoGeraeteartDTO | null;
    hersteller: EwoHerstellerDTO | null;
    kombi: boolean;
    maxNutzung: number | null;
    objektbezeichnung: EwoObjektbezeichnungDTO | null;
    softwareversionen: EwoSoftwareversionDTO[];
    verbot: boolean;
    softwareSummary: string | null;
    summary: string | null;
    einzelEwo: EwoDTO[];
    kombiTyp = 0;

    static getEinweisungspflicht(einweisungspflicht) {
        switch(einweisungspflicht) {
            case 0: return "Unterweisungspflicht (Medizinprodukt §4 MPBetreibV)";  break;
            case 1: return "Einweisungspflicht (Medizinprodukt §10 MPBetreibV)";  break;
            case 2: return "Andere Regelung"; break;
        }
        return ""
    }

    static getKombiTyp(param) {
        switch(param) {
            case 0: return "Als Einzelprodukt und als Teilprodukt verwendbar";  break;
            case 1: return "Nur als Einzelprodukt verwendbar";  break;
            case 2: return "Nur als Teil eines Kombiprodukts verwendbar"; break;
        }
        return ""
    }

    profilePhoto: FileDTO | null;
    maxAlter: number | null;
}
