import axios, {AxiosResponse} from "axios";
import {
    AbstractDTO,
    AbstractEntityTaskFormRequestDTO,
    AbstractListItemDTO,
    AdditionalFieldDTO,
    AdditionalFieldResultDTO,
    DienstDTO,
    EntityFileDTO,
    ErrorDTO,
    EwoGeraeteartDTO,
    FileDTO,
    KfzKennungDTO,
    KfzResponseDTO,
    MaterialArtDTO,
    MaterialDTO,
    QualificationDTO,
    ResponseDTO,
    UserResponseDTO,
    WachenCloudDTO
} from "@/model/dto";
import {v4 as uuid} from 'uuid';

export class ObjectEvent<T> {
    id: number | null;

    data: T | null;


    constructor(id: number | null, data: T | null) {
        this.id = id;
        this.data = data;
    }
}

export class CrudEvent {
    name: string;
    dto: AbstractDTO | null;
    id: string | number | null;


    constructor(name: string, dto: AbstractDTO | null, id = null) {
        this.name = name;
        this.dto = dto;

        if (id === null && dto) {
            this.id = dto.id
        } else if (id) {
            this.id = id
        }
    }
}

export class AbstractClass implements AbstractDTO {
    id;
    tempId = uuid()

    originalId: number | null;
    statusDate: number | null;
    statusFile: number | null;
    statusEmeldReport: number | null;
    warnings: string[];
    addFieldId: number | null;
}

export class AbstractListItem extends AbstractClass implements AbstractListItemDTO {
    type: string | null;
    icon: string | null;
    searchText: string | null;
    text: string | null;
    typeText: string | null;
    iconPlain: string | null;
}

export class ExistingFile {
    id: number | null;

    name: string;
    size: number | null;


    constructor(id: number | null, name: string, size: number | null) {
        this.id = id;
        this.name = name;
        this.size = size;
    }
}

export class ExistingFiles {
    files: FileDTO[]

    constructor(files: FileDTO[]) {
        this.files = files;
    }
}

export class ListElement {
    text: string
    id: number

    constructor(text: string, value: number) {
        this.text = text;
        this.id = value;
    }
}


export class RewisFile extends AbstractClass implements FileDTO {
    description: string | null;
    identifier: string | null;
    name: string | null;
    path: string | null;
    size: number | null;
    uploadTimestamp: string | null;
    comment: string | null;
    clone: boolean;


    constructor(uploadFile = null) {
        super();
        this.clone = false
        if (uploadFile) {
            this.identifier = uploadFile.id
            this.name = uploadFile.name
            this.size = uploadFile.size
        }
    }

    mimeType: string | null;

    validUntil: string | null;
    cloud: WachenCloudDTO | null;
}

export class ApiResponse<T> implements ResponseDTO {
    data: T;
    error: ErrorDTO[] | string | null;
    fieldErrors: [] | null;
    raw: AxiosResponse;

    constructor(raw) {
        if (axios.isAxiosError(raw)) {
            try {
                const r = raw.response.data
                if (r != undefined && Array.isArray(r['fieldErrors'])) {
                    this.fieldErrors = r['fieldErrors'] as [];
                }

                if (r != undefined && r['error'] != undefined) {
                    this.error = r['error'];
                } else {
                    this.error = raw.message
                }
            } catch (err) {
                this.error = err
            }
        } else {
            this.data = raw.data.data
        }
        this.raw = raw
    }
}

export class EventBusEvent {
    name: string;
    callback: any;
}

export class AdditionalField extends AbstractClass implements AdditionalFieldDTO {
    fieldTyp: number | null = 1;
    name: string | null = '';
    options: string[] = [];
    typ: number | null;
    sortOrder: number | null = 0;
    identifier: string | null;
    required: boolean | null;
    bCheckinBezug: boolean | null = false;
    checkinField: string | null = null;
    multi: boolean | null = false;
    vorbelegung = null;
    filterGeraeteart: EwoGeraeteartDTO | null;
    filterMaterialArt: MaterialArtDTO | null;
}

export class AdditionalFieldResult extends AbstractClass implements AdditionalFieldResultDTO {
    field: AdditionalFieldDTO | null;
    fieldValue: string | null;

    constructor(field: AdditionalFieldDTO | null = null) {
        super();
        this.field = field;
    }

    file: FileDTO | null;

    qualifikation: QualificationDTO;


    kfz: KfzResponseDTO[] | null;
    material: MaterialDTO[] | null;
    user: UserResponseDTO | null;
    identifier: string | null;
    fieldTyp: number | null;
    kfzKmBeginn: number | null;
    kfzKmEnde: number | null;
    dienst: DienstDTO | null;
    kennung: KfzKennungDTO[] | null;
    userText: string | null;
    medProdukt: [];
}

export class EntityFile extends AbstractClass implements EntityFileDTO {
    comment: string | null;
    file: FileDTO | null;
    frontendRef: number | null;
}

export class AbstractEntityTaskFormRequest implements AbstractEntityTaskFormRequestDTO {
    archive: boolean;
    dateFrom: string | null;
    dateTo: string | null;
    kfzId: number | null;
    ouId: number | null;
    countOnly: boolean | null;
    groupCount: boolean | null;
    materialId: number | null;
    medProduktId: number | null;
}

export class LastRequest {
    path: string;
    timestamp: number;

    constructor(path: string) {
        this.timestamp = Date.now();
        this.path = path;
    }
}