
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import '@/scripts/dataTables_German'
import UserEditView from "@/views/user/UserEditView.vue";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {MaterialApi} from "@/services/MaterialApi";
import {MaterialDTO} from "@/model/dto";
import {Material, MaterialRequest} from "@/model/Material";
import LocationChooser from "@/components/LocationChooser.vue";
import moment from "moment/moment";


@Component(
    {
      components: {LocationChooser, UserEditView},
      watch: {}
    }
)
export default class MaterialStandortKorrekturView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  materialsCopy = null;
  materials = null;

  error = null;
  key = 0
  showDialog = false
  mymoment = moment

  mounted() {
    bus.$on(Event.materialStandortChanged, (changedMaterial: MaterialDTO) => {
      this.init(changedMaterial)
    })
    bus.$on(Event.materialReviewSubProducts, (changedMaterial: MaterialDTO) => {
      this.init(changedMaterial, true)
    })

    bus.$on(Event.materialParentArchive, (data) => {
      this.materials = data
      this.materialsCopy = structuredClone(data)
      this.showDialog = (this.materials.length > 0)
    })
  }

  async init(changedMaterial: MaterialDTO, info = false) {
    const requestDto = new MaterialRequest()
    requestDto.withChilds = true
    requestDto.parentId = changedMaterial.id
    MaterialApi.getMaterials(requestDto).then(materialData => {
      this.materials = materialData['rows']
      this.materialsCopy = structuredClone(materialData['rows'])

      this.showDialog = (this.materials.length > 0)
      if (info && !this.showDialog) {
        this.$alert("Es wurden keine zugeordneten Produkte gefunden.")
      }
    })
    //TODO
  }

  onSubmit(material) {
    this.data = material
    const backup = this.materialsCopy.find(bup => bup.id == material.id)

    this.error = new Material();
    const p = MaterialApi.putMaterial(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send materialChanged event')
        material.location = this.data.location
        material.locationText = this.data.locationText

        if (backup) {
          backup.location = structuredClone(material.location)
          backup.locationText = structuredClone(material.locationText)
        }

        bus.$emit(Event.materialChanged, new ObjectEvent(this.data.id, data.data));
      } else {
        if (backup) {
          if (data instanceof ApiResponse && data.fieldErrors && data.fieldErrors.length > 0) {
            const locationError = data.fieldErrors.find(error => error["name"] == 'location')
            if (locationError) {
              this.$alert(locationError.status, "Standort ungültig", "error")
            }
          }
          material.location = structuredClone(backup.location)
          material.locationText = structuredClone(backup.locationText)

        }
      }
    })
  }

  destroyed() {
    bus.$off(Event.materialStandortChanged)
    bus.$off(Event.materialReviewSubProducts)

  }


  archiv(data) {
    this.$confirm('Sind sie Sicher ?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      MaterialApi.archivMaterial(data.id)
          .then((response) => {
            data.archive = !data.archive
            bus.$emit(Event.materialArchived, new ObjectEvent(data.id, data));

            response.data.forEach(child => {
              const tableChild = this.materials.find(material => material.id == child.id)
              if (tableChild) {
                tableChild.location = child.location
              }
              this.materialsCopy = structuredClone(this.materials)
              bus.$emit(Event.materialChanged, new ObjectEvent(child.id, child))
            })
          })
          .catch((e) => {
            console.log(e)
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

}
