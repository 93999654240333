import {apiClient} from './AbstractHTTPService'
import store from '../store'

import Axios from "axios";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {PersonenkreisDataDTO, PersonenkreisDTO} from "@/model/dto";
import {Event} from "@/model/Constants";


export abstract class PersonenkreisApi {
    private static usersAxios = Axios.create();

    static async getPersonenkreise(): Promise<PersonenkreisDTO[]> {
        const response = await apiClient.get('/user/personenkreislist');
        return response.data.data;
    }

    static async getPersonenkreis(id): Promise<ApiResponse<PersonenkreisDataDTO>> {
        const response = await apiClient.get('/user/personenkreisdatalist/' + id);
        return new ApiResponse(response);
    }

    static async update(dto: PersonenkreisDataDTO): Promise<ApiResponse<PersonenkreisDataDTO>> {
        const response = await apiClient.put('/user/personenkreisdatalist/' + dto.id + '/', dto);
        store.dispatch("crudEvent", new CrudEvent(Event.personenkreisChanged, response.data.data));
        return new ApiResponse(response);
    }


    static async archive(id): Promise<any> {
        const response = await apiClient.delete('/user/personenkreisdatalist/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.personenkreisChanged, response.data.data));
        return response.data;
    }


}