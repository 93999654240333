
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {EwoFileDTO} from "@/model/dto";
import moment from "moment/moment";
import {FileApi} from "@/services/FileApi";
import {EwoFile} from "@/model/File";
import EwoFileEditModal from "@/views/ewmpg/modal/EwoFileEditModal.vue";
import {Module} from "@/model/Constants";
import {ListItemHelper} from "../../model/Shared";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";


@Component(
    {
      computed: {
        ListItemHelper() {
          return ListItemHelper
        },
        Module() {
          return Module
        }
      },
      components: {ListItemComponent, EwoFileEditModal},
    }
)
export default class EwoFileEwoView extends Vue {
  @Prop() private ewo;
  @Prop() private medProdukt;

  @Prop() private crud;

  data: EwoFileDTO | null = null
  files: EwoFileDTO[] = []
  modalId = 'ewo-file-edit-modal'
  withArchive = false

  async init() {
    this.files = (!this.medProdukt) ? await FileApi.getEwoFileByEwo(this.$props.ewo.id, this.withArchive) : await FileApi.getEwoFileByMpId(this.$props.medProdukt.id);
  }

  async updateTable(data) {
    await this.init()
  }

  async created() {
    this.$root.$emit('loadingStart')
    await this.init()
    this.$root.$emit('loadingDone')
  }

  newFile() {
    this.data = new EwoFile()
    this.data.ewo = this.$props.ewo

    this.$bvModal.show(this.modalId)
  }

  edit(rowdata) {
    if (this.crud) {
      this.data = rowdata
      this.$bvModal.show(this.modalId)
    }
  }

  toMoment(d) {
    return moment(d)
  }


  getFilename(file) {
    let arr = []
    arr.push(file.comment ? file.comment : '')
    arr.push(file.fileCategories ? file.fileCategories.map(fC => fC.name).join(", ") : '')
    return arr.filter(v => v).join(" - ")
  }

  get getFileData() {
    return this.files;
  }


  async archiveToggle(checked) {
    this.$root.$emit('loadingStart')
    await this.init()
    this.$root.$emit('loadingDone')
  }
}
