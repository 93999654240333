
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EntityTaskTemplateObjectDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {EntityTaskTemplateModus, EntityTaskTemplateObject} from "@/model/Task";
import {TaskApi} from "@/services/TaskApi";
import {DateHelper} from "../../../model/Date";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    },
    EntityTaskTemplateModus() {
      return EntityTaskTemplateModus
    }
  },
  components: {
    ListItemComponent
  }
})
export default class EntityTaskTemplateSelectorView extends mixins<SimpleViewMixin<EntityTaskTemplateObjectDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: EntityTaskTemplateObjectDTO = new EntityTaskTemplateObject()
  error: GenericError = new GenericError()

  @Prop() modul;
  @Prop() entity;

  async created() {
    this.initialize()
  }

  async initialize() {
    this.$root.$emit('loadingStart')
    if (!this.entity) {
      this.objects = []
      this.$root.$emit('loadingDone')
    }

    let entityId = this.entity.originalId

    await this.init(EntityTaskTemplateObject,
        TaskApi.getEntityTaskTemplateObject,
        (a) => null,
        (b) => null,
        this.modul + "/" + entityId
    )
    this.$root.$emit('loadingDone')
  }

  getArt(q: EntityTaskTemplateObjectDTO) {
    const obj = this.$store.getters['task/getEntityTaskTemplateZmodus'].find(item => item.value === q.zModus)
    return obj ? obj.text : ''
  }

  @Watch('entity')
  public watchModus(newValue) {
    this.initialize()
  }

}
