
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, OutsourcingCategoryDTO} from "@/model/dto";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {OutsourcingApi} from "@/services/OutsourcingApi";
import {OutsourcingCategory} from "@/model/Outsourcing";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader, SettingsTooltip, KfzView}
})
export default class OutsourcingServiceView extends mixins<SimpleViewMixin<OutsourcingCategoryDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: OutsourcingCategoryDTO = new OutsourcingCategory()
  error: GenericError = new GenericError()

  async created() {
    await this.init(OutsourcingCategory,
        OutsourcingApi.getCategories,
        (a) => OutsourcingApi.putCategory(a),
        (b) => OutsourcingApi.remvoeOutsourcingCategory(b)
    )
    this.$root.$emit('loadingDone')
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
