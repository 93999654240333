

import {Component, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import MedProduktNavComponent from "@/views/medprodukt/components/MedProduktNavComponent.vue";
import MedProduktListView from "@/views/medprodukt/MedProduktListView.vue";
import EntityTaskTemplateListView from "@/views/task/entity/EntityTaskTemplateListView.vue";
import EntityTaskListView from "@/views/task/entity/EntityTaskListView.vue";
import FormListView from "@/views/form/FormListView.vue";

@Component(
    {
      components: {
        FormListView,
        EntityTaskListView, EntityTaskTemplateListView,
        MedProduktNavComponent,
        DatatableButtonHeader,
        MedProduktListView
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class MedProduktTables extends Vue {

  crud = false
  showMedproduktList = false
  showTaskTable = false
  showTaskTemplateTable = false
  showFormTable = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == ('medproduktlist') || route.name == ('medproduktoverview')) {
      this.showMedproduktList = true
      this.showTaskTable = false
      this.showTaskTemplateTable = false
      this.showFormTable = false
    } else if (route.name == 'medprodukttasktemplatelist') {
      this.showTaskTable = false
      this.showTaskTemplateTable = true
      this.showFormTable = false
      this.showMedproduktList = false
    } else if (route.name == 'medprodukttasklist') {
      this.showTaskTable = true
      this.showTaskTemplateTable = false
      this.showFormTable = false
      this.showMedproduktList = false
    } else if (route.name == 'medproduktformlist') {
      this.showTaskTable = false
      this.showTaskTemplateTable = false
      this.showFormTable = true
      this.showMedproduktList = false
    }

  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
