
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {FobiKombiThemaDTO} from "@/model/dto";
import {FobiKombiThema} from "@/model/Fobi";
import {FobiApi} from "@/services/FobiApi";
import FobiKombiThemaDataView from "@/views/fobi/FobiKombiThemaDataView.vue";


@Component({
  components: {
    FobiKombiThemaDataView,
  }
})
export default class FobiKombiThemaCreateView extends mixins<GenericMixin<FobiKombiThemaDTO, FobiKombiThemaDTO>>(GenericMixin) {
  data = new FobiKombiThema();
  error = new FobiKombiThema();

  created() {
    this.data.themen = [];
  }


  onSubmit(goBack: boolean) {
    const p = FobiApi.putKombiThema(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send fobiKombiThemaCreated event')
        bus.$emit(Event.fobiKombiThemaCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/fobi/kombithema", query: queryParam})
  }

}
