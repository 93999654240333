import {KfzOutsourcingDTO, KfzResponseDTO, MaterialDTO, MaterialOutsourcingDTO, MedProduktDTO, MedProduktOutsourcingDTO, OrgUnitDTO, OutsourcingCategoryDTO, OutsourcingDTO} from "@/model/dto";
import {AbstractClass} from "@/model/AbstractClasses";


export class OutsourcingEdit extends AbstractClass implements OutsourcingDTO {
    sonstigesService: boolean;
    category: OutsourcingCategoryDTO[];
    city: string | null;
    comment: string | null;
    company: string;
    email: string | null;
    houseNo: string | null;
   
    isoCert: boolean;
    passOnChilds: boolean;
    phone: string | null;
    plz: string | null;
    street: string | null;
    typString: string | null;
    archive: boolean;
    locationSummary: string | null;
    kfzStandort: boolean;
    materialStandort: boolean;
    medProduktStandort: boolean;
    type: string | null;
    icon: string | null;
    searchText: string | null;
    text: string | null;
    typeText: string | null;
    iconPlain: string | null;
    orgUnit: OrgUnitDTO | null;
}

export class KfzOutsourcing extends AbstractClass implements KfzOutsourcingDTO {
    caterory: OutsourcingCategoryDTO[];
   
    inheritCategories: boolean;
    kfz: KfzResponseDTO | null;
    outsourcing: OutsourcingDTO | null;
}

export class MedProduktOutsourcing extends AbstractClass implements MedProduktOutsourcingDTO {
    caterory: OutsourcingCategoryDTO[];
   
    inheritCategories: boolean;
    medProdukt: MedProduktDTO | null;
    outsourcing: OutsourcingDTO | null;
}

export class MaterialOutsourcing extends AbstractClass implements MaterialOutsourcingDTO {
    caterory: OutsourcingCategoryDTO[];
   
    inheritCategories: boolean;
    material: MaterialDTO | null;
    outsourcing: OutsourcingDTO | null;
}

export class OutsourcingCategory extends AbstractClass implements OutsourcingCategoryDTO {
    name: string;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean | null;
    typ: number;

}