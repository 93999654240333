import {Module} from "@/model/Constants";
import HygieneView from "@/views/hygiene/HygieneView.vue";
import HygieneTables from "@/views/hygiene/HygieneTables.vue";
import DesiEditView from "@/views/hygiene/DesiEditView.vue";
import DesiCreateView from "@/views/hygiene/DesiCreateView.vue";
import HygieneAmpelView from "@/views/hygiene/HygieneAmpelView.vue";
import HygieneConcentrationView from "@/views/hygiene/HygieneConcentrationView.vue";
import HygieneScopeView from "@/views/hygiene/HygieneScopeView.vue";
import HygieneProtectionView from "@/views/hygiene/HygieneProtectionView.vue";
import HygieneEinwirkzeitView from "@/views/hygiene/HygieneEinwirkzeitView.vue";
import HygieneDisinfectantView from "@/views/hygiene/HygieneDisinfectantView.vue";
import HygieneDiseaseView from "@/views/hygiene/HygieneDiseaseView.vue";
import HygieneDesiAdviceView from "@/views/hygiene/HygieneDesiAdviceView.vue";
import HygieneDesiRoutineView from "@/views/hygiene/HygieneDesiRoutineView.vue";
import HygieneInheritanceView from "@/views/hygiene/HygieneInheritanceView.vue";
import {Permission, PermissionModul} from "@/model/dto";

const moduleMeta = {
    module: Module.HYGIENE,
}

export const hygieneRoutes = [
    {
        path: 'hygiene',
        name: 'hygiene',
        component: HygieneView,
        children: [
            {
                path: 'inheritance',
                name: 'inheritance',
                component: HygieneInheritanceView,
                meta: {
                    loading: true,
                    title: 'Vererbung',
                    permission: [PermissionModul.Hygiene_Settings, Permission.Vererbung]
                }
            },
            {
                path: 'desiadvice',
                name: 'desiadvice',
                component: HygieneDesiAdviceView,
                meta: {
                    title: 'Desinfektionsmaßnahmen',
                    permission: [PermissionModul.Hygiene_Settings, Permission.DesiMassnahmen]
                }
            },
            {
                path: 'desiroutine',
                name: 'desiroutine',
                component: HygieneDesiRoutineView,
                meta: {
                    title: 'Desinfektionsroutinen',
                    permission: [PermissionModul.Hygiene_Settings, Permission.DesiRoutine]
                }
            },
            {
                path: 'ampel',
                name: 'ampel',
                component: HygieneAmpelView,
                meta: {
                    title: 'Ampel-Kategorien',
                    permission: [PermissionModul.Hygiene_Settings, Permission.Ampel]
                }
            },
            {
                path: 'concentration',
                name: 'concentration',
                component: HygieneConcentrationView,
                meta: {
                    title: 'Konzentration',
                    permission: [PermissionModul.Hygiene_Settings, Permission.Konzentration]
                }
            },
            {
                path: 'scopes',
                name: 'scopes',
                component: HygieneScopeView,
                meta: {
                    title: 'Umfänge',
                    permission: [PermissionModul.Hygiene_Settings, Permission.Umfang]
                }
            },
            {
                path: 'protection',
                name: 'protection',
                component: HygieneProtectionView,
                meta: {
                    title: 'Schutzmaßnahmen',
                    permission: [PermissionModul.Hygiene_Settings, Permission.Schutz]
                }
            },
            {
                path: 'einwirkzeit',
                name: 'einwirkzeit',
                component: HygieneEinwirkzeitView,
                meta: {
                    title: 'Einwirkzeiten',
                    permission: [PermissionModul.Hygiene_Settings, Permission.Einwirkzeit]
                }
            },
            {
                path: 'disinfectant',
                name: 'disinfectant',
                component: HygieneDisinfectantView,
                meta: {
                    title: 'Desinfektionsmittel',
                    permission: [PermissionModul.Hygiene_Settings, Permission.Desinfektionsmittel]
                }
            },
            {
                path: 'disease',
                name: 'disease',
                component: HygieneDiseaseView,
                meta: {
                    title: 'Infektionskrankheiten',
                    permission: [PermissionModul.Hygiene_Settings, Permission.Krankheiten]
                }
            },
            {
                path: 'desioverview',
                name: 'desilist',
                component: HygieneTables,
                meta: {
                    datatable: true,
                    title: 'Übersicht',
                    permission: [PermissionModul.Hygiene, Permission.Overview],
                    activeRoutes: ['desilist', 'desicreate', 'desiedit']
                }
            },
            {
                path: 'desi/create',
                name: 'desicreate',
                components: {
                    default: HygieneTables,
                    CRUD: DesiCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Hygiene, Permission.Create]
                }
            },
            {
                path: 'desi/edit/:id',
                name: 'desiedit',
                components: {
                    default: HygieneTables,
                    CRUD: DesiEditView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Hygiene, Permission.Overview]
                }
            },
        ]
    }
]
