
import {Component, Vue, Watch} from "vue-property-decorator";
import {UserEdit} from "@/model/User";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {OrgUnitDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import DateForm from "@/components/DateForm.vue";
import LabelComponent from "@/components/LabelComponent.vue";

const DataProps = Vue.extend({
  props: {
    value: UserEdit,
    error: UserEdit
  }
})

@Component({
  components: {LabelComponent, NumberForm, Multiselect, DateForm}
})
export default class UserDataView extends mixins<GenericMixin<UserEdit, UserEdit>>(GenericMixin, DataProps) {
  data: UserEdit = this.$props.value;
  invoiceOuList: OrgUnitDTO[] = []
  dbValue: UserEdit = new UserEdit()
  windowWith = null

  async created() {
    this.windowWith = this.$screen.width
    this.invoiceOuList = await this.$store.dispatch('selectize/fetchInvoiceOrgUnits')
    if (this.data.invoiceOrgUnit != null) {
      if (this.invoiceOuList.find((listElem) => listElem.id == this.data.invoiceOrgUnit.id) == undefined) {
        this.invoiceOuList.push({id: this.data.invoiceOrgUnit.id, name: this.data.invoiceOrgUnit.name})
      }
    }
    this.init()
  }

  nameChanged() {
    if (!this.dbValue.username && this.data.firstname && this.data.lastname) {
      this.data.username = this.data.lastname + this.data.firstname.substring(0, 2);
    }
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }

  // a Watch Method to recognize the screen width
  /*
  Do as here but with @Watch
  watch: {
    '$screen.width'() {
      this.$store.dispatch('setSidebarVisible', this.$screen.xl)
      //  this.sbHidden = true
      this.loadingFullscreen = !this.$screen.xl
    }
  }
   */
  @Watch('$screen.width')
  onWidthChange() {
    this.windowWith = this.$screen.width
  }

  init() {
    this.dbValue = structuredClone(this.data)
  }

}
