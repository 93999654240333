import Vue from 'vue';

Vue.mixin({
    data() {
        return {
            globalFieldsetClasses: ['form-group', 'border', 'px-3', 'mb-5', 'mt-4', 'table-responsive-sm', 'table-responsive-md'],
            globalLegendClasses: ['w-auto', 'px-2']
        };
    }
});
