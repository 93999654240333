
import {Component, Prop, Vue} from 'vue-property-decorator';
import {v4 as uuid} from 'uuid';

@Component
export default class ChainIconTooltipTableCell
    extends Vue {
  @Prop({default: false}) isCell: boolean
  @Prop({default: uuid()}) uuid;
  @Prop() text;

}
