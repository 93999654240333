import {apiClient} from './AbstractHTTPService'
import Axios from "axios";
import {EntityTaskFormRequestDTO, FobiRequestDTO} from "@/model/dto";

export abstract class DashboardAdminApi {
    private static usersAxios = Axios.create();

    static async getDasboardData(moduleId: number, chain: boolean): Promise<any> {
        const response = await apiClient.get(`/dashboard/overview/${moduleId}${chain ? '/chain' : ''}`);
        return response.data.data;
    }
    static async getDashboardFobiData(request: FobiRequestDTO): Promise<any> {
        const response = await apiClient.post('/fobi/fortbildung', request);
        return response.data.data;
    }

    static async getDashboardTaskData(request): Promise<any> {
        const response = await apiClient.post('/task/task', request);
        return response.data.data;
    }

    static async getTaskHygieneData(request: EntityTaskFormRequestDTO): Promise<any> {
        const response = await apiClient.post('/task/entitytask', request);
        return response.data.data;
    }

    static async getDesiDashboardData(request: {ouId: number, onlyUnconfirmed: boolean, ouChain: boolean}): Promise<any> {
        const response = await apiClient.post('/hygiene/desi', request);
        return response.data.data;
    }

    static async getReportsDashboardData(request: {ouId: number, groupCount: boolean, ouChain: boolean}): Promise<any> {
        const response = await apiClient.post('/emeld/report/', request);
        return response.data.data;
    }

    static async getDashboardModalTableData(moduleId: number, typId: number, chain: boolean): Promise<any> {
        const response = await apiClient.get(`/dashboard/moduletype/${moduleId}/${typId}${chain ? '/chain' : ''}`);
        return response.data.data;
    }
}
