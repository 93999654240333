
import {Component, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import UserListView from "@/views/user/UserListView.vue";
import UserClothingListView from "@/views/user/UserClothingListView.vue";
import MaterialListView from "@/views/material/MaterialListView.vue";
import MaterialListServersideView from "@/views/material/MaterialListServersideView.vue";
import MaterialKategorieListView from "@/views/material/MaterialKategorieListView.vue";
import MaterialNavComponent from "@/views/material/components/MaterialNavComponent.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import FormListView from "@/views/form/FormListView.vue";
import EntityTaskTemplateListView from "@/views/task/entity/EntityTaskTemplateListView.vue";
import EntityTaskListView from "@/views/task/entity/EntityTaskListView.vue";


@Component(
    {
      components: {
        EntityTaskListView, EntityTaskTemplateListView,
        FormListView,
        DatatableButtonHeader, MaterialNavComponent,
        MaterialKategorieListView,
        MaterialListServersideView, MaterialListView, UserClothingListView, UserListView
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class MaterialTables extends Vue {

  showTable1 = false
  showTable2 = false

  showTaskTable = false
  showTaskTemplateTable = false
  showFormTable = false


  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'materialkategorielist') {
      this.showTable2 = true
      this.showTable1 = false

      this.showTaskTable = false
      this.showTaskTemplateTable = false
      this.showFormTable = false
    } else if (route.name == 'materialtasktemplatelist') {
      this.showTaskTable = false
      this.showTaskTemplateTable = true
      this.showFormTable = false

      this.showTable2 = false
      this.showTable1 = false
    } else if (route.name == 'materialtasklist') {
      this.showTaskTable = true
      this.showTaskTemplateTable = false
      this.showFormTable = false

      this.showTable2 = false
      this.showTable1 = false
    } else if (route.name == 'materialformlist') {
      this.showTaskTable = false
      this.showTaskTemplateTable = false
      this.showFormTable = true

      this.showTable2 = false
      this.showTable1 = false
    }  else {
      this.showTable1 = true
      this.showTable2 = false

      this.showTaskTable = false
      this.showTaskTemplateTable = false
      this.showFormTable = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
