
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import {Module} from "@/model/Constants";
import {func} from "@/scripts/scripts";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {ListItemHelper} from "@/model/Shared";

@Component({
  components: { DatatableButtonHeader }
})
export default class AdminDashboardFileStatus extends Vue {
  @Prop() moduleId!: any;
  @Prop() chain!: boolean;

  tableId: string = 'dTable_' + uuid();
  loading = true;
  headers: string[] = [];
  fields = [];

  mounted() {
    this.init();
  }

  @Watch('chain')
  async onChainChange() {
    await this.init();
  }

  async init() {
    this.loading = true;
    let art = '';

    switch (this.moduleId) {
      case Module.USER:
        this.fields = [
          {
            class: '',
            title: 'Nachname',
            data: 'user.lastname'
          },
          {
            class: '',
            title: 'Vorname',
            data: 'user.firstname'
          },
          {
            class: '',
            title: 'Benutzername',
            data: 'user.username'
          },
          {
            class: '',
            title: 'Status',
            data: null,
            render: function (d, type, row) {
              return func.getDashboardStatusIcon(d.schweregrad, 'file');
            }
          },
          {
            class: '',
            title: 'Kategorie',
            data: 'fileCategory.name'
          },
          {
            class: '',
            title: 'Ursprung',
            data: 'fileCategory.orgUnit.text'
          }
        ];
        art = null;
        break;
      case Module.KFZ:
        art = 'kfz';
        break;
      case Module.MEDPRODUKT:
        art = 'medProdukt';
        break;
      case Module.MATERIAL:
        art = 'material';
        break;
    }

    if (art) {
      this.fields = [
        {
          class: '',
          title: 'Objekt',
          data: null,
          render: function (d, type, row) {
            return ListItemHelper.getTag(d[art]);
          }
        },
        {
          class: '',
          title: 'Status',
          data: null,
          render: function (d, type, row) {
            return func.getDashboardStatusIcon(d.schweregrad, 'file');
          }
        },
        {
          class: '',
          title: 'Kategorie',
          data: 'fileCategory.name'
        },
        {
          class: '',
          title: 'Ursprung',
          data: 'fileCategory.orgUnit.text'
        },
      ];
    }

    DashboardAdminApi.getDashboardModalTableData(this.moduleId, 2, this.chain).then(response => this.initializeDataTable(response));
  }

  initializeDataTable(data) {
    this.$nextTick(() => {
      const tableElement = jquery('#' + this.tableId);
      if (tableElement.length) {
        if (jquery.fn.dataTable.isDataTable(tableElement)) {
          tableElement.DataTable().clear().destroy();
        }
        const dTable = tableElement.DataTable({
          order: [[0, "asc"]],
          responsive: true,
          stateSave: false,
          data: data.filter(entry => entry.schweregrad === 2),
          columns: this.fields,
          buttons: func.addDefaultButtons([], true),
        });

        func.datatableInitComplete(dTable, 0, this);
        this.loading = false;
      }
    });
  }
}
