
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {LevelOuDTO} from "@/model/dto";
import EmeldLevelDataView from "@/views/emeld/data/EmeldLevelDataView.vue";
import {LevelOu} from "@/model/Emeld";
import {EmeldApi} from "@/services/EmeldApi";
import EmeldLevelOuDataView from "@/views/emeld/data/EmeldLevelOuDataView.vue";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component({
  components: {
    EmeldLevelOuDataView,
    EmeldLevelDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class EmeldLevelOuEditView extends mixins<GenericMixin<LevelOuDTO, LevelOuDTO>>(GenericMixin, DataProps) {
  data = new LevelOu();
  error = new LevelOu();
  showTabs = false

  async created() {
    this.init();
  }


  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = EmeldApi.getLevelOuByLevelId(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean) {
    const p = EmeldApi.putLevelOu(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send emeldLevelOuChanged event')
        bus.$emit(Event.emeldLevelOuChanged, new ObjectEvent(data.data.id, data.data));
        this.init()
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    //do nothing
  }

  archiv() {
    if (this.data.own) {
      bus.$emit(Event.emeldLevelRemoveRequest, new ObjectEvent(this.data.level.id, this.data.level));
    } else {
      bus.$emit(Event.emeldLevelOuRemoveRequest, new ObjectEvent(this.data.id, this.data));
    }
  }

  createChild() {
    this.$router.push({path: "/emeld/levelou/" + this.data.level.id + "/create"})
  }

  get archivTxt() {
    return this.data['archive'] ? "Reaktivieren" : "Archivieren"
  }
}
