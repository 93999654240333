

import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {CheckinPersonalDTO, GenericError} from "@/model/dto";
import {CheckinPersonal} from "@/model/Checkin";
import {ApiResponse} from "@/model/AbstractClasses";
import {CheckinApi} from "@/services/CheckinApi";
import DateForm from "@/components/DateForm.vue";
import CheckinPersonalComponent from "@/views/checkin/components/CheckinPersonalComponent.vue";
import moment from "moment";


@Component({components: {CheckinPersonalComponent, DateForm}})
export default class CheckinChangePersonalMask extends mixins<GenericMixin<CheckinPersonalDTO, GenericError>>(GenericMixin) {
  error: GenericError = new GenericError()
  data: CheckinPersonalDTO = new CheckinPersonal()

  async created() {
    this.data.datumStart = moment().format(moment.HTML5_FMT.DATE);
    this.data.zeitStart = moment().format(moment.HTML5_FMT.TIME);

    this.$root.$emit('loadingDone');
  }


  onSubmit(goBack = true) {
    this.data.id = this.$store.state.checkin.checkin.id
    const p = CheckinApi.changePersonel(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data !== undefined) {
        this.$store.dispatch('checkin/setCheckin', data.data)
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push("/checkinlist");
  }
}
