
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {EwoDTO, EwoGeraeteartDTO, EwoHerstellerDTO, EwoObjektbezeichnungDTO, EwoSoftwareversionDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import {EwoEdit} from "@/model/Ewmpg";
import {notAllowedText as txt} from "@/model/MedProdukt";


@Component({components: {NumberForm, Multiselect}})
export default class EwoDataView extends mixins<GenericMixin<EwoDTO, EwoDTO>>(GenericMixin) {
  @Prop() value: EwoDTO;
  @Prop() error: EwoDTO;
  data: EwoDTO = this.$props.value;

  noCommercialUse = txt

  herstellerOpts: EwoHerstellerDTO[] = []
  geraeteartOpts: EwoGeraeteartDTO[] = []
  objektbezeichnungOpts: EwoObjektbezeichnungDTO[] = []
  softwareversionOpts: EwoSoftwareversionDTO[] = []
  einzelEwoOpts: EwoDTO[] = []


  async created() {
    this.herstellerOpts = await this.$store.dispatch('medprodukt/fetchHersteller')
    this.geraeteartOpts = await this.$store.dispatch('medprodukt/fetchGeraeteart')
    this.objektbezeichnungOpts = await this.$store.dispatch('medprodukt/fetchObjektbezeichnung')
    this.softwareversionOpts = await this.$store.dispatch('medprodukt/fetchSoftwareversionen')

    this.einzelEwoOpts = await this.$store.dispatch('medprodukt/fetchEwos')
    this.einzelEwoOpts = this.einzelEwoOpts.filter(e => !e.kombi).filter(e => e.kombiTyp == 0 || e.kombiTyp == 2).filter(e => e.id != this.value.id)

    this.sortierteHersteller()
    this.sortierteGeraeteart()
    this.sortierteObjektbezeichnung()
  }

  kombiChanged() {
    this.data.kombiTyp = 1

    if (!this.data.kombi) {
      this.data.einzelEwo = []
      this.data.kombiTyp = 0
    }
  }
  removeEwoSelection(ewoId) {
    this.data.einzelEwo = this.data.einzelEwo.filter(e => e.id != ewoId)
  }

  getEinweisungspflicht(param) {
    return EwoEdit.getEinweisungspflicht(param);
  }
  getKombiTyp(param) {
    return EwoEdit.getKombiTyp(param);
  }

  sortierteHersteller() {
    return this.herstellerOpts.sort((a, b) => a.name.localeCompare(b.name))
  }

  sortierteGeraeteart() {
    return this.geraeteartOpts.sort((a, b) => a.name.localeCompare(b.name))
  }

  sortierteObjektbezeichnung() {
    return this.objektbezeichnungOpts.sort((a, b) => a.name.localeCompare(b.name))
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

}
