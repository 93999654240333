
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import LabelComponent from "@/components/LabelComponent.vue";
import {HistoryEvent, ListItemHelper} from "@/model/Shared";


@Component(
    {
      components: {LabelComponent},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("fobizeitraumtable")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
          } else {
            this.table.fixedHeader.disable()
          }
          this.handleDatableRoute(true);
          this.$root.$emit("loadingDone")
        }
      }
    }
)
export default class FobiZeitraumView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false
  getUrl() {
    return '/fobi/zeitraum/chain/' + ((this.archive) ? 'archive' : '');
  }

  created() {
    this.handleDatableRoute(true)
  }


  async mounted() {
    this.registerEvents([Event.fobiZeitraumCreated, Event.fobiZeitraumArchived, Event.fobiZeitraumChanged]);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    const dateHelper = DateHelper


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      stateSave: false,
      responsive: false,
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      rowId: "id",
      columns: [
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return data.orgUnit.id != self.$store.getters.getLocation.id ? ListItemHelper.getInheritance('chain_' + data.id) : ''
          }
        },
        {
          class: '',
          data: 'name'
        },
        {
          class: '',
          data: null,
          render: function (data, type, row, meta) {
            return dateHelper.parseDate(data.start)
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row, meta) {
            return dateHelper.parseDate(data.end)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            let themenNamen = data.themen.map(t =>
                t.themen.map(thema => thema.name).join('; ')
            ).join('; ');
            return themenNamen;
          }
        },
        {
          class: '',
          data: 'orgUnit.name'
        }
      ],
      rowCallback: function (row, data) {
        if (data && data.orgUnit.id != self.$store.getters.getLocation.id) {
          jquery(row).addClass('foreign-row');
        }
      },
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons([
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Bearbeiten',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            self.edit(id)
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Zeilenhistorie',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/fobi/zeitraum', id))
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Archiv',
          text: '<i class="fas fa-archive" />',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }
      ])
    })
    self.table = table
    self.initContextMenu()
    func.datatableInitComplete(table)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }


  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/fobi/zeitraum/create/", query: queryParam})
  }

  edit(id) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();
    if (rowdata.orgUnit.id != this.$store.getters.getLocation.id) {
      this.$alert("Sie haben keine Berechtigung")
      return
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/fobi/zeitraum/edit/" + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "fobizeitraumtable", query: queryParam})
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  initContextMenu() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr:not(.foreign-row)';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: {
        edit: {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        },
        archive: {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        },
        history: {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/fobi/zeitraum', tr.attr('id')))
          }
        },
        duplicate: {
          name: "Duplizieren",
          icon: "fas fa-copy",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.duplicate(row.data())
          }
        }
      }
    });
  }

  archiv(data) {
    if (data.orgUnit.id != this.$store.getters.getLocation.id) {
      this.$alert("Sie haben keine Berechtigung")
      return
    }
    bus.$emit(Event.fobiZeitraumArchiveRequest, new ObjectEvent(data.id, data));
  }

  duplicate(data) {
    this.table.fixedHeader.disable()
    this.$router.push({name: "zeitraumcreate", params: {fobiZeitraumData: data}})
  }

}
