
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {DateHelper} from "@/model/Date";
import {EmeldApi} from "@/services/EmeldApi";
import EmeldReportCards from "@/views/emeld/components/EmeldReportCards.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import EmeldReportTable from "@/views/emeld/EmeldReportTable.vue";
import {ReportDTO} from "@/model/dto";
import ReportNotizModal from "@/views/emeld/components/ReportNotizModal.vue";
import jquery from "jquery";

@Component(
    {
      computed: {
        DateHelper() {
          return DateHelper
        },
      },
      components: {ReportNotizModal, EmeldReportTable, EmeldReportCards}
    }
)
export default class MyReportsView extends mixins<GenericMixin, SimpleViewMixin<null, null>>(GenericMixin, SimpleViewMixin) {
  @Prop() userDashboard: boolean
  isTableView = false
  showNotizModal = false
  selectedItem: ReportDTO = null
  donePromise = null

  clb = () => {
    this.$root.$emit("loadingStart")
    this.init()
  }

  async created() {
    bus.$on(Event.emeldReportFilterChanged, this.clb)
    await this.init()
  }

  getPromise() {
    return this.donePromise
  }

  async init() {
    this.donePromise = new Promise((resolve, reject) => {
      this.initFilter()
      const request = this.$store.state.emeld.requestFilter.formDataToRequest()
      request.userId = this.$store.getters.getUser.id

      EmeldApi.getReports(request).then(data => {
        this.objects = data.rows ? data.rows : []

        if (this.userDashboard) {
          this.$nextTick(() => {
            jquery('#reportcontent').children().appendTo("#myCards");
            resolve(this.objects.length)
          });
        }

        this.$root.$emit('loadingDone')
      })
    })
  }

  tableView() {
    this.$router.push('/emeld/myreportstable')
  }

  destroyed() {
    bus.$off(Event.emeldReportFilterChanged, this.clb)
  }

  reportSelected(id) {
    this.$router.push('/emeld/myreports/edit/' + id)
  }

  reportNotizSelected(item) {
    this.selectedItem = item
    this.showNotizModal = true
  }

}
