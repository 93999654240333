import {
    FileDTO,
    OrgUnitDTO,
    QualificationDTO,
    RfidDTO,
    SkillDTO,
    UserAdditionalFieldDTO,
    UserDressSizeDTO,
    UserEditDTO,
    UserOrgUnitDTO,
    UserProfileDTO,
    UserQualificationDTO,
    UserResponseDTO,
    UserSkillDTO
} from "@/model/dto";
import {AbstractClass, AbstractListItem} from "@/model/AbstractClasses";

export class UserProfile extends AbstractListItem implements UserProfileDTO {
    emailBusiness: string;
    alertEmailBusiness: boolean;
    profilGeprueft: boolean;
    firstname = "";
    lastname = "";
    alertEmail: boolean;
    alertSms: boolean;
    city: string;
    dateOfBirth: string | null;
    email: string;
    mobile: string;
    phone: string;
    placeOfBirth: string;
    plz: string;
    qrCode: string | null;
    salutation: string;
    street: string;
    validSms: boolean;
    dressSize: UserDressSizeDTO | null;
    houseNo: string | null;
    consentPublicBirthday: boolean;
    consentPublicMobile: boolean;
    consentPublicPhone: boolean;
    consentProfilePhoto: boolean;
    profilePhoto: FileDTO | null;
    formatted: string;
    formattedShort: string;
    fromattedLong: string;
    sFormattedShort: string;
    username: string | null;
    lockProfile = false;
    formattedLong: string;
    divaModus: number | null;
}

export class UserEdit extends UserProfile implements UserEditDTO {
    campusId: string;
    keinFahrzeugfuehrer: boolean;
    additionalFields: UserAdditionalFieldDTO[] | null;
    dateEntry: string;
    dateExit: string;
    forcePasswordChange: boolean;
    forceProfileCheck: boolean;
    invoiceOrgUnit: OrgUnitDTO | null;
    jobScope: string;
    licenseClass: string;
    licenseNo: string;
    personalNo: string;
    rfid: RfidDTO | null;
    archive: boolean;
    externNo: string | null;
    licenseIssuingAuthority: string | null;
    licenseIssuingDate: string | null;
    vivendiNo: string | null;
    qualis: UserQualificationDTO[] | null = [];
}

export class UserQualification extends AbstractClass implements UserQualificationDTO {
    comment: string | null;
   
    qualification: QualificationDTO | null;
    validFrom: string | null;
    validTo: string | null;
    user: UserResponseDTO | null;
    icon: string | null;
    status: string;
    statusNumeric: number | null;
}

export class UserSkill extends AbstractClass implements UserSkillDTO {
   
    skill: SkillDTO;
    comment: string | null;
    user: UserResponseDTO | null;

}
export interface IUser {
    id?: number;
    email: string;
    first_name: string;
    last_name: string;
    avatar: string;
}

export class UserDTO extends AbstractClass implements IUser {
    avatar = '';
    email = '';
    first_name = '';
    last_name = '';
}

export class UserOrgUnit extends AbstractClass implements UserOrgUnitDTO {
    ouId: number;
    userId: number;

    constructor(userId: number, orgUnitId: number) {
        super()
        this.ouId = orgUnitId;
        this.userId = userId;
    }
}

export default class User extends UserDTO {
    constructor(dto: UserDTO) {
        super();
        Object.assign(this, dto);
    }

    get fullName(): string {
        return `${this.first_name} ${this.last_name}`;
    }
}

export class Qualification extends AbstractListItem implements QualificationDTO {
    archive: boolean | null;
   
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean | null;
    rank: number | null;
    typ: number | null;
}

export class Skill extends AbstractClass implements SkillDTO {
    archive: boolean | null;
   
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean | null;
}