import {apiClient} from './AbstractHTTPService'
import {ApiResponse} from "@/model/AbstractClasses";
import {VivendiDienstDTO, VivendiDienstOuMapDTO, VivendiOuSyncDTO} from "@/model/dto";


export abstract class VivendiApi {
    static async getSyncDto(): Promise<VivendiOuSyncDTO> {
        const response = await apiClient.get('/diva/vivendiousync');
        return response.data.data;
    }

    static async saveSyncDto(dto: VivendiOuSyncDTO): Promise<ApiResponse<VivendiOuSyncDTO>> {
        const response = await apiClient.put('/diva/vivendiousync/' + dto.id + '/', dto);
        return new ApiResponse(response);
    }

    static async getDienste(): Promise<VivendiDienstDTO[]> {
        const response = await apiClient.get('/diva/vivendidienste');
        return response.data.data;
    }

    static async getDienstOuMap(): Promise<VivendiDienstOuMapDTO[]> {
        const response = await apiClient.get('/diva/vivendidienstoumap');
        return response.data.data;
    }

    static async saveDienstOuMap(dto: VivendiOuSyncDTO): Promise<ApiResponse<VivendiDienstOuMapDTO>> {
        const response = await apiClient.put('/diva/vivendidienstoumap/' + dto.id + '/', dto);
        return new ApiResponse(response);
    }
    static async removeDienstOuMap(id): Promise<ApiResponse<VivendiDienstOuMapDTO>> {
        const response = await apiClient.delete('/diva/vivendidienstoumap/' + id + '/');
        return new ApiResponse(response);
    }

}