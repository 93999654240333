
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EwoSoftwareversionDTO} from "@/model/dto";
import {EwoBaseDataGeneric} from "@/model/Ewmpg";
import {EwmpgApi} from "@/services/EwmpgApi";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";

const DataProps = Vue.extend({
  props: {}
})
@Component({
  components: {SimpletableButtonHeader}
})
export default class EwoSoftwareversionView extends mixins<SimpleViewMixin<EwoSoftwareversionDTO, EwoSoftwareversionDTO>>(SimpleViewMixin, DataProps) {
  data: EwoSoftwareversionDTO = new EwoBaseDataGeneric()
  error: EwoSoftwareversionDTO = new EwoBaseDataGeneric()

  async created() {
    await this.init(EwoBaseDataGeneric, EwmpgApi.getSoftwareversionList, (a) => EwmpgApi.putSoftwareversion(a), (b) => EwmpgApi.removeSoftwareversion(b))
    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  get importRoute() {
    return this.$route.name.includes('import')
  }
}
