
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import {OutsourcingApi} from "@/services/OutsourcingApi";
import {HistoryEvent, ListItemHelper} from "@/model/Shared";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("outsourcinglist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class OutsourcingListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  table = null

  archive = false


  getUrl() {
    return '/outsourcing/outsourcinglist/' + ((this.archive) ? 'archive/' : 'noarchive/' + "chain");
  }


  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)


    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    this.registerEvents([Event.outsourcingChanged, Event.outsourcingCreated, Event.outsourcingArchived])


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      "rowCallback": function (row, data) {
        if (data && data.orgUnit.id != self.$store.getters.getLocation.id) {
          jquery(row).addClass('foreign-row');
        }
      },
      order: [[1, "asc"]],
      rowId: "id",
      columns: [
        {
          class: 'not-export-col text-center',
          data: null,
          render: function (data, type, row) {
            // if row is foreign-row then return ListItemHelper.getInheritance() else return ''
            return data.orgUnit.id != self.$store.getters.getLocation.id ? ListItemHelper.getInheritance('chain_' + data.id) : ''
          }
        },
        {
          class: '',
          data: 'company'
        },
        {
          class: '',
          data: 'typString'
        },
        {
          class: '',
          data: 'phone'
        },
        {
          class: '',
          data: 'email'
        },
        {
          class: 'text-center',
          data: 'isoCert',
          render: function (data, type) {
            return data ? "X" : "";
          },
        },
        {
          class: '',
          data: 'orgUnit.name'
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons)
    })

    func.datatableInitComplete(table)
    self.table = table

    self.initContextMenu(contextMenuButtons)

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      if (!self.$store.getters.hasPermission(PermissionModul.Outsourcing, Permission.Overview)) {
        return;
      }
      let rowId = jquery(this).attr('id')
      let rowData = table.row(this).data();
      if (rowData && !rowData.inherited) {
        self.editOutsourcing(rowId)
      } else {
        self.$alert("Bearbeiten nicht möglich, da es sich um ein vererbtes Element handelt.")
      }
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Outsourcing, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.createOutsourcing()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Outsourcing, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.editOutsourcing(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.editOutsourcing(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Outsourcing, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/outsourcing/outsourcingdata', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/outsourcing/outsourcingdata', tr.attr('id')))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Outsourcing, Permission.Archive,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }, contextMenuButtons
    )
  }

  createOutsourcing() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/outsourcing/create/", query: queryParam})
  }

  editOutsourcing(id) {
    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();

    if (rowdata.orgUnit.id != this.$store.getters.getLocation.id) {
      this.$alert("Sie haben keine Berechtigung")
      return
    }

    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/outsourcing/edit/" + id, query: queryParam})
  }


  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "outsourcinglist", query: queryParam})
  }

  archiv(data) {
    this.$confirm('Sind sie Sicher ?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      OutsourcingApi.archivOutsourcing(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.outsourcingArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }
}
