
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {OrgUnitTreeDTO} from "@/model/dto";
import {OrgUnitSelectorItem} from "@/components/model/OrgUnitSelectorItem";
import {Tree, VueTreeList} from '@/libs/treelist';

@Component({components: {VueTreeList}})
export default class OrgUnitSelector extends Vue {
  data = [];
  showTree = false;
  tree: null;
  selected = []
  merkmale = []

  @Prop({default: false}) private noButtons: boolean;
  @Prop() private anchor;
  @Prop() private checkboxes: boolean;
  @Prop() private preSelected: number[];
  @Prop() private allowToSelect: number[];

  created() {
    if (this.preSelected) {
      this.selected = [...this.preSelected]
    }
    this.$store.getters.getLocations.forEach((o: OrgUnitTreeDTO) => {
      if (!this.anchor) {
        this.data.push(new OrgUnitSelectorItem(o, this.$store.getters.getLocation.id));
      } else {
        this.findAnchor(o)
      }
    })

    this.tree = new Tree(this.data.sort((a, b) => {
      return a.name.localeCompare(b.name)
    }))
    this.showTree = true;

    if (this.checkboxes) this.parseMerkmale(this.data)
  }


  defaultAction() {
    console.log('defaultAction')
  }

  customClick(params) {
    this.$emit('locationSelected', {id: params.model.id})
  }

  checkboxClicked(event, id) {
    if (event.target.checked) {
      this.selected.push(id)
    } else {
      this.selected = this.selected.filter(i => i != id)
    }


    this.$emit('checkboxClicked', this.selected.filter(id => !this.allowToSelect || this.allowToSelect.includes(id)))
  }

  subClicked(id, check) {
    this.$store.getters.getLocations.forEach((o: OrgUnitTreeDTO) => {
      this.selectRecursive(o, id, check)
    })

    this.$emit('checkboxClicked', this.selected)
  }

  merkmalClicked(id) {
    this.checkMerkmal(this.data, id)
    this.$emit('checkboxClicked', this.selected)
  }


  deselectAllClicked(id) {
    this.selected = []
    this.$emit('checkboxClicked', this.selected)
  }

  findAnchor(o: OrgUnitTreeDTO) {
    if (o.id == this.anchor) {
      this.data.push(new OrgUnitSelectorItem(o));
    } else {
      o.childs.forEach(child => this.findAnchor(child))
    }
  }

  selectRecursive(o: OrgUnitTreeDTO, selectedId, select = true) {
    if (o.id == selectedId) {
      o.childs.forEach(child => {
        if (select) {
          if (!this.selected.includes(child.id)) this.selected.push(child.id);
        } else {
          this.selected = this.selected.filter(i => i != child.id)
        }
        this.selectRecursive(o, child.id, select)
      });
    } else {
      o.childs.forEach(child => this.selectRecursive(child, selectedId, select))
    }
  }


  parseMerkmale(arr) {
    arr.forEach((elem) => {
      this.merkmale = [...this.merkmale, ...elem.dto.merkmale]
      this.parseMerkmale(elem.children)
    })
  }

  checkMerkmal(arr, merkmalId) {
    arr.forEach((elem) => {
      if (elem.dto.merkmale.find(merkmal => merkmal.id == merkmalId)) {
        this.selected.push(elem.dto.id)
      }
      this.checkMerkmal(elem.children, merkmalId)
    })
  }


  @Watch('preSelected')
  public watchValue(value) {
    this.selected = [...value]
  }

  get merkmalOpts() {
    return [...new Map(this.merkmale.map((item) => [item["id"], item])).values()].sort((a, b) => a.name.localeCompare(b.name));
  }
}
