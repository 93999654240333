
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import AdminDashboardFobiUnconfirmedStatus from "@/views/adminDashboard/components/modal/AdminDashboardFobiUnconfirmedStatus.vue";
import AdminDashboardEmeldStatus from "@/views/adminDashboard/components/modal/AdminDashboardEmeldStatus.vue";
import {DateHelper} from "../../../model/Date";
import {ListItemHelper} from "@/model/Shared";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

@Component({
  computed: {
    ListItemHelper() {
      return ListItemHelper
    },
    DateHelper() {
      return DateHelper
    }
  },
  components: {ListItemComponent, AdminDashboardEmeldStatus, AdminDashboardFobiUnconfirmedStatus}
})
export default class AdminDashboardBtMCard extends Vue {
  @Prop() chain!: boolean
  openCount = 0
  data = null
  tableData = []
  loading = true

  created() {
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    const paramsTasks = {
      ouId: this.$store.getters.getLocation.id,
      statusId: -1,
      countOnly: true,
      ouChain: this.chain,
      kategorie: 2 // BtM
    }

    this.data = await DashboardAdminApi.getDashboardTaskData(paramsTasks);
    this.openCount = this.data.count;
    this.loading = false
  }

  async showOpenChecks() {
    const paramsTasks = {
      ouId: this.$store.getters.getLocation.id,
      statusId: -1,
      ouChain: this.chain,
      kategorie: 2 // BtM
    }

    this.tableData = await DashboardAdminApi.getDashboardTaskData(paramsTasks);

    (this.$refs.btmModal as any).show();


  }
}
