import {OrgUnitTreeDTO} from "@/model/dto";

export class OrgUnitSelectorItem {
    public name: string;
    public children = [];
    public isLeaf = false;
    public id: number;
    public disabled = true;
    public selected = false;
    public expanded = true
    public dragDisabled = true
    public dto;
    public childCount = 0;


    constructor(elem: OrgUnitTreeDTO, selectedOrgUnitId = null) {
        this.dto = elem.dto
        this.isLeaf = elem.childs.length < 1
        this.name = elem.name
        this.id = elem.id
        this.children = elem.childs.map((child: OrgUnitTreeDTO) => {
            return new OrgUnitSelectorItem(child, selectedOrgUnitId);
        }
        ).sort( (a, b) => {
            return a.name.localeCompare(b.name)
        });

        this.countRecursive(this);
        if (this.childCount > 20) {
            this.expanded = (selectedOrgUnitId && this.containsSelectedId(selectedOrgUnitId));
        }
    }


    private countRecursive(self) {
        if (this.children && this.children.length > 0) {
            this.children.forEach(child => {
                self.childCount++;
                child.countRecursive(self);
            });
        }
    }

    private containsSelectedId(selectedOrgUnitId) {
        if (this.children && this.children.length > 0) {
            for (const child of this.children) {
                if (child.id === selectedOrgUnitId) {
                    return true;
                } else if (child.containsSelectedId(selectedOrgUnitId)) {
                    return true;
                }
            }
        }

        return false;
    }
}

