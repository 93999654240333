
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {MedProduktOutsourcingDTO, Permission, PermissionModul} from "@/model/dto";
import {OutsourcingApi} from "@/services/OutsourcingApi";
import PseudoTag from "@/components/PseudoTag.vue";
import {MedProduktOutsourcing} from "@/model/Outsourcing";
import MedProduktOutsourcingEditModal from "@/views/medprodukt/modal/MedProduktOutsourcingEditModal.vue";
import {MedProduktHelper} from "@/model/MedProdukt";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";


@Component(
    {
      components: {ListItemComponent, MedProduktOutsourcingEditModal, PseudoTag},
    }
)
export default class MedProduktOutsourcingMedProduktView extends Vue {
  @Prop() private medprodukt;
  @Prop() private holder: boolean;

  data: MedProduktOutsourcingDTO | null = null
  outsourcing: MedProduktOutsourcingDTO[] = []
  helper = MedProduktHelper
  modalId = 'medprodukt-outsourcing-edit-modal'
  edit(rowdata) {
    if (!this.editPermission) return

    this.data = rowdata
    this.$bvModal.show(this.modalId)
  }

  remove(rowdata) {
    this.$confirm(' Wirklich löschen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      OutsourcingApi.removeMedProduktOutsourcing(rowdata.id).then(() => {
        this.outsourcing = this.outsourcing.filter((tmp) => {
          return tmp.id != rowdata.id
        })
      }).catch(() => {
        this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
      })
    });
  }


  newElement() {
    this.data = new MedProduktOutsourcing()
    this.data.medProdukt = this.$props.medprodukt
    this.$bvModal.show(this.modalId)
  }



  updateTable(data) {
    this.$nextTick(() => {
      let found = false
      this.outsourcing = this.outsourcing.map((d) => {
        if (d.id == data.id) {
          found = true
          return data
        } else {
          return d
        }
      })
      if (!found) {
        this.outsourcing.push(data)
      }
    });
  }


  async created() {
    this.$root.$emit('loadingStart')
    this.outsourcing = await OutsourcingApi.getOutsourcingByMedProdukt(this.$props.medprodukt.id);
    this.$root.$emit('loadingDone')
  }

  get outsourcingData() {
    return this.outsourcing
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Update)
  }
}
