
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {GenericError, TaskTemplateDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import LabelComponent from "@/components/LabelComponent.vue";
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import NumberForm from "@/components/NumberForm.vue";
import DateForm from "@/components/DateForm.vue";
import moment from "moment/moment";
import {Task} from "@/model/Task";
import {TaskApi} from "@/services/TaskApi";

@Component({
  computed: {
    Task() {
      return Task
    }
  },
  components: {
    DateForm,
    NumberForm,
    PersonenkreisChooser,
    OrgUnitSelector,
    LabelComponent,
    Multiselect
  }
})
export default class TaskRhythmusComponent extends Vue {
  @Prop() value: TaskTemplateDTO;
  @Prop() error: GenericError;
  @Prop() taskKategorie;
  @Prop({default: true}) withInterval;
  @Prop({default: false}) standalone: boolean;

  data: TaskTemplateDTO = this.$props.value;

  weekdays = [{text: 'Montag', value: 'MO'}, {text: 'Dienstag', value: 'TU'}, {text: 'Mittwoch', value: 'WE'},
    {text: 'Donnerstag', value: 'TH'}, {text: 'Freitag', value: 'FR'}, {text: 'Samstag', value: 'SA'}, {
      text: 'Sonntag',
      value: 'SU'
    }]


  get taskRepetition() {
    const rep = this.$store.getters["task/getRepetitionText"]
    return this.withInterval ? rep : rep.filter(obj => obj.value != "INTERVAL")
  }

  get rruleWeekday() {
    if (!this.data.rrule_byweekday) return [];

    return this.data.rrule_byweekday.split(",").map(value => {
      return this.weekdays.find(obj => obj.value == value)
    })
  }

  set rruleWeekday(values) {
    if (this.data.rrule_freq == "WEEKLY") {
      this.data.rrule_byweekday = (values) ? values.value : null
    }
    else {
      this.data.rrule_byweekday = (values) ? values.map(value => value.value).join(",") : null
    }
  }

  rruleFreqChanged() {
    this.data.gueltigAb = null

    if (this.data.rrule_freq == "INTERVAL") {
      this.data.rrule_bymonth = null
      this.data.rrule_dynamik = null
      this.data.rrule_daysBefore = 0
      this.data.gueltigAb = moment().add(1, 'd').format(moment.HTML5_FMT.DATE);

      this.rruleDynamikChanged()

      this.data.rrule_daytype = null
      this.rruleDayTypeChanged()
    } else if (this.data.rrule_freq == "DAILY") {
      this.data.rrule_bymonth = null
      this.data.rrule_dynamik = null
      this.rruleDynamikChanged()

      this.data.rrule_daytype = null
      this.rruleDayTypeChanged()
    } else if (this.data.rrule_freq == "WEEKLY") {
      this.data.rrule_bymonth = null

      this.data.rrule_dynamik = "DYNAMIC"
      this.rruleDynamikChanged()

      this.data.rrule_daytype = "WEEKDAY"
      this.rruleDayTypeChanged()
    } else if (this.data.rrule_freq == "MONTHLY") {
      this.data.rrule_bymonth = null

      this.data.rrule_dynamik = "MONTHDAY"
      this.rruleDynamikChanged()
    } else if (this.data.rrule_freq == "YEARLY") {
      this.data.rrule_bymonth = "1"
      this.data.rrule_dynamik = "MONTHDAY"
      this.rruleDynamikChanged()
    }
  }

  rruleDayTypeChanged() {
    if (this.data.rrule_daytype == "WORKDAY") {
      this.data.rrule_byweekday = "MO,TU,WE,TH,FR"
    } else if (this.data.rrule_daytype == "WEDAY") {
      this.data.rrule_byweekday = "SA,SU"
    } else if (this.data.rrule_daytype == "WEEKDAY") {
      this.data.rrule_byweekday = (this.data.rrule_freq == "WEEKLY") ? "MO" : null
    } else if (!this.data.rrule_daytype) {
      this.data.rrule_byweekday = null
    }
  }

  rruleDynamikChanged() {
    if (this.data.rrule_dynamik == "MONTHDAY") {
      this.data.rrule_bymonthday = 1
      this.data.rrule_bysetpos = null
      this.data.rrule_daytype = null
      this.data.rrule_byweekday = null
    } else if (this.data.rrule_dynamik == "DYNAMIC") {
      this.data.rrule_bymonthday = null
      this.data.rrule_bysetpos = "1"
      this.data.rrule_daytype = "WORKDAY"
      this.rruleDayTypeChanged()
    } else if (!this.data.rrule_dynamik) {
      this.data.rrule_bymonthday = null
      this.data.rrule_bysetpos = null
    }
  }

  rruleSim() {
    TaskApi.getRrule(this.data).then((response) => {
      this.$alert(response.join("<br>"), "Simulierter Rhythmus", 'info', true)
    }).catch(e => {
      this.$alert("Unerwarteter Fehler. Prüfen Sie Ihre Eingaben.", "Fehler", 'error')
    })
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

  @Watch('withInterval')
  public watchWithInterval(newValue) {
    if (!this.withInterval && this.data.rrule_freq == "INTERVAL") {
      this.data.rrule_freq = "DAILY"
      this.rruleFreqChanged()
    }
  }
}
