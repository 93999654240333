import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import VuexRouterSync from 'vuex-router-sync';
import store from '../store'
import NProgress from 'nprogress'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Start from '../template/StartView.vue'
import LoginView from "@/views/auth/LoginView.vue";
import QrLoginView from "@/views/auth/QrLoginView.vue";
import {globalRoutes} from "@/router/routes/global";
import {userRoutes} from "@/router/routes/user";
import {kfzRoutes} from "@/router/routes/kfz";
import {medProduktRoutes} from "@/router/routes/medProdukt";
import {outsourcingRoutes} from "@/router/routes/outsourcing";
import {materialRoutes} from "@/router/routes/material";
import {einweisungRoutes} from "@/router/routes/einweisung";
import {fobiRoutes} from "@/router/routes/fobi";
import {taskRoutes} from "@/router/routes/task";
import {formRoutes} from "@/router/routes/form";
import {emeldRoutes} from "@/router/routes/emeld";
import {einsatzRoutes} from "@/router/routes/einsatz";
import {checkinRoutes} from "@/router/routes/checkin";
import {manualRoutes} from "@/router/routes/manual";
import {hygieneRoutes} from "@/router/routes/hygiene";
import {fileLexRoutes} from "@/router/routes/docs";
import {tagebuchRoutes} from "@/router/routes/tagebuch";
import {superadmin} from "@/router/routes/superadmin";
import PwVergessenView from "@/views/auth/PwVergessenView.vue";
import Impressum from "@/views/auth/Impressum.vue";
import PwResetView from "@/views/auth/PwResetView.vue";
import {divaRoutes} from "@/router/routes/diva";
import TokenView from "@/views/diva/TokenView.vue";


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Start,
        children: [
            ...globalRoutes,
            ...userRoutes,
            ...kfzRoutes,
            ...medProduktRoutes,
            ...outsourcingRoutes,
            ...materialRoutes,
            ...einweisungRoutes,
            ...fobiRoutes,
            ...taskRoutes,
            ...manualRoutes,
            ...formRoutes,
            ...emeldRoutes,
            ...einsatzRoutes,
            ...checkinRoutes,
            ...hygieneRoutes,
            ...fileLexRoutes,
            ...tagebuchRoutes,
            ...divaRoutes,
            ...superadmin,
            {
                path: '/loggedInImpressum',
                name: 'loggedInImpressum',
                component: Impressum
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/pwvergessen',
        name: 'pwvergessen',
        component: PwVergessenView
    },
    {
        path: '/pwreset/:token',
        name: 'pwreset',
        component: PwResetView,
        props: true
    },
    {
        path: '/qrlogin',
        name: 'qrlogin',
        component: QrLoginView
    },
    {
        path: '/token/:token',
        name: 'divatoken',
        component: TokenView,
        props: true
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: Impressum
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    base: process.env.BASE_URL,
    routes,
})


const originalPush = router.push
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }

        return Promise.reject(err)
    })
}


const copiedRoutes = new Map()

function hasRoutePermissions(to) {
    let noPermission = false;
    to.matched.forEach((route) => {
        if (route.meta && (route.meta.permission !== undefined || route.meta.permissions !== undefined)) {
            let toCheck = []
            if (route.meta.permissions !== undefined) {
                toCheck = route.meta.permissions
            } else {
                toCheck.push(route.meta.permission)
            }

            let hasPermission = false
            toCheck.forEach((permission) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hasPermission |= store.getters.hasPermission(permission[0], permission[1])
            })

            if (!hasPermission) {
                const keys = Object.keys(route.components)

                keys.forEach((key) => {
                    if (!copiedRoutes.has(route.name)) {
                        copiedRoutes.set(route.name, Object.assign({}, route.components))
                    }

                    if (keys.includes("CRUD")) {
                        if (key === "CRUD") {
                            route.components["CRUD"] = () => import('@/views/auth/NoPermissionView.vue')
                        }
                    } else {
                        route.components[key] = () => import('@/views/auth/NoPermissionView.vue')
                    }
                })
                noPermission = true
            } else {
                if (copiedRoutes.has(route.name)) {
                    const components = copiedRoutes.get(route.name)
                    Object.keys(components).forEach((key) => {
                        route.components[key] = components[key]
                    })
                    copiedRoutes.delete(route.name)
                }

            }
        }
    })

    return !noPermission
}

router
    .beforeEach((to, from, next) => {
        let routePermissions = false
        let tabClicked = false
        if (to.params
            && to.params['idx'] !== undefined
            && to.params['id'] !== undefined
            && from.params
            && from.params['id'] !== undefined
        ) {
            const fromIdx = (from.params['idx'] !== undefined) ? from.params.idx : -1
            tabClicked = to.params.id == from.params.id && to.params.idx != fromIdx
        }

        if (!tabClicked) {
            store.dispatch('menu/setLoading', false)
        }

        NProgress.start()
        const q = to.query;

        if (q.setlocation) {
            console.log('setlocation')
            store.dispatch('setLocation', {id: q.setlocation})
        }

        if (q.token) {
            console.log('settoken')
            store.dispatch('updateToken', q.token)
        }

        if (q.ou) {
            console.log('changeLocation')
            store.dispatch('changeLocation', {location: {id: q.ou}}).then(() => {
                next('/')
            })
            return true
        }

        // console.log('...' + to.path)
        if (to.path.includes('/register')) {
            store.dispatch('logout')
            next()
            return true
        }

        if (to.path === '/login' || to.path === '/qrlogin') {
            store.dispatch('logout')
            next()
            return true
        }

        if (to.path.includes('/pwvergessen')) {
            store.dispatch('logout')
            next()
            return true
        }

        if (to.path.includes('/impressum')) {
            store.dispatch('logout')
            next()
            return true
        }

        if (to.path.includes('/pwreset')) {
            store.dispatch('logout')
            next()
            return true
        }

        if (to.path.includes('/token')) {
            next()
            return true
        }

        if (to.path) {
            if ((to.path !== '/login' && to.path !== '/qrlogin') && (!store.getters.getToken || !store.getters.getUser || !store.getters.getLocation)) {
                store.dispatch('menu/calledRoute', {path: to.path})


                store.dispatch('fetchToken')
                    .then(function (data) {
                        routePermissions = hasRoutePermissions(to)

                        if (routePermissions && to.meta.loading && !tabClicked) {
                            store.dispatch('menu/setLoading', true)
                        }
                        next()
                    })
                    .catch((e) => {
                        next('/login')
                    })

            } else {
                store.dispatch('menu/calledRoute', {path: to.path})
                routePermissions = hasRoutePermissions(to)
                next()
            }

            store.dispatch('menu/setActive', {path: to.path})

            if (routePermissions && to.meta.loading && !tabClicked) {
                store.dispatch('menu/setLoading', true)
            }
        }
    })

router.afterEach(() => {
    NProgress.done()
})

VuexRouterSync.sync(store, router);

export default router
