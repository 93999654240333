import { render, staticRenderFns } from "./VivendiView.vue?vue&type=template&id=987eff62&scoped=true&"
import script from "./VivendiView.vue?vue&type=script&lang=ts&"
export * from "./VivendiView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "987eff62",
  null
  
)

export default component.exports