
import {Component, Prop} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {MaterialDateDTO, Permission, PermissionModul} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import {DateHelper} from "@/model/Date";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {ListItemHelper} from "@/model/Shared";
import MaterialDateEditModal from "@/views/material/components/MaterialDateEditModal.vue";
import GenericMixin from "@/views/GenericMixin.vue";


@Component(
    {
      components: {DatatableButtonHeader, MaterialDateEditModal},
    }
)
export default class MaterialDateView extends GenericMixin {
  @Prop() private id: string;
  tableId = 'dTable_' + uuid();
  data: MaterialDateDTO | null = null
  modalId = 'material-date-edit-modal'


  edit(id) {
    if (!this.editPermission) {
      return;
    }

    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();
    this.data = rowdata
    this.$bvModal.show(this.modalId)
    this.modalId = 'material-date-edit-modal' + uuid()
  }

  updateTable(data) {
    let rowId = "#" + data.material.id;
    let table = jquery('#' + this.tableId).DataTable();
    if (table.row(rowId).data()) {
      table.row(rowId).data(data).draw();
    }
  }


  mounted() {
    const buttons = (this.editPermission) ? [
      {
        className: 'text-secondary bg-white',
        text: '<i class="fas fa-pen" />',
        extend: 'selectedSingle',
        action: function (e, dt, node, config) {
          let id = table.row({selected: true}).id()
          self.edit(id)
        }
      },
      {
        className: 'text-secondary bg-white',
        titleAttr: 'Betrifft',
        text: '<i class="fas fa-angle-double-right" />',
        action: function (e, dt, node, config) {
          self.openModal()
        }
      }
    ] : []

    this.$root.$emit("loadingStart")
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/material/materialdate/datetyp/' + self.id,
        type: 'GET'
      }),
      rowId: "material.id",
      'columnDefs': [
        {'orderData': [4], 'targets': [3]},
        {'sortable': true, 'searchable': false, 'visible': false, 'type': 'num', 'targets': [5]}
      ],
      columns: [
        {
          data: null,
          render: function (data, type, row) {
            if (data.material) {
              return ListItemHelper.getTag(data.material)
            }
            return ""
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.date ? moment(data.date).format("DD.MM.YYYY") : ""
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseSkip(data)
          }
        },
        {
          class: 'center',
          data: 'skipReason'
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return data.icon + "<span class='d-none'>" + data.status + "</span>"
          }
        },
        {
          class: '',
          data: 'statusNumeric'
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(buttons, true)
    })

    func.datatableInitComplete(table)

    jquery('#' + this.tableId + ' tbody').on('click', 'tr', function () {
      let rowId = jquery(this).attr('id')
      console.log(rowId)
      self.edit(rowId)
    })
  }


  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Material_Dates, Permission.Update)
  }

  openModal() {
    this.$emit('openModal')
  }
}
