import { apiClient } from './AbstractHTTPService'
import { func } from '@/scripts/scripts'

const PATH = '/jwt/'

export default {
  getToken () {
    let location = false
    if (func.isLocalStorage()) {
      console.log('READ LOCAL STORAGE location')
      location = localStorage.getItem('location')
      console.log(location)
    }

    if (location && typeof (location) !== 'undefined') {
      return apiClient.get(PATH + '?location=' + location)
    } else {
      return apiClient.get(PATH)
    }
  }
}
