import {apiClient} from './AbstractHTTPService'
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {FormDTO, FormRowDTO, FormRowRequestDTO, FormVersionDTO, UpsertFormDTO} from "@/model/dto";
import {UpsertForm, UpsertFormError} from "@/model/FormVersion";
import store from "@/store";
import {Event} from "@/model/Constants";


export abstract class FormApi {
    static async putForm(Form: UpsertForm): Promise<ApiResponse<UpsertFormDTO | UpsertFormError>> {
        const response = await apiClient.put('/form/form/', Form);
        //   store.dispatch("medprodukt/herstellerUpdate");
        return new ApiResponse(response);
    }

    static async putFormVersion(Form: UpsertFormDTO): Promise<ApiResponse<UpsertFormDTO>> {
        const response = await apiClient.put('/form/formversion/' + Form.id + '/', Form);
        //   store.dispatch("medprodukt/herstellerUpdate");
        return new ApiResponse(response);
    }

    static async getFormVersion(id): Promise<ApiResponse<UpsertFormDTO>> {
        const response = await apiClient.get('/form/formversion/' + id);
        return new ApiResponse(response);
    }

    static async getFormVersions(formId): Promise<FormVersionDTO[]> {
        const response = await apiClient.get('/form/formversion/form/' + formId);
        return response.data.data;
    }


    static async removeFormVersion(id): Promise<any> {
        const response = await apiClient.delete('/form/formversion/' + id);
        //store.dispatch("kfz/outsourcingUpdate")
        return response.data;
    }

    static async removeField(id): Promise<any> {
        const response = await apiClient.delete('/form/formfield/' + id);
        //store.dispatch("kfz/outsourcingUpdate")
        return response.data;
    }

    static async releaseFormVersion(versionId: number): Promise<ApiResponse<FormVersionDTO>> {
        const response = await apiClient.put('/form/formrelease/' + versionId);
        store.dispatch("crudEvent", new CrudEvent(Event.formChanged, response.data.data));
        return new ApiResponse(response);
    }

    static async archivForm(id): Promise<ApiResponse<FormDTO>> {
        const response = await apiClient.delete('/form/form/'+ id );
        store.dispatch("crudEvent", new CrudEvent(Event.formChanged, response.data.data));
        return response.data.data;
    }


    static async getFormOuChain(): Promise<FormDTO[]> {
        const response = await apiClient.get('/form/form/chain');
        return response.data.data;
    }

    static async getFormRows(dto: FormRowRequestDTO): Promise<FormRowDTO[]> {
        const response = await apiClient.post('/form/formrow', dto);
        return response.data.data;
    }

    static async getFieldIdentifiers(): Promise<string[]> {
        const response = await apiClient.get('/form/fieldidentifier');
        return response.data.data;
    }

}