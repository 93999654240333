
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Permission, PermissionModul, UserDateDTO} from "@/model/dto";
import UserDateEditModal from "@/views/user/modal/UserDateEditModal.vue";
import {DateApi} from "@/services/DateApi";
import moment from "moment/moment";
import {DateHelper} from "@/model/Date";
import {v4 as uuid} from 'uuid';


@Component(
    {
      components: {UserDateEditModal},
    }
)
export default class UserDateUserView extends Vue {
  @Prop() private id: string;
  data: UserDateDTO | null = null
  dates: UserDateDTO[] = []
  dateHelper = DateHelper
  modalId = 'user-date-edit-modal'

  edit(rowdata) {
    if (!this.editPermission) {
      return;
    }
    this.data = rowdata
    this.$bvModal.show(this.modalId)
    this.modalId = 'user-date-edit-modal' + uuid()
  }

  updateTable(data) {
    this.$nextTick(() => {
      this.dates = this.dates.map((d) => {
        if (d.dateTyp.id == data.dateTyp.id) {
          return data
        } else {
          return d
        }
      })
    });
  }


  toMoment(d) {
    return moment(d)
  }


  async created() {
    this.$root.$emit('loadingStart')
    this.dates = await DateApi.getUserDatesByUser(this.$props.id);
    this.$root.$emit('loadingDone')
  }

  get datesData() {
    return this.dates.sort((a, b) => {
      return b.statusNumeric - a.statusNumeric
    })
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Dates, Permission.Update)
  }
}
