<template>
  <div>
    <div v-if="item.submenu && item.submenu.length>0" :class="{'my-3': item.isHeading}">
      <div @click="handleClick(item)"
           class="nav-link py-2"
           v-bind:class="{ active: item.active && !item.isHeading, 'sb-sidenav-menu-heading': item.isHeading}"
           v-b-toggle="'accordion' +removeWhitespaces(item.name)">
        <div v-if="item.icon" class="sb-nav-link-icon">
          <i v-bind:class="item.icon ? item.icon : ''"></i>
        </div>
        {{ item.name }}
        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
      </div>

      <b-collapse :visible="item.isHeading" :id="'accordion' + removeWhitespaces(item.name)"
                  :accordion="'my-accordion-' + removeWhitespaces(item.name)"
                  role="tabpanel">
        <div v-bind:class="{ 'sb-sidenav-menu-nested': !item.isHeading}">
          <SideMenuItem v-for="(sidemenuitem,idx) in item.submenu" :key="idx" :item="sidemenuitem"></SideMenuItem>
        </div>
      </b-collapse>
    </div>
    <div v-else>
      <div @click="handleClick(item)" v-bind:class="{ active: item.active }" class="nav-link py-2">
        <div class="sb-nav-link-icon">
          <i v-if="item.icon" v-bind:class="item.icon ? item.icon : ''"></i>
        </div>
        {{ item.name }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'SideMenuItem',
  methods: {
    removeWhitespaces(str) {
      return str.replace(/\s/g, '_')
    },
    handleClick(item) {
      if (item.path !== undefined && item.path && item.path !== '') {
        this.$router.push(item.path);
        if (!this.$screen.xl) {
          this.toggleSideNav()
        }
      }
    },
    toggleSideNav() {
      this.$store.dispatch('toggleSidebar')
    }
  },
  props: [
    'item'
  ]
}
</script>

<style scoped>

</style>
