import {AdditionalFieldResultDTO, EinsatzDTO, EinsatzRequestDTO, EinsatzVorlageDTO, FormDTO, FormRowDTO, OrgUnitDTO, UserResponseDTO} from "@/model/dto";
import moment from "moment/moment";
import {DateHelper} from "@/model/Date";
import {CheckinRequest} from "@/model/Checkin";
import {AbstractClass, AbstractEntityTaskFormRequest} from "@/model/AbstractClasses";


export class Einsatz extends AbstractClass implements EinsatzDTO {
    addFields: AdditionalFieldResultDTO[];
    archive: boolean | null;
    datumEnde: string | null;
    datumStart: string | null;
    orgUnit: OrgUnitDTO | null;
    rows: FormRowDTO[];
    sent: boolean | null;
    text: string | null;
    vorlage: EinsatzVorlageDTO | null;
    zeitEnde: string | null;
    zeitStart: string | null;
    creator: UserResponseDTO | null;
}

export class EinsatzVorlage extends AbstractClass  implements EinsatzVorlageDTO {
    archive: boolean | null;
    forms: FormDTO[];
    name: string;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;
}

export class EinsatzRequest extends AbstractEntityTaskFormRequest implements EinsatzRequestDTO {
    creatorId: number | null;
    ouChain: boolean;
    userId: number | null;
    vorlageId: number | null;

    constructor() {
        super()
        this.dateFrom = moment().subtract(2, 'weeks').format(moment.HTML5_FMT.DATE);
        this.dateTo = moment().format(moment.HTML5_FMT.DATE);
        this.archive = false;
    }

    vorlage: EinsatzVorlageDTO = null;


    formDataToRequest(self = new EinsatzRequest()) {
        if (self instanceof EinsatzRequest) {
            self.vorlageId = this.vorlage ? this.vorlage.id : null
            self.ouChain = this.ouChain
            self.creatorId = null
        }

        if (self instanceof CheckinRequest) {
            //TODO
        }
        self.dateFrom = DateHelper.getSqlDate(this.dateFrom)
        self.dateTo = DateHelper.getSqlDate(this.dateTo)
        self.archive = this.archive


        self.ouId = null
        self.userId = null

        return self
    }


}