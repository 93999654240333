
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {GenericError} from "@/model/dto";
import FileLexTreeData from "@/views/file/data/FileLexTreeData.vue";
import {FileLexTree} from "@/model/File";
import {FileApi} from "@/services/FileApi";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component({
  components: {
    FileLexTreeData,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class FileLexTreeEdit extends mixins<GenericMixin<FileLexTree, GenericError>>(GenericMixin, DataProps) {
  data = new FileLexTree();
  error = new GenericError();
  showTabs = false

  async created() {
    this.init();
  }


  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = FileApi.getTree(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean) {
    const p = FileApi.putFilelexTree(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        bus.$emit(Event.treeChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    //do nothing
  }

  remove(id) {
    this.$confirm('Wollen Sie diese Ebene und darunterliegende Ebenen wirklich löschen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      FileApi.removeFileLexTree(id)
          .then(() => {
            bus.$emit(Event.treeChanged, null);
            this.$router.push({path: "/docs/tree/config"})
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten. Prüfen Sie, ob die Ebenen noch in Verwendung sind.");
          })
    });
  }

  createChild() {
    this.$router.push({path: "/docs/tree/" + this.data.id + "/create"})
  }

  get enabled() {
    return (this.data.orgUnit && this.data.orgUnit.id == this.$store.getters.getLocation.id)
  }
}
