
import {Component, Prop} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {MedProduktDateDTO, Permission, PermissionModul} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import MedProduktDateEditModal from "@/views/medprodukt/modal/MedProduktDateEditModal.vue";
import {MedProduktHelper} from "@/model/MedProdukt";
import {DateHelper, MedProduktDateListItem} from "@/model/Date";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import GenericMixin from "@/views/GenericMixin.vue";


@Component(
    {
      components: {DatatableButtonHeader, MedProduktDateEditModal},
    }
)
export default class MedProduktDateView extends GenericMixin {
  @Prop() private dataSet: MedProduktDateListItem;
  tableId = 'dTable_' + uuid();
  data: MedProduktDateDTO | null = null
  helper = MedProduktHelper
  modalId = 'medprodukt-date-edit-modal'


  edit(id) {
    if (!this.editPermission) {
      return;
    }

    if (id.includes('_')) {
      let tmpArr = id.split("_")
      id = tmpArr[0]
      let rowId = "#" + id;
      let table = jquery('#' + this.tableId).DataTable();
      let rowdata: MedProduktDateDTO = table.row(rowId).data();
      this.data = rowdata.childs.find(child => child.medProdukt.id == tmpArr[1])
    } else {
      let rowId = "#" + id;
      let table = jquery('#' + this.tableId).DataTable();
      let rowdata = table.row(rowId).data();
      this.data = rowdata
    }

    if (this.data.locked) return;
    this.$bvModal.show(this.modalId)
    this.modalId = 'medprodukt-date-edit-modal' + uuid()
  }

  updateTable(data) {
    //jquery('#' + this.tableId).dataTable().api().ajax.reload();

    let rowId = "#" + toId((data))
    let table = jquery('#' + this.tableId).DataTable();

    if (table.row(rowId).data()) {
      table.row(rowId).child.hide();

      table.row(rowId).data(data).draw();
    }
  }

  mounted() {
    const buttons = (this.editPermission) ? [
      {
        className: 'text-secondary bg-white',
        titleAttr: 'Betrifft',
        text: '<i class="fas fa-angle-double-right" />',
        action: function (e, dt, node, config) {
          self.openModal()
        }
      }
    ] : []

    this.$root.$emit("loadingStart")
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/medprodukt/medproduktdate/datetyplist/',
        type: 'POST',
        data: function () {
          return JSON.stringify(self.dataSet);
        },
      }),
      rowId: function (row) {
        return toId(row)
      },
      order: [5],
      select: false,
      columnDefs: [
        {
          targets: 0,
          "createdCell": function (td, cellData, rowData, row, col) {
            if (rowData.childs.length === 0) {
              jquery(td).removeClass('dt-control')
            }
          }
        },
        {'orderData': [5], 'targets': [4]},
        {'sortable': true, 'searchable': false, 'visible': false, 'type': 'num', 'targets': [6]}
      ],
      columns: [
        {
          className: 'dt-control not-export-col',
          orderable: false,
          data: null,
          defaultContent: ''
        },
        {
          class: '',
          data: null,
          render: (data, type, row) => {
            return this.helper.getMedProduktTagHtml(data.medProdukt)
          }
        },
        {
          class: 'center',
          data: null,
          render: function (data, type, row) {
            return getDate(data)
          }
        },
        {
          class: 'center text-center',
          data: null,
          render: function (data, type, row) {
            return getSkip(data)
          }
        },
        {
          class: 'center',
          data: 'skipReason'
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return data.icon + "<span class='d-none'>" +data.status + "</span>"
          }
        },
        {
          class: 'center',
          data: 'statusNumeric'
        },
        {
          class: '',
          data: null,
          render: function (data,type,row) {
            return data.ewmpgDateTyp ? data.ewmpgDateTyp.infotext : ''
          }
        },
        {
          class: 'center',
          data: null,
          render: function (data, type, row) {
            return data.dateTyp ? data.dateTyp.orgUnit?.name : "EWO"
          }
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(buttons, true)
    })
    func.datatableInitComplete(table)

    jquery('#' + this.tableId + ' tbody').on('click', 'tr', function (e) {
      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }

      let rowId = jquery(this).attr('id')
      if (!rowId) return;
      self.edit(rowId)
    })

    // Add event listener for opening and closing details
    table.on('click', 'td.dt-control', async function (e) {
      let tr = e.target.closest('tr');
      let row = table.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row

        const childRow = openChildContent(row.data());
        row.child(childRow).show();
      }
    });
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Dates, Permission.Update)
  }

  openModal() {
    this.$emit('openModal')
  }
}


function openChildContent(d: MedProduktDateDTO) {
  let str = '<table id="myChildTable" class="table-sm my-3 ml-5" style="width: 90%">'
  d.childs.sort((a, b) => a.statusNumeric > b.statusNumeric ? -1 : 1).forEach(child => str += getChildRow(child, d));
  str += '</table>';

  return str;
}

function getChildRow(d: MedProduktDateDTO, parent: MedProduktDateDTO) {
  let medProdukt = d.medProdukt


  return (
      '<tr id="' + toId(parent) + '_' + d.medProdukt.id + '">' +
      '<td>' + MedProduktHelper.getMedProduktTagHtml(medProdukt) + '</td>' +
      '<td>' + getDate(d) + '</td>' +
      '<td>' + getSkip(d) + '</td>' +
      '<td class="text-center">' + (d.icon) + '</td>' +
      '</tr>'
  );
}

function getDate(data) {
  return data.date ? moment(data.date).format("DD.MM.YYYY") : ""
}

function getSkip(data) {
  return DateHelper.parseSkip(data)
}

function toId(row) {
  return row.medProdukt.id + "-" + (row.dateTyp ? "mpg-" + row.dateTyp.id : "ewo-" + row.ewmpgDateTyp.id);
}
