
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DateTypDTO, FileCategoryDTO, GenericError, MaterialArtDTO, MaterialDateTypDTO} from "@/model/dto";
import Multiselect from '@/libs/multiselect'
import {MaterialDateTyp} from "@/model/Date";
import {DateApi} from "@/services/DateApi";
import NumberForm from "@/components/NumberForm.vue";
import PseudoTag from "@/components/PseudoTag.vue";
import MaterialView from "@/views/material/MaterialView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {
    ChainIconTooltipTableCell,
    LabelComponent,
    ListItemComponent,
    SimpletableButtonHeader,
    SettingsTooltip,
    MaterialView,
    PseudoTag,
    NumberForm,
    Multiselect
  }
})
export default class MaterialDateSettingsView extends mixins<SimpleViewMixin<MaterialDateTyp, GenericError>>(SimpleViewMixin, DataProps) {
  data: MaterialDateTypDTO = new MaterialDateTyp()
  error: GenericError = new GenericError()

  showDateTypModal = false
  dateTypes: DateTypDTO[] = []
  orgUnitDateTypes: MaterialDateTypDTO[] = []
  chainDateTypes: MaterialDateTypDTO[] = []

  materialArtOpts: MaterialArtDTO[] = []
  fileCategories: FileCategoryDTO[] = []

  async created() {
    await this.init(MaterialDateTyp,
        DateApi.getMaterialDateTypesOu,
        (a) => DateApi.putMaterialDateTypOu(a),
        (b) => DateApi.removeMaterialDateTypOu(b))

    this.dateTypes = await this.$store.dispatch("material/fetchDateTypCatalog")
    this.fileCategories = await this.$store.dispatch("material/fetchDateiKategorienKatalog")
    this.orgUnitDateTypes = await DateApi.getMaterialDateTypesOu()
    this.materialArtOpts = await this.$store.dispatch("material/fetchMaterialArt")
    this.chainDateTypes = await this.$store.dispatch("material/fetchDateTypesOu")
    this.$root.$emit('loadingDone')

  }

  get getDateTypes() {
    return this.dateTypes.sort((a, b) => a.name.localeCompare(b.name))
  }


  get getOrgUnitCategories() {
    return this.sort(this.orgUnitDateTypes)
  }

  get getChainCategories() {
    return this.sort(this.chainDateTypes
        .filter(dateType => dateType.orgUnit.id != this.$store.getters.getLocation.id))
  }

  sort(objects) {
    return objects ? objects.sort((a, b) => a.formatted.localeCompare(b.formatted)) : []
  }

  get archivTxt() {
    return "Löschen"
  }

  removeElement(id) {
    this.data.materialArt = this.data.materialArt.filter((e) => e.id != id);
  }

}
