import {CrudEvent} from "@/model/AbstractClasses";
import {AbstractListItemDTO, EntityStandortDTO} from "@/model/dto";

export class StoreHelper {
    static update(commitMethod: string, currentState, event: CrudEvent, commit) {
        if (!currentState || currentState.length == 0) return;

        if (!event.dto) {
            commit(commitMethod, currentState.filter(stateItem => stateItem['id'] != event.id))
        } else if (event.dto['archive']) {
            commit(commitMethod, currentState.filter(stateItem => stateItem['id'] != event.dto.id))
        } else {
            if (!event.dto) return;

            const replace = currentState.filter(stateItem => stateItem['id'] != event.dto.id)
            replace.push(event.dto);
            commit(commitMethod, replace)
        }
    }

    static updateEntityStandorte(remove: boolean, objectField: string, commitMethod: string, currentState, event: CrudEvent, commit) {
        if (!event.dto) return;
        if (!currentState || currentState.length == 0) return;

        const eventDto: AbstractListItemDTO = <AbstractListItemDTO>event.dto

        if (remove || event.dto['archive']) {
            let newStandorte = []
            if (objectField === null) {
                newStandorte = currentState.filter((standort: EntityStandortDTO) => standort.id != eventDto.id)
            } else {
                newStandorte = currentState.filter((standort: EntityStandortDTO) => !standort[objectField] || (standort[objectField] && standort[objectField].id != eventDto.id))
            }
            commit(commitMethod, newStandorte)
        } else {
            let existingStandort
            if (objectField != null) {
                existingStandort = currentState.find((standort: EntityStandortDTO) => (standort[objectField] && standort[objectField].id == eventDto.id))

                if (!existingStandort) {
                    const randomId = Math.floor(Math.random() * 10000000000000000) * -1;
                    existingStandort = {id: randomId}

                }
                existingStandort[objectField] = eventDto;
                existingStandort.dto = eventDto;
                existingStandort.icon = eventDto.icon;
                existingStandort.iconPlain = eventDto.iconPlain;
                existingStandort.text = eventDto.text;
                existingStandort.searchText = eventDto.searchText;
                existingStandort.type = eventDto.type;
            } else {
                existingStandort = event.dto;
            }


            let newStandorte = []
            if (objectField === null) {
                newStandorte = currentState.filter((standort: EntityStandortDTO) => standort.id != eventDto.id)
            } else {
                newStandorte = currentState.filter((standort: EntityStandortDTO) => (!standort[objectField] || (standort[objectField] && standort[objectField].id != eventDto.id)))
            }

            newStandorte.push(existingStandort);
            commit(commitMethod, newStandorte)
        }
    }
}