
import {Component} from "vue-property-decorator";
import DateForm from "@/components/DateForm.vue";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {CheckinDTO, GenericError} from "@/model/dto";
import {Checkin} from "@/model/Checkin";
import {CheckinApi} from "@/services/CheckinApi";
import {ApiResponse} from "@/model/AbstractClasses";
import NumberForm from "@/components/NumberForm.vue";
import moment from "moment";
import {TaskApi} from "@/services/TaskApi";
import {Task} from "@/model/Task";

@Component({components: {NumberForm, DateForm}})
export default class CheckoutMask extends mixins<GenericMixin<CheckinDTO, GenericError>>(GenericMixin) {
  error: GenericError = new GenericError()
  data: CheckinDTO = new Checkin()


  undoneTask = false
  async created() {
    this.data = this.$store.state.checkin.checkin

    this.data.datumEnde = moment().format(moment.HTML5_FMT.DATE);
    Checkin.handleEndzeit(this.data)

    const tasks = await TaskApi.getCheckinTasks(this.data.id)
    this.undoneTask = (tasks.find(a => a.status.id != Task.STATUS_DONE)) ? true : false

    this.$root.$emit('loadingDone');
  }


  onSubmit(goBack = true) {
    this.data.checkout = true
    const p = CheckinApi.putCheckin(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data !== undefined) {
        this.$store.dispatch('checkin/setCheckin', data.data)
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push("/checkinlist");
  }
}
