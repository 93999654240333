
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {DesiDTO, GenericError, TaskDTO} from "@/model/dto";
import {Route} from "vue-router";
import DesiDataView from "@/views/hygiene/data/DesiDataView.vue";
import {Desi} from "@/model/Hygiene";
import {HygieneApi} from "@/services/HygieneApi";
import moment from "moment";


@Component({
  components: {
    DesiDataView,
  },
  watch: {
    '$route'(val: Route) {
      this.init()
    }
  }
})
export default class DesiCreateView extends mixins<GenericMixin<DesiDTO, GenericError>>(GenericMixin) {
  @Prop({default:true}) standalone;
  @Prop() task:TaskDTO | null;

  data = new Desi();
  error = new GenericError();


  async created() {
    this.init()
  }

  init() {
    this.data = new Desi()
    if (!this.standalone && this.task) {
      this.data.task = this.task
      this.data.kfz = this.task['kfz']
      if (this.task['medProdukt']) {
        this.data.medProdukts = [this.task['medProdukt']]
      }
      if (this.task['material']) {
        this.data.materials = [this.task['material']]
      }
      this.data.category = Desi.CATEGORY_ROUTINE
      this.data.scopes = this.task.scopes

      if (this.task.desiRoutine) {
        this.data.desiRoutine = this.task.desiRoutine
        this.data.disinfectant = this.task.desiRoutine.disinfectant
        this.data.concentration = this.task.desiRoutine.concentration
        this.data.einwirkZeit = this.task.desiRoutine.einwirkZeit
      }
    }
    this.data.date = moment().format(moment.HTML5_FMT.DATE);
  }

  onSubmit(goBack: boolean) {
    const p = HygieneApi.putDesi(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send desiCreated event')
        bus.$emit(Event.desiCreated, new ObjectEvent(data.data.id, data.data));
        if (this.standalone) {
          this.goBack()
        } else {
          this.$emit("created", data.data)
        }
      }
    })
  }

  goBack() {
    this.$router.push(this.$store.state.route.from.fullPath)
  }

}
