
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import Multiselect from "@/libs/multiselect";
import MaterialStandortKorrekturView from "@/views/material/MaterialStandortKorrekturView.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import MaterialNavComponent from "@/views/material/components/MaterialNavComponent.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import {TaskApi} from "@/services/TaskApi";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {
        MaterialNavComponent,
        DatatableButtonHeader,
        MaterialStandortKorrekturView,
        Multiselect
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class MaterialView extends Vue {
  tableRoute = false
  clb = (payload: ObjectEvent<any>) => this.archivTask(payload.data)

  handleRoute(route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'material'
        && (this.$store.getters.hasPermission(PermissionModul.Material, Permission.Overview)
            || this.$store.getters.hasPermission(PermissionModul.Material, Permission.Overview_Public))
    ) {
      this.$router.push("/material/overview")
    }

  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.taskArchiveRequest, this.clb)
  }

  destroyed() {
    bus.$off(Event.taskArchiveRequest, this.clb)
  }

  archivTask(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie Aufgabe ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      TaskApi.archivEntityTaskTemplate(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.taskArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

}
