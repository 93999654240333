
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {AuktionDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import {Auktion} from "@/model/Diva";
import {DivaApi} from "@/services/DivaApi";
import AuktionDataView from "@/views/diva/data/AuktionDataView.vue";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component({
  components: {
    AuktionDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class AuktionEditView extends mixins<GenericMixin<AuktionDTO, GenericError>>(GenericMixin, DataProps) {
  data = new Auktion();
  error = new GenericError();
  showTabs = false
  tabIndex = 0

  async created() {
    this.init();
  }


  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = DivaApi.getAuktion(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean) {
    const p = DivaApi.putAuktion(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        bus.$emit(Event.divaChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push("/diva/auktionlist")
  }

  archiv() {
    bus.$emit(Event.divaArchiveRequest, new ObjectEvent(this.data.id, this.data));
  }

  get archivTxt() {
    if (!this.editPermission) {
      return null;
    }

    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  get editPermission() {
    return !!this.$store.getters.hasPermission(PermissionModul.Diva, Permission.Access);
  }
}
