

import {Component, Prop, Vue} from "vue-property-decorator";
import ReportNotizView from "@/views/emeld/ReportNotizView.vue";
import {ReportDTO} from "@/model/dto";

@Component({
  components: {ReportNotizView}
})
export default class ReportNotizModal extends Vue {
  @Prop() private value;
  @Prop() private selectedItem: ReportDTO
  headerTextVariant: 'warning'

  get showModal() {
    return this.value
  }

  set showModal(v) {
    this.$emit("input", v);
  }
}
