
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DesiDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import DateForm from "@/components/DateForm.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import FileUpload from "@/components/FileUpload.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {Desi} from "@/model/Hygiene";
import MedProduktChooser from "@/components/MedProduktChooser.vue";
import {UserId} from "@/model/Constants";
import PseudoTag from "@/components/PseudoTag.vue";
import LabelComponent from "@/components/LabelComponent.vue";

@Component({
  computed: {
    UserId() {
      return UserId
    },
    Desi() {
      return Desi
    }
  },
  components: {
    LabelComponent,
    PseudoTag,
    MedProduktChooser,
    ListItemComponent,
    FileUpload,
    FormInput,
    DateForm,
    PersonenkreisChooser,
    Multiselect
  }
})
export default class DesiDataView extends Vue {
  @Prop() value: DesiDTO;
  @Prop() error: GenericError;
  @Prop({default: false}) useCheckin: boolean;

  data: DesiDTO = this.$props.value;
  checkin = false

  adviceOpts = []
  desiRoutineOpts = []

  kfzOpts = []
  medproduktOpts = []
  materialOpts = []

  users = []
  kennungOpts = []

  disinfecantOpts = []
  concentrationOpts = []
  einwirkZeitOpts = []
  scopeOpts = []
  protectionOpts = []

  openModal = false

  async created() {
    if (this.useCheckin) {
      this.checkin = true
      await this.checkinChanged()
    } else {
      this.users = await this.$store.dispatch("user/fetchUser")
      this.materialOpts = await this.$store.dispatch("material/fetchMaterial")
      this.medproduktOpts = await this.$store.dispatch("medprodukt/fetchMedizinprodukte")
    }

    this.adviceOpts = await this.$store.dispatch("hygiene/fetchDesiAdvice")
    this.desiRoutineOpts = await this.$store.dispatch("hygiene/fetchDesiRoutines")
    this.disinfecantOpts = await this.$store.dispatch("hygiene/fetchDisinfectant")
    this.concentrationOpts = await this.$store.dispatch("hygiene/fetchConcentration")
    this.einwirkZeitOpts = await this.$store.dispatch("hygiene/fetchEinwirkZeit")
    this.scopeOpts = await this.$store.dispatch("hygiene/fetchScopes")
    this.protectionOpts = await this.$store.dispatch("hygiene/fetchProtection")

    this.kfzOpts = await this.$store.dispatch("kfz/fetchKfz")
    this.kennungOpts = await this.$store.dispatch("kfz/fetchKennungen")
  }


  async checkinChanged() {
    if (this.checkin) {
      const checkin = this.$store.getters["checkin/getCheckin"]
      if (checkin) {
        this.data.kfz = checkin.kfz
        this.data.kennung = checkin.kennung
        this.data.user1 = checkin.fahrer
        this.data.user1Text = checkin.fahrerText
        this.data.user2 = checkin.beifahrer
        this.data.user2Text = checkin.beifahrerText
        this.data.user3 = checkin.dritter
        this.data.user3Text = checkin.dritterText

        this.medproduktOpts = await this.$store.dispatch("checkin/fetchMedProdukte")
        this.materialOpts = await this.$store.dispatch("checkin/fetchMaterial")
        this.users = this.$store.getters["checkin/getUsers"]
      } else {
        this.medproduktOpts = []
        this.materialOpts = []
        this.users = []
      }
    }

    if (!this.checkin || this.users.length == 0) {
      this.users = await this.$store.dispatch("user/fetchUser")
    }
    if (!this.checkin || this.medproduktOpts.length == 0) {
      this.medproduktOpts = await this.$store.dispatch("medprodukt/fetchMedizinprodukte")
    }
    if (!this.checkin || this.materialOpts.length == 0) {
      this.materialOpts = await this.$store.dispatch("material/fetchMaterial")
    }
  }

  categoryChanged() {
    if (this.data.category == Desi.CATEGORY_INFECTION) {
      this.data.desiRoutine = null
    } else if (this.data.category == Desi.CATEGORY_ROUTINE) {
      this.data.advice = null
      this.data.desinfektorInfo = false
      this.data.erkrankungVorabbekannt = false
    }
  }

  adviceChanged() {
    if (this.data.advice) {
      this.data.desinfektorInfo = this.data.advice.desinfektorInfo
      this.data.disinfectant = this.data.advice.disinfectant
      this.data.einwirkZeit = this.data.advice.einwirkZeit
      this.data.concentration = this.data.advice.concentration
      this.data.scopes = this.data.advice.scope
      this.data.protections = this.data.advice.protection
    }
  }


  desiRoutineChanged() {
    if (this.data.desiRoutine) {
      this.data.disinfectant = this.data.desiRoutine.disinfectant
      this.data.einwirkZeit = this.data.desiRoutine.einwirkZeit
      this.data.concentration = this.data.desiRoutine.concentration
      this.data.scopes = []
      this.data.protections = []
    }
  }

  disinfectantChanged() {
    if (this.data.disinfectant) {
      this.data.einwirkZeit = null
      this.data.concentration = null
    }
  }

  removeMaterial(id) {
    this.data.materials = this.data.materials.filter((e) => e.id != id);
  }

  get userOpts() {
    return [...this.users,
      {originalId: UserId.FREITEXT, id: UserId.FREITEXT, formatted: 'Freitext'}]
  }

  openInfoModal() {
    this.openModal = !this.openModal
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

  get validatePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Validate);
  }
}
