
import {QrcodeStream} from 'vue-qrcode-reader'
import {Component, Prop, Vue} from 'vue-property-decorator';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {func} from '@/scripts/scripts'
import {ConstantsDTO} from "@/model/dto";
import {ExistingFiles} from "@/model/AbstractClasses";
import jquery from "jquery";
import {bus} from "@/main";
import {Event} from "@/model/Constants";

const DataProps = Vue.extend({
  props: {
    modul: String,
    field: String,
    existingFiles: ExistingFiles,
    withFileDescription: Boolean,
    //withFileRestrictions: Boolean,
    customDropzoneText: String
  }
})
@Component({
  components: {
    QrcodeStream,
    vueDropzone: vue2Dropzone
  },
})
export default class FileUpload extends DataProps {
  @Prop({default: 'dropzone'}) id;
  @Prop({default: true}) downloadButton;
  @Prop({default: true}) deleteButton;
  @Prop() moduleId;

  loading = true
  dropzoneOptions: null
  constants: ConstantsDTO | null
  datei = "Datei"
  ignoreRemove = false

  async created() {
    this.constants = this.$store.getters.getConstants;

    let maxFileSize = this.constants.maxFilesize
    let acceptedFiles = ''
    let maxFiles = 1

    if (this.$props.modul) {
      let modul = this.constants.permissions.find((p) => p.identifier.toLowerCase() === this.$props.modul.toLowerCase())
      if (modul != undefined) {
        let field = modul.filePermission.find((fP) => fP.field.toLowerCase() === this.$props.field.toLowerCase())
        if (field) {
          if (field.allowedTypes.length > 0) acceptedFiles = "." + field.allowedTypes.join(',.')
          maxFileSize = field.maxSize
          maxFiles = field.maxFiles
        }
      }
    }


    if (maxFiles > 1) this.datei = "Dateien"
    this.dropzoneOptions = func.addAuth({
      type: 'POST',
      url: '/file/file/',
      maxFiles: maxFiles,
      addRemoveLinks: false,
      acceptedFiles: acceptedFiles,
      maxFilesize: maxFileSize,
      createImageThumbnails: false,
      previewTemplate: this.filePreviewTemplate(this.downloadButton, this.deleteButton),
      dictDefaultMessage: 'Legen Sie Dateien hier ab um Sie hochzuladen',
      dictFallbackMessage: 'Ihr Browser unterstützt Drag&Drop Dateiuploads nicht',
      dictFallbackText: 'Benutzen Sie das Formular um Ihre Dateien hochzuladen',
      dictFileTooBig: 'Die Datei ist zu groß. Die maximale Dateigröße beträgt {{maxFilesize}}MB',
      dictInvalidFileType: 'Eine Datei dieses Typs kann nicht hochgeladen werden',
      dictResponseError: 'Der Server hat ihre Anfrage mit Status {{statusCode}} abgelehnt',
      dictCancelUpload: 'Hochladen abbrechen',
      dictCancelUploadConfirmation: null,
      dictRemoveFile: 'Datei entfernen',
      dictMaxFilesExceeded: 'Zu viele Dateien'
    })


    this.loading = false

    //  this.$refs.myVueDropzone
  }

  filePreviewTemplate(downloadButton, deleteButton) {
    let str = `<div class="m-1 dz-preview dz-file-preview">
                <div class="dz-image"><img data-dz-thumbnail></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>

                <div class="dz-details text-center overflow-hidden">
                    <div class="d-flex justify-content-between">
                        <a class="text-muted h5 file-description" data-dz-file-description><i class="fas fa-pen"></i></a>`;
    if (downloadButton) str += `            <a class="text-muted h5" data-dz-file-download><i class="fas fa-download"></i></a>`;
    if (deleteButton) str += `            <a class="text-muted h5" data-dz-remove /><i class="fas fa-trash"></i></a>`;
    str += `    </div>
                    <div class="file-description"><span style="word-break: break-all" data-dz-comment></span>
                    </div>
                    <div class="small"><span class="my-drop-filename" data-dz-name></span></div>
                    <div class="small text-muted"><span data-dz-size></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>`;

    return str;
  }

  callback = (data) => {
    if (data.uuid == undefined || data.files == undefined) {
      data = {uuid: "dropzone", files: data}
    }
    jquery(".dz-comment-" + data.uuid).each((idx, span) => {
      const file = data.files.find(f => f.id == jquery(span).attr('id') || this.htmlEncode(f.identifier) == this.htmlEncode(jquery(span).attr('id')))
      if (file) {
        span.innerHTML = file.comment ? file.comment : ''
      }
    })
  }

  htmlEncode(str) {
    if (!str) return str
    return str.replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');

  }

  mounted() {
    bus.$on(Event.uploadDescription, this.callback)

    this.init();
  }

  destroyed() {
    bus.$off(Event.uploadDescription, this.callback)
  }

  init() {
    if (this.$props.existingFiles != undefined) {
      this.ignoreRemove = true
      this.$refs.myVueDropzone.removeAllFiles()
      if (this.$props.existingFiles.files) {
        this.$props.existingFiles.files.forEach((f) => this.$refs.myVueDropzone.manuallyAddFile(f, ''))
      }
      this.ignoreRemove = false
    }
  }

  added(file) {
    if (!this.$props.withFileDescription) {
      jquery(".file-description").addClass("file-description-none")
    } else {
      const ref = file.previewElement.querySelectorAll("[data-dz-file-description]");
      for (let j = 0, len = ref.length; j < len; j++) {
        ref[j].id = file.id;

        jquery(ref[j]).bind("click", (e) => {
          this.edit(ref[j].id)
        });
      }

      const ref2 = file.previewElement.querySelectorAll("[data-dz-comment]");
      for (let j = 0, len = ref2.length; j < len; j++) {
        ref2[j].innerHTML = "<span id='" + file.id + "' class='dz-comment-" + this.id + "'>" + ((file.comment) ? file.comment : '') + "</span>";
      }
    }


    const ref3 = file.previewElement.querySelectorAll("[data-dz-file-download]");
    for (let j = 0, len = ref3.length; j < len; j++) {
      ref3[j].id = file.id;

      jquery(ref3[j]).bind("click", (e) => {
        let id = ref3[j].id

        if (id == "undefined") {
          this.$alert("Datei noch nicht hochgeladen!", '', 'warning')
        } else {
          this.download(id, this.moduleId)
        }
      });
    }
  }


  edit(id) {
    this.$emit('changeFile', id, this.id)
  }

  sendingEvent(file, xhr, formData) {
    formData.append('modul', this.$props.modul);
    formData.append('uploadField', this.$props.field);
  }


  uploadSuccess(file, response) {
    if (this.$props.withFileDescription) {
      const ref = file.previewElement.querySelectorAll("[data-dz-file-description]");
      for (let j = 0, len = ref.length; j < len; j++) {
        ref[j].id = response.upload.id;
      }

      const ref2 = file.previewElement.querySelectorAll("[data-dz-comment]");
      for (let j = 0, len = ref2.length; j < len; j++) {
        ref2[j].innerHTML = "<span id='" + this.htmlEncode(response.upload.id) + "' class='dz-comment-" + this.id + "'>" + ((file.comment) ? file.comment : '') + "</span>";
      }
    }
    file.uploadId = response.upload.id
    this.$emit('fileUploaded', {file: file, ...response.upload})
  }

  removedFile(file, error, xhr) {
    if (this.ignoreRemove) {
      console.log("ignored")
      return;
    }

    if (file.uploadId != undefined) this.$emit('fileRemoved', {file: file, id: file.uploadId})
    else if (file.id != undefined) this.$emit('fileDeleted', {file: file, id: file.id})
    else if (file.identifier != undefined) this.$emit('fileRemoved', {file: file, id: file.identifier})
  }

  errorFile(file, error, xhr) {
    let filename = ''
    if (file != undefined) {
      filename = file.name + ": "
    }

    if (typeof error === 'string' || error instanceof String) {
      this.$root.$emit('alert', filename + error, 'warning')
    } else if (error.error != undefined) {
      let txts = []
      if (Array.isArray(error.error)) {
        Object.entries(error.error).forEach(
            ([key, value]) => txts.push(value.text)
        );
      } else {
        txts.push(error.error)
      }
      if (txts.length > 0) this.$root.$emit('alert', filename + txts.join('<br>'), 'warning')
    }

    this.$refs.myVueDropzone.removeFile(file);
  }
}
