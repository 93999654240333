
import {QrcodeStream} from 'vue-qrcode-reader'
import { Component, Vue } from 'vue-property-decorator';


@Component({
  components: {
    QrcodeStream,
  },
})
export default class QrCodeReader extends Vue {
  loading = false
  camera = 'auto'
  result = null
  showScanConfirmation = false

  async onInit(promise) {
    this.loading = true
    try {
      await promise
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
      this.showScanConfirmation = this.camera === 'off'
    }
  }

  async onDecode(content) {
    this.result = content

    this.$emit('input', this.result)

    this.pause()
    await this.timeout(500)
    this.unpause()
  }

  unpause() {
    this.camera = 'auto'
  }

  pause() {
    this.camera = 'off'
  }

  timeout(ms) {
    return new Promise(resolve => {
      window.setTimeout(resolve, ms)
    })
  }
}
