
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {KfzKennungDTO} from "@/model/dto";
import {KfzKennung} from "@/model/Kfz";
import {KfzApi} from "@/services/KfzApi";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader, SettingsTooltip, KfzView}
})
export default class KfzKennungView extends mixins<SimpleViewMixin<KfzKennungDTO, KfzKennungDTO>>(SimpleViewMixin, DataProps) {
  data: KfzKennungDTO = new KfzKennung()
  error: KfzKennungDTO = new KfzKennung()

  chainKennungen: KfzKennungDTO[] = []

  async created() {
    await this.init(KfzKennung,
        KfzApi.getKennungen,
        (a) => KfzApi.putKennung(a),
        (b) => KfzApi.removeKennung(b)
    )

    this.chainKennungen = await this.$store.dispatch('kfz/fetchKennungen')
    this.chainKennungen = this.sort(this.chainKennungen.filter(cat => (cat.orgUnit.id != this.$store.getters.getLocation.id)))
    this.$root.$emit('loadingDone')
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
