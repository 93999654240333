
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {OutsourcingDTO} from "@/model/dto";
import OutsourcingDataView from "@/views/outsourcing/OutsourcingDataView.vue";
import {OutsourcingEdit} from "@/model/Outsourcing";
import {OutsourcingApi} from "@/services/OutsourcingApi";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from "@/main";
import {Event} from "@/model/Constants";


@Component({
  components: {
    OutsourcingDataView,
  },
  watch: {
  }
})
export default class OutsourcingCreateView extends mixins<GenericMixin<OutsourcingDTO, OutsourcingDTO>>(GenericMixin) {
  data = new OutsourcingEdit();
  error = new OutsourcingEdit();
  errorTabs = []
  created() {
    this.init();
  }

  init = function () {
    //TODO
  }


  onSubmit(goBack: boolean) {
    this.errorTabs = []
    const p = OutsourcingApi.updateOutsourcing(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      let queryParam = {}
      if (this.$route.query.archive !== undefined) {
        queryParam = {archive: 1}
      }


      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send outsourcingCreated event')
        bus.$emit(Event.outsourcingCreated, new ObjectEvent(data.data.id, data.data));
        this.$router.push({path: "/outsourcing/edit/" + data.data.id, query: queryParam})
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/outsourcing/overview", query: queryParam})
  }

}
