
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import Multiselect from "@/libs/multiselect";
import {EinweisungConfigDTO, Permission, PermissionModul} from "@/model/dto";
import {EinweisungApi} from "@/services/EinweisungApi";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {HistoryEvent} from "@/model/Shared";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";

@Component(
    {
      components: {ListItemComponent, Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class EinweisungNavComponent extends mixins(NavMixin) {

  configOpts: EinweisungConfigDTO[] = []
  selected: EinweisungConfigDTO | null = null
  routeName = null

  navBar = []
  navBarSettings = []

  async created() {
    this.fillNavbar(
        ['einweisungoverview', 'einweisungtable'],
        this.navBar)

    this.fillNavbar(
        ['einweisungconfigoverview'], this.navBarSettings)

    this.handleRoute()
    this.configOpts = await EinweisungApi.getEinweisungConfigList()
  }


  handleRoute() {
    this.routeName = this.$route.name
    if (this.routeName == 'einweisungoverview' && this.selected && this.selected.requestDto) {
      this.configChanged()
    }
  }

  configChanged() {
    bus.$emit(Event.einweisungOverviewChanged, this.selected.requestDto);
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/ewmpg/einweisunghistory'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.History)
  }
}
