
import {Component, Prop, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import HygieneNavComponent from "@/views/hygiene/components/HygieneNavComponent.vue";
import DesiListView from "@/views/hygiene/DesiListView.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";


@Component(
    {
      components: {
        DatatableButtonHeader,
        DesiListView,
        HygieneNavComponent,
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class HygieneTables extends Vue {
  @Prop() id: number;

  showTable1 = false
  showTable2 = false
  showTable3 = false

  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'desilist') {
      this.showTable1 = true
      this.showTable2 = false
      this.showTable3 = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
