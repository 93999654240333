
import {Component, Prop, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import KfzNavComponent from "@/views/kfz/components/KfzNavComponent.vue";
import EntityTaskTemplateListView from "@/views/task/entity/EntityTaskTemplateListView.vue";
import {Module} from "@/model/Constants";
import EntityTaskListView from "@/views/task/entity/EntityTaskListView.vue";
import FormListView from "@/views/form/FormListView.vue";
import KfzListView from "@/views/kfz/KfzListView.vue";


@Component(
    {
      computed: {
        Module() {
          return Module
        }
      },
      components: {
        KfzListView,
        FormListView,
        EntityTaskListView,
        EntityTaskTemplateListView,
        KfzNavComponent,
        DatatableButtonHeader,
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class KfzTables extends Vue {
  @Prop() id: number;

  showTaskTable = false
  showTaskTemplateTable = false
  showKfzList = false
  showFormTable = false

  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'kfztasktemplatelist') {
      this.showTaskTable = false
      this.showTaskTemplateTable = true
      this.showFormTable = false
      this.showKfzList = false
    } else if (route.name == 'kfzlist') {
      this.showKfzList = true
      this.showTaskTable = false
      this.showTaskTemplateTable = false
      this.showFormTable = false
    } else if (route.name == 'kfztasklist') {
      this.showTaskTable = true
      this.showTaskTemplateTable = false
      this.showFormTable = false
      this.showKfzList = false
    } else if (route.name == 'kfzformlist') {
      this.showTaskTable = false
      this.showTaskTemplateTable = false
      this.showFormTable = true
      this.showKfzList = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
