
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ExistingFiles, ObjectEvent, RewisFile} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, Module} from "@/model/Constants";
import {MaterialAusgabeDTO, MaterialDTO, Permission, PermissionModul} from "@/model/dto";
import {Material} from "@/model/Material";
import {MaterialApi} from "@/services/MaterialApi";
import MaterialDataView from "@/views/material/MaterialDataView.vue";
import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import jquery from "jquery";
import FileUpload from "@/components/FileUpload.vue";
import EinweisungOverviewTable from "@/views/einweisung/EinweisungOverviewTable.vue";
import {EinweisungOverviewRequest} from "@/model/Einweisung";
import Multiselect from "@/libs/multiselect";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import MaterialBestandChooser from "@/views/material/components/MaterialBestandChooser.vue";
import MaterialDateMaterialView from "@/views/material/MaterialDateMaterialView.vue";
import MaterialFileMaterialView from "@/views/material/MaterialFileMaterialView.vue";
import MaterialOutsourcingMaterialView from "@/views/material/MaterialOutsourcingMaterialView.vue";
import MaterialAusgabeMaterialView from "@/views/material/MaterialAusgabeMaterialView.vue";
import EntityTaskListView from "@/views/task/entity/EntityTaskListView.vue";
import EntityTaskTemplateEntityView from "@/views/task/entity/EntityTaskTemplateEntityView.vue";
import EntityFormListServersideView from "@/views/task/entity/EntityFormListServersideView.vue";
import {FileApi} from "@/services/FileApi";


const DataProps = Vue.extend({
  props: {
    id: String,
    from: String
  }
})
@Component({
  computed: {
    Module() {
      return Module
    },
    FileApi() {
      return FileApi
    }
  },
  components: {
    EntityFormListServersideView,
    EntityTaskTemplateEntityView, EntityTaskListView,
    MaterialAusgabeMaterialView,
    MaterialOutsourcingMaterialView,
    MaterialFileMaterialView,
    MaterialDateMaterialView,
    MaterialBestandChooser,
    ListItemComponent,
    EinweisungOverviewTable,
    FileUpload,
    OrgUnitSelectorLiquor,
    MaterialDataView,
    Multiselect
  },
  watch: {
    id(val) {
      this.init()
      this.reloadTree()
      this.tabIndex = 0
    }
  }
})
export default class MaterialEditView extends mixins<GenericMixin<MaterialDTO, MaterialDTO>>(GenericMixin, DataProps) {
  data = new Material();
  error = new Material();
  showTabs = false
  basePath = process.env.VUE_APP_REWIS
  locationTree = []
  tabIndex = 0
  errorTabs = []
  existingProfilePhoto = null
  showProfilePhotoUploadModal = false
  einweisungRequest: EinweisungOverviewRequest | null = null
  addBestand = false
  treeKey = 0
  uploadKey = 0
  openTree = false

  @Prop({default: 0}) idx: number


  materialAusgabeCallback = (data: ObjectEvent<MaterialAusgabeDTO>) => {
    this.init()
  }


  async created() {
    bus.$on(Event.materialAusgabeCreated, this.materialAusgabeCallback)
    bus.$on(Event.materialAusgabeChanged, this.materialAusgabeCallback)
    this.loadOpenTreeStatus()
    this.init();
    this.reloadTree()
  }

  init() {
    this.$root.$emit('loadingStart')
    this.tabIndex = Number(this.idx)

    this.showTabs = false
    const p = MaterialApi.getMaterial(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');

      this.einweisungRequest = new EinweisungOverviewRequest();
      this.einweisungRequest.materialId = this.$props.id

      if (this.data.profilePhoto) this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
      this.showTabs = true
    })
    this.uploadKey += 1
  }

  async reloadTree() {
    const tmp = await MaterialApi.getMaterialTree(this.$props.id)
    this.locationTree = tmp
    this.treeKey++
  }

  treeClicked($e) {
    this.$router.push({
      name: 'materialedit', params: {
        id: "" + $e.id,
        from: this.$store.state.route.fullPath
      }
    })
  }

  reviewSubProducts() {
    bus.$emit(Event.materialReviewSubProducts, this.data)
  }

  archiv() {
    this.$prompt('Bitte geben Sie einen Grund an.', '', this.archivTxt).then((reason) => {
      MaterialApi.archivMaterial(this.data.id, {reason: reason})
          .then((response) => {
            this.data.archive = !this.data.archive
            bus.$emit(Event.materialArchived, new ObjectEvent(this.$props.id, this.data));

            bus.$emit(Event.materialParentArchive, response.data.childs)

            response.data.childs.forEach(child => {
              bus.$emit(Event.materialChanged, new ObjectEvent(child.id, child))
            })

            this.goBack()
          })
          .catch((e) => {
            this.$alert(e.error, 'Fehler', 'error');
          })
    });
  }

  addSubProduct() {
    this.onSubmit(false, () => {
      this.$router.push({name: 'materialcreate', params: {parentId: this.data.id}})
    })
  }

  openMaterialBestandChooserDialog() {
    this.onSubmit(false, () => {
      this.addBestand = true
    })
  }

  toggleAddBestand() {
    this.addBestand = !this.addBestand;
  }

  toggleOpenTree() {
    this.openTree = !this.openTree;
    this.saveOpenTreeStatus()
  }

  onSubmit(goBack: boolean, callback = null) {
    this.errorTabs = []

    const p = MaterialApi.putMaterial(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      jquery("#materialtabs .tab-pane").each(
          function (index) {
            if (jquery(this).find('.is-invalid').length > 0) {
              self.errorTabs.push(parseInt(this.getAttribute('tab')));
            }
          }
      );

      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send materialChanged event')
        bus.$emit(Event.materialChanged, new ObjectEvent(this.$props.id, data.data));

        if (callback) {
          callback()
        }
        if (goBack) {
          this.goBack()
        }
      }
    })
  }

  materialBestandChanged() {
    console.log('received materialBestandChanged event')
    this.toggleAddBestand()
    this.reloadTree()
  }

  goBack() {
    if (this.$props.from) {
      this.$router.push({path: this.$props.from})
      return;
    }

    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/material/overview", query: queryParam})
  }

  editProfilePhoto() {
    this.showProfilePhotoUploadModal = true
  }

  profilePhotoFileUploadSuccess(d) {
    let file = new RewisFile(d);

    MaterialApi.updatePhoto(this.data.id, file)
        .then((photo) => {
          this.showProfilePhotoUploadModal = false
          this.data.profilePhoto = photo
          this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
        })
        .catch((e) => {
          this.$alert("Beim Upload des Bildes trat ein Fehler auf!");
        });
  }

  profilePhotoFileDelete(d) {
    MaterialApi.removePhoto(this.data.id)
        .then((photo) => {
          this.data.profilePhoto = null
          this.existingProfilePhoto = null
        })
        .catch((e) => {
          this.$alert("Beim Löschen des Profilbiles trat ein Fehler auf!");
        });
  }

  get archivTxt() {
    if (!this.$store.getters.hasPermission(PermissionModul.Material, Permission.Archive)) {
      return null
    }
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Material, Permission.Update)
  }

  get overviewAllPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Material, Permission.Overview)
  }

  get ausgegeben() {
    return this.data.ausgegeben
  }

  saveOpenTreeStatus() {
    localStorage.setItem('openTreeStatus', JSON.stringify(this.openTree));
  }

  loadOpenTreeStatus() {
    const savedOpenTree = localStorage.getItem('openTreeStatus');
    if (savedOpenTree !== null) {
      this.openTree = JSON.parse(savedOpenTree);
    }
  }

  destroyed() {
    bus.$off(Event.materialAusgabeCreated, this.materialAusgabeCallback)
    bus.$off(Event.materialAusgabeChanged, this.materialAusgabeCallback)
  }

  @Watch('tabIndex')
  public watchValue(newValue, oldvalue) {
    const newRoute = "/material/edit/" + this.$props.id + "/" + this.tabIndex
    if (this.$route.fullPath != newRoute) {
      this.$router.replace(newRoute)
    }
  }

}
