
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FileLexTreeDTO, GenericError} from "@/model/dto";

@Component({
  computed: {
  },
  components: {

  }
})
export default class FileLexTreeData extends mixins<GenericMixin<FileLexTreeDTO, GenericError>>(GenericMixin) {
  @Prop() value: FileLexTreeDTO;
  @Prop() error: GenericError;
  @Prop({default: false}) disabled: boolean;

  data: FileLexTreeDTO = this.$props.value;

  loadingComplete = false

  async created() {
    window.scrollTo(0, 0);
    this.init()
  }


  async init() {
    this.loadingComplete = true
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }

}
