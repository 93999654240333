
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import {EinweisungApi} from "@/services/EinweisungApi";

@Component(
    {
      components: {DatatableButtonHeader},
    }
)
export default class EinweisungEinweiserView extends Vue {
  @Prop() private id;
  tableId = 'dTable_' + uuid();

  updateTable() {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
  }

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/ewmpg/einweisungen/einweiser/' + self.id,
        type: 'GET'
      }),
      rowId: "id",
      order: [[1, 'asc']],
      stateSave: false,
      columns: [
        {
          class: '',
          data: 'id'
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return data.confirmed ? "<i class='fas fa-2x fa-hexagon-check text-success' title='Bereits bestätigt'></i>" : "<i class='far fa-2x fa-hexagon text-danger dt-check' title='Bestätigen'></i>"
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            const item = data
            if (item.kfz) {
              return item.kfz.text
            } else if (item.medProdukt) {
              return item.medProdukt.text
            } else if (item.material) {
              return item.material.text
            } else if (item.kfzCategory) {
              return item.kfzCategory.text
            } else if (item.materialKlasse) {
              return item.materialKlasse.text
            }
          }
        },
        {
          class: 'cw-100',
          data: null,
          render: function (data, type, row) {
            const item = data
            if (item.kfz) {
              return item.kfz.typeText
            } else if (item.medProdukt) {
              return item.medProdukt.typeText
            } else if (item.material) {
              return item.material.typeText
            } else if (item.kfzCategory) {
              return item.kfzCategory.typeText
            } else if (item.materialKlasse) {
              return item.materialKlasse.typeText
            }
          }
        },
        {
          class: 'cw-150',
          data: "einweisung.id"
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.einweisung ? moment(data.einweisung.datum).format("DD.MM.YYYY") : ""
          }
        }
      ],
      buttons: func.addDefaultButtons([], true),

    })

    func.datatableInitComplete(table)


    jquery('#' + self.tableId).on('click', '.dt-check', function (e) {
      e.preventDefault()
      let tr = jquery(this).closest('tr')
      let row = jquery('#' + self.tableId).DataTable().row(tr)
      let id = row.data().id
      let data = row.data()

      self.$confirm('Wollen Sie die ID ' + id + ' wirklich bestätigen?', '', 'question', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein'
      }).then(() => {
        EinweisungApi.putEinweisungGegenstandConfirm(data).then((d) => {
          self.updateTable()
        }).catch(() => {
          self.$alert("Zugriff verweigert!", "Fehler", "error");
        })
      });
    })

  }

}
