
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EwoHerstellerDTO, GenericError} from "@/model/dto";
import {EwoHersteller} from "@/model/Ewmpg";
import {EwmpgApi} from "@/services/EwmpgApi";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import Multiselect from "@/libs/multiselect";

const DataProps = Vue.extend({
  props: {}
})
@Component({
  components: {SimpletableButtonHeader, Multiselect},
})
export default class EwoHerstellerView extends mixins<SimpleViewMixin<EwoHerstellerDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: EwoHerstellerDTO = new EwoHersteller()
  error: GenericError = new GenericError()

  countries = []

  async created() {
    await this.init(EwoHersteller, EwmpgApi.getHerstellerList, (a) => EwmpgApi.putHersteller(a), (b) => EwmpgApi.removeHersteller(b))
    this.countries = await this.$store.dispatch("medprodukt/fetchCountries")

    this.$root.$emit('loadingDone')
  }

  newObject() {
    this.data = new EwoHersteller();
    this.data.country = this.countries.find(c => c.id === 1)
    this.createErrorDto()
    this.showModal = true
  }


  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
