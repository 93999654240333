import PermissionGroupView from "@/views/auth/PermissionGroupView.vue";
import {Permission, PermissionModul} from "@/model/dto";

export const superadmin = [
    {
        path: 'superadmin/permissiongroup',
        name: 'permissiongroup',
        component: PermissionGroupView,
        meta: {
            title: 'Rechtegruppen (Superadmin)',
            permission: [PermissionModul.Superadmin, Permission.Rechtegruppen]
        }
    }
]
