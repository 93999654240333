
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {EntityStandortDTO, KfzAufbauerDTO, KfzCategoryDTO, KfzDTO, KfzKennungDTO, QualificationDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import DateForm from "@/components/DateForm.vue";
import LocationChooser from "@/components/LocationChooser.vue";
import LabelComponent from "@/components/LabelComponent.vue";


@Component({components: {LabelComponent, DateForm, LocationChooser, NumberForm, Multiselect}})
export default class KfzDataView extends mixins<GenericMixin<KfzDTO, KfzDTO>>(GenericMixin) {
  @Prop() value: KfzDTO;
  @Prop() error: KfzDTO;
  data: KfzDTO = this.$props.value;
  qualis: QualificationDTO[] = []

  kennungOpts: KfzKennungDTO[] = []
  categoryOpts: KfzCategoryDTO[] = []
  locationsOpts: EntityStandortDTO[] = []
  aufbauerOpts: KfzAufbauerDTO[] = []
  herstellerOpts: KfzAufbauerDTO[] = []

  externLocation = false
  disableMpgLocation = false

  async created() {
    this.kennungOpts = await this.$store.dispatch('kfz/fetchKennungen')
    this.categoryOpts = await this.$store.dispatch('kfz/fetchKategorien')
    this.categoryOpts = this.categoryOpts.filter(cat => !cat.abstract).sort((a, b) => a.name.localeCompare(b.name))
    this.locationsOpts = await this.$store.dispatch('kfz/fetchStandorte')
    this.aufbauerOpts = await this.$store.dispatch('kfz/fetchAufbauer')
    this.herstellerOpts = await this.$store.dispatch('kfz/fetchHersteller')
    this.qualis = await this.$store.dispatch("user/fetchQualis");
    this.qualis = this.qualis.filter(q => q.typ == 1)

    this.handleKfzCategory()
  }


  handleKfzCategory() {
    const map = this.$store.getters["selectize/getKfzCategoryMedStandortMap"]
    if (this.data.category && this.data.category.filter(cat => map.includes(cat.name.toUpperCase())).length > 0) {
      this.data.mpgLocation = true;
      this.disableMpgLocation = true;
      return true;
    } else {
      this.disableMpgLocation = false;
      return false;
    }
  }

  get getGesamtstatus() {
    return this.$store.getters['selectize/getKfzStatus']
  }

  get verwaltungsStandortOpts() {
    return this.locationsOpts.filter(location => location.orgUnit != null && !location.customLocation).map(location => location.orgUnit)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    if (this.data.outsourcingLocation != null) {
      this.externLocation = true
    }
  }

}
