
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {TaskDTO, TaskNotizDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {TaskNotiz} from "@/model/Task";
import {TaskApi} from "@/services/TaskApi";
import {DateHelper} from "../../model/Date";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    }
  },
  components: {SimpletableButtonHeader}
})
export default class TaskNotizView extends mixins<SimpleViewMixin<TaskNotizDTO, TaskNotizDTO>>(SimpleViewMixin, DataProps) {
  @Prop() private task: TaskDTO;
  @Prop() private embedded;

  id = null;
  data: TaskNotizDTO = new TaskNotiz()
  error: TaskNotizDTO = new TaskNotiz()

  async created() {
    this.id = this.task.id
    await this.init(TaskNotiz,
        TaskApi.getNotizen,
        (a) => TaskApi.putNotiz(this.id, a),
        null,
        this.id,
    )
  }

  sort(objects) {
    return objects.sort((a, b) => a.id < b.id ? 1 : -1)
  }

}
