

import {Component} from "vue-property-decorator";
import DateForm from "@/components/DateForm.vue";
import Multiselect from "@/libs/multiselect";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {_} from 'vue-underscore';
import {Tagebuch} from "@/model/Tagebuch";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";


@Component({
  components: {DateForm, Multiselect},
})
export default class TagebuchNavComponent extends mixins(NavMixin) {
  tagebuchOptions = []
  onChangeDebounced = null

  navBar = []
  navBarSettings = []

  async created() {
    this.fillNavbar(
        ['tagebucheintraglist'],
        this.navBar)

    this.fillNavbar(
        ['tagebuchsettings'], this.navBarSettings)

    this.$store.state.tagebuch.requestFilter.onlyOpen = true;
    this.tagebuchOptions = await this.$store.dispatch("tagebuch/fetchOwnTagebuecher")

    this.onChangeDebounced = _.debounce(() => {
      bus.$emit(Event.tagebuchFilterChanged)
    }, 1500)
  }

  get tagebuch() {
    return this.$store.state.tagebuch.requestFilter.tagebuch
  }

  set tagebuch(val) {
    this.$store.state.tagebuch.requestFilter.tagebuch = val
    this.onChangeDebounced()
  }


  get datefrom() {
    return this.$store.state.tagebuch.requestFilter.dateFrom
  }

  set datefrom(val) {
    this.$store.state.tagebuch.requestFilter.dateFrom = val
    this.onChangeDebounced()
  }

  get dateto() {
    return this.$store.state.tagebuch.requestFilter.dateTo
  }

  set dateto(val) {
    this.$store.state.tagebuch.requestFilter.dateTo = val
    this.onChangeDebounced()
  }

  get onlyOpenOption() {
    return this.statusOptions.find(option => option.value === this.$store.state.tagebuch.requestFilter.onlyOpen) || true;
  }

  set onlyOpenOption(val) {
    this.$store.state.tagebuch.requestFilter.onlyOpen = val ? val.value : null;
    this.onChangeDebounced();
  }

  get statusOptions() {
    return [
      {id: 0, name: 'Offen', value: true},
      {id: 1, name: 'Alle', value: false},
    ]
  }

  get routeName() {
    return this.$router.currentRoute.name
  }

  get tagebuecher() {
    return [...this.tagebuchOptions, ...[new Tagebuch("Übergabebuch")]]
  }
}
