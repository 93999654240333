import { render, staticRenderFns } from "./AdminDashboardMaterialCard.vue?vue&type=template&id=652d3c38&scoped=true&"
import script from "./AdminDashboardMaterialCard.vue?vue&type=script&lang=ts&"
export * from "./AdminDashboardMaterialCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652d3c38",
  null
  
)

export default component.exports