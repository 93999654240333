
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EntityStandortDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {EntityStandort} from "@/model/Shared";
import {StandortApi} from "@/services/StandortApi";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader, SettingsTooltip}
})
export default class StandorteView extends mixins<SimpleViewMixin<EntityStandortDTO, EntityStandortDTO>>(SimpleViewMixin, DataProps) {
  data: EntityStandortDTO = new EntityStandort()
  error: EntityStandortDTO = new EntityStandort()

  chainStandorte: EntityStandortDTO[] = []
  getParam = null

  async created() {
    if (this.$route.name.includes("kfz")) {
      this.getParam = 'kfz'
    } else if (this.$route.name.includes("medprodukt")) {
      this.getParam = 'medProdukt'
    } else if (this.$route.name.includes("material")) {
      this.getParam = 'material'
    }

    await this.init(EntityStandort,
        StandortApi.getOrgUnitandorte,
        (a) => StandortApi.putStandort(a),
        (b) => StandortApi.archivStandort(b),
        this.getParam
    )

    this.chainStandorte = this.getObjects.filter(cat => (cat.orgUnit.id != this.$store.getters.getLocation.id))
    this.$root.$emit('loadingDone')
  }

  newObject() {
    this.data = structuredClone(this.newT);
    if (this.getParam) {
      this.data[this.getParam + 'Standort'] = true

    }
    this.createErrorDto()
    this.showModal = true
  }



  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  get archivTxt() {
    return "Löschen"
  }


  get getOrgUnitObjects() {
    return this.getObjects.filter(cat => (cat.orgUnit.id == this.$store.getters.getLocation.id))
  }

}
