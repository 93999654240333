
import {Component, Prop, Vue} from "vue-property-decorator";
import {AdditionalFieldDTO, AdditionalFieldResultDTO, GenericError} from "@/model/dto";
import AdditionalFieldResultForm from "@/components/AdditionalFieldResultForm.vue";

@Component({
  components: {AdditionalFieldResultForm}
})
export default class FormInputField extends Vue {
  @Prop() value: AdditionalFieldResultDTO;
  @Prop() error: string | GenericError[];
  @Prop() fieldTyp: AdditionalFieldDTO;
  @Prop() id;
  @Prop() identifier;
  @Prop() readOnly;
  @Prop() moduleId;

  get workingValue() {
    return this.value;
  }

  set workingValue(v) {
    this.$emit("input", v);
  }

  get myError() {
    if (!this.error) return false

    let val = null
    if (typeof this.error === 'object') {
      Object.entries(this.error).forEach(
          ([key, value]) => {
            if (value[this.identifier]) {
              val = value[this.identifier]
            }
          }
      );
    } else {
      val = this.error
    }
    return val
  }
}
