
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import DateForm from "@/components/DateForm.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {_} from 'vue-underscore';
import {HistoryEvent} from "@/model/Shared";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {DateForm},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class FobiNavComponent extends mixins(NavMixin) {
  navBar = []
  navBarSettings = []

  routeName = null
  onChangeDebounced = null
  fobiStatus = []

  async created() {
    this.fillNavbar(
        ['fobitable', 'zeitraumkummulativ', 'fobicampusoverview', 'fobimyfobi'],
        this.navBar)

    this.fillNavbar(
        ['fobithemen', 'fobikombithematable', 'fobiklassen', 'fobizeitraumtable'], this.navBarSettings)


    this.fobiStatus = await this.$store.dispatch("fobi/fetchStatus")
    this.handleRoute()


    this.onChangeDebounced = _.debounce(() => {
      bus.$emit(Event.fobiFilterRequest)
    }, 1500)

  }

  handleRoute() {
    this.routeName = this.$route.name
  }

  get datefrom() {
    return this.$store.state.fobi.requestFilter.dateFrom
  }

  set datefrom(val) {
    this.$store.state.fobi.requestFilter.dateFrom = val
    this.onChangeDebounced()
  }

  get dateto() {
    return this.$store.state.fobi.requestFilter.dateTo
  }

  set dateto(val) {
    this.$store.state.fobi.requestFilter.dateTo = val
    this.onChangeDebounced()
  }

  get status() {
    return this.$store.state.fobi.requestFilter.statusId
  }

  set status(val) {
    this.$store.state.fobi.requestFilter.statusId = val
    this.onChangeDebounced()
  }

  get fobiStatusOptions() {
    let opts = [
      ...[{text: 'Status wählen...', value: 0, disabled: true}],
      ...[{text: 'Alle', value: null}],
      ...this.fobiStatus.map(status => ({text: status.name, value: status.id}))
    ]
    return opts
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/fobi/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Fobi_Settings, Permission.History)
  }
}
