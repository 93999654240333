import {apiClient} from './AbstractHTTPService'
import store from '../store'

import Axios from "axios";
import {CrudEvent} from "@/model/AbstractClasses";
import {
    DateTypDTO,
    EwmpgDateTypDTO,
    FileDTO,
    KfzDateDTO,
    KfzDateTypDTO,
    MaterialDateDTO,
    MaterialDateTypDTO,
    MedProduktDateDTO,
    MedProduktDateTypDTO,
    MedProduktEwmpgDateTypDTO,
    UserDateDTO,
    UserDateTypDTO
} from "@/model/dto";
import {Event} from "@/model/Constants";


export abstract class DateApi {
    private static usersAxios = Axios.create();

    static async getDateTypes(typ): Promise<DateTypDTO[]> {
        const response = await apiClient.get('/date/datetyp/' + typ + "/chain");
        return response.data.data;
    }

    static async putDateTyp(q: DateTypDTO): Promise<DateTypDTO> {
        const response = await apiClient.put('/date/datetyp', q);
        store.dispatch("crudEvent", new CrudEvent(Event.dateTypUpdate, response.data.data));

        return response.data;
    }

    static async removeDateTyp(id): Promise<any> {
        const response = await apiClient.delete('/date/datetyp/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.dateTypUpdate, response.data.data, id));
        return response.data;
    }


    static async getUserDateTypesOuChain(): Promise<UserDateTypDTO[]> {
        const response = await apiClient.get('/date/userdatetypou/chain');
        return response.data.data;
    }

    static async getUserDatesByDateTyp(id): Promise<UserDateDTO[]> {
        const response = await apiClient.get('/user/userdate/datetyp/' + id);
        return response.data.data;
    }

    static async getUserDatesByUser(id): Promise<UserDateDTO[]> {
        const response = await apiClient.get('/user/userdate/user/' + id);
        return response.data.data;
    }

    static async getUserDateTypesOu(): Promise<UserDateTypDTO[]> {
        const response = await apiClient.get('/date/userdatetypou');
        return response.data.data;
    }

    static async putUserDateTypOu(q: UserDateTypDTO): Promise<UserDateTypDTO> {
        const response = await apiClient.put('/date/userdatetypou', q);
        store.dispatch("crudEvent", new CrudEvent(Event.userDateOuUpdate, response.data.data));

        return response.data;
    }

    static async removeUserDateTypOu(id): Promise<any> {
        const response = await apiClient.delete('/date/userdatetypou/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.userDateOuUpdate, response.data.data, id));
        return response.data;
    }

    static async putUserDate(q: UserDateDTO): Promise<UserDateDTO> {
        const response = await apiClient.put('/user/userdate', q);
        return response.data;
    }


    static async getKfzDateTypesOuChain(): Promise<KfzDateTypDTO[]> {
        const response = await apiClient.get('/date/kfzdatetypou/chain');
        return response.data.data;
    }

    static async getKfzDatesByDateTyp(id): Promise<KfzDateDTO[]> {
        const response = await apiClient.get('/kfz/kfzdate/datetyp/' + id);
        return response.data.data;
    }

    static async getKfzDatesByKfz(id): Promise<KfzDateDTO[]> {
        const response = await apiClient.get('/kfz/kfzdate/kfz/' + id);
        return response.data.data;
    }


    static async getKfzDateTypesOu(): Promise<KfzDateTypDTO[]> {
        const response = await apiClient.get('/date/kfzdatetypou');
        return response.data.data;
    }

    static async putKfzDateTypOu(q: KfzDateTypDTO): Promise<KfzDateTypDTO> {
        const response = await apiClient.put('/date/kfzdatetypou', q);
        store.dispatch("crudEvent", new CrudEvent(Event.kfzDateOuUpdate, response.data.data));
        return response.data;
    }

    static async removeKfzDateTypOu(id): Promise<any> {
        const response = await apiClient.delete('/date/kfzdatetypou/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.kfzDateOuUpdate, response.data.data, id));
        return response.data;
    }

    static async putKfzDate(q: KfzDateDTO): Promise<KfzDateDTO> {
        const response = await apiClient.put('/kfz/kfzdate', q);
        return response.data;
    }


    static async getEwoDatesByEwo(id): Promise<EwmpgDateTypDTO[]> {
        const response = await apiClient.get('/date/ewmpgdatetyp/ewo/' + id);
        return response.data.data;
    }

    static async putEwoDate(q: EwmpgDateTypDTO): Promise<EwmpgDateTypDTO> {
        const response = await apiClient.put('/date/ewmpgdatetyp/', q);
        return response.data;
    }

    static async removeEwoDate(id): Promise<any> {
        const response = await apiClient.delete('/date/ewmpgdatetyp/' + id);
        return response.data;
    }


    static async getMedProduktDateTypesOu(): Promise<MedProduktDateTypDTO[]> {
        const response = await apiClient.get('/date/medproduktdatetypou');
        return response.data.data;
    }

    static async putMedProduktDateTypOu(q: MedProduktDateTypDTO): Promise<MedProduktDateTypDTO> {
        const response = await apiClient.put('/date/medproduktdatetypou', q);
        // store.dispatch("medprodukt/dateTypOuUpdate")
        return response.data;
    }

    static async removeMedProduktDateTypOu(id): Promise<any> {
        const response = await apiClient.delete('/date/medproduktdatetypou/' + id);
        // store.dispatch("medprodukt/dateTypOuUpdate")
        return response.data;
    }

    static async getMedProduktDatesByMedProdukt(id): Promise<MedProduktDateDTO[]> {
        const response = await apiClient.get('/medprodukt/medproduktdate/mpg/' + id);
        return response.data.data;
    }

    static async putMedProduktDate(q: MedProduktDateDTO): Promise<MedProduktDateDTO> {
        const response = await apiClient.put('/medprodukt/medproduktdate', q);
        return response.data;
    }


    static async getMedProduktEwmpgDateTypeOverviewChain(): Promise<MedProduktEwmpgDateTypDTO[]> {
        const response = await apiClient.get('/date/medproduktdatetypou/overviewchain');
        return response.data.data;
    }



    static async getMaterialDateTypesOuChain(): Promise<MaterialDateTypDTO[]> {
        const response = await apiClient.get('/date/materialdatetypou/chain');
        return response.data.data;
    }

    static async getMaterialDatesByDateTyp(id): Promise<MaterialDateDTO[]> {
        const response = await apiClient.get('/material/materialdate/datetyp/' + id);
        return response.data.data;
    }

    static async getMaterialDatesByMaterial(id): Promise<MaterialDateDTO[]> {
        const response = await apiClient.get('/material/materialdate/material/' + id);
        return response.data.data;
    }


    static async getMaterialDateTypesOu(): Promise<MaterialDateTypDTO[]> {
        const response = await apiClient.get('/date/materialdatetypou');
        return response.data.data;
    }

    static async putMaterialDateTypOu(q: MaterialDateTypDTO): Promise<MaterialDateTypDTO> {
        const response = await apiClient.put('/date/materialdatetypou', q);
        store.dispatch("crudEvent", new CrudEvent(Event.materialDateOuUpdate, response.data.data));
        return response.data;
    }

    static async removeMaterialDateTypOu(id): Promise<any> {
        const response = await apiClient.delete('/date/materialdatetypou/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.materialDateOuUpdate, response.data.data, id));
        return response.data;
    }

    static async putMaterialDate(q: MaterialDateDTO): Promise<MaterialDateDTO> {
        const response = await apiClient.put('/material/materialdate', q);
        return response.data;
    }

    static async getFilesByUserDate(id): Promise<FileDTO[]> {
        if (!id) return [];
        const response = await apiClient.get('/user/userdate/files/' + id);
        return response.data.data;
    }
    static async getFilesByKfzDate(id): Promise<FileDTO[]> {
        if (!id) return [];
        const response = await apiClient.get('/kfz/kfzdate/files/' + id);
        return response.data.data;
    }
    static async getFilesByMedProduktDate(id): Promise<FileDTO[]> {
        if (!id) return [];
        const response = await apiClient.get('/medprodukt/medproduktdate/files/' + id);
        return response.data.data;
    }
    static async getFilesByMaterialDate(id): Promise<FileDTO[]> {
        if (!id) return [];
        const response = await apiClient.get('/material/materialdate/files/' + id);
        return response.data.data;
    }
}