
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import UserDataView from "@/views/user/UserDataView.vue";
import {UserEdit} from "@/model/User";
import {UsersApi} from "@/services/UserApi";


@Component({
  components: {
    UserDataView,
  },
  watch: {}
})
export default class UserCreateView extends mixins<GenericMixin<UserEdit, UserEdit>>(GenericMixin) {
  data = new UserEdit();
  error = new UserEdit();
  errorTabs = []


  created() {
    this.init();
  }

  init = function () {
    this.data.rfid = {publicId: '', id: null}

    let location = (this.$store.getters.getLocation !== undefined) ? this.$store.getters.getLocation : ''
    this.data.invoiceOrgUnit = {id: location.id, name: location.sName}
    this.data.forceProfileCheck = true
    this.data.forcePasswordChange = true
  }


  onSubmit(goBack: boolean) {
    this.errorTabs = []
    const p = UsersApi.updateUser(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      let queryParam = {}
      if (this.$route.query.archive !== undefined) {
        queryParam = {archive: 1}
      }


      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send userCreated event')
        bus.$emit(Event.userCreated, new ObjectEvent(data.data.id, data.data));

        this.$router.push({
          name: "useredit",
          params: {
            id: "" + data.data.id,
            from: this.$store.state.route.from.fullPath
          }
        })
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/user/overview", query: queryParam})
  }

}
