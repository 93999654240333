
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import '@/scripts/dataTables_German'
import {FobiApi} from "@/services/FobiApi";
import {UsersApi} from "@/services/UserApi";
import {FobiOverview, FobiUserOverview} from "@/model/Fobi";
import {FobiZeitraumDTO, UserResponseDTO} from "@/model/dto";
import FileUpload from "@/components/FileUpload.vue";
import FobiUserOverviewComponent from "@/views/fobi/components/FobiUserOverviewComponent.vue";
import {DateHelper} from "../../model/Date";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {Route} from "vue-router";


@Component(
    {
      computed: {
        DateHelper() {
          return DateHelper
        }
      },
      components: {DatatableButtonHeader, FobiUserOverviewComponent, FileUpload},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("zeitraumkummulativ" || "/user/edit")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit('loadingDone');
          } else {
            this.table.fixedHeader.disable()
          }
          this.handleDatableRoute(true);
        }
      }
    }
)
export default class FobiZeitraumKummulativView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop({default: false}) userId;
  @Prop({default: false}) userEdit;
  data = null;
  error = null;
  table = null
  tableId = 'dTable_' + uuid();

  dialogZeitraum = null
  dialogOverview = []
  showDialog = false

  chainZeitraum: FobiZeitraumDTO[] = []
  users: UserResponseDTO[] = []

  userZeitraeume: FobiUserOverview[] = []
  overviews: FobiOverview[] = []


  mounted() {
    this.init()
  }

  async init() {
    this.users = await UsersApi.getOrgUnitUserList();
    if (this.userId) {
      this.users = this.users.filter((u) => u.id == this.userId);
    }
    this.users.forEach((user) => {
      this.userZeitraeume.push(new FobiUserOverview(user.id));
    });

    this.chainZeitraum = await FobiApi.getZeitraumeWithUser(this.userId);
    for (const zeitraum of this.chainZeitraum) {
      const overviewData = await FobiApi.getZeitraumOverview(zeitraum);
      let clazz = new FobiOverview(zeitraum.id);
      clazz.overviewData = overviewData;

      overviewData.forEach((userRow) => {
        let userZeitraum = this.userZeitraeume.find((item) => item.userId == userRow.user.id);
        if (!userZeitraum) return;

        if (userRow.gesamtStatus == 1) {
          userZeitraum.completeZeitraeume.push(zeitraum);
          clazz.completeUserIds.push(userRow.user.id);
        } else {
          userZeitraum.uncompleteZeitraeume.push(zeitraum);
          clazz.uncompleteUserIds.push(userRow.user.id);
        }
      });

      this.overviews.push(clazz);
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    let columns = []
    if (!this.userId) {
      columns.push({data: 'user', title: 'Benutzer'});
    }


    columns.push({
      data: null,
      class: "text-center",
      orderable: false,
      render: function (data, type, row) {
        data = data.zeitraeume;
        if (!data || !Array.isArray(data) || data.length === 0) {
          return "Keine Zeiträume";
        }

        return data
            .map(
                (zeitraum) => `
                  <span class="badge badge-${
                    zeitraum.complete ? "success" : "warning"
                } mx-2 pointer"
                        data-zeitraum-id='${zeitraum.id}'>
                    ${zeitraum.name} <br>
                    <small>${zeitraum.start} - ${zeitraum.end}</small>
                  </span>`
            )
            .join("");
      },
    })


    let table = jquery('#' + this.tableId).DataTable({
      data: this.formatTableData(),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      stateSave: false,
      responsive: false,
      rowId: 'id',
      columns: columns,
      buttons: func.addDefaultButtons(),
      initComplete: function () {
        self.$root.$emit('loadingDone')
      }
    });
    self.table = table
    func.datatableInitComplete(table)

    jquery(`#${this.tableId}`).on('click', '.badge', function () {
      const zeitraumId = parseInt(jquery(this).attr('data-zeitraum-id'));
      self.zeitraumClick(zeitraumId);
    });
  }

  formatTableData() {
    return this.usersSort.map((user) => {
      const overview = this.getUserOverview(user.id);
      if (!overview) return {user: user.fromattedLong, zeitraeume: []};

      return {
        user: user.fromattedLong,
        zeitraeume: [
          ...overview.uncompleteZeitraeume.map((z) => ({
            id: z.id,
            name: z.name,
            start: DateHelper.parseDate(z.start),
            end: DateHelper.parseDate(z.end),
            complete: false,
          })),
          ...overview.completeZeitraeume.map((z) => ({
            id: z.id,
            name: z.name,
            start: DateHelper.parseDate(z.start),
            end: DateHelper.parseDate(z.end),
            complete: true,
          })),
        ],
      };
    });
  }


  get usersSort() {
    return this.users.sort((a, b) => a.fromattedLong.localeCompare(b.fromattedLong))
  }

  getUserOverview(userId) {
    return this.userZeitraeume.find(item => item.userId == userId)
  }

  zeitraumClick(zeitraumId) {
    // Suche den vollständigen Zeitraum anhand der ID
    const fullZeitraum = this.chainZeitraum.find(
        (z) => z.id === zeitraumId
    );

    if (!fullZeitraum) {
      console.error(`Kein vollständiger Zeitraum gefunden für ID: ${zeitraumId}`);
      return;
    }

    const overview = this.overviews.find(
        (item) => item.zeitraumId === fullZeitraum.id
    );

    if (!overview) {
      console.error(`Kein Überblick gefunden für ZeitraumId: ${fullZeitraum.id}`);
      return;
    }

    this.dialogZeitraum = fullZeitraum;
    this.dialogOverview = overview.overviewData;
    this.showDialog = true;
  }

  destroyed() {
    this.table.fixedHeader.disable()

  }
}
