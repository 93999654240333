
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import {v4 as uuid} from 'uuid';
import {DateHelper} from "@/model/Date";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import DateForm from "@/components/DateForm.vue";
import {_} from 'vue-underscore';
import moment from "moment/moment";
import {HistoryRequestDTO} from "@/model/dto";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {DateForm, DatatableButtonHeader}
})
export default class HistoryView extends mixins<SimpleViewMixin<null, null>>(GenericMixin, DataProps) {
  @Prop({default: 'POST'}) method;
  @Prop({default: ''}) path;
  @Prop({default: null}) id;

  onChangeDebounced = null


  table = null
  tableId = 'dTable_' + uuid();
  loading = true;

  dateFromIntern = null
  dateToIntern = null


  get datefrom() {
    return this.dateFromIntern
  }

  set datefrom(val) {
    this.dateFromIntern = val
    this.onChangeDebounced()
  }

  get dateto() {
    return this.dateToIntern
  }

  set dateto(val) {
    this.dateToIntern = val
    this.onChangeDebounced()
  }

  created() {
    this.dateFromIntern = moment().subtract(4, 'weeks').format(moment.HTML5_FMT.DATE);
    this.dateToIntern = moment().format(moment.HTML5_FMT.DATE);

    this.onChangeDebounced = _.debounce(() => {
      this.init()
    }, 1000)
  }

  async mounted() {
    this.init();
  }

  async init() {
    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      jquery('#' + this.tableId + ' thead').empty();
    }

    let requestDTO : HistoryRequestDTO = {
      id: this.id,
      dateFrom: this.dateFromIntern,
      dateTo: this.dateToIntern + " 23:59:59"
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const dateHelper = DateHelper

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.path,
        type: self.method,
        data: function () {
          return JSON.stringify(requestDTO);
        },
      }),
      order: [[0, 'desc']],
      stateSave: false,
      responsive: false,
      rowId: "id",
      columns: [
        {
          data: null,
          title: 'Zeitstempel',
          render: function (data, type, row) {
            return DateHelper.parseDateTime(data.timestamp)
          }
        },
        {
          data: null,
          title: 'Benutzer',
          render: function (data, type, row) {
            return data.user?.formatted
          }
        },
        {
          data: null,
          title: 'Objektart',
          render: function (data, type, row) {
            return data.class
          }
        },
        {
          data: null,
          title: 'Objekt-ID',
          render: function (data, type, row) {
            return data.fk
          }
        },
        {
          data: null,
          title: 'Änderungen',
          class: 'cw-400',
          render: function (data, type, row) {
            let changes = ''
            if (data.archived) {
              changes = "<strong>Archiviert</strong><br>"
              if (data.archiveReason) {
                changes += "<strong>Grund</strong>: " + data.archiveReason + "<br>"
              }
            } else if (data.archived !== null) {
              changes = "<strong>Reaktiviert</strong><br>"
              if (data.archiveReason) {
                changes += "<strong>Grund</strong>: " + data.archiveReason + "<br>"
              }
            } else if (data.removed) {
              changes = "<strong>Gelöscht</strong><br>"
            }

            if (data.changes) {
              changes += data.changes.map(change => {
                return "<strong>" + change.fieldName + '</strong>: '
                    + (change.oldValue ? change.oldValue : "<i>kein Wert</i>")
                    + " <strong>=></strong> "
                    + (change.newValue ? change.newValue : "<i>kein Wert</i>")
              }).join('<br>')
            }
            return changes;
          }
        },
      ],
      buttons: func.addDefaultButtons([], false, self.tableId)
    })

    func.datatableInitComplete(table, self.tableId, self)
    this.table = table
  }
}
