import TagebuchTables from "@/views/tagebuch/TagebuchTables.vue";
import TagebuchSettingsView from "@/views/tagebuch/TagebuchSettingsView.vue";
import TagebuchView from "@/views/tagebuch/TagebuchView.vue";
import {Permission, PermissionModul} from "@/model/dto";

export const tagebuchRoutes = [
    {
        path: 'tagebuch',
        name: 'tagebuch',
        redirect: '/tagebuch/tagebucheintraglist',
        component: TagebuchView,
        children: [
            {
                path: 'tagebucheintraglist',
                name: 'tagebucheintraglist',
                component: TagebuchTables,
                meta: {
                    loading: true,
                    datatable: true,
                    title: 'Übersicht',
                    permission: [PermissionModul.Diary, Permission.CRUD]
                }
            },
            {
                path: 'tagebuchsettings',
                name: 'tagebuchsettings',
                component: TagebuchSettingsView,
                meta: {
                    loading: true,
                    title: 'Tagebücher verwalten',
                    permission: [PermissionModul.Diary, Permission.Config]
                }
            },
        ],
        meta : {

        }
    }
]
