
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {FobiDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import FobiDataView from "@/views/fobi/FobiDataView.vue";
import {FobiUser, Fortbildung} from "@/model/Fobi";
import {FobiApi} from "@/services/FobiApi";
import EinweisungUserComponent from "@/views/einweisung/components/EinweisungUserComponent.vue";
import EinweisungDataView from "@/views/einweisung/EinweisungDataView.vue";
import FobiUserComponent from "@/views/fobi/components/FobiUserComponent.vue";
import {DateHelper} from "../../model/Date";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  computed: {
    DateHelper() {
      return DateHelper
    }
  },
  components: {
    FobiUserComponent,
    EinweisungDataView,
    EinweisungUserComponent,
    FobiDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class FobiEditView extends mixins<GenericMixin<FobiDTO, GenericError>>(GenericMixin, DataProps) {
  data = new Fortbildung();
  error = new GenericError();
  showTabs = false
  tabIndex = 0
  ownFobis = false

  created() {
    this.ownFobis = this.$route.name.includes("My");
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = FobiApi.getFobi(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean) {
    const p = FobiApi.putFobi(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send fobiChanged event')
        this.tabIndex = 0
        bus.$emit(Event.fobiChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }

    if (this.ownFobis) {
      this.$router.push({path: "/fobi/fobimyfobi", query: queryParam})
    } else {
      this.$router.push({path: "/fobi/fobitable", query: queryParam})
    }
  }

  archiv() {
    bus.$emit(Event.fobiArchiveRequest, new ObjectEvent(this.data.id, this.data));
  }

  removeUser(idx) {
    this.data.user.splice(idx, 1)
  }

  addUser() {
    const user = new FobiUser();
    user.files = []
    this.data.user.push(user);
  }

  extractDuration() {
    if (this.data.themen && this.data.themen.length > 0) {
      return this.data.themen[0].tn ? "nur Teilnahme" : this.data.themen[0].stunden + " Stunde(n)"
    }
    return 'unbekannt'
  }

  get archivTxt() {
    if (this.data.courseId || this.ownFobis) {
      return null;
    }
    if (!this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Archive)) {
      return null
    }

    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  get editPermission() {
    if (this.data.courseId) {
      return false;
    }

    if (this.ownFobis) {
      return this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Access_Own)
          && (!this.data.status || this.data.status.id == 1)
    }
    return (this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Update))
  }
}
