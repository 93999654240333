import { render, staticRenderFns } from "./OrgUnitListView.vue?vue&type=template&id=5ae4fed4&scoped=true&"
import script from "./OrgUnitListView.vue?vue&type=script&lang=ts&"
export * from "./OrgUnitListView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae4fed4",
  null
  
)

export default component.exports