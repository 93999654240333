import {apiClient} from './AbstractHTTPService'
import store from '../store'

import Axios from "axios";
import {TagebuchDTO, TagebuchEintragDTO, TagebuchNotizDTO} from "@/model/dto";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";
import {TagebuchEintragRequest} from "@/model/Tagebuch";


export abstract class TagebuchApi {
    private static usersAxios = Axios.create();

    static async getOwnTagebuecher(): Promise<TagebuchDTO[]> {
        const response = await apiClient.get('/checkin/tagebuch/user');
        return response.data.data;
    }

    static async getOrgUnitTagebuecher(): Promise<TagebuchDTO[]> {
        const response = await apiClient.get('/checkin/tagebuch/ou');
        return response.data.data;
    }

    static async putTagebuch(q: TagebuchDTO): Promise<TagebuchDTO> {
        const response = await apiClient.put('/checkin/tagebuch', q);
        store.dispatch("crudEvent", new CrudEvent(Event.tagebuchUpdate, response.data.data));
        return response.data;
    }


    static async putEintrag(eintrag: TagebuchEintragDTO): Promise<ApiResponse<TagebuchEintragDTO>> {
        const response = await apiClient.put('/checkin/tagebucheintrag', eintrag);
        return new ApiResponse(response);
    }

    static async getCheckinCount(checkiId): Promise<any> {
        const payload = new TagebuchEintragRequest();
        payload.checkinId = checkiId;
        payload.onlyOpen = true;
        payload.countOnly = true;
        payload.dateFrom = null
        payload.dateTo = null
        const response = await apiClient.post('/checkin/tagebucheintrag', payload);
        return response.data.data;
    }

    static async getNotizen(refId: number): Promise<TagebuchNotizDTO[]> {
        const response = await apiClient.get('/checkin/tagebuchnotiz/' + refId);
        return response.data.data;
    }

    static async putNotiz(refId: number, q: TagebuchNotizDTO): Promise<TagebuchNotizDTO> {
        q.tagebuchEintragId = refId;

        const response = await apiClient.put('/checkin/tagebuchnotiz', q);
        return response.data;
    }

}