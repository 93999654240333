
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {TaskChatDTO, TaskDTO} from "@/model/dto";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {TaskChat} from "@/model/Task";
import {TaskApi} from "@/services/TaskApi";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import RewisChat from "@/components/RewisChat.vue";
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";
import {OrgUnit} from "@/model/OrgUnit";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {OrgUnitSelector, RewisChat, PersonenkreisChooser, SimpletableButtonHeader, SettingsTooltip, KfzView}
})
export default class TaskChatView extends mixins<SimpleViewMixin<TaskChatDTO, TaskChatDTO>>(SimpleViewMixin, DataProps) {
  @Prop() private task: TaskDTO;
  id = null;
  data: TaskChatDTO = new TaskChat()
  error: TaskChatDTO = new TaskChat()
  chatId = null
  showChatModal = false
  preSeleted = []
  async created() {
    this.id = this.task.id
    await this.init(TaskChat,
        TaskApi.getChats,
        (a) => TaskApi.putChat(this.id, a),
        null,
        this.id,
        (data) => {
          if (data.data != undefined) {
            this.openChat(data.data)
          }
        }
    )
  }

  newObject() {
    this.data = new TaskChat();
    this.createErrorDto()

    let ref = this.task.master ? this.task.master : this.task
    //this.data.title = ref.typText + " " + ref.id;
    
    this.data.personenkreis = this.personenkreisOptions

    if (this.task.orgUnit) {
      this.preSeleted = [this.task.orgUnit.id]
      this.selectedLocationChanged(this.preSeleted)
    } else {
      this.preSeleted = [this.$store.getters.getLocation.id]
      this.selectedLocationChanged(this.preSeleted)
    }

    this.showModal = true
  }

  openChat(data) {
    this.chatId = data.id
    this.showChatModal = true
  }

  get personenkreisOptions() {
    let opts = []
    let ref = this.task.master ? this.task.master : this.task

    opts = [...opts, ...ref.zustaendig]
    opts = [...opts, ...ref.verantwortlich]
    opts = [...opts, ...this.task.betroffen]
    return [...new Map(opts.map((item) => [item["id"], item])).values()];
  }

  get locationAnchor() {
    let ref = this.task.master ? this.task.master : this.task
    return ref.orgUnit.id
  }
  get taskOrgUnitIds() {
    let ref = this.task.master ? this.task.master : this.task
    return ref.orgUnits.map(orgUnit => orgUnit.id)
  }

  selectedLocationChanged(locationIds) {
    this.data.orgUnits = locationIds.map(id => {
      let orgUnit = new OrgUnit()
      orgUnit.id = id
      return orgUnit
    })
  }

}
