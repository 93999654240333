
import {Component, Vue} from 'vue-property-decorator';
import QrCodeReader from "@/components/QrCodeReader.vue";
import jquery from "jquery";

@Component({
  components: {
    QrCodeReader,
  },
})
export default class QrCodeReaderView extends Vue {
  qrcode = ''

  onScan() {
    if (this.qrcode.startsWith('http')) {
      this.$confirm(this.qrcode + ' öffnen?', '', 'question', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein'
      }).then(() => {
        var winRef = window.open(this.qrcode, '_blank');

        jquery.ajax({
          type: '',
          url: '',
          data: '',
          success: function (json) {
            winRef.location = this.qrcode;
          }
        });
      }).catch(e => {
        console.log(e)
      })
    } else {
      this.$alert(this.qrcode, 'QR-Inhalt')
    }
  }
}

