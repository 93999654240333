
import { Vue, Component, Prop } from 'vue-property-decorator';
import { UserProfile } from "@/model/User";
import QrcodeVue from 'qrcode.vue'

@Component({
  components: { QrcodeVue }
})
export default class QRCodeTab extends Vue {
  @Prop({ required: true }) readonly data!: UserProfile;
  @Prop({ required: true }) readonly tabId!: string | number;

  downloadQrCode = function () {
    let wrapper = document.getElementById("myQrCode");
    let canvas = wrapper?.firstChild;

    if (canvas instanceof HTMLCanvasElement) { // Überprüfen, ob canvas ein HTMLCanvasElement ist
      let link = document.createElement('a');
      link.download = 'qrcode.png';
      link.href = canvas.toDataURL("image/png");
      link.click();
    }
  }
}
