import Vue from 'vue'
import Vuex, {ActionTree, GetterTree, MutationTree} from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import JwtService from '../services/JwtService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LocationService from '../services/LocationService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {func} from '@/scripts/scripts'


import menu from "@/store/modules/menu";
import selectize from "@/store/modules/selectize";
import checkin from "@/store/modules/checkin";
import {AuthorizedUserDto, ConstantsDTO, OrgUnitDTO, OrgUnitTreeDTO, PermissionDTO, UserResponseDTO} from "@/model/dto";
import kfz from "@/store/modules/kfz";
import user from "@/store/modules/user";
import outsourcing from "@/store/modules/outsourcing";
import medprodukt from "@/store/modules/medprodukt";
import tags from "@/store/modules/tags";
import material from "@/store/modules/material";
import manual from "@/store/modules/manual";

import {CrudEvent, LastRequest} from "@/model/AbstractClasses";
import fobi from "@/store/modules/fobi";
import task from "@/store/modules/task";
import emeld from "@/store/modules/emeld";
import form from "@/store/modules/form";
import einsatz from "@/store/modules/einsatz";
import hygiene from "@/store/modules/hygiene";
import filelex from "@/store/modules/filelex";
import tagebuch from "@/store/modules/tagebuch";
import diva from "@/store/modules/diva";

Vue.use(Vuex)


class State {
    token: string | null = null;
    location: OrgUnitDTO | null = null;
    permissions: PermissionDTO[] = [];
    locations: OrgUnitTreeDTO[] = [];
    loggedInUser: UserResponseDTO | null = null;
    loading: string[] = [];
    constants: ConstantsDTO | null = null
    sidebarVisible = false
    childChain = []
    lastRequests: LastRequest[] = []
}


const getters = <GetterTree<State, any>>{
    hasPermission: (state) => (modul, permissionId): boolean => {
        return state.permissions.find(e => e.modul === modul && e.permission === permissionId) !== undefined
    },
    getUserId: (state): number => {
        return state.loggedInUser.id
    },
    getConstants: (state): ConstantsDTO => {
        return state.constants
    },
    getToken: (state): string => {
        return state.token
    },
    getLocation: (state): OrgUnitDTO => {
        return state.location
    },
    getLocations: (state): OrgUnitTreeDTO[] => {
        return state.locations
    },
    getUser: (state): UserResponseDTO => {
        return state.loggedInUser
    },
    getLoading: (state) => {
        return state.loading
    },
    getSidebarVisibleStatus: (state): boolean => state.sidebarVisible,
    getChildChain: (state) => {
        return state.childChain
    },
};

const mutations = <MutationTree<State>>{
    SET_TOKEN(state, data) {
        state.token = data
        if (data && func.isLocalStorage()) {
            console.log('SET LOCAL STORAGE TOKEN')
            localStorage.setItem('token', data)
        }
    },
    SET_LOCATION(state, data) {
        if (data && func.isLocalStorage()) {
            console.log('SET LOCAL STORAGE LOCATION')
            localStorage.setItem('location', data.id)
        }
        state.location = data
    },
    SET_CONSTANTS(state, data) {
        state.constants = data
    },
    SET_CHILDCHAIN(state, data) {
        state.childChain = data
    },
    SET_USER(state, data) {
        state.loggedInUser = data
    },
    SET_LOCATIONS(state, data: []) {
        state.locations = data
    },
    SET_PERMISSIONS(state, data) {
        state.permissions = data
    },
    SET_LASTREQUEST(state, data) {
        state.lastRequests = data
    },
    PUSH_LOADING(state, data) {
        state.loading.push(data)
    },
    POP_LOADING(state, data) {
        state.loading = state.loading.filter(e => e !== data)
    },
    CLEAR_LOADING(state, data) {
        state.loading = []
    },
    toggleSidebar(state) {
        state.sidebarVisible = !state.sidebarVisible
    },
    setSidebar(state, value) {
        state.sidebarVisible = value
    }
};

const actions = <ActionTree<State, any>>{
    logout({commit, state, dispatch}) {
        commit('SET_TOKEN', null)
        commit('SET_LOCATION', null)
        commit('SET_PERMISSIONS', [])
        commit('SET_LOCATIONS', [])
        commit('SET_USER', null)
        commit('SET_CONSTANTS', null)
        commit('SET_CHILDCHAIN', [])
        commit('SET_LASTREQUEST', [])

        dispatch('reset')

        //    commit('menu/SET_CALLEDROUTE', null)
        commit('menu/SET_ACTIVE', null)

        if (func.isLocalStorage()) {
            console.log('REMOVE LOCAL STORAGE TOKEN')
            localStorage.removeItem('token')
        }
    },
    changeLocation({commit, dispatch}, data) {
        return new Promise(function (resolve, reject) {
            LocationService.setLocations(data)
                .then(response => {
                    dispatch('auth', response.data)
                    dispatch('reset')
                    dispatch('menu/fetchMenu')

                    resolve(response)
                })
                .catch(e => {
                    reject(e)
                    console.log(e)
                })
        })
    },
    setLocation({commit}, data) {
        commit('SET_LOCATION', data)
    },
    login({dispatch}, response: AuthorizedUserDto) {
        this.dispatch('auth', response)
    },
    fetchToken({commit, state, dispatch}, bForce = false) {
        return new Promise((resolve, reject) => {
            if (!bForce && state.token) {
                resolve(state.token)
            } else {
                console.log('fetch Token!')
                JwtService.getToken()
                    .then(response => {
                        dispatch('auth', response.data)
                        resolve(response)
                    })
                    .catch(e => {
                        reject(e)
                        //console.log(e)
                    })
            }
        })
    },
    updateToken({commit, state}, token) {
        commit('SET_TOKEN', token)
        this.dispatch('fetchToken', true).then(function (data) {
            console.log('token refreshed')
        })
    },
    addLoading({commit, state}, data) {
        commit('PUSH_LOADING', data)
    },
    removeLoading({commit}, data) {
        commit('POP_LOADING', data)
    },
    clearLoading({commit}, data) {
        commit('CLEAR_LOADING')
    },
    toggleSidebar({state}) {
        state.sidebarVisible = !state.sidebarVisible;
    },
    setSidebarVisible({state}, value) {
        state.sidebarVisible = value;
    },
    auth({commit}, response: AuthorizedUserDto) {
        commit('SET_TOKEN', response.token)
        commit('SET_LOCATION', response.ou)
        commit('SET_USER', response.user)
        commit('SET_LOCATIONS', response.ouArr)
        commit('SET_CONSTANTS', response.constants)
        commit('SET_CHILDCHAIN', response.childChain)
        commit('SET_PERMISSIONS', response.permissions)
    },
    reset({commit, dispatch}) {
        commit('user/resetState')
        commit('kfz/resetState')
        commit('menu/resetState')

        commit('material/resetLocationState')
        commit('medprodukt/resetLocationState')
        commit('fobi/resetLocationState')
        commit('emeld/resetLocationState')
        commit('form/resetLocationState')
        commit('checkin/resetLocationState')
        commit('einsatz/resetLocationState')
        commit('task/resetLocationState')
        commit('hygiene/resetLocationState')
        commit('filelex/resetLocationState')
        commit('diva/resetLocationState')

    },
    crudEvent({state}, event: CrudEvent) {
        this.dispatch("user/crudEvent", event)
        this.dispatch("kfz/crudEvent", event)
        this.dispatch("medprodukt/crudEvent", event)
        this.dispatch("material/crudEvent", event)
        this.dispatch("outsourcing/crudEvent", event)
        this.dispatch("fobi/crudEvent", event)
        this.dispatch("task/crudEvent", event)
        this.dispatch("emeld/crudEvent", event)
        this.dispatch("form/crudEvent", event)
        this.dispatch("checkin/crudEvent", event)
        this.dispatch("einsatz/crudEvent", event)
        this.dispatch("hygiene/crudEvent", event)
        this.dispatch("filelex/crudEvent", event)
        this.dispatch("tagebuch/crudEvent", event)
        this.dispatch("diva/crudEvent", event)

    }
};

export default new Vuex.Store({
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
    modules: {
        menu,
        selectize,
        checkin,
        kfz,
        user,
        outsourcing,
        medprodukt,
        tags,
        material,
        manual,
        fobi,
        task,
        emeld,
        form,
        einsatz,
        hygiene,
        filelex,
        tagebuch,
        diva
    }
})

