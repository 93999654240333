import EwoHerstellerView from "@/views/ewmpg/EwoHerstellerView.vue";
import EwmpgView from "@/views/ewmpg/EwmpgView.vue";
import EwoEditView from "@/views/ewmpg/EwoEditView.vue";
import EwoGeraeteartView from "@/views/ewmpg/EwoGeraeteartView.vue";
import EwoSoftwareversionView from "@/views/ewmpg/EwoSoftwareversionView.vue";
import EwoObjektbezeichnungView from "@/views/ewmpg/EwoObjektbezeichnungView.vue";
import EwoFileCategoryView from "@/views/ewmpg/EwoFileCategoryView.vue";
import MedProdukteView from "@/views/medprodukt/MedProduktView.vue";
import MedProduktCreateView from "@/views/medprodukt/MedProduktCreateView.vue";
import MedProduktEditView from "@/views/medprodukt/MedProduktEditView.vue";
import MedProduktDateList from "@/views/medprodukt/MedProduktDateList.vue";
import MedProduktFileList from "@/views/medprodukt/MedProduktFileList.vue";
import MedProduktFileCategoriesView from "@/views/medprodukt/MedProduktFileCategoriesView.vue";
import MedProduktDateSettingsView from "@/views/medprodukt/MedProduktDateSettingsView.vue";
import EwoCreateView from "@/views/ewmpg/EwoCreateView.vue";
import StandorteView from "@/views/shared/StandorteView.vue";
import FileCategoryCatalogView from "@/views/shared/FileCategoryCatalogView.vue";
import DateTypCatalogView from "@/views/shared/DateTypCatalogView.vue";
import {EntityTaskTemplateModus} from "@/model/Task";
import {Module} from "@/model/Constants";
import MedProduktTables from "@/views/medprodukt/MedProduktTables.vue";
import EwmpgTables from "@/views/ewmpg/EwmpgTables.vue";
import EntityTaskTemplateCreateView from "@/views/task/entity/EntityTaskTemplateCreateView.vue";
import EntityTaskTempalteEditView from "@/views/task/entity/EntityTaskTempalteEditView.vue";
import {Permission, PermissionModul} from "@/model/dto";
import EwoList from "@/views/ewmpg/EwoList.vue";
import MedProduktImportView from "@/views/medprodukt/MedProduktImportView.vue";


const moduleMeta = {
    entityTaskModus: EntityTaskTemplateModus.MEDPRODUKT_TASK,
    module: Module.MEDPRODUKT,
    entityKey: 'medProdukt'
}

export const medProduktRoutes = [
    {
        path: 'medprodukt',
        name: 'medprodukt',
        component: MedProdukteView,
        children: [
            {
                path: 'import',
                name: 'medproduktimport',
                component: MedProduktImportView,
                meta: {
                    title: 'Import',
                    permission: [PermissionModul.Medprodukt, Permission.Import],
                }
            },
            {
                path: 'ewoimportlist',
                name: 'ewoimportlist',
                component: EwoList,
                meta: {
                    title: 'Einweisungsobjekte',
                    permission: [PermissionModul.Medprodukt_Settings, Permission.Import_Daten]
                }
            },
            {
                path: 'ewosoftwareimportlist',
                name: 'ewosoftwareimportlist',
                component: EwoSoftwareversionView,
                meta: {
                    loading: true,
                    title: 'Softwareversionen',
                    permission: [PermissionModul.Medprodukt_Settings, Permission.Import_Daten]
                }
            },
            {
                path: 'medproduktstandorte',
                name: 'medproduktstandorte',
                component: StandorteView,
                meta: {
                    loading: true,
                    title: 'Standorte',
                    permission: [PermissionModul.Medprodukt_Settings, Permission.Standorte]
                }
            },
            {
                path: 'medproduktfilecategory',
                name: 'medproduktfilecategory',
                component: MedProduktFileCategoriesView,
                meta: {
                    loading: true,
                    title: 'verpflichtende Dateikategorien',
                    permission: [PermissionModul.Medprodukt_Settings, Permission.Dateikategorien]
                }
            },
            {
                path: 'medproduktfilecategorycatalog',
                name: 'medproduktfilecategorycatalog',
                component: FileCategoryCatalogView,
                meta: {
                    loading: true,
                    title: 'Dateikategorie-Katalog',
                    permission: [PermissionModul.Medprodukt_Settings, Permission.Dateikategorien_Katalog]
                }
            },
            {
                path: 'medproduktdatesettings',
                name: 'medproduktdatesettings',
                component: MedProduktDateSettingsView,
                meta: {
                    loading: true,
                    title: 'Termine',
                    permission: [PermissionModul.Medprodukt_Settings, Permission.Termine]
                }
            },
            {
                path: 'medproduktdatetypcatalog',
                name: 'medproduktdatetypcatalog',
                component: DateTypCatalogView,
                meta: {
                    loading: true,
                    title: 'Termin-Katalog',
                    permission: [PermissionModul.Medprodukt_Settings, Permission.Termine_Katalog]
                }
            },
            {
                path: 'filelist',
                name: 'medproduktfilelist',
                component: MedProduktFileList,
                meta: {
                    loading: false,
                    title: 'Dateien',
                    permission: [PermissionModul.Medprodukt_Files, Permission.Overview]
                }
            },
            {
                path: 'datelist',
                name: 'medproduktdatelist',
                component: MedProduktDateList,
                meta: {
                    loading: false,
                    title: 'Termine',
                    permission: [PermissionModul.Medprodukt_Dates, Permission.Overview]
                }
            },
            {
                path: 'overview',
                name: 'medproduktoverview',
                component: MedProduktTables,
                meta: {
                    loading: true,
                    datatable: true,
                    title: 'Übersicht',
                    permissions: [
                        [PermissionModul.Medprodukt, Permission.Overview],
                        [PermissionModul.Medprodukt, Permission.Overview_Public]
                    ],
                    activeRoutes: ['medproduktlist', 'medproduktcreate', 'medproduktedit']
                }
            },
            {
                path: 'list',
                name: 'medproduktlist',
                component: MedProduktTables,
                meta: {
                    loading: true,
                    datatable: true,
                    title: 'Alle Geräte',
                    permission: [PermissionModul.Medprodukt, Permission.Overview]
                }
            },
            {
                path: 'create',
                name: 'medproduktcreate',
                components: {
                    default: MedProduktTables,
                    CRUD: MedProduktCreateView
                },
                meta: {
                    permission: [PermissionModul.Medprodukt, Permission.Create],
                }
            },
            {
                path: 'edit/:id/:idx?',
                name: 'medproduktedit',
                components: {
                    default: MedProduktTables,
                    CRUD: MedProduktEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    ...moduleMeta,
                    permissions: [
                        [PermissionModul.Medprodukt, Permission.Overview],
                        [PermissionModul.Medprodukt, Permission.Overview_Public]
                    ],
                }
            },
            {
                path: 'tasktemplatelist',
                name: 'medprodukttasktemplatelist',
                component: MedProduktTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Aufgabenvorlagen',
                    permission: [PermissionModul.Medprodukt_Task_Plan, Permission.Overview],
                    activeRoutes: ['medprodukttasktemplatelist', 'medprodukttasktemplatecreate', 'medprodukttasktemplateedit']
                }
            },
            {
                path: 'tasklist',
                name: 'medprodukttasklist',
                component: MedProduktTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Aufgaben',
                    permission: [PermissionModul.Medprodukt_Task, Permission.Overview]
                }
            },
            {
                path: 'form',
                name: 'medproduktformlist',
                component: MedProduktTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Formulare',
                    permission: [PermissionModul.Medprodukt_Settings, Permission.Formulare]
                }
            },
            {
                path: 'tasktemplate/create',
                name: 'medprodukttasktemplatecreate',
                components: {
                    component: MedProduktTables,
                    CRUD: EntityTaskTemplateCreateView
                },
                meta: {
                    ...moduleMeta,
                    permission: [PermissionModul.Medprodukt_Task_Plan, Permission.Update]
                }
            },
            {
                path: 'tasktemplate/edit/:id/:idx?',
                name: 'medprodukttasktemplateedit',
                components: {
                    component: MedProduktTables,
                    CRUD: EntityTaskTempalteEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    ...moduleMeta,
                    permission: [PermissionModul.Medprodukt_Task_Plan, Permission.Update]
                }
            },
        ]
    },
    {
        path: 'ewo',
        name: 'ewo',
        component: EwmpgView,
        children: [
            {
                path: '/',
                name: 'ewolist',
                component: EwmpgTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Übersicht',
                    permission: [PermissionModul.Ewmpg, Permission.CRUD],
                    activeRoutes: ['ewolist', 'ewocreate', 'ewoedit']
                }
            },
            {
                path: 'create',
                name: 'ewocreate',
                components: {
                    default: EwmpgTables,
                    CRUD: EwoCreateView
                },
                meta: {
                    permission: [PermissionModul.Ewmpg, Permission.CRUD]
                }
            },
            {
                path: 'edit/:id',
                name: 'ewoedit',
                components: {
                    default: EwmpgTables,
                    CRUD: EwoEditView

                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Ewmpg, Permission.CRUD]
                }
            },
            {
                path: 'hersteller',
                name: 'ewohersteller',
                component: EwoHerstellerView,
                meta: {
                    loading: true,
                    title: 'Hersteller',
                    permission: [PermissionModul.Ewmpg, Permission.CRUD]
                }
            },
            {
                path: 'geraeteart',
                name: 'ewogeraeteart',
                component: EwoGeraeteartView,
                meta: {
                    loading: true,
                    title: 'Gerätearten',
                    permission: [PermissionModul.Ewmpg, Permission.CRUD]
                }
            },
            {
                path: 'softwareversion',
                name: 'ewosoftwareversion',
                component: EwoSoftwareversionView,
                meta: {
                    loading: true,
                    title: 'Softwareversion',
                    permission: [PermissionModul.Ewmpg, Permission.CRUD]
                }
            },
            {
                path: 'objektbezeichnung',
                name: 'ewoobjektbezeichnung',
                component: EwoObjektbezeichnungView,
                meta: {
                    loading: true,
                    title: 'Objektbezeichnungen',
                    permission: [PermissionModul.Ewmpg, Permission.CRUD]
                }
            },
            {
                path: 'filecategory',
                name: 'ewofilecategory',
                component: EwoFileCategoryView,
                meta: {
                    loading: true,
                    title: 'Pflicht-Dateikategorien',
                    permission: [PermissionModul.Ewmpg, Permission.CRUD]
                }
            },
        ]
    }
]
