
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EwmpgFileCategoryEwoDTO, FileCategoryDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import {EwmpgFileCategory, EwmpgFileCategoryEwo} from "@/model/File";
import {FileApi} from "@/services/FileApi";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {SimpletableButtonHeader, NumberForm, Multiselect}
})
export default class EwoFileCategoryEwoView extends mixins<SimpleViewMixin<EwmpgFileCategoryEwoDTO, EwmpgFileCategoryEwoDTO>>(SimpleViewMixin, DataProps) {
  data: EwmpgFileCategoryEwoDTO = new EwmpgFileCategoryEwo()
  error: EwmpgFileCategoryEwoDTO = new EwmpgFileCategoryEwo()
  @Prop() private id: string;

  categoryOpts: FileCategoryDTO[] = []

  async created() {
    this.$root.$emit('loadingStart')
    this.categoryOpts = await this.$store.dispatch("medprodukt/fetchDateiKategorienKatalog")

    await this.init(EwmpgFileCategory, FileApi.getEwoFileCategoriesByEwo, (a) => {
      a.ewo = {id: this.$props.id};
      return FileApi.addEwoFileCategoryZuordnung(a);
    }, (b) => FileApi.removeEwoFileCategoryZuordnung(this.$props.id, b), this.$props.id)
    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects.sort((a, b) => a.fileCategory.name.localeCompare(b.fileCategory.name))
  }

  get archivTxt() {
    return "Zuordnung entfernen"
  }


}
