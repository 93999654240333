
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, TagebuchEintragDTO, TagebuchNotizDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {DateHelper} from "../../model/Date";
import {TagebuchNotiz} from "@/model/Tagebuch";
import {TagebuchApi} from "@/services/TagebuchApi";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    }
  },
  components: {SimpletableButtonHeader}
})
export default class TagebuchNotizView extends mixins<SimpleViewMixin<TagebuchNotizDTO, GenericError>>(SimpleViewMixin, DataProps) {
  @Prop() private eintrag: TagebuchEintragDTO;
  id = null;
  data: TagebuchNotizDTO = new TagebuchNotiz()
  error: GenericError = new GenericError()

  async created() {
    this.id = this.eintrag.id
    await this.init(TagebuchNotiz,
        TagebuchApi.getNotizen,
        (a) => TagebuchApi.putNotiz(this.id, a),
        null,
        this.id,
    )
  }

  sort(objects) {
    return objects.sort((a,b) => a.id < b.id ? 1 : -1)
  }

}
