
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event, Module} from "@/model/Constants";
import {bus} from "@/main";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {ReportRequest} from "@/model/Emeld";
import {EmeldApi} from "@/services/EmeldApi";
import {AdditionalFieldHelper, ListItemHelper} from "@/model/Shared";
import {DateHelper} from "@/model/Date";
import {_} from 'vue-underscore';


@Component(
    {
      components: {DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (this.standalone) {
            if (val.name.includes("reportlistpublic")) {
              this.table.fixedHeader.adjust();
              this.table.fixedHeader.enable()
              this.$root.$emit('loadingDone')
            } else {
              this.table.fixedHeader.disable()
            }
          }
        }
      }
    }
)
export default class ReportListPublicServersideView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop({default: () => new ReportRequest()}) request: ReportRequest;
  @Prop({default: true}) standalone;


  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  moduleId = Module.REPORT
  windowWith = null;
  thresholdExceeded = true;

  helper = ListItemHelper

  processing = false;
  clb = () => {
    this.$root.$emit("loadingStart")
    this.init()
  }
  onChangeDebounced = _.debounce(() => {
    this.init()
  }, 1500)
  onResizeDebounced = _.debounce(() => {
    this.init();
  }, 500);

  async mounted() {
    bus.$on(Event.emeldReportFilterChanged, this.clb)
    this.registerEvents([Event.emeldReportCreated, Event.emeldReportArchived, Event.emeldReportChanged]);

    this.checkWith(false);
    await this.init();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    jquery('#' + this.tableId).on('dblclick', 'tr', function (e) {
      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }
      let rowId = jquery(this).attr('id')
      if (!rowId) return;
      self.edit(rowId)
    })

  }

  async init() {
    if (this.processing) return;
    this.processing = true
    this.archive = false


    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      jquery('#' + this.tableId + ' thead').empty();
    }

    let request = null
    request = this.$store.state.emeld.requestFilter.formDataToRequest()
    request.onlyPublic = true
    request.ouId = this.$store.getters.getLocation.id


    //request.includeOpen = true

    this.data = await EmeldApi.getReports(request);
    const fixCols = [
      {
        class: '',
        title: 'ID',
        data: 'id'
      },
      {
        class: '',
        title: 'Ergeignisdatum',
        data: 'ereignisDatum',
        render: function (d, type, row) {
          return DateHelper.parseDate(d);
        }
      },
      {
        class: 'text-center',
        title: 'Status',
        data: 'done',
        render: function (d, type, row) {
          return d ? "<i class='fas fa-xl fa-check-circle'></i>" : "<i class='fas fa-xl fa-hourglass-start'></i>";
        }
      },
      {
        class: '',
        title: 'Kategorie',
        data: 'level1.name'
      },
      {
        class: '',
        title: 'Kategorie 2',
        data: 'level2.name'
      },
      {
        class: '',
        title: 'Kategorie 3',
        data: 'level3.name'
      },
      {
        class: '',
        title: 'Kategorie 4',
        data: 'level4.name'
      },
      {
        class: '',
        title: 'Kategorie 5',
        data: 'level5.name'
      },
      {
        class: '',
        title: 'Zuständigkeit',
        data: 'zustaendigkeit',
        render: function (d, type, row) {
          return d ? self.helper.getTag(d) : "";
        }
      },
      {
        class: '',
        title: 'Ersteller',
        data: 'creator',
        render: function (d, type, row) {
          return d ? d.formatted : "";
        }
      },
      {
        class: '',
        title: 'Erstelldatum',
        data: 'createTS',
        render: function (d, type, row) {
          return DateHelper.parseDate(d);
        }
      }
    ]


    const cols = this.data["cols"].length ? [
      ...fixCols,
      ...AdditionalFieldHelper.getTableColumns(this.data.cols)
    ] : fixCols;
    const rows = this.data["rows"];

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let tableButtons = func.addDefaultButtons([
      {
        className: 'text-secondary bg-primary',
        titleAttr: 'Hinzufügen',
        text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
        action: function (e, dt, node, config) {
          self.create()
        },
      },
    ])

    let tableOptions = {
      fixedHeader: {
        header: true,
        headerOffset: this.changeOffset(),
      },
      stateSave: false,
      responsive: false,
      rowId: "id",
      order: [[1, "asc"]],
      columns: cols,
      data: rows,
      buttons: tableButtons,
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
    }


    let table = jquery('#' + this.tableId).DataTable(tableOptions)
    func.datatableInitComplete(table, 0, self)

    self.table = table

    this.processing = false
    this.$root.$emit('loadingDone')
  }

  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/emeld/report/create/", query: queryParam})
  }

  edit(id) {
    let data = this.table.row("#" + id).data();
    if (data.sent) {
      this.$alert("Meldung bereits abgesendet!");
      return;
    }
    this.table.fixedHeader.disable()

    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }

    this.table.row("#" + id).child.hide()
    this.$router.push({path: "/emeld/report/edit/" + id, query: queryParam})
  }

  changeOffset() {
    if(this.thresholdExceeded) {
      return this.$DesktopHeaderOffset;
    } else {
      return this.$MobilHeaderOffset;
    }
  }


  destroyed() {
    bus.$off(Event.emeldReportFilterChanged, this.clb)
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }


  @Watch('request', {deep: true})
  public watchValue(newValue) {
    this.onChangeDebounced()
  }


  @Watch('$screen.width')
  onWidthChange() {
    this.checkWith()
  }

  checkWith(reload= true) {
    this.windowWith = this.$screen.width;
    const exceedsThreshold = this.windowWith > this.$navBarUmbruch;

    if (exceedsThreshold != this.thresholdExceeded) {
      this.thresholdExceeded = exceedsThreshold;
      if (reload) this.onResizeDebounced();
    }
  }
}

