import EinsatzTables from "@/views/einsatz/EinsatzTables.vue";
import EinsatzVorlageView from "@/views/einsatz/EinsatzVorlageView.vue";
import EinsatzView from "@/views/einsatz/EinsatzView.vue";
import EinsatzEditView from "@/views/einsatz/EinsatzEditView.vue";
import EinsatzCreateView from "@/views/einsatz/EinsatzCreateView.vue";
import CheckinEditView from "@/views/einsatz/CheckinEditView.vue";
import {Module} from "@/model/Constants";
import {Permission, PermissionModul} from "@/model/dto";

const moduleMeta = {
    module: Module.EINSATZ,
}

export const einsatzRoutes = [
    {
        path: 'einsatz',
        name: 'einsatz',
        component: EinsatzView,
        children: [
            {
                path: 'vorlagen',
                name: 'einsatzvorlagen',
                component: EinsatzVorlageView,
                meta: {
                    loading: true,
                    title: 'Einsatzprotokoll-Vorlagen',
                    permission: [PermissionModul.Einsatz_Settings, Permission.Vorlagen]
                }
            },
            {
                path: 'einsatzoverview',
                name: 'einsatzlist',
                component: EinsatzTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Einsatzübersicht',
                    permission: [PermissionModul.Einsatz, Permission.Overview],
                    activeRoutes: ['einsatzlist', 'einsatzcreate', 'einsatzedit']
                }
            },
            {
                path: 'einsatzoverviewown',
                name: 'einsatzlistown',
                component: EinsatzTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Einsatzübersicht (eigene)',
                    permission: [PermissionModul.Einsatz, Permission.Overview_Own]
                }
            },
            {
                path: 'checkinoverview',
                name: 'checkinoverviewlist',
                component: EinsatzTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Übersicht Check-in',
                    permission: [PermissionModul.Checkin, Permission.Overview],
                    activeRoutes: ['checkinoverviewlist', 'checkinedit']
                }
            },
            {
                path: 'create',
                name: 'einsatzcreate',
                components: {
                    default: EinsatzTables,
                    CRUD: EinsatzCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Einsatz, Permission.Create],
                }
            },
            {
                path: 'edit/:id',
                name: 'einsatzedit',
                components: {
                    default: EinsatzTables,
                    CRUD: EinsatzEditView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    loading: true,
                    meta: {
                        permission: [PermissionModul.Einsatz, Permission.Overview],
                    }
                }
            },
            {
                path: 'checkin/edit/:id',
                name: 'checkinedit',
                components: {
                    default: EinsatzTables,
                    CRUD: CheckinEditView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Checkin, Permission.Validate],
                }
            },
            {
                path: 'form',
                name: 'einsatzformlist',
                component: EinsatzTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Formulare',
                    permission: [PermissionModul.Einsatz_Settings, Permission.Formulare]
                }
            },
        ]
    }
]
