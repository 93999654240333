
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {KfzCategoryDTO} from "@/model/dto";
import {KfzCategory} from "@/model/Kfz";
import {KfzApi} from "@/services/KfzApi";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {SimpletableButtonHeader, SettingsTooltip, KfzView}
})
export default class KfzCategoryView extends mixins<SimpleViewMixin<KfzCategoryDTO, KfzCategoryDTO>>(SimpleViewMixin, DataProps) {
  data: KfzCategoryDTO = new KfzCategory()
  error: KfzCategoryDTO = new KfzCategory()

  chainCategories: KfzCategoryDTO[] = []

  async created() {
    await this.init(KfzCategory,
        KfzApi.getCategories,
        (a) => KfzApi.putCategory(a),
        (b) => KfzApi.removeCategory(b),
        'ou'
    )

    this.chainCategories = await this.$store.dispatch('kfz/fetchKategorien')
    this.chainCategories = this.sort(this.chainCategories.filter(cat => (cat.orgUnit && cat.orgUnit.id != this.$store.getters.getLocation.id)))
    this.$root.$emit('loadingDone')
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
