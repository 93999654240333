
import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event, Module} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import EntityTaskTemplateEntityData from "@/views/task/entity/EntityTaskTemplateEntityData.vue";
import {EntityTaskTemplateObjectDTO, Permission, PermissionModul} from "@/model/dto";
import {TaskApi} from "@/services/TaskApi";
import {_} from 'vue-underscore';
import {HistoryEvent} from "@/model/Shared";


@Component(
    {
      components: {EntityTaskTemplateEntityData},
      watch: {
        '$route'(val: Route) {
          if (val.name == this.myRouteName) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class EntityTaskTemplateListView extends mixins<GenericMixin<null, null>>(GenericMixin) {

  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false
  windowWith = null;
  thresholdExceeded = true;

  modul;
  modulPrefix = ""
  myRouteName = "tasktemplatelist"
  myPath = "tasktemplate"

  reloadFunction = (dto) => {
    this.reloadTable()
  }
  onResizeDebounced = _.debounce(() => {
    this.init();
  }, 500);

  getUrl() {
    return '/task/entitytasktemplate/' + ((this.archive) ? 'archive' : 'noarchive') + "/" + this.modul;
  }

  created() {
    this.modul = this.$router.currentRoute.meta['module'];
    this.modulPrefix = this.$router.currentRoute.meta['entityKey'];

    this.$store.dispatch("form/fetchForms")
    this.$store.dispatch("user/fetchPersonenkreise")
    this.$store.dispatch("kfz/fetchKfz")
    this.$store.dispatch("material/fetchMaterial")
    this.$store.dispatch("medprodukt/fetchMedizinprodukte")
  }

  mounted() {
    this.checkWith(false);
    this.init()
  }

  init() {
    this.archive = this.$route.query.archive !== undefined

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      // jquery('#' + this.tableId + ' thead').empty();
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    this.registerEvents([Event.taskChanged, Event.taskCreated, Event.taskArchived]);


    var p = jquery(".dt-button-bar").last();
    let childToggle = []
    let order = [[0, "asc"]]

    let columns = [
      {
        className: 'dt-control',
        orderable: false,
        data: null,
        defaultContent: '',
        width: "15px"
      },
      {data: 'id'},
      {data: 'title'},
      {data: 'orgUnit.name'}
    ];

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      fixedHeader: {
        header: true,
        headerOffset: this.changeOffset(),
      },
      stateSave: false,
      responsive: false,
      order: order,
      rowId: "id",
      columns: childToggle.concat(columns),
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons),
    })
    self.table = table
    func.datatableInitComplete(table)
    self.initContextMenu(contextMenuButtons)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
    jquery('#' + this.tableId + ' tbody').on('click', 'td.dt-control', async function (e) {
      let tr = jquery(this).closest('tr');
      let row = self.table.row(tr);

      if (row.child.isShown()) {
        row.child.hide();
      } else {
        const childRow = await self.openChildContent(row.data(), row.id());
        row.child(childRow).show();
      }
    });
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        self.permissionModul, Permission.Update,
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        self.permissionModul, Permission.Update,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        self.permissionModul, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/task/entitytasktemplate', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/task/entitytasktemplate', tr.attr('id')))
          }
        }, contextMenuButtons)

    this.addButton(
        self.permissionModul, Permission.Update,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }, contextMenuButtons
    )
  }

  async create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/" + this.modulPrefix + "/" + this.myPath + "/create/", query: queryParam})
  }

  edit(id) {
    if (!this.editPermission) return;

    if (id.includes('_')) {
      let tmpArr = id.split("_")
      id = tmpArr[1]
    }


    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()


    this.$router.push({path: "/" + this.modulPrefix + "/" + this.myPath + '/edit/' + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: (this.modulPrefix + "" + this.myRouteName), query: queryParam})
  }

  changeOffset() {
    if (this.thresholdExceeded) {
      return this.$DesktopHeaderOffset;
    } else {
      return this.$MobilHeaderOffset;
    }
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  @Watch('$screen.width')
  onWidthChange() {
    this.checkWith()
  }

  checkWith(reload= true) {
    this.windowWith = this.$screen.width;
    const exceedsThreshold = this.windowWith > this.$navBarUmbruch;

    if (exceedsThreshold != this.thresholdExceeded) {
      this.thresholdExceeded = exceedsThreshold;
      if (reload) this.onResizeDebounced();
    }
  }

  initContextMenu(myContextButtons) {
    if (myContextButtons.length == 0) return
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr[id]:not(.foreign-row)';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: myContextButtons
    });
  }

  archiv(data) {
    bus.$emit(Event.taskArchiveRequest, new ObjectEvent(data.id, data));
  }

  async openChildContent(d, parentRowId) {
    const entityTaskResponse = await TaskApi.getEntityTaskTemplate(d.id);
    if (entityTaskResponse.data.entityTasks.length === 0) {
      return '<table id="childtableNoItems" class="table table-striped">'
          + '<thead><tr>'
          + '<th>Keine Unteraufgaben vorhanden</th>'
          + '</tr></thead><tbody></tbody></table>';
    }

    let str = '<table id="childtable" class="table table-striped table-bordered">'
        + '<thead><tr class="foreign-row">';

    if (this.modul == Module.KFZ) str += '<th>Bezeichnung</th><th>Kennzeichen</th><th>Funkrufname</th>';
    if (this.modul == Module.MEDPRODUKT) str += '<th>Geräteart</th><th>Bezeichnung</th><th>SN</th><th>UDI</th>';
    if (this.modul == Module.MATERIAL) str += '<th>Kategorie</th><th>Seriennummer</th>';

    str +=
        '<th>Modus</th>' +
        '<th>Ursprung</th>' +
        '<th>Wiederholung</th>' +
        '<th>Intervall</th>' +
        '<th>Generierung vor Fälligkeit</th>' +
        '<th>Nächste Generierung</th>' +
        '<th>Nächste Fälligkeit</th>' +
        '<th>Letzte Generierung</th>' +
        '<th>Letzte Fälligkeit</th>';
    str += '</tr></thead><tbody>';

    entityTaskResponse.data.entityTasks.forEach(kfz => str += this.getChildRow(kfz, parentRowId));

    str += '</tbody></table>';
    return str;
  }

  getChildRow(d: EntityTaskTemplateObjectDTO, parentRowId) {
    const dateHelper = DateHelper

    const repetitionTexts = this.$store.getters['task/getRepetitionText'];
    const repetitionOption = repetitionTexts.find(option => option.value === d.rrule_freq);
    const modus = this.$store.getters['task/getEntityTaskTemplateZmodus'].filter(v => v.value === d.zModus).map(v => v.text).join(",")
    const rruleFreq = repetitionOption ? repetitionOption.text : '';
    const rruleInterval = d.rrule_interval || "";
    const rruleDaysBefore = d.rrule_daysBefore || "";
    const nextGeneration = dateHelper.parseDate(d.rrule_nextGen) || "";
    const nextDueDate = dateHelper.parseDate(d.rrule_nextFaellig) || "";
    const lastGeneration = dateHelper.parseDate(d.rrule_lastGen) || "";
    const lastDueDate = dateHelper.parseDate(d.rrule_lastFaellig) || "";
    const orgUnit = d.orgUnit?.name || "";

    let str = `<tr class='foreign-row' id="${d.id}_${parentRowId}">`
    if (this.modul == Module.KFZ) {
      let kfzName = d.kfz?.name || ""
      if (d.kfzCategory) {
        kfzName = "Kategorie " + d.kfzCategory.name
      }
      const licensePlate = d.kfz?.licensePlate || "";
      const kennungName = d.kfz?.kennung && d.kfz?.kennung.name ? d.kfz?.kennung.name : "";

      str += `<td>${kfzName}</td>
        <td>${licensePlate}</td>
        <td>${kennungName}</td>`
    }

    if (this.modul == Module.MEDPRODUKT) {
      const name = d.medProdukt?.name || "";
      const sN = d.medProdukt?.serienNummer || "";
      const udi = d.medProdukt?.udi || "";
      const cat = d.medProdukt?.ewoGeraeteart || d.medProduktArt?.name;

      str += `<td>${cat}</td>
        <td>${name}</td>
        <td>${sN}</td>
        <td>${udi}</td>`
    }

    if (this.modul == Module.MATERIAL) {
      const cat = d.material?.art?.name || d.materialArt?.name;
      const sN = d.material?.serienNummer || "";

      str += `<td>${cat}</td><td>${sN}</td>`
    }

    str += `
        <td>${modus}</td>
        <td>${orgUnit}</td>
        <td>${rruleFreq}</td>
        <td>${rruleInterval}</td>
        <td>${rruleDaysBefore}</td>
        <td>${nextGeneration}</td>
        <td>${nextDueDate}</td>
        <td>${lastGeneration}</td>
        <td>${lastDueDate}</td>
        </tr>`

    return str;
  }


  get permissionModul() {
    if (!this.modul) return null;

    let permissionModul = PermissionModul.Kfz_Task_Plan;
    if (this.modul == Module.MEDPRODUKT) permissionModul = PermissionModul.Medprodukt_Task_Plan;
    if (this.modul == Module.MATERIAL) permissionModul = PermissionModul.Material_Task_Plan;

    return permissionModul
  }

  get editPermission() {
    return this.$store.getters.hasPermission(this.permissionModul, Permission.Update)
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(this.permissionModul, Permission.History)
  }
}
