
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import EinweisungNavComponent from "@/views/einweisung/components/EinweisungNavComponent.vue";
import {EinweisungApi} from "@/services/EinweisungApi";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {EinweisungNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class EinweisungView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'einweisung' && this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.Overview_Chain)) {
      this.$router.push("/einweisung/overview")
    }
  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.einweisungArchiveRequest, (payload: ObjectEvent<any>) => this.archivEinweisung(payload.data))
    bus.$on(Event.einweisungConfigArchiveRequest, (payload: ObjectEvent<any>) => this.archivEinweisungConfig(payload.data))

  }

  destroyed() {
    bus.$off(Event.einweisungArchiveRequest)
    bus.$off(Event.einweisungConfigArchiveRequest)
  }

  archivEinweisung(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie Einweisung ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      EinweisungApi.archivEinweisung(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.einweisungArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

  archivEinweisungConfig(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie Einweisungskonfiguration ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      EinweisungApi.archivEinweisungConfig(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.einweisungConfigArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

}
