
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {FobiDTO, GenericError} from "@/model/dto";
import {FobiUser, Fortbildung} from "@/model/Fobi";
import {FobiApi} from "@/services/FobiApi";
import FobiDataView from "@/views/fobi/FobiDataView.vue";


@Component({
  components: {
    FobiDataView,
  }
})
export default class FobiCreateView extends mixins<GenericMixin<FobiDTO, GenericError>>(GenericMixin) {
  data = new Fortbildung();
  error = new GenericError();
  ownFobis = false;

  @Prop() fobiData: Fortbildung

  async created() {
    console.log('FobiCreateView created')
    this.data = new Fortbildung();
    this.data.user = [];
    this.data.nachweise = [];
    this.data.themen = [];

    if (this.fobiData) {
      console.log('fobiData')
      const p = FobiApi.getFobi(this.fobiData.id);
      this.handleApiRequest(p)
      p.then(() => {
        this.data.id = null;
        this.data.dateStart = null;
        this.data.dateEnd = null;
        this.data.dateNachweis = null;
        this.data.timeStart = null;
        this.data.timeEnd = null;
        this.data.user = [];
        this.data.nachweise = [];
      })
    }

    this.ownFobis = this.$route.name.includes("My");
    const statusOpts = await this.$store.dispatch("fobi/fetchStatus");

    if (!this.ownFobis) {
      const genehmigtStatus = statusOpts.find(status => status.id === 2);
      if (genehmigtStatus) {
        this.data.status = genehmigtStatus
      }
    } else {
      const offenStatus = statusOpts.find(status => status.id === 1);
      if (offenStatus) {
        this.data.status = offenStatus;
      }

      const fobiUser = new FobiUser()
      fobiUser.user = this.$store.getters.getUser
      this.data.user = [fobiUser]
    }
  }


  onSubmit(goBack: boolean) {
    const p = FobiApi.putFobi(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send fobiCreated event')
        bus.$emit(Event.fobiCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }

    if (this.ownFobis) {
      this.$router.push({path: "/fobi/fobimyfobi", query: queryParam})
    } else {
      this.$router.push({path: "/fobi/fobitable", query: queryParam})
    }
  }

}
