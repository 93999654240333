
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DashboardAdminApi } from "@/services/DashboardAdminApi";
import {FobiRequestDTO} from "@/model/dto";

@Component
export default class AdminDashboardFobiUnconfirmedStatus extends Vue {
@Prop() chain!: boolean;

  data: any = null;
  tableId: string | null = null;
  typId = 1;
  loading = true

  created() {
    this.init();
  }

  @Watch('chain')
  async onChainChange() {
    await this.init();
  }

  async init() {
    const params: FobiRequestDTO = {
      userId: null,
      ouId: this.$store.getters.getLocation.id,
      statusId: 1,
      dateFrom: null,
      dateTo: null,
      archive: null,
      ouChain: this.chain,
      countOnly: false
    };
    this.data = await DashboardAdminApi.getDashboardFobiData(params);
    this.loading = false
  }

}
