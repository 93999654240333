
import {Component, Prop, Vue} from "vue-property-decorator";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import OutsourcingNavComponent from "@/views/outsourcing/components/OutsourcingNavComponent.vue";
import {Route} from "vue-router";
import OutsourcingListView from "@/views/outsourcing/OutsourcingListView.vue";

@Component(
    {
      components: {
        OutsourcingListView,
        DatatableButtonHeader,
        OutsourcingNavComponent
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)

export default class OutsourcingTables extends Vue {
  showTable1 = false
  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }
    this.crud = false
    if (route.name === 'outsourcinglist') {
      this.showTable1 = true
    } else {
      this.showTable1 = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
