
import {Component, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import MaterialListServersideView from "@/views/material/MaterialListServersideView.vue";
import MaterialKategorieListView from "@/views/material/MaterialKategorieListView.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import EinweisungConfigListView from "@/views/einweisung/EinweisungConfigListView.vue";
import TaskNavComponent from "@/views/task/components/TaskNavComponent.vue";
import TaskListView from "@/views/task/TaskListView.vue";
import TaskTemplateListView from "@/views/task/TaskTemplateListView.vue";
import TaskPublicListView from "@/views/task/TaskPublicListView.vue";
import FormListView from "@/views/form/FormListView.vue";


@Component(
    {
      components: {
        FormListView,
        TaskPublicListView,
        TaskTemplateListView,
        TaskListView,
        TaskNavComponent,
        EinweisungConfigListView,
        DatatableButtonHeader,
        MaterialKategorieListView,
        MaterialListServersideView
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class TaskTables extends Vue {

  showTable1 = false
  showTable2 = false
  showTable3 = false
  showTablePublic =  false
  showTableForm = false

  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'taskzeitraumtable') {
      this.showTable1 = false
      this.showTable2 = false
      this.showTable3 = true
      this.showTableForm = false
      this.showTablePublic =  false
    } else if (route.name == 'tasktemplatetable') {
      this.showTable1 = false
      this.showTable2 = true
      this.showTable3 = false
      this.showTableForm = false
      this.showTablePublic =  false
    } else if (route.name == 'taskpublictable')  {
      this.showTable1 = false
      this.showTable2 = false
      this.showTable3 = false
      this.showTableForm = false
      this.showTablePublic =  true
    } else if (route.name == 'taskformlist')  {
      this.showTable1 = false
      this.showTable2 = false
      this.showTable3 = false
      this.showTableForm = true
      this.showTablePublic =  false
    }  else {
      this.showTable1 = true
      this.showTable2 = false
      this.showTable3 = false
      this.showTableForm = false
      this.showTablePublic =  false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
