
import {Component, Vue, Watch} from "vue-property-decorator";
import {UserProfile, UserSkill} from "@/model/User";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {Permission, PermissionModul, ResponseDTO, SkillDTO, UserSkillDTO} from "@/model/dto";
import {UsersApi} from "@/services/UserApi";
import Multiselect from "@/libs/multiselect";

const DataProps = Vue.extend({
  props: {
    value: UserProfile
  }
})

@Component({
  components: {Multiselect}
})
export default class UserSkillView extends mixins<GenericMixin<UserSkill, UserSkill>>(GenericMixin, DataProps) {
  data: UserSkillDTO = new UserSkill()
  selectedSkill: UserSkillDTO = new UserSkill()
  error: UserSkillDTO = new UserSkill()

  showUserSkillModal = false
  user = this.$props.value;
  userSkills: UserSkillDTO[] = []
  skills: SkillDTO[] = []

  showAddUserQualiForm = false

  async created() {
    this.$root.$emit('loadingStart')
    this.skills = await this.$store.dispatch("user/fetchSkills")
    this.userSkills = await UsersApi.getSkillsOfUser(this.user.id)
    this.$root.$emit('loadingDone')
  }


  saveUserSkill() {
    const p = UsersApi.addUserSkill(this.user.id, this.selectedSkill);
    this.handleApiRequest(p, true, false).then((data: ResponseDTO) => {
      if (data.data != undefined) {
        this.selectedSkill = new UserSkill()
        this.userSkills.push(data.data)
        this.showUserSkillModal = false
      }
    });
  }

  removeUserSkill(uQ: UserSkillDTO) {
    this.$confirm(' Wirklich löschen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      UsersApi.removeUserSkill(uQ.id).then(() => {
        this.userSkills = this.userSkills.filter((tmp) => {
          return tmp.id != uQ.id
        })
      }).catch(() => {
        this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
      })
    });
  }

  newUserSkill() {
    this.selectedSkill = new UserSkill()
    this.showUserSkillModal = true
  }

  @Watch('value')
  public watchValue(newValue) {
    this.user = newValue;
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User, Permission.Update)
  }
}
