
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import Multiselect from "@/libs/multiselect";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import DateForm from "@/components/DateForm.vue";
import {_} from 'vue-underscore';
import {HistoryEvent} from "@/model/Shared";
import {Permission, PermissionModul} from "@/model/dto";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";


@Component(
    {
      components: {DateForm, Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class EinsatzNavComponent extends mixins(NavMixin) {
  routeName = null
  vorlageOptions = []
  onChangeDebounced = null

  navBar = []
  navBarSettings = []

  async created() {
    this.fillNavbar(
        ['einsatzlist', 'einsatzlistown', 'checkinoverviewlist'],
        this.navBar)

    this.fillNavbar(
        ['einsatzvorlagen', 'einsatzformlist'], this.navBarSettings)

    this.handleRoute()
    this.vorlageOptions = await this.$store.dispatch("einsatz/fetchVorlagen")

    this.onChangeDebounced = _.debounce(() => {
      bus.$emit(Event.einsatzFilterChanged)
    }, 1500)
  }

  handleRoute() {
    this.routeName = this.$route.name
  }


  get vorlage() {
    return this.$store.state.einsatz.requestFilter.vorlage
  }

  set vorlage(val) {
    this.$store.state.einsatz.requestFilter.vorlage = val
    this.onChangeDebounced()
  }


  get datefrom() {
    return this.$store.state.einsatz.requestFilter.dateFrom
  }

  set datefrom(val) {
    this.$store.state.einsatz.requestFilter.dateFrom = val
    this.onChangeDebounced()
  }

  get dateto() {
    return this.$store.state.einsatz.requestFilter.dateTo
  }

  set dateto(val) {
    this.$store.state.einsatz.requestFilter.dateTo = val
    this.onChangeDebounced()
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/einsatz/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Einsatz_Settings, Permission.History)
  }
}
