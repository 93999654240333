
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EinsatzVorlageDTO, FormDTO, KfzCategoryDTO} from "@/model/dto";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {EinsatzVorlage} from "@/model/Einsatz";
import {EinsatzApi} from "@/services/EinsatzApi";
import Multiselect from "@/libs/multiselect";
import {Module} from "@/model/Constants";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, LabelComponent, SimpletableButtonHeader, KfzView, Multiselect}
})
export default class EinsatzVorlageView extends mixins<SimpleViewMixin<EinsatzVorlageDTO, EinsatzVorlageDTO>>(SimpleViewMixin, DataProps) {
  data: EinsatzVorlage = new EinsatzVorlage()
  error: EinsatzVorlage = new EinsatzVorlage()

  chainCategories: KfzCategoryDTO[] = []
  formOptions: FormDTO[] = []

  async created() {
    await this.init(EinsatzVorlage,
        EinsatzApi.getVorlagen,
        (a) => EinsatzApi.putVorlage(a),
        (b) => EinsatzApi.removeVorlage(b),
        'ou'
    )

    this.chainCategories = await this.$store.dispatch('einsatz/fetchVorlagen')
    this.chainCategories = this.sort(this.chainCategories.filter(cat => (cat.orgUnit && cat.orgUnit.id != this.$store.getters.getLocation.id)))
    this.formOptions = await this.$store.dispatch("form/fetchForms")
    this.formOptions = this.formOptions.filter(form => !form.typ || form.typ == Module.EINSATZ)
    this.$root.$emit('loadingDone')

  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
