import EmeldView from "@/views/emeld/EmeldView.vue";
import EmeldLevelTree from "@/views/emeld/EmeldLevelTree.vue";
import EmeldLevel from "@/views/emeld/EmeldLevel.vue";
import EmeldLevelEditView from "@/views/emeld/EmeldLevelEditView.vue";
import EmeldLevelCreateView from "@/views/emeld/EmeldLevelCreateView.vue";
import EmeldEmpty from "@/views/emeld/components/EmeldEmpty.vue";
import EmeldLevelOuTree from "@/views/emeld/EmeldLevelOuTree.vue";
import EmeldLevelOuCreateView from "@/views/emeld/EmeldLevelOuCreateView.vue";
import EmeldLevelOuEditView from "@/views/emeld/EmeldLevelOuEditView.vue";
import ReportCreateView from "@/views/emeld/ReportCreateView.vue";
import MyReportsView from "@/views/emeld/MyReportsView.vue";
import ReportEditView from "@/views/emeld/ReportEditView.vue";
import EmeldTables from "@/views/emeld/EmeldTables.vue";
import {Module} from "@/model/Constants";
import {Permission, PermissionModul} from "@/model/dto";
import EmeldReportTable from "@/views/emeld/EmeldReportTable.vue";

const moduleMeta = {
    module: Module.REPORT,
}

export const emeldRoutes = [
    {
        path: 'emeld',
        name: 'emeld',
        component: EmeldView,
        children: [
            {
                path: 'report',
                name: 'reportlist',
                component: EmeldTables,
                props: true,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Übersicht',
                    permissions: [
                        [PermissionModul.Emeld, Permission.Overview],
                        [PermissionModul.Emeld, Permission.Overview_Responsible]
                    ],
                    activeRoutes: ['reportlist', 'reportcreate', 'reportedit']
                }
            },
            {
                path: 'reportown', //von mir erstellt
                name: 'reportlistown',
                component: EmeldTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Eigene',
                    permission: [PermissionModul.Emeld, Permission.Overview_Own]
                }
            },
            {
                path: 'reportpublic',
                name: 'reportlistpublic',
                component: EmeldTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Öffentliche',
                    permission: [PermissionModul.Emeld, Permission.Overview_Public],
                }
            },
            {
                path: 'report/create/:parentId?',
                name: 'reportcreate',
                components: {
                    default: EmeldTables,
                    CRUD: ReportCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Emeld, Permission.Create],
                }
            },
            {
                path: 'report/edit/:id/:idx?',
                name: 'reportedit',
                components: {
                    default: EmeldTables,
                    CRUD: ReportEditView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    loading: true,
                    permissions: [
                        [PermissionModul.Emeld, Permission.Overview],
                        [PermissionModul.Emeld, Permission.Overview_Responsible],
                        [PermissionModul.Emeld, Permission.Overview_Own]
                    ]
                }
            },
            {
                path: 'myreports',
                name: 'myreports',
                component: MyReportsView,
                meta: {
                    loading: true,
                    title: 'Mir zugewiesen',
                    permission: [PermissionModul.Emeld, Permission.Overview_Responsible]
                }
            },
            {
                path: 'myreportstable',
                name: 'myreportstable',
                component: EmeldReportTable,
                meta: {
                    //loading: true,
                    permission: [PermissionModul.Emeld, Permission.Overview_Responsible]
                }
            },
            {
                path: 'myreports/create',
                name: 'myReportCreate',
                component: ReportCreateView,
                meta: {
                    permission: [PermissionModul.Emeld, Permission.Create]
                }
            },
            {
                path: 'myreports/edit/:id',
                name: 'myReportEdit',
                component: ReportEditView,
                props: true,
                meta: {
                    permission: [PermissionModul.Emeld, Permission.Overview_Responsible]
                }
            },
            {
                path: 'level',
                name: 'level',
                component: EmeldLevel,
                children: [
                    {
                        path: '/',
                        name: 'leveloverview',
                        components: {
                            tree: EmeldLevelTree,
                            treeContent: EmeldEmpty
                        },
                        props: {
                            default: false
                        },
                    },
                    {
                        path: ':id/create',
                        name: 'levelchildcreate',
                        components: {
                            tree: EmeldLevelTree,
                            treeContent: EmeldLevelCreateView
                        },
                        props: {
                            default: false, treeContent: true, tree: true
                        },
                    },
                    {
                        path: 'create',
                        name: 'levelcreate',
                        components: {
                            tree: EmeldLevelTree,
                            treeContent: EmeldLevelCreateView
                        },
                        props: {
                            default: false, treeContent: true, tree: false
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'leveledit',
                        components: {
                            tree: EmeldLevelTree,
                            treeContent: EmeldLevelEditView
                        },
                        props: {
                            default: false, treeContent: true, tree: true
                        },
                    },
                ],
                meta: {
                    permission: [PermissionModul.Emeld_Settings, Permission.Vorlagen]
                }
            },
            {
                path: 'levelou',
                name: 'levelou',
                component: EmeldLevel,
                children: [
                    {
                        path: '/',
                        name: 'levelouoverview',
                        components: {
                            tree: EmeldLevelOuTree,
                            treeContent: EmeldEmpty
                        },
                        props: {
                            default: false
                        },
                        meta: {
                            loading: true
                        }
                    },
                    {
                        path: ':id/create',
                        name: 'levelouchildcreate',
                        components: {
                            tree: EmeldLevelOuTree,
                            treeContent: EmeldLevelOuCreateView
                        },
                        props: {
                            default: false, treeContent: true, tree: true
                        },
                    },
                    {
                        path: 'create',
                        name: 'leveloucreate',
                        components: {
                            tree: EmeldLevelOuTree,
                            treeContent: EmeldLevelOuCreateView
                        },
                        props: {
                            default: false, treeContent: true, tree: false
                        },
                        meta: {
                            loading: true
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'levelouedit',
                        components: {
                            tree: EmeldLevelOuTree,
                            treeContent: EmeldLevelOuEditView
                        },
                        props: {
                            default: false, treeContent: true, tree: true
                        },
                    },
                ],
                meta: {
                    title: 'Vorlagen',
                    permission: [PermissionModul.Emeld_Settings, Permission.Vorlagen]
                }
            },
            {
                path: 'form',
                name: 'emeldformlist',
                component: EmeldTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Formulare',
                    permission: [PermissionModul.Emeld_Settings, Permission.Formulare]
                }
            },
        ]
    }
]
