
import {Component, Prop, Vue} from "vue-property-decorator";
import {MedProduktHelper} from "@/model/MedProdukt";
import {GegenstandListItem} from "@/model/Shared";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";


@Component(
    {
      components: {ListItemComponent},
    }
)
export default class GegenstandListItemComponent extends Vue {
  @Prop() private props: GegenstandListItem;
  @Prop() private close: boolean;

  medProduktHelper = MedProduktHelper

  removeElement(id) {
    this.$emit("remove", id)
  }
}
