<template>
  <div>
    <file-upload modul="user" field="profilePhoto" @uploaded="fileUploadSuccess"  @removed="fileRemoved"></file-upload>
    <br>
    <table border="1" class="table">
      <thead>
      <tr>
        <th width="50%">name</th>
        <th width="25%">age</th>
        <th width="25%">gender</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>John</td>
        <td>20</td>
        <td>male</td>
      </tr>
      <tr>
        <td>Emma</td>
        <td>18</td>
        <td>female</td>
      </tr>
      <tr>
        <td>Peter</td>
        <td>21</td>
        <td>male</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import LiquorTree from "liquor-tree";
import {OrgUnitSelectorItemLiquor} from "@/components/model/OrgUnitSelectorItemLiquor";
import FileUpload from "@/components/FileUpload";
export default {
  name: "AboutView",
  components: {FileUpload },
  data() {
    return {
      filter: '',
      items: [
        {
          text: "Manage Users",
          "state": {"expanded": true},
          children: [
            { text: "list"},
            { text: "edit" },
            { text: "delete" },
            { text: "activate" },
          ],
        },
        {
          text: "Manage Roles",
          children: [
            { text: "list" },
            { text: "edit" },
            { text: "delete" },
            { text: "assign" },
          ],
        },
        {
          text: "Manage Groups",
          children: [
            { text: "list" },
            { text: "edit" },
            { text: "delete" },
            { text: "assign" },
          ],
        },
        {
          text: "Manage Locations",
          children: [{ text: "list" }, { text: "edit" }, { text: "delete" }],
        },
        {
          text: "Manage Slideshows",
          children: [{ text: "publish" }, { text: "edit" }, { text: "delete" }],
        },
        {
          text: "Manage Monitors",
          id: 123,
          children: [{ text: "list" }, { text: "edit" }, { text: "delete" }],
        },
        { text: "Settings", id: "settings", children: [] },
      ],
      data: [],
      options: {
        parentSelect: true,
        checkbox: false,
        multiple: false,
        filter: {
          emptyText: 'Kein Match gefunden!',
          plainList: true
        }
      },
    };
  },
  created() {
    this.$store.getters.getLocations.forEach((o) => {
      this.data.push(new OrgUnitSelectorItemLiquor(o));
    })
    this.data = this.data.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
    this.items = this.data
  },
  methods: {
    fileUploadSuccess(d) {
      console.log(d)
    },
    fileRemoved(d) {
      console.log(d)
    },
    test(t) {
      console.log(t)
    }
  }
};
</script>
