
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {ChatDTO} from "@/model/dto";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import RewisChat from "@/components/RewisChat.vue";
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";
import {ChatApi} from "@/services/ChatApi";
import {bus} from "@/main";
import {Event, Module} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import {DateHelper} from "../../model/Date";
import {debounce} from '@/scripts/scripts'
import TaskCreateView from "@/views/task/TaskCreateView.vue";
import TaskEditView from "@/views/task/TaskEditView.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  computed: {
    Module() {
      return Module
    },
    DateHelper() {
      return DateHelper
    }
  },
  components: {TaskEditView, TaskCreateView, OrgUnitSelector, RewisChat, PersonenkreisChooser, SimpletableButtonHeader, SettingsTooltip, KfzView}
})
export default class MyChatView extends mixins<SimpleViewMixin<ChatDTO, ChatDTO>>(SimpleViewMixin, DataProps) {
  id = null;
  chatId = null
  showChatModal = false
  showTaskModal = false
  taskId = null

  async created() {
    await this.init()

    bus.$on(Event.chatRefresh, (payload) => {
      this.init()
    })
  }

  async init() {
    this.debounceFn = debounce(() => { this.search = this.searchInput }, 300)

    this.loadingDone = false
    this.objects = await ChatApi.getMyChats()
    this.loadingDone = true
  }

  destroyed() {
    bus.$off(Event.chatRead)
  }

  openChat(data) {
    this.chatId = data.id
    this.showChatModal = true

    ChatApi.setRead(this.chatId).then(() => {
      bus.$emit(Event.chatRead, new ObjectEvent(this.chatId, null));
    });

    data.newMessages = false
  }

  openTask(id) {
    this.taskId = ""+id
    this.showTaskModal = true
  }
}
