<template>
  <div class="container-fluid mt-2 px-5">
    <button type="button" @click="createCheckin" class="btn btn-primary mt-2 mb-2"><i class="fas fa-truck-arrow-right fa-2x"></i></button>

    <div class="row mt-2">
      <div class="col-12 mb-2" v-if="getCheckins.length === 0">Keine Einträge vorhanden</div>
      <div class="col-12 mb-2" v-for="item in getCheckins" :key="item.id">
        <checkin-list-item :checkin="item"></checkin-list-item>
      </div>
    </div>
  </div>
</template>

<script>
import {CheckinApi} from "@/services/CheckinApi";
import {CheckinRequest, Checkin} from "@/model/Checkin";
import CheckinListItem from "@/views/checkin/components/CheckinListItem.vue";

export default {
  name: 'CheckinDashboard',
  components: {CheckinListItem},
  async created() {
    await this.init()
  },
  data() {
    return {
      checkins: []
    }
  },
  methods: {
    async init() {
      let request = new CheckinRequest()
      request.ouId = this.$store.getters.getLocation.id
      request.userId = this.$store.getters.getUserId
      request.onlyOpen = true
      this.checkins = await CheckinApi.getCheckins(request)

      let maxCheckin = null
      if (this.checkins.length > 0 && !this.$store.getters["checkin/getCheckin"]) {
        maxCheckin = this.checkins.reduce((max, checkin) => max.id > checkin.id ? max : checkin);
        if (!maxCheckin) maxCheckin = null
        this.$store.dispatch('checkin/setCheckin', maxCheckin)
      }

      this.$root.$emit('loadingDone');
    },
    createCheckin() {
      this.$store.dispatch('checkin/setCheckin', new Checkin())
      this.$router.push("/checkin")
    }
  },
  computed: {
    getCheckins() {
      return this.checkins
    },
    getLocation() {
      return this.$store.getters.getLocation
    }
  },
  watch: {
    'getLocation'() {
      this.init()
    }
  }
}
</script>

<style lang="scss">
</style>
