
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, VivendiDienstDTO, VivendiDienstOuMapDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";


import Column from "primevue/column/Column";
import DataTable from "primevue/datatable/DataTable";
import ContextMenu from "primevue/contextmenu/ContextMenu";
import LabelComponent from "@/components/LabelComponent.vue";
import {VivendiDienstOuMap} from "@/model/Diva";
import {VivendiApi} from "@/services/VivendiApi";
import Multiselect from "@/libs/multiselect/";

@Component({
  computed: {
    VivendiDienstOuMap() {
      return VivendiDienstOuMap
    }
  },
  components: {
    LabelComponent,
    Multiselect,
    DataTable, Column, ContextMenu,
    SimpletableButtonHeader
  }
})
export default class VivendiDienstOuMapList extends mixins<SimpleViewMixin<VivendiDienstOuMapDTO, GenericError>>(SimpleViewMixin) {
  data: VivendiDienstOuMapDTO = new VivendiDienstOuMap()
  error: GenericError = new GenericError()

  dienste: VivendiDienstDTO[] = []

  menuModel = []

  async created() {
    this.menuModel = [
      {label: 'Bearbeiten', icon: 'fas fa-pen', command: () => this.edit(this.selectedItem)},
      {label: 'Löschen', icon: 'fas fa-trash', command: () => this.remove(this.selectedItem)}
    ]

    this.$root.$emit('loadingStart')

    await this.init(VivendiDienstOuMap,
        () => VivendiApi.getDienstOuMap(),
        (a) => VivendiApi.saveDienstOuMap(a),
        (b) => VivendiApi.removeDienstOuMap(b),
    )

    this.dienste = await VivendiApi.getDienste()

    this.$root.$emit('loadingDone')
  }

  edit(dto) {
    if (dto.orgUnit.id != this.$store.getters.getLocation.id) {
      this.$alert('Sie können nur Einträge bearbeiten, die Ihrem Standort zugeordnet sind', 'Fehler', 'error')
    } else {
      this.editObject(dto)
    }
  }

  remove(dto) {
    if (dto.orgUnit.id != this.$store.getters.getLocation.id) {
      this.$alert('Sie können nur Einträge löschen, die Ihrem Standort zugeordnet sind', 'Fehler', 'error')
    } else {
      this.removeObject(dto)
    }
  }


  get archivTxt() {
    return "Löschen"
  }
}
