
import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Event} from "@/model/Constants";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {Route} from "vue-router";
import {Permission, PermissionModul} from "@/model/dto";


@Component({
  components: {DatatableButtonHeader},
  watch: {
    '$route'(val: Route) {
      if (val.name.includes("userclothinglist")) {
        this.table.fixedHeader.adjust();
        this.table.fixedHeader.enable()
      } else {
        this.table.fixedHeader.disable()
      }
      this.handleDatableRoute(false);
      this.$root.$emit('loadingDone')
    }

  }
})
export default class UserClothingListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  error = null;
  tableId = 'dTable_' + uuid();
  data = null
  key = 0
  table = null


  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }


  mounted() {
    this.registerEvents([Event.userDressSizeChanged, Event.userArchived])

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/user/userlist/list/clothes',
        type: 'GET',
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      responsive: false,
      rowId: "user.id",
      columns: [
        {
          class: 'center',
          data: 'user.username'
        },
        {
          class: 'center',
          data: 'user.lastname'
        },
        {
          class: 'center',
          data: 'user.firstname'
        },
        {
          class: 'center',
          data: 'hose'
        },
        {
          class: 'center',
          data: 'poloshirt'
        },
        {
          class: 'center',
          data: 'pullover'
        },
        {
          class: 'center',
          data: 'schuhe'
        },
        {
          class: 'center',
          data: 'tshirt'
        },
        {
          class: 'center',
          data: 'hemd'
        },
        {
          class: 'center',
          data: 'softshelljacke'
        },
        {
          class: 'center',
          data: 'fleecejacke'
        },
        {
          class: 'center',
          data: 'einsatzjacke'
        },
        {
          class: 'center',
          data: 'einsatzweste'
        },
        {
          class: 'center',
          data: 'helm'
        },
        {
          class: 'center',
          data: 'muetzen'
        },
        {
          class: 'center',
          data: 'einmalhandschuhe'
        },
        {
          class: 'center',
          data: 'arbeitshandschuhe'
        },
        {
          class: 'center',
          data: 'guertel'
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(this.addButton(PermissionModul.User_Clothes, Permission.Update,
          {
            className: 'text-secondary bg-white',
            text: '<i class="fas fa-pen fa-xs" />',
            extend: 'selectedSingle',
            action: function (e, dt, node, config) {
              let id = table.row({selected: true}).id()
              console.log('edit user with id ' + id)
              self.editUser(id)
            },
          },
      ))
    })
    self.table = table
    func.datatableInitComplete(table)

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.editUser(rowId)
    })
  }

  editUser(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.User_Clothes, Permission.Update)) {
      return;
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/user/edit/" + id})
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }
}

