import {apiClient, silentClient} from './AbstractHTTPService'
import {ApiResponse} from "@/model/AbstractClasses";
import {ChatDTO, ChatMessageDTO, FullChatDTO} from "@/model/dto";


export abstract class ChatApi {
    static async putMessage(chatId, dto: ChatMessageDTO): Promise<ApiResponse<ChatMessageDTO>> {
        const response = await apiClient.put('/chat/message/' + chatId, dto);
        return new ApiResponse(response);
    }


    static async getChat(chatId): Promise<FullChatDTO> {
        const response = await apiClient.get('/chat/chat/' + chatId);
        return response.data.data;
    }

    static async checkMessages(): Promise<number> {
        //return 0; //TODO activate!!!!
        const response = await silentClient.get('/chat/check');
        return response.data;
    }

    static async getMyChats(): Promise<ChatDTO[]> {
        const response = await apiClient.get('/chat/chat/');
        return response.data.data;
    }

    static async setRead(id): Promise<any> {
        const response = await apiClient.put('/chat/check/' + id);
        return response.data;
    }
}