
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {MaterialFileCategoryDTO} from "@/model/dto";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import MaterialFileView from "@/views/material/MaterialFileView.vue";
import PseudoTag from "@/components/PseudoTag.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import InstructionText from "@/components/InstructionText.vue";


@Component(
    {
      components: {ListItemComponent, PseudoTag, MaterialFileView, PersonenkreisTag, InstructionText},
    }
)
export default class MaterialFileList extends Vue {
  fileCategories: MaterialFileCategoryDTO[] = []
  key = 0
  showAllTags = false
  maxTags: number;
  clb = null;
  selectedElem = null
  showModal = false

  async created() {
    this.maxTags = this.$store.getters['tags/getMaxTags']

    this.selectedElem = this.$store.state.material.filter.fileCategory
    this.clb = (fileCategory) => {
      this.selectedElem = fileCategory
      this.key++
    }

    this.fileCategories = await this.$store.dispatch("material/fetchDateiKategorienOu")
    bus.$on(Event.materialFileCategoryFilter, this.clb)
  }

  destroyed() {
    bus.$off(Event.materialFileCategoryFilter, this.clb)
  }

  get personenkreise() {
    let arr = []
    this.fileCategories.filter((ufk) => ufk.fileCategory.id == this.selectedElem.id).some((ufk: MaterialFileCategoryDTO) => {
      ufk.materialArt.forEach((art) => {
        arr.push(art)
      })
    })


    return arr
  }

  openModal() {
    this.showModal = true
  }

}
