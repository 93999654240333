import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {KfzApi} from "@/services/KfzApi";
import {DateApi} from "@/services/DateApi";
import {FileApi} from "@/services/FileApi";
import {StandortApi} from "@/services/StandortApi";
import {CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";
import {StoreHelper} from "@/store/StoreHelper";
import {OutsourcingDTO, TaskRequestDTO} from "@/model/dto";
import {TaskRequest} from "@/model/Task";
import {TaskApi} from "@/services/TaskApi";

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        kennungen: [],
        kategorien: [],
        standorte: [],
        outsourcing: [],
        kfz: [],
        dateTypes: [],
        dateTypesOu: [],
        dateiKategorien: [],
        dateiKategorienOu: [],
        aufbauer: [],
        hersteller: [],
        filter: {
            fileCategory: null,
            dateType: null
        }
    }
}
export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        SET_KENNUNGEN(state: any, data: any) {
            state.kennungen = data
        },
        SET_KATEGORIEN(state: any, data: any) {
            state.kategorien = data
        },
        SET_STANDORTE(state: any, data: any) {
            state.standorte = data
        },
        SET_OUTSOURCING(state: any, data: any) {
            state.outsourcing = data
        },
        SET_KFZ(state: any, data: any) {
            state.kfz = data
        },
        SET_DATE_TYPES(state: any, data: any) {
            state.dateTypes = data
        },
        SET_DATE_TYPES_OU(state: any, data: any) {
            state.dateTypesOu = data
        },
        SET_DATEI_KATEGORIEN(state: any, data: any) {
            state.dateiKategorien = data
        },
        SET_DATEI_KATEGORIEN_OU(state: any, data: any) {
            state.dateiKategorienOu = data
        },
        SET_AUFBAUER(state: any, data: any) {
            state.aufbauer = data
        },
        SET_HERSTELLER(state: any, data: any) {
            state.hersteller = data
        },
        resetState(state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {
        kfzUpdate({commit, state}: any) {
            commit('SET_KFZ', [])
        },
        standortUpdate({commit, state}: any) {
            commit('SET_STANDORTE', [])
        },
        dateTypOuUpdate({commit, state}: any) {
            commit('SET_DATE_TYPES_OU', [])
        },
        fileCategoryOuUpdate({commit, state}: any) {
            commit('SET_DATEI_KATEGORIEN_OU', [])
        },
        outsourcingUpdate({commit, state}: any) {
            commit('SET_OUTSOURCING', [])
        },
        kennungUpdate({commit, state}: any) {
            commit('SET_KENNUNGEN', [])
        },
        categoryUpdate({commit, state}: any) {
            commit('SET_KATEGORIEN', [])
        },
        taskRequestFilterUpdate({commit, state}, value: any) {
            commit('SET_REQUEST_FILTER', value)
        },
        async fetchKennungen({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.kennungen && state.kennungen.length > 0) {
                    resolve(state.kennungen)
                } else {
                    KfzApi.getKennungenOuChain()
                        .then(data => {
                            commit('SET_KENNUNGEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchKategorien({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.kategorien && state.kategorien.length > 0) {
                    resolve(state.kategorien)
                } else {
                    KfzApi.getCategories()
                        .then(data => {
                            commit('SET_KATEGORIEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchStandorte({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.standorte && state.standorte.length > 0) {
                    resolve(state.standorte)
                } else {
                    StandortApi.getStandorte("kfz")
                        .then(data => {
                            commit('SET_STANDORTE', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchOutsourcing({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.outsourcing && state.outsourcing.length > 0) {
                    resolve(state.outsourcing)
                } else {
                    KfzApi.getOutsourcing()
                        .then(data => {
                            commit('SET_OUTSOURCING', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchKfz({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.kfz && state.kfz.length > 0) {
                    resolve(state.kfz)
                } else {
                    KfzApi.getKfzList()
                        .then(data => {
                            commit('SET_KFZ', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateTypCatalog({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateTypes && state.dateTypes.length > 0) {
                    resolve(state.dateTypes)
                } else {
                    DateApi.getDateTypes('kfz')
                        .then(data => {
                            commit('SET_DATE_TYPES', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateTypesOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateTypesOu && state.dateTypesOu.length > 0) {
                    resolve(state.dateTypesOu)
                } else {
                    DateApi.getKfzDateTypesOuChain()
                        .then(data => {
                            commit('SET_DATE_TYPES_OU', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },

        async fetchDateiKategorienKatalog({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateiKategorien && state.dateiKategorien.length > 0) {
                    resolve(state.dateiKategorien)
                } else {
                    FileApi.getFileCategory('kfz')
                        .then(data => {
                            commit('SET_DATEI_KATEGORIEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateiKategorienOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateiKategorienOu && state.dateiKategorienOu.length > 0) {
                    resolve(state.dateiKategorienOu)
                } else {
                    FileApi.getKfzFileCategoryOuChain()
                        .then(data => {
                            commit('SET_DATEI_KATEGORIEN_OU', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchAufbauer({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.aufbauer && state.aufbauer.length > 0) {
                    resolve(state.aufbauer)
                } else {
                    KfzApi.getAufbauer()
                        .then(data => {
                            commit('SET_AUFBAUER', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchHersteller({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.hersteller && state.hersteller.length > 0) {
                    resolve(state.hersteller)
                } else {
                    KfzApi.getHersteller()
                        .then(data => {
                            commit('SET_HERSTELLER', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({state, commit}, event: CrudEvent) {
            switch (event.name) {
                case Event.kfzCategoryUpdate:
                    StoreHelper.update("SET_KATEGORIEN", state.kategorien, event, commit)
                    break;
                case Event.kfzKennungUpdate:
                    StoreHelper.update("SET_KENNUNGEN", state.kennungen, event, commit)
                    break;
                case Event.kfzChanged:
                    StoreHelper.update("SET_KFZ", state.kfz, event, commit)
                    break;
                case Event.kfzDateOuUpdate:
                    StoreHelper.update("SET_DATE_TYPES_OU", state.dateTypesOu, event, commit)
                    break;
                case Event.kfzFileCategoryOuUpdate:
                    StoreHelper.update("SET_DATEI_KATEGORIEN_OU", state.dateiKategorienOu, event, commit)
                    break;
                case Event.outsourcingChanged:
                    if (event.dto) {
                        StoreHelper.updateEntityStandorte((!event.dto['kfzStandort']), "outsourcing", "SET_STANDORTE", state.standorte, event, commit);
                    }

                    if (event.dto) {
                        const outsourcingDto = <OutsourcingDTO>event.dto
                        if (!outsourcingDto.kfzStandort) {
                            event.id = outsourcingDto.id
                            event.dto = null
                        }
                    }
                    StoreHelper.update("SET_OUTSOURCING", state.outsourcing, event, commit)

                    break
                case Event.entityStandortUpdate:
                    if (!event.dto) return;
                    StoreHelper.updateEntityStandorte((!event.dto['kfzStandort']), null, "SET_STANDORTE", state.standorte, event, commit);
                    break;
                case Event.fileCategoryUpdate:
                    if (event.dto && event.dto['typ'] == 2) {
                        StoreHelper.update("SET_DATEI_KATEGORIEN", state.dateiKategorien, event, commit)
                    }
                    break;
                case Event.dateTypUpdate:
                    if (event.dto && event.dto['typ'] == 2) {
                        StoreHelper.update("SET_DATE_TYPES", state.dateTypes, event, commit)
                    }
                    break;
            }
        },
    },
    getters: {},
    modules: {}
}
