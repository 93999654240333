
import {Component, Prop, Vue} from "vue-property-decorator";
import Multiselect from "@/libs/multiselect";
import {TaskDTO} from "@/model/dto";
import {Module} from "@/model/Constants";


@Component(
    {
      computed: {
        Module() {
          return Module
        }
      },
      components: {Multiselect}
    }
)
export default class TaskModal extends Vue {
  @Prop() private value;
  @Prop() private selectedItem: TaskDTO;
  @Prop() private hideProcess;

  cleanText = ""

  created() {
    this.cleanText = this.$sanitize(this.selectedItem.text)
  }

  get showModal() {
    return this.value
  }

  set showModal(v) {
    this.$emit("input", v);
  }

  process() {
    this.$emit('process', this.selectedItem)
  }
}
