
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DesiRoutineDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {DctTriple, DesiRoutine} from "@/model/Hygiene";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {HygieneApi} from "@/services/HygieneApi";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import Multiselect from '@/libs/multiselect'
import HygieneDctTriple from "@/views/hygiene/components/HygieneDctTriple.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {Multiselect, ListItemComponent, ChainIconTooltipTableCell, SimpletableButtonHeader, HygieneDctTriple}
})
export default class HygieneDesiRoutineView extends mixins<SimpleViewMixin<DesiRoutineDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: DesiRoutineDTO = new DesiRoutine()
  error: GenericError = new GenericError()


  disinfecantOpts = []
  concentrationOpts = []
  einwirkZeitOpts = []
  typeOpts = []

  async created() {
    await this.init(DesiRoutine,
        HygieneApi.getDesiRoutines,
        (a) => HygieneApi.putDesiRoutine(a),
        (b) => HygieneApi.removeDesiRoutine(b)
    )

  //  this.typeOpts = await this.$store.dispatch("hygiene/fetchTypes")
    this.disinfecantOpts = await this.$store.dispatch("hygiene/fetchDisinfectant")
    this.concentrationOpts = await this.$store.dispatch("hygiene/fetchConcentration")
    this.einwirkZeitOpts = await this.$store.dispatch("hygiene/fetchEinwirkZeit")
  }


  removeAlternative(idx) {
    this.data.alternatives.splice(idx, 1)
  }

  addAlternative() {
    const alternative = new DctTriple();
    this.data.alternatives.push(alternative);
  }


  get archivTxt() {
    return "Löschen"
  }

}
