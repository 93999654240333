import {
    AbfragezeitenDTO,
    DienstAusnahmeDTO,
    DienstDTO,
    MappedPermissionDTO,
    OrgUnitDienstzuordnungDTO,
    OrgUnitDTO,
    OrgUnitEvdDTO,
    OrgUnitExtendedDTO,
    OrgUnitMerkmalDTO,
    OrgUnitRechtstraegerDTO,
    OrgUnitTreeDTO,
    OrgUnitTypDTO,
    UserResponseDTO
} from "@/model/dto";
import {AbstractClass} from "@/model/AbstractClasses";


export class OrgUnit extends AbstractClass implements OrgUnitDTO {
    name: string;
    sName: string;
    type: string | null;
    icon: string | null;
    searchText: string | null;
    text: string | null;
    typeText: string | null;
    merkmale: OrgUnitMerkmalDTO[];
    locked: boolean | null;
    typ: OrgUnitTypDTO | null;
    module: string[] = [];
    forbiddenModule: string[] = [];
}

export class OrgUnitExtended extends OrgUnit implements OrgUnitExtendedDTO {
    ansprechpartner: string | null;
    ansprechpartnerAdresse: string | null;
    ansprechpartnerEmail: string | null;
    ansprechpartnerTelefon: string | null;
    dienstzuordnung: OrgUnitDienstzuordnungDTO | null;
    rechnungsportalId: string | null;
    rechtstraeger: OrgUnitRechtstraegerDTO | null;
    sapNummer: string | null;
    standortId: string | null;
    vertragsNummer: string | null;
    smsVertrag: string | null;
    zeusId: number | null;

}
export class OrgUnitTree extends AbstractClass implements OrgUnitTreeDTO {
    childs: OrgUnitTreeDTO[];
    name: string;
    permissions: MappedPermissionDTO[];
    dto: any;
    locked: boolean | null;
}

export class Dienst extends AbstractClass implements  DienstDTO {
    abfragezeiten: AbfragezeitenDTO[] = [];
    adresse: string | null;
    anzeigenCheckin: boolean;
    anzeigenVerfuegbarkeiten: boolean;
    ausnahmen: DienstAusnahmeDTO[] = [];
    autoCheckoutStunden: number | null;
    beifahrerPflicht: boolean;
    endZeit: string | null;
   
    kennungPflicht: boolean;
    kfzPflicht: boolean;
    kmPflicht: boolean;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;
    startZeit: string | null;
    archive: boolean | null;
}

export class DienstAusnahme extends AbstractClass implements  DienstAusnahmeDTO {
    endZeit: string | null;
   
    startZeit: string | null;
    tag: string | null;
}

export class Abfragezeit extends AbstractClass implements  AbfragezeitenDTO {
    d0: boolean;
    d1: boolean;
    d2: boolean;
    d3: boolean;
    d4: boolean;
    d5: boolean;
    d6: boolean;
   
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;
    archive: boolean | null;
}

export class OrgUnitEvd extends AbstractClass implements OrgUnitEvdDTO {
    nummer: string | null;
    nummerUser: boolean | null;
    user: UserResponseDTO | null;
    vererbt: boolean | null;
    zeigeName: boolean | null;

}