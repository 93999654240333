<template>
  <div class="container text-center mt-2">
    <h2>Impressum</h2>
    <h4>Angaben gemäß § 5 TMG</h4>
    <br>
    findSolutions GmbH<br>
    Heiligkreuzweg 89F<br>
    55130 Mainz<br><br>

    Vertreten durch:<br>
    Alexander Pohl

    <br><br>
    Kontakt:<br>
    Telefon: 06131-9450645<br>
    E-Mail: info(at)findsolutions.de<br>
    <br>
    Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE306477752
    <br><br>

    <a href="javascript:history.back()">Zurück</a>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Impressum'
}
</script>

<style scoped>

</style>
