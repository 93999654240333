
import {Component, Prop, Vue} from 'vue-property-decorator';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import 'vue-pdf-embed/src/styles/text-layer.css'

@Component({
  components: {VuePdfEmbed},
})
export default class PdfView extends Vue {
  @Prop({default: true}) downloadButton;
  @Prop() source;
  @Prop() filename;
  isLoading = false;

  download() {
    this.$refs.pdfviewer.download(this.filename)
  }

  loaded() {
    this.isLoading = false;
  }
}
