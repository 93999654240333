
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EwoObjektbezeichnungDTO} from "@/model/dto";
import {EwoBaseDataGeneric} from "@/model/Ewmpg";
import {EwmpgApi} from "@/services/EwmpgApi";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";

const DataProps = Vue.extend({
  props: {}
})
@Component({
  components: {SimpletableButtonHeader}
})
export default class EwoObjektbezeichnungView extends mixins<SimpleViewMixin<EwoObjektbezeichnungDTO, EwoObjektbezeichnungDTO>>(SimpleViewMixin, DataProps) {
  data: EwoObjektbezeichnungDTO = new EwoBaseDataGeneric()
  error: EwoObjektbezeichnungDTO = new EwoBaseDataGeneric()

  async created() {
    await this.init(EwoBaseDataGeneric, EwmpgApi.getObjektbezeichnungList, (a) => EwmpgApi.putObjektbezeichnung(a), (b) => EwmpgApi.removeObjektbezeichnung(b))
    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects.sort((a, b) => a.id - b.id)
  }

}
