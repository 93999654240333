
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {EinsatzDTO, GenericError} from "@/model/dto";
import {Route} from "vue-router";
import {Actions} from "@/model/Actions";
import EinsatzDataView from "@/views/einsatz/data/EinsatzDataView.vue";
import {Einsatz} from "@/model/Einsatz";
import {EinsatzApi} from "@/services/EinsatzApi";
import moment from "moment";


@Component({
  components: {
    EinsatzDataView,
  },
  watch: {
    '$route'(val: Route) {
      this.init()
    }
  }
})
export default class EinsatzCreateView extends mixins<GenericMixin<EinsatzDTO, GenericError>>(GenericMixin) {
  data = new Einsatz();
  error = new GenericError();


  async created() {
    this.init()
  }

  init() {
    this.data = new Einsatz()
    this.data.addFields = []
    this.data.datumStart = moment().format(moment.HTML5_FMT.DATE);
    this.data.rows = []
  }

  onSubmit(send: boolean) {
    const p = EinsatzApi.putEinsatz(this.data, send ? Actions.SEND : Actions.SAVE);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send einsatzCreated event')
        bus.$emit(Event.einsatzCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push(this.$store.state.route.from.fullPath)
  }

}
