
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, SkillDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {Skill} from "@/model/User";
import {UsersApi} from "@/services/UserApi";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, LabelComponent, SimpletableButtonHeader, SettingsTooltip}
})
export default class SkillCatalogView extends mixins<SimpleViewMixin<SkillDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: SkillDTO = new Skill()
  error: GenericError = new GenericError()

  chainKatalog: SkillDTO[] = []
  getParam = null

  async created() {

    await this.init(Skill,
        UsersApi.getSkills,
        (a) => UsersApi.putSkill(a),
        (b) => UsersApi.removeSkill(b)
    )

    this.chainKatalog = this.getObjects.filter(cat => (!cat.orgUnit || cat.orgUnit.id != this.$store.getters.getLocation.id))
    this.$root.$emit('loadingDone')
  }


  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  get archivTxt() {
    return "Löschen"
  }


  get getOrgUnitObjects() {
    return this.getObjects.filter(cat => (cat.orgUnit && cat.orgUnit.id == this.$store.getters.getLocation.id))
  }

}
