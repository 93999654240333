
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {FobiZeitraumDTO} from "@/model/dto";
import {FobiZeitraum} from "@/model/Fobi";
import {FobiApi} from "@/services/FobiApi";
import FobiZeitraumDataView from "@/views/fobi/FobiZeitraumDataView.vue";
import FobiZeitraumOverview from "@/views/fobi/FobiZeitraumOverview.vue";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  components: {
    FobiZeitraumOverview,
    FobiZeitraumDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class FobiZeitraumEditView extends mixins<GenericMixin<FobiZeitraumDTO, FobiZeitraumDTO>>(GenericMixin, DataProps) {
  data = new FobiZeitraum();
  error = new FobiZeitraum();
  showTabs = false
  readonly = false;

  created() {
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = FobiApi.getZeitraum(this.$props.id);
    this.handleApiRequest(p)
    p.then(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
      this.readonly = (this.data.orgUnit.id !=  this.$store.getters.getLocation.id)
    })
  }

  onSubmit(goBack: boolean) {
    const p = FobiApi.putZeitraum(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send fobiZeitraumChanged event')
        bus.$emit(Event.fobiZeitraumChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/fobi/zeitraum", query: queryParam})
  }

  archiv() {
    bus.$emit(Event.fobiZeitraumArchiveRequest, new ObjectEvent(this.data.id, this.data));
  }

  get archivTxt() {
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }
}
