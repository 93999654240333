
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {FormApi} from "@/services/FormApi";
import {FormVersion, UpsertForm, UpsertFormError} from "@/model/FormVersion";
import FormDataView from "@/views/form/components/FormDataView.vue";
import FormVersionDataView from "@/views/form/FormVersionDataView.vue";
import FormInput from "@/views/form/components/FormInput.vue";


@Component({
  components: {
    FormInput,
    FormVersionDataView,
    FormDataView,
  }
})
export default class FormCreateView extends mixins<GenericMixin<UpsertForm, UpsertFormError>>(GenericMixin) {
  data = new UpsertForm();
  error = new UpsertFormError();

  async created() {
    const from = this.$store.state.route.from
    if (from.meta['module']) {
      this.data.typ = from.meta['module']
    }

    this.data.rows = []
    if (this.$route.params['formVersion']) {
      this.data = await FormVersion.copy(this.data, this.$route.params['formVersion'])
    }
  }

  onSubmit(goBack: boolean) {
    const p = FormApi.putForm(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send formCreated event')
        bus.$emit(Event.formCreated, new ObjectEvent(data.data.id, data.data));
        self.$router.push(
            {
              name: "formversionedit", params: {
                fromRoute: this.$store.state.route.from,
                form: "" + data.data.form.id,
                id: "" + data.data.id
              }
            })
      }
    })
  }

  goBack() {
    const fromName = this.$store.state.route.from.name
    const from = this.$store.state.route.from.fullPath


    if (!(fromName && from)) {
      this.$router.push({name: "formtable"})
      return
    }

    if (fromName != this.$route.name && (fromName.includes("table") || fromName.includes("list"))) {
      this.$router.push(from)
    } else {
      this.$router.push({name: "formtable"})
    }
  }

}
