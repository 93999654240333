
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import MedProduktDateView from "@/views/medprodukt/MedProduktDateView.vue";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import PseudoTag from "@/components/PseudoTag.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import UserDateView from "@/views/user/UserDateView.vue";
import InstructionText from "@/components/InstructionText.vue";

@Component(
    {
      components: {UserDateView, ListItemComponent, PseudoTag, PersonenkreisTag, MedProduktDateView, InstructionText},
    }
)
export default class MedProduktDateList extends Vue {
  showAllTags = false;
  maxTags: number;
  showModal = false

  clb = null;
  selectedElem = null
  key = 0

  async created() {
    this.maxTags = this.$store.getters['tags/getMaxTags']
    this.selectedElem = this.$store.state.medprodukt.filter.dateType
    this.clb = (dateType) => {
      this.selectedElem = dateType
      this.key++
    }
    bus.$on(Event.medProduktDateCategoryFilter, this.clb)
  }

  destroyed() {
    bus.$off(Event.medProduktDateCategoryFilter, this.clb)
  }


  getTagsForSlice(arr: []) {
    return this.showAllTags ? arr : arr.slice(0, this.maxTags)
  }


  get dateTypeTabs() {
    return this.dateTypes
  }

  openModal() {
    this.showModal = true
  }
}
