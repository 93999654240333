
import {Component} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {ManualFileDTO} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import {ManualFile} from "@/model/File";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import ManualFileEditModal from "@/views/manual/ManualFileEditModal.vue";
import {Route} from "vue-router";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FileApi} from "@/services/FileApi";
import {bus} from "@/main";
import {Event, Module} from "@/model/Constants";
import {HistoryEvent} from "@/model/Shared";


@Component(
    {
      components: {ManualFileEditModal, DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("manuallist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
          this.handleDatableRoute(true)
        }
      }
    }
)
export default class ManualFileList extends mixins<GenericMixin<null, null>>(GenericMixin) {
  table = null
  tableId = 'dTable_' + uuid();
  data: ManualFileDTO | null = null
  modalId = 'manual-file-edit-modal'

  getUrl() {
    return '/file/manualfile/' + ((this.archive) ? 'archive/' : 'noarchive/');
  }

  newFile() {
    this.data = new ManualFile()
    this.$bvModal.show(this.modalId)
  }


  edit(id) {
    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();
    this.data = rowdata
    this.$bvModal.show(this.modalId)
  }

  updateTable() {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
  }


  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: this.getUrl(),
        type: 'GET'
      }),
      rowId: "id",
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      columns: [
        {
          class: '',
          data: 'hersteller'
        },
        {
          class: '',
          data: 'modell'
        },
        {
          class: '',
          data: 'version'
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.fileCategories ? data.fileCategories.map(fC => fC.name).join(", ") : ""
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.standVon ? moment(data.standVon).format("DD.MM.YYYY") : ""
          }
        },
        {
          class: 'text-center cw-100',
          data: null,
          render: function (data, type, row) {
            return data.file != null ? '<button file="' + data.file.id + '" class="btn btn-outline-info download" type="button"><i class="fas fa-download" /></button>' : ''
          }
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons([
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.newFile()
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Bearbeiten',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            self.edit(id)
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Zeilenhistorie',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/file/manualfile', id))
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Archiv',
          text: '<i class="fas fa-archive" />',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        },
      ], true)
    })
    func.datatableInitComplete(table)

    self.initContextMenu()
    self.table = table

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })

    jquery('#' + this.tableId + ' tbody').on('click', '.download', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      let myId = jquery(this).attr('file')
      self.download(myId, Module.Manual)
    });
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "manual", query: queryParam})
  }

  initContextMenu() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: {
        copy: {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        },
        archive: {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        },
        history: {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/file/manualfile', tr.attr('id')))
          }
        }
      }
    });
  }

  archiv(data) {
    this.$confirm('Sind sie Sicher ?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      FileApi.archiveManualFile(data)
          .then(() => {
            data.archive = !data.archive
            this.updateTable()
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }
}


