import {apiClient} from './AbstractHTTPService'
import store from '../store'

import Axios from "axios";
import {ApiResponse} from "@/model/AbstractClasses";
import {CountryDTO, EwoDTO, EwoGeraeteartDTO, EwoHerstellerDTO, EwoObjektbezeichnungDTO, EwoSoftwareversionDTO, FileDTO} from "@/model/dto";


export abstract class EwmpgApi {
    private static usersAxios = Axios.create();

    static async getHerstellerList(): Promise<EwoHerstellerDTO[]> {
        const response = await apiClient.get('/ewmpg/hersteller');
        return response.data.data;
    }

    static async putHersteller(hersteller: EwoHerstellerDTO): Promise<ApiResponse<EwoHerstellerDTO>> {
        const response = await apiClient.put('/ewmpg/hersteller' + hersteller.id + '/', hersteller);
        store.dispatch("medprodukt/herstellerUpdate");
        return new ApiResponse(response);
    }

    static async removeHersteller(id: number): Promise<ApiResponse<any>> {
        const response = await apiClient.delete('/ewmpg/hersteller' + id);
        store.dispatch("medprodukt/herstellerUpdate");
        return new ApiResponse(response);
    }


    static async getGeraeteartList(): Promise<EwoGeraeteartDTO[]> {
        const response = await apiClient.get('/ewmpg/geraeteart');
        return response.data.data;
    }

    static async putGeraeteart(obj: EwoGeraeteartDTO): Promise<ApiResponse<EwoGeraeteartDTO>> {
        const response = await apiClient.put('/ewmpg/geraeteart' + obj.id + '/', obj);
        store.dispatch("medprodukt/geraeteartUpdate");
        return new ApiResponse(response);
    }

    static async removeGeraeteart(id: number): Promise<ApiResponse<any>> {
        const response = await apiClient.delete('/ewmpg/geraeteart' + id);
        store.dispatch("medprodukt/geraeteartUpdate");
        return new ApiResponse(response);
    }



    static async getSoftwareversionList(): Promise<EwoSoftwareversionDTO[]> {
        const response = await apiClient.get('/ewmpg/softwareversion');
        return response.data.data;
    }

    static async putSoftwareversion(obj: EwoSoftwareversionDTO): Promise<ApiResponse<EwoSoftwareversionDTO>> {
        const response = await apiClient.put('/ewmpg/softwareversion' + obj.id + '/', obj);
        store.dispatch("medprodukt/softwareversionenUpdate");
        return new ApiResponse(response);
    }

    static async removeSoftwareversion(id: number): Promise<ApiResponse<any>> {
        const response = await apiClient.delete('/ewmpg/softwareversion' + id);
        store.dispatch("medprodukt/softwareversionenUpdate");
        return new ApiResponse(response);
    }

    

    static async getObjektbezeichnungList(): Promise<EwoObjektbezeichnungDTO[]> {
        const response = await apiClient.get('/ewmpg/objektbezeichnung');
        return response.data.data;
    }

    static async putObjektbezeichnung(obj: EwoObjektbezeichnungDTO): Promise<ApiResponse<EwoObjektbezeichnungDTO>> {
        const response = await apiClient.put('/ewmpg/objektbezeichnung' + obj.id + '/', obj);
        store.dispatch("medprodukt/objektbezeichnungUpdate");
        return new ApiResponse(response);
    }

    static async removeObjektbezeichnung(id: number): Promise<ApiResponse<any>> {
        const response = await apiClient.delete('/ewmpg/objektbezeichnung' + id);
        store.dispatch("medprodukt/objektbezeichnungUpdate");
        return new ApiResponse(response);
    }

    static async getEwoList(): Promise<EwoDTO[]> {
        const response = await apiClient.get('/ewmpg/einweisungsobjekt');
        return response.data.data;
    }


    static async getEwo(id): Promise<EwoDTO[]> {
        const response = await apiClient.get('/ewmpg/ewodata/'+id);
        return response.data;
    }

    static async putEwo(obj: EwoDTO): Promise<ApiResponse<EwoDTO>> {
        const response = await apiClient.put('/ewmpg/ewodata/' + obj.id + '/', obj);
        store.dispatch("medprodukt/ewoUpdate");
        return new ApiResponse(response);
    }

    static async archiveEwo(id: number): Promise<ApiResponse<any>> {
        const response = await apiClient.delete('/ewmpg/ewodata/' + id);
        store.dispatch("medprodukt/ewoUpdate");
        return new ApiResponse(response);
    }


    static async updatePhoto(ewoId: number, dto): Promise<FileDTO> {
        const response = await apiClient.put('/ewmpg/ewmpgphoto/' + ewoId + '/', dto);
        return response.data.data;
    }

    static async removePhoto(ewoId: number): Promise<any> {
        const response = await apiClient.delete('/ewmpg/ewmpgphoto/' + ewoId + '/');
        return response.data;
    }

    static async getCountries(): Promise<CountryDTO[]> {
        const response = await apiClient.get('/ewmpg/land');
        return response.data.data;
    }



}