import { render, staticRenderFns } from "./DiensteView.vue?vue&type=template&id=5ff9dff8&scoped=true&"
import script from "./DiensteView.vue?vue&type=script&lang=ts&"
export * from "./DiensteView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff9dff8",
  null
  
)

export default component.exports