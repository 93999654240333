
export class UserId {
    static readonly FREITEXT = -100
    static readonly UNBESETZT = -200
}


export class Module {
    static readonly TASK = 1
    static readonly REPORT = 2
    static readonly EINSATZ = 3


    static readonly MEDPRODUKT = 20
    static readonly KFZ = 21
    static readonly USER = 22
    static readonly MATERIAL = 23

    static readonly KENNUNG = 25


    static readonly EWO = 30
    static HYGIENE = 31;
    static Fobi = 32;
    static Einweisung = 33;
    static FileLex = 34;
    static Manual = 35;
    static Cloud = 36;

    static readonly FORMROW = 99
}


export class Event {
    static readonly userChanged = 'userChanged';
    static readonly userArchived = 'userArchived';
    static readonly userCreated = 'userCreated';
    static readonly userDressSizeChanged = 'userDressSizeChanged';

    static readonly userFileCategoryFilter = 'userFileCategoryFilter';
    static readonly userDateCategoryFilter = 'userDateCategoryFilter';
    static readonly userQualificationChanged = 'userQualificationChanged';

    static readonly kfzFileCategoryFilter = 'kfzFileCategoryFilter';
    static readonly kfzDateCategoryFilter = 'kfzDateCategoryFilter';
    static readonly kfzChanged = 'kfzChanged';
    static readonly kfzCreated = 'kfzCreated';
    static readonly kfzArchived = 'kfzArchived';
    static readonly kfzUmzugRequest = 'kfzUmzugRequest';


    static readonly ewoChanged = 'ewoChanged';
    static readonly ewoCreated = 'ewoCreated';
    static readonly ewoArchived = 'ewoArchived';

    static readonly medProduktDateCategoryFilter = 'medProduktDateCategoryFilter';
    static readonly medProduktFileCategoryFilter = 'medProduktFileCategoryFilter';
    static readonly medproduktChanged = 'medProduktChanged';
    static readonly medproduktCreated = 'medProduktCreated';
    static readonly medproduktArchived = 'medProduktArchived';

    static readonly outsourcingChanged = 'outsourcingChanged';
    static readonly outsourcingArchived = 'outsourcingArchived';
    static readonly outsourcingCreated = 'outsourcingCreated';

    static readonly medProduktInbetriebnahmeChange = 'medProduktInbetriebnahmeChange';
    static readonly medProduktChildDataChange = 'medProduktChildDataChange';

    static readonly personenkreisChanged = 'personenkreisChanged';
    static readonly personenkreisArchived = 'personenkreisArchived';
    static readonly personenkreisCreated = 'personenkreisCreated';


    static readonly materialFileCategoryFilter = 'materialFileCategoryFilter';
    static readonly materialDateCategoryFilter = 'materialDateCategoryFilter';
    static readonly materialArtChanged = 'materialArtChanged';
    static readonly materialArtArchived = 'materialArtArchived';
    static readonly materialArtCreated = 'materialArtCreated';


    static readonly materialChanged = 'materialChanged';
    static readonly materialArchived = 'materialArchived';
    static readonly materialCreated = 'materialCreated';

    static readonly materialAusgabeChanged = 'materialAusgabeChanged';
    static readonly materialAusgabeCreated = 'materialAusgabeCreated';

    static readonly materialArtFilterChanged = 'materialArtFilterChanged';
    static readonly materialStandortChanged = 'materialStandortChanged';
    static readonly materialReviewSubProducts = 'materialReviewSubProducts';
    static readonly materialParentArchive = 'materialParentArchive';

    static readonly einweisungChanged = 'einweisungChanged';
    static readonly einweisungArchived = 'einweisungArchived';
    static readonly einweisungCreated = 'einweisungCreated';
    static readonly einweisungArchiveRequest = 'einweisungArchiveRequest';


    static readonly einweisungConfigChanged = 'einweisungConfigChanged';
    static readonly einweisungConfigArchived = 'einweisungConfigArchived';
    static readonly einweisungConfigCreated = 'einweisungConfigCreated';
    static readonly einweisungConfigArchiveRequest = 'einweisungConfigArchiveRequest';

    static readonly einweisungOverviewChanged = 'einweisungOverviewChanged';


    static readonly entityStandortUpdate = 'entityStandortUpdate';
    static readonly kfzCategoryUpdate = 'kfzCategoryUpdate';
    static readonly kfzKennungUpdate = 'kfzKennungUpdate';
    static readonly kfzDateOuUpdate = 'kfzDateOuUpdate';
    static readonly kfzFileCategoryOuUpdate = 'kfzFileCategoryOuUpdate';
    static readonly medProduktFileCategoryOuUpdate = 'medProduktFileCategoryOuUpdate';
    static readonly userDateOuUpdate = 'userDateOuUpdate';
    static readonly userFileCategoryOuUpdate = 'userFileCategoryOuUpdate';
    static readonly fileCategoryUpdate = 'fileCategoryUpdate';
    static readonly dateTypUpdate = 'dateTypUpdate';
    static readonly skillUpdate = 'skillUpdate';
    static readonly qualiUpdate = 'qualiUpdate';
    static readonly materialDateOuUpdate = 'materialDateOuUpdate';
    static readonly materialFileCategoryOuUpdate = 'materialFileCategoryOuUpdate';


    static readonly fobiChanged = 'fobiChanged';
    static readonly fobiArchived = 'fobiArchived';
    static readonly fobiCreated = 'fobiCreated';
    static readonly fobiArchiveRequest = 'fobiArchiveRequest';

    static readonly fobiKombiThemaChanged = 'fobiKombiThemaChanged';
    static readonly fobiKombiThemaArchived = 'fobiKombiThemaArchived';
    static readonly fobiKombiThemaCreated = 'fobiKombiThemaCreated';
    static readonly fobiKombiThemaArchiveRequest = 'fobiKombiThemaArchiveRequest';

    static readonly fobiZeitraumChanged = 'fobiZeitraumChanged';
    static readonly fobiZeitraumArchived = 'fobiZeitraumArchived';
    static readonly fobiZeitraumCreated = 'fobiZeitraumCreated';
    static readonly fobiZeitraumArchiveRequest = 'fobiZeitraumArchiveRequest';
    
    static readonly fobiThemaChanged = 'fobiThemaChanged';
    static readonly fobiKlasseChanged = 'fobiKlasseChanged';
    static readonly fobiFilterRequest = 'fobiFilterRequest';

    static readonly uploadDescription = 'uploadDescription';


    static readonly taskChanged = 'taskChanged';
    static readonly taskArchived = 'taskArchived';
    static readonly taskCreated = 'taskCreated';

    static readonly taskTemplateChanged = 'taskTemplateChanged';
    static readonly taskTemplateArchived = 'taskTemplateArchived';
    static readonly taskTemplateCreated = 'taskTemplateCreated';

    static readonly taskArchiveRequest = 'taskArchiveRequest';
    static readonly taskFilterChanged = 'taskFilterChanged';

    static readonly chatRead = 'chatRead';
    static readonly chatRefresh = 'chatRefresh';


    static readonly formChanged = 'formChanged';
    static readonly formVersionArchived = 'formVersionArchived';
    static readonly formCreated = 'formCreated';
    static readonly formVersionRemoveRequest = 'formVersionRemoveRequest';
    static readonly formTakeOver = 'formTakeOver';
    static readonly formFilterChanged = 'formFilterChanged';
    static readonly formArchiveRequest = 'formArchiveRequest';



    static readonly emeldLevelRemoveRequest = 'emeldLevelRemoveRequest';
    static readonly emeldLevelChanged = 'emeldLevelChanged';
    static readonly emeldLevelArchived = 'emeldLevelArchived';
    static readonly emeldLevelCreated = 'emeldLevelCreated';

    static readonly emeldLevelOuRemoveRequest = 'emeldLevelOuRemoveRequest';
    static readonly emeldLevelOuChanged = 'emeldLevelOuChanged';
    static readonly emeldLevelOuArchived = 'emeldLevelOuArchived';
    static readonly emeldLevelOuCreated = 'emeldLevelOuCreated';


    static readonly emeldReportFilterChanged = 'emeldReportFilterChanged';
    static readonly emeldReportRemoveRequest = 'emeldReportRemoveRequest';
    static readonly emeldReportChanged = 'emeldReportChanged';
    static readonly emeldReportArchived = 'emeldReportArchived';
    static readonly emeldReportCreated = 'emeldReportCreated';



    static readonly einsatzFilterChanged = 'einsatzFilterChanged';
    static readonly einsatzRemoveRequest = 'einsatzRemoveRequest';
    static readonly einsatzChanged = 'einsatzChanged';
    static readonly einsatzArchived = 'einsatzArchived';
    static readonly einsatzCreated = 'einsatzCreated';


    static readonly abfragezeitenUpdate = 'abfragezeitenUpdate';
    static readonly dienstUpdate = 'dienstUpdate';
    static readonly entityTaskTemplateUpdate = 'entityTaskTemplateUpdate';
    static readonly einsatzVorlageUpdate = 'einsatzVorlageUpdate';

    static readonly checkinUpdate = 'checkinUpdate';
    static readonly checkinArchiveRequest = 'checkinArchiveRequest';
    static readonly checkinChanged = 'checkinChanged';
    static readonly checkinArchived = 'checkinArchived';
    static readonly checkinCreated = 'checkinCreated';

    static readonly entityTaskFilterChanged = 'entityTaskFilterChanged';


    static readonly hygieneFilterChanged = 'hygieneFilterChanged';
    static readonly desiRemoveRequest = 'desiRemoveRequest';
    static readonly desiChanged = 'desiChanged';
    static readonly desiArchived = 'desiArchived';
    static readonly desiCreated = 'desiCreated';
    static readonly hygieneAmpelUpdate = 'hygieneAmpelUpdate';
    static readonly hygieneConcentrationUpdate = 'hygieneConcentrationUpdate';
    static readonly hygieneTypeUpdate = 'hygieneTypeUpdate';
    static readonly hygieneScopeUpdate = 'hygieneScopeUpdate';
    static readonly hygieneProtectionUpdate = 'hygieneProtectionUpdate';
    static readonly hygieneEinwirkzeitUpdate = 'hygieneEinwirkzeitUpdate';
    static readonly hygieneDisinfectantUpdate = 'hygieneDisinfectedUpdate';
    static readonly hygieneDiseaseUpdate = 'hygieneDiseaseUpdate';
    static readonly hygieneDesiRoutineUpdate = 'hygieneDesiRoutineUpdate';
    static readonly hygieneDesiAdviceUpdate = 'hygieneDesiAdviceUpdate';
    static readonly hygieneDesiInheritanceUpdate = 'hygieneDesiInheritanceUpdate';

    static readonly fileLexCategoryUpdate = 'fileLexCategoryUpdate';

    static readonly tagebuchUpdate = 'tagebuchUpdate';
    static readonly tagebuchFilterChanged = 'tagebuchFilterChanged';

    static readonly outsourcingCategoryChanged = 'outsourcingCategoryChanged';
    static readonly history = 'history';

    static readonly treeChanged = 'treeChanged';


    static readonly abfrageChanged = 'abfrageChanged';
    static readonly abfrageCreated = 'abfrageCreated';
    static readonly abfrageArchived = 'abfrageArchived';

    static readonly diensteChanged = 'diensteChanged';
    static readonly diensteCreated = 'diensteCreated';
    static readonly diensteArchived = 'diensteArchived';


    static readonly divaChanged = 'divaChanged';
    static readonly divaArchived = 'divaArchived';
    static readonly divaCreated = 'einsatzCreated';
    static readonly divaArchiveRequest = 'divaArchiveRequest';

}

