
import UserService from '@/services/UserService'
import {Component, Vue} from 'vue-property-decorator';
import {func} from '@/scripts/scripts'
import {Login} from "@/model/Auth";

@Component({
  components: {},
})
export default class LoginView extends Vue {
  sEmail = ''
  sPassword = ''
  sError = ''
  modus = 0

  onSubmit() {
    this.sError = ''

    var location: any
    if (func.isLocalStorage()) {
      location = localStorage.getItem('location')
    } else {
      location = 0
    }


    var payload = new Login();
    payload.username = this.sEmail
    payload.location = location
    payload.password = this.sPassword

    UserService.login(payload)
        .then(response => {
          if (response.data && response.data.token && response.data.ou && response.data.user) {
            this.$store.dispatch('login', response.data)

            if (this.$store.getters['menu/getCalledRoute']) {
              this.$router.push(this.$store.getters['menu/getCalledRoute'])
            } else {
              if (response.data.forcePasswordChange) {
                this.$router.push('/userpassword/1')
              } else if (response.data.forceProfileCheck) {
                this.$router.push('/profile/1')
              } else {
                this.$router.push('/')
              }
            }
          } else {
            this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
          }
        })
        .catch(e => {
          if (e.raw.response.status === 401) {
            this.sError = 'Zugangsdaten ungültig!'
          } else if (e.raw.response.status === 403) {
            this.sError = 'Keine Standortberechtigungen!'
          } else if (e.raw.response.status === 404) {
            this.sError = 'Benutzer nicht gefunden!'
          } else {
            this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
          }
        })
  }
}

