
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {KfzDateTypDTO} from "@/model/dto";
import KfzDateView from "@/views/kfz/KfzDateView.vue";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import PseudoTag from "@/components/PseudoTag.vue";
import {KfzDateTyp} from "@/model/Date";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import InstructionText from "@/components/InstructionText.vue";


@Component(
    {
      components: {PseudoTag, PersonenkreisTag, KfzDateView, InstructionText},
    }
)
export default class KfzDateList extends Vue {
  dateTypes: KfzDateTypDTO[] = []
  showAllTags = false
  maxTags: number;
  clb = null;
  selectedElem = null
  key = 0
  showModal = false

  async created() {
    this.maxTags = this.$store.getters['tags/getMaxTags']
    this.dateTypes = await this.$store.dispatch("kfz/fetchDateTypesOu")
    this.selectedElem = this.$store.state.kfz.filter.dateType
    this.clb = (dateType) => {
      this.selectedElem = dateType
      this.key++
    }
    bus.$on(Event.kfzDateCategoryFilter, this.clb)
  }

  get dateTypeTabs() {
    return this.dateTypes
  }

  destroyed() {
    bus.$off(Event.kfzDateCategoryFilter, this.clb)
  }


  getTags(dateType: KfzDateTyp) {
    let arr = []

    if (dateType.forAll) {
      arr.push({text: "Alle Kfz", ouText: dateType.orgUnit.name, group: null})
    }


    dateType.kfz.forEach((kfz) => {
      arr.push({text: kfz.licensePlate, ouText: dateType.orgUnit.name, group: 'Fahrzeug'})
    })

    dateType.kfzCategory.forEach((kfz) => {
      arr.push({text: kfz.name, ouText: dateType.orgUnit.name, group: 'Kategorie'})
    })


    return arr
  }

  openModal() {
    this.showModal = true
  }

}
