
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {EinsatzDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import {Actions} from "@/model/Actions";
import EinsatzDataView from "@/views/einsatz/data/EinsatzDataView.vue";
import {Einsatz} from "@/model/Einsatz";
import {EinsatzApi} from "@/services/EinsatzApi";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component({
  components: {
    EinsatzDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class EinsatzEditView extends mixins<GenericMixin<EinsatzDTO, GenericError>>(GenericMixin, DataProps) {
  data = new Einsatz();
  error = new GenericError();
  showTabs = false
  tabIndex = 0

  async created() {
    this.init();
  }


  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = EinsatzApi.getEinsatz(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean, send) {
    const p = EinsatzApi.putEinsatz(this.data, send ? Actions.SEND : Actions.SAVE);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send einsatzChanged event')
        bus.$emit(Event.einsatzChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push("/einsatz/einsatzoverview")
  }

  get editPermission() {
    if (!this.data.sent) {
      if (this.$store.getters.hasPermission(PermissionModul.Einsatz, Permission.Create)) {
        return true;
      }
    }

    if (this.$store.getters.hasPermission(PermissionModul.Einsatz, Permission.Update)) {
      return true;
    }

    if (this.$store.getters.hasPermission(PermissionModul.Einsatz, Permission.Update_Own)) {
      return this.data.creator?.id == this.$store.getters.getUser.id;
    }

    return false;
  }
}
