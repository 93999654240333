import {apiClient} from './AbstractHTTPService'

import Axios from "axios";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {KfzOutsourcingDTO, MaterialOutsourcingDTO, MedProduktOutsourcingDTO, OutsourcingCategoryDTO, OutsourcingDTO} from "@/model/dto";
import store from "@/store";
import {Event} from "@/model/Constants";


export abstract class OutsourcingApi {
    private static usersAxios = Axios.create();

    static async getCategories(): Promise<OutsourcingCategoryDTO[]> {
        const response = await apiClient.get('/outsourcing/outsourcingcategory/chain' );
        return response.data.data;
    }
    static async putCategory(outsourcing: OutsourcingDTO): Promise<ApiResponse<OutsourcingDTO>> {
        const response = await apiClient.put('/outsourcing/outsourcingcategory/' + outsourcing.id + '/', outsourcing);

        store.dispatch("crudEvent", new CrudEvent(Event.outsourcingCategoryChanged, response.data.data));

        return new ApiResponse(response);
    }


    static async remvoeOutsourcingCategory(id): Promise<any> {
        const response = await apiClient.delete('/outsourcing/outsourcingcategory/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.outsourcingCategoryChanged, null, id));

        return response.data;
    }


    static async getOutsourcing(id): Promise<ApiResponse<OutsourcingDTO>> {
        const response = await apiClient.get('/outsourcing/outsourcingdata/' + id);
        return new ApiResponse(response);
    }

    static async getOutsourcingListDataChain(): Promise<OutsourcingDTO> {
        const response = await apiClient.get('/outsourcing/outsourcinglist/chain');
        return response.data;
    }

    static async updateOutsourcing(outsourcing: OutsourcingDTO): Promise<ApiResponse<OutsourcingDTO>> {
        const response = await apiClient.put('/outsourcing/outsourcingdata/' + outsourcing.id + '/', outsourcing);

        store.dispatch("crudEvent", new CrudEvent(Event.outsourcingChanged, response.data.data));

        return new ApiResponse(response);
    }


    static async archivOutsourcing(id): Promise<any> {
        const response = await apiClient.delete('/outsourcing/outsourcingdata/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.outsourcingChanged, response.data.data));

        return response.data;
    }


    static async getOutsourcingByKfz(id): Promise<KfzOutsourcingDTO[]> {
        const response = await apiClient.get('/kfz/kfzoutsourcing/'+id);
        return response.data.data;
    }

    static async putKfzOutsourcing(file:KfzOutsourcingDTO): Promise<KfzOutsourcingDTO> {
        const response = await apiClient.put('/kfz/kfzoutsourcing', file);
        return response.data;
    }
    static async removeKfzOutsourcing(id:number): Promise<KfzOutsourcingDTO> {
        const response = await apiClient.delete('/kfz/kfzoutsourcing/'+id);
        return response.data;
    }

    static async getOutsourcingByMedProdukt(id): Promise<MedProduktOutsourcingDTO[]> {
        const response = await apiClient.get('/medprodukt/medproduktoutsourcing/'+id);
        return response.data.data;
    }

    static async putMedProduktOutsourcing(file:MedProduktOutsourcingDTO): Promise<MedProduktOutsourcingDTO> {
        const response = await apiClient.put('/medprodukt/medproduktoutsourcing', file);
        return response.data;
    }
    static async removeMedProduktOutsourcing(id:number): Promise<MedProduktOutsourcingDTO> {
        const response = await apiClient.delete('/medprodukt/medproduktoutsourcing/'+id);
        return response.data;
    }


    static async getOutsourcingByMaterial(id): Promise<MaterialOutsourcingDTO[]> {
        const response = await apiClient.get('/material/materialoutsourcing/'+id);
        return response.data.data;
    }

    static async putMaterialOutsourcing(file:MaterialOutsourcingDTO): Promise<MaterialOutsourcingDTO> {
        const response = await apiClient.put('/material/materialoutsourcing', file);
        return response.data;
    }
    static async removeMaterialOutsourcing(id:number): Promise<MaterialOutsourcingDTO> {
        const response = await apiClient.delete('/material/materialoutsourcing/'+id);
        return response.data;
    }
    
    
}
