
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {FileCategoryDTO, GenericError, PersonenkreisDTO, SuchenPersonDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";


import Column from "primevue/column/Column";
import DataTable from "primevue/datatable/DataTable";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import ContextMenu from "primevue/contextmenu/ContextMenu";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import {SuchenPerson} from "@/model/Diva";
import {DivaApi} from "@/services/DivaApi";

@Component({
  components: {
    LabelComponent,
    ListItemComponent,
    ChainIconTooltipTableCell,
    DataTable, Column, ContextMenu,
    SimpletableButtonHeader, SettingsTooltip,
    PersonenkreisTag, PersonenkreisChooser, Multiselect,
  }
})
export default class SuchenPersonListComponent extends mixins<SimpleViewMixin<SuchenPersonDTO, GenericError>>(SimpleViewMixin) {
  @Prop() userList: SuchenPersonDTO[]
  data: SuchenPersonDTO = new SuchenPerson()
  error: GenericError = new GenericError()

  fileCategories: FileCategoryDTO[] = []
  personenkreisOptions: PersonenkreisDTO[] = []

  async created() {

    await this.init(SuchenPerson,
        () => this.userList,
        (a) => DivaApi.putSuchenPerson(a),
        (b) => {
          return;
        }
    )
    this.$root.$emit('loadingDone')
  }

  editObject(dto: SuchenPersonDTO) {
    if (dto.suche.lock) {
      this.$alert('Die Suche ist gesperrt und kann nicht bearbeitet werden', 'Achtung', 'warning')
      return;
    }

    this.data = structuredClone(dto)
    this.createErrorDto()
    this.showModal = true
  }
}
