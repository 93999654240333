import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {TagebuchEintragRequest} from "@/model/Tagebuch";
import {TagebuchApi} from "@/services/TagebuchApi";
import {CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        requestFilter: new TagebuchEintragRequest(),
        tagebuecher: [],
    },
    mutations: {
        SET_REQUEST_FILTER(state: any, data: any) {
            state.requestFilter = data
        },
        SET_TAGEBUECHER(state: any, data: any) {
            state.tagebuecher = data
        },
    },
    actions: {
        async fetchOwnTagebuecher({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.tagebuecher && state.tagebuecher.length > 0) {
                    resolve(state.tagebuecher)
                } else {
                    TagebuchApi.getOwnTagebuecher()
                        .then(data => {
                            commit('SET_TAGEBUECHER', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.tagebuchUpdate:
                    commit('SET_TAGEBUECHER', [])
                    break;
            }
        },
    },
    getters: {},
    modules: {}
}