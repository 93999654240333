
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, KfzAufbauerDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {KfzApi} from "@/services/KfzApi";

const DataProps = Vue.extend({
  props: {}
})
@Component({
  components: {SimpletableButtonHeader}
})
export default class KfzHerstellerModellView extends mixins<SimpleViewMixin<KfzAufbauerDTO, GenericError>>(SimpleViewMixin, DataProps) {
  async created() {
    await this.init(null, KfzApi.getHersteller, (b) => { return; }, (b) => { return; })
    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
