import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";
import {StoreHelper} from "@/store/StoreHelper";
import {FobiApi} from "@/services/FobiApi";
import {FobiRequest} from "@/model/Fobi";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        themen: [],
        kombithemen: [],
        klassen: [],
        zeitraum: [],
        status: [],
        requestFilter: new FobiRequest()
    },
    mutations: {
        SET_THEMEN(state: any, data: any) {
            state.themen = data
        },
        SET_KOMBITHEMEN(state: any, data: any) {
            state.kombithemen = data
        },
        SET_KLASSEN(state: any, data: any) {
            state.klassen = data
        },
        SET_ZEITRAUM(state: any, data: any) {
            state.zeitraum = data
        },
        SET_STATUS(state: any, data: any) {
            state.status = data
        },
        resetLocationState(state) {
            state.themen = []
            state.kombithemen = []
            state.klassen = []
            state.zeitraum = []
        }
    },
    actions: {
        async fetchThemen({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.themen && state.themen.length > 0) {
                    resolve(state.themen)
                } else {
                    FobiApi.getThema()
                        .then(data => {
                            commit('SET_THEMEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchStatus({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.status && state.status.length > 0) {
                    resolve(state.status)
                } else {
                    FobiApi.getStatus()
                        .then(data => {
                            commit('SET_STATUS', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchKombiThemen({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.kombithemen && state.kombithemen.length > 0) {
                    resolve(state.kombithemen)
                } else {
                    FobiApi.getKombiThemen()
                        .then(data => {
                            commit('SET_KOMBITHEMEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchKlassen({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.klassen && state.klassen.length > 0) {
                    resolve(state.klassen)
                } else {
                    FobiApi.getKlassen()
                        .then(data => {
                            commit('SET_KLASSEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchZeitraeume({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.zeitraum && state.zeitraum.length > 0) {
                    resolve(state.zeitraum)
                } else {
                    FobiApi.getZeitraume()
                        .then(data => {
                            commit('SET_ZEITRAUM', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.fobiThemaChanged:
                    StoreHelper.update("SET_THEMEN", state.themen, event, commit)
                    break;
                case Event.fobiKlasseChanged:
                    StoreHelper.update("SET_KLASSEN", state.klassen, event, commit)
                    break;
                case Event.fobiKombiThemaChanged:
                    StoreHelper.update("SET_KOMBITHEMEN", state.kombithemen, event, commit)
                    break;
                case Event.fobiZeitraumChanged:
                    StoreHelper.update("SET_ZEITRAUM", state.zeitraum, event, commit)
                    break;
            }
        },
    },
    getters: {
        getFobiRequestFilter: (state): FobiRequest => {
            return state.requestFilter ? state.requestFilter : new FobiRequest()
        },
    },
    modules: {}
}
