
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, OrgUnitSettingsDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import {ApiResponse} from "@/model/AbstractClasses";
import VivendiDienstOuMapList from "@/views/diva/components/VivendiDienstOuMapList.vue";
import {OrgUnitApi} from "@/services/OrgUnitApi";

@Component({
  computed: {},
  components: {
    VivendiDienstOuMapList,
    LabelComponent,
  }
})
export default class OrgUnitSettingsView extends mixins<SimpleViewMixin<OrgUnitSettingsDTO, GenericError>>(SimpleViewMixin) {
  syncDto: OrgUnitSettingsDTO = null;
  error: GenericError = new GenericError()


  async created() {
    this.syncDto = await OrgUnitApi.getSyncDto()
    this.$root.$emit('loadingDone')
  }

  async saveSyncDto() {
    const promise = OrgUnitApi.saveSyncDto(this.syncDto)
    this.handleApiRequest(promise, true).then((data) => {
      if (data instanceof ApiResponse && data.data !== undefined) {
        this.syncDto = data.data
      }
    })
  }
}
