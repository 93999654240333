import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        maxShownTags: 3,
    },
    getters: {
        getMaxTags(state: { maxShownTags: any }) {
            return state.maxShownTags
        }
    }
}
