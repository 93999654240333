
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {GenericError, TaskTemplateDTO} from "@/model/dto";
import {TaskApi} from "@/services/TaskApi";
import {TaskTemplate} from "@/model/Task";
import TaskTemplateDataView from "@/views/task/TaskTemplateDataView.vue";


@Component({
  components: {
    TaskTemplateDataView,
  }
})
export default class TaskTemplateCreateView extends mixins<GenericMixin<TaskTemplateDTO, GenericError>>(GenericMixin) {
  data = new TaskTemplate();
  error = new GenericError();

  created() {
    this.data.rrule_freq = 'DAILY'
    this.data.rrule_interval = 1
    this.data.template = true
    this.data.rrule_daysBefore = 0


    this.data.typ = 1
    this.data.zModus = 0
  }

  onSubmit(goBack: boolean) {
    const p = TaskApi.putTaskTemplate(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send taskTemplateCreated event')
        bus.$emit(Event.taskTemplateCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }

    this.$router.push({path: "../tasktable", query: queryParam})
  }

}
