<template>
  <b-modal v-model="showModal" :title="fileName" size="xl" hide-footer scrollable>
    <b-button @click="download">Download</b-button><br>

    <div class="">
      <img class="img-fluid mt-2" :src="imgSrc" :alt="fileName"/>
    </div>
  </b-modal>
</template>

<script>
import {FileApi} from "@/services/FileApi";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: ''
    },
    imgSrc: {
      type: String,
      default: ''
    },
    fileId: {
      type: String,
    }
  },
  methods: {
    download() {
      FileApi.download(this.fileId, true)
    }
  },
  computed: {
    showModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
</script>
