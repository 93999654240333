import {EntityStandortDTO, FileDTO, KfzAufbauerDTO, KfzCategoryDTO, KfzDTO, KfzKennungDTO, KfzStandortDTO, OrgUnitDTO, OutsourcingDTO, QualificationDTO, RfidDTO} from "@/model/dto";
import {AbstractClass, AbstractListItem} from "@/model/AbstractClasses";


export class KfzEdit extends AbstractListItem implements KfzDTO {
    quali: QualificationDTO;
    herstellerModell: KfzAufbauerDTO;
    caseMovedDate: string;
    locationText: string;
    builderNumber: string | null;
    caseMoved: boolean;
    category: KfzCategoryDTO[] | null;
    chassisNumber: string | null;
    comment: string | null;
    constructionYear: string | null;
    firstRegistrationDate: string | null;
    kennung: KfzKennungDTO | null;
    keyCount: number | null;
    licensePlate: string;
    mpgLocation: boolean;
    name: string;
    outOfServiceDate: string | null;
    repInsurance: boolean;
    repInsuranceNumber: string | null;
    repInsuranceValidUntilDate: string | null;
    repInsuranceValidUntilKm: string | null;
    rfid: RfidDTO | null;
    status: number | null;
    tire: string | null;
    typ: string | null;
    archive: boolean;
    profilePhoto: FileDTO | null;
    location: EntityStandortDTO | null;
    outsourcingLocation: OutsourcingDTO | null;
    repInsuranceProvider: string | null;
    aufbauer: KfzAufbauerDTO | null;
    materialLocation: boolean;
    orgUnit: OrgUnitDTO;
    investNummer: string | null;
    nichtEinsatzbereitKeineTasks = false;
    fremdeigentum = false;
}

export class KfzStandort extends AbstractClass implements KfzStandortDTO {
   
    name: string;
    passOnChilds: boolean;
    orgUnit: OrgUnitDTO | null;
    temporary: boolean;
}

export class KfzKennung extends AbstractListItem implements KfzKennungDTO {
   
    name: string;
    archive: boolean;
    passOnChilds: boolean;
    orgUnit: OrgUnitDTO | null;
}

export class KfzCategory extends  AbstractListItem implements KfzCategoryDTO {
    abstract: boolean | null;
    name: string;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;

}