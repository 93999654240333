
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {FobiZeitraumDTO} from "@/model/dto";
import {FobiZeitraum} from "@/model/Fobi";
import {FobiApi} from "@/services/FobiApi";
import FobiZeitraumDataView from "@/views/fobi/FobiZeitraumDataView.vue";


@Component({
  components: {
    FobiZeitraumDataView,
  }
})
export default class FobiZeitraumCreateView extends mixins<GenericMixin<FobiZeitraumDTO, FobiZeitraumDTO>>(GenericMixin) {
  data = new FobiZeitraum();
  error = new FobiZeitraum();

  @Prop() fobiZeitraumData: FobiZeitraum

  created() {
    this.data = new FobiZeitraum()
    this.data.themen = []
    this.data.klassen = []
    this.data.gesamt = []

    if (this.fobiZeitraumData) {
      const p = FobiApi.getZeitraum(this.fobiZeitraumData.id);
      this.handleApiRequest(p)
      p.then(() => {
        this.data.id = null;
        this.data.start = null;
        this.data.end = null;
      })
    }
  }


  onSubmit(goBack: boolean) {
    const p = FobiApi.putZeitraum(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send fobiZeitraumCreated event')
        bus.$emit(Event.fobiZeitraumCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/fobi/zeitraum", query: queryParam})
  }

}
