

import {Component, Vue} from "vue-property-decorator";
import {UserDateDTO} from "@/model/dto";
import {DateHelper} from "@/model/Date";
import {DateApi} from "@/services/DateApi";
import jquery from "jquery";

@Component({
  components: {}
})
export default class UserDashboardDateCard extends Vue {
  dates: UserDateDTO[] = []
  dateHelper = DateHelper
  donePromise;

  async created() {
    this.donePromise = new Promise((resolve, reject) => {
      let userId = this.$store.getters.getUser.id
      DateApi.getUserDatesByUser(userId).then(data => {
        this.dates = data

        this.$nextTick(() => {
          jquery('#datecontent').children().appendTo("#myCards");
          resolve(this.dates.length)
        });
      })
    })
  }

  getPromise() {
    return this.donePromise
  }

  get datesData() {
    return this.dates.filter((date) => date.statusNumeric == 2 || date.statusNumeric == 3).sort((a, b) => {
      return b.statusNumeric - a.statusNumeric
    })
  }
}
