
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {FileCategoryDTO, KfzFileDTO, Permission, PermissionModul} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import {KfzFile} from "@/model/File";
import KfzFileEditModal from "@/views/kfz/modal/KfzFileEditModal.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {Module} from "@/model/Constants";


@Component(
    {
      components: {DatatableButtonHeader, KfzFileEditModal},
    }
)
export default class KfzFileView extends Vue {
  @Prop() private id: string;
  @Prop() private category: FileCategoryDTO|null;

  tableId = 'dTable_' + uuid();
  data: KfzFileDTO | null = null
  modalId = 'kfz-file-edit-modal'


  newFile() {
    this.data = new KfzFile()
    this.data.fileCategories = this.$props.category ? [this.$props.category] : null;
    this.$bvModal.show(this.modalId)
  }


  edit(id) {
    if (!this.editPermission) {
      return;
    }
    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();
    this.data = rowdata
    this.$bvModal.show(this.modalId)
    this.modalId = 'kfz-file-edit-modal' + uuid()
  }

  updateTable(data) {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
    /*
        let rowId = "#" + data.frontendRef;
        let table = jquery('#' + this.tableId).DataTable();
        if (table.row(rowId).data()) {
          table.row(rowId).data(data).draw();
        }
        */
  }


  mounted() {
    this.$root.$emit('loadingStart')
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    const buttons = (this.editPermission) ? [
      {
        className: 'text-secondary bg-primary border-transparent',
        titleAttr: 'Hinzufügen',
        text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
        action: function (e, dt, node, config) {
          self.newFile()
        },
      },
      {
        className: 'text-secondary bg-white',
        titleAttr: 'Bearbeiten',
        text: '<i class="fas fa-pen" />',
        extend: 'selectedSingle',
        action: function (e, dt, node, config) {
          let id = table.row({selected: true}).id()
          self.edit(id)
        }
      },
      {
        className: 'text-secondary bg-white',
        titleAttr: 'Betrifft',
        text: '<i class="fas fa-angle-double-right" />',
        action: function (e, dt, node, config) {
          self.openModal()
        }
      }] : []

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/kfz/kfzfile/filecategory/' + self.id,
        type: 'GET'
      }),
      rowId: "id",
      'columnDefs': [
        {'orderData': [3], 'targets': [2]},
        {'sortable': true, 'searchable': false, 'visible': false, 'type': 'num', 'targets': [4]}
      ],
      columns: [
        {
          class: '',
          data: 'kfz.licensePlate'
        },
        {
          class: '',
          data: 'kfz.name'
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return data.icon + "<span class='d-none'>" +data.status + "</span>"
          }
        },
        {
          class: 'center',
          data: 'skipReason'
        },
        {
          class: '',
          data: 'statusNumeric'
        },
        {
          class: 'text-center cw-100',
          data: null,
          render: function (data, type, row) {
            if (!self.downloadPermission) return ""
            return data.downloadFile != null ? '<button file="' + data.downloadFile.id + '" class="btn btn-outline-info download" type="button"><i class="fas fa-download" /></button>' : ''
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.validUntil ? moment(data.validUntil).format("DD.MM.YYYY") : ""
          }
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.downloadFile && data.downloadFile.uploadTimestamp ? moment(data.downloadFile.uploadTimestamp).format("DD.MM.YYYY") : ""
          }
        },
        {
          class: '',
          data: 'comment'
        },
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            return data.categoryMap.map(c => c.orgUnit.name).join(", ")
          }
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(buttons, true)
    })
    func.datatableInitComplete(table)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      console.log(rowId)
      self.edit(rowId)
    })

    jquery('#' + this.tableId + ' tbody').on('click', '.download', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      let myId = jquery(this).attr('file')

      self.download(myId, Module.KFZ)
    });
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz_Files, this.category.confidential ? Permission.Update_Vertrauliche : Permission.Update)
  }

  get downloadPermission() {
    if (this.category.audit && !this.$store.getters.hasPermission(PermissionModul.Audit, Permission.Access)) {
      return true
    }

    return this.$store.getters.hasPermission(PermissionModul.Kfz_Files, this.category.confidential
        ? Permission.Access_Vertraulich : Permission.Access)
  }

  openModal() {
    this.$emit('openModal')
  }
}


