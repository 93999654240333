
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class NumberForm extends Vue {
  @Prop() private id: string;
  @Prop() private value: string;
  @Prop() private onlyInt: boolean;
  @Prop() private min: number;
  @Prop() private max: number;
  @Prop() private placeholder: string;
  @Prop({default: 1}) private step: number;
  @Prop() disabled;

  input(str) {
    if (str.length == 0) {
      str = null
    } else {
      str = (this.onlyInt) ? parseInt(str) : parseFloat(str)

      if (this.min != undefined && str != undefined) {
        str = (str >= this.min) ? str : this.min
      }
      if (this.max != undefined && str != undefined) {
        str = (str <= this.max) ? str : this.max
      }
    }

    this.$emit("input", str);
    this.$emit("change", str);
  }

}
