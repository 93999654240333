
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event, Module} from "@/model/Constants";
import {bus} from "@/main";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {AdditionalFieldHelper, HistoryEvent, ListItemHelper} from "@/model/Shared";
import {DateHelper} from "@/model/Date";
import {_} from 'vue-underscore';
import {EinsatzRequest} from "@/model/Einsatz";
import {EinsatzApi} from "@/services/EinsatzApi";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (this.standalone) {
            if (val.name.includes("einsatzlist")) {
              this.table.fixedHeader.adjust();
              this.table.fixedHeader.enable()
              this.$root.$emit('loadingDone')

              let fromRoute = this.$store.state.route.from
              if (fromRoute.name.includes("einsatzlist")) {
                this.init()
              }
            } else {
              this.table.fixedHeader.disable()
            }
          }
        },
        '$route.query.archive'(val) {
          if (this.standalone) {
            if (this.$route.query.archive != this.archive) this.init()
          }
        }
      }
    }
)
export default class EinsatzListServersideView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop({default: true}) standalone;
  @Prop({default: () => new EinsatzRequest()}) request: EinsatzRequest;


  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null

  helper = ListItemHelper

  processing = false;

  overviewPermission = Permission.Overview
  moduleId = Module.EINSATZ


  clb = async () => {
    this.$root.$emit("loadingStart")
    await this.init()
  }
  onChangeDebounced = _.debounce(() => { //TODO check in use?
    this.init()
  }, 1500)

  async mounted() {
    if (this.standalone) {
      bus.$on(Event.einsatzFilterChanged, this.clb)
      this.registerEvents([Event.einsatzCreated, Event.einsatzArchived, Event.einsatzChanged]);
    }

    await this.init();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    jquery('#' + this.tableId).on('dblclick', 'tr', function (e) {
      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }
      let rowId = jquery(this).attr('id')
      if (!rowId) return;
      self.edit(rowId)
    })
  }

  async init() {
    if (this.processing) return;
    this.processing = true

    const vorlageOptions = await this.$store.dispatch("einsatz/fetchVorlagen")
    if (vorlageOptions.length == 1) {
      this.$store.state.einsatz.requestFilter.vorlage = vorlageOptions[0];
    }

    if (this.standalone) {
      this.archive = this.$route.query.archive !== undefined
    } else {
      this.archive = false
    }

    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      jquery('#' + this.tableId + ' thead').empty();
    }

    let request = null
    if (this.standalone) {
      request = this.$store.state.einsatz.requestFilter.formDataToRequest()
      request.ouId = this.$store.getters.getLocation.id
    } else {
      request = this.request.formDataToRequest()
      request.ouId = this.$store.getters.getLocation.id
    }


    if (this.$router.currentRoute.name.includes('listown')) {
      request.creatorId = this.$store.getters.getUser.id
      this.overviewPermission = Permission.Overview_Own
    } else {
      this.overviewPermission = Permission.Overview
    }


    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)


    //request.includeOpen = true

    this.data = await EinsatzApi.getEinsaetze(request);
    const fixCols = [
      {
        class: '',
        title: 'ID',
        data: 'id'
      },
      {
        class: '',
        title: 'Datum Einsatzbeginn',
        data: 'datumStart',
        render: function (d, type, row) {
          return DateHelper.parseDate(d);
        }
      },
      {
        class: '',
        title: 'Datum Einsatzende',
        data: 'datumEnde',
        render: function (d, type, row) {
          return DateHelper.parseDate(d);
        }
      },
      {
        class: '',
        title: 'Uhrzeit Start',
        data: 'zeitStart',
        render: function (d, type, row) {
          return DateHelper.parseTime(d);
        }
      },
      {
        class: '',
        title: 'Uhrzeit Ende',
        data: 'zeitEnde',
        render: function (d, type, row) {
          return DateHelper.parseTime(d);
        }
      },
      {
        class: '',
        title: 'Vorlage',
        data: 'vorlage.name'
      },
    ]


    const cols = this.data["cols"].length ? [
      ...fixCols,
      ...AdditionalFieldHelper.getTableColumns(this.data.cols)
    ] : fixCols;
    const rows = this.data["rows"];


    let tableButtons = !self.standalone ? [] : func.addDefaultButtons(myButtons)

    let tableOptions = {
      fixedHeader: {
        header: (self.standalone),
        headerOffset: 190,
      },
      stateSave: false,
      responsive: false,
      rowId: "id",
      order: [[1, "asc"]],
      columns: cols,
      data: rows,
      buttons: tableButtons,
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
    }
    if (!this.standalone) {
      tableOptions.dom = 'frtip'
    }

    let table = jquery('#' + this.tableId).DataTable(tableOptions)
    if (self.standalone) func.datatableInitComplete(table, 0, self)

    self.table = table
    if (self.standalone) self.initContextMenu(contextMenuButtons)

    this.processing = false
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Einsatz, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Einsatz, self.overviewPermission,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Einsatz, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/einsatz/einsatz', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/einsatz/einsatz', tr.attr('id')))
          }
        }, contextMenuButtons)

  }


  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/einsatz/create/", query: queryParam})
  }

  edit(id) {
    if (!this.standalone) {
      let data = this.table.row("#" + id).data();
      this.$emit("einsatz", data)
      return;
    }

    if (!this.$store.getters.hasPermission(PermissionModul.Einsatz,
        this.overviewPermission)) {
      return;
    }

    this.table.fixedHeader.disable()

    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }

    this.table.row("#" + id).child.hide()
    this.$router.push({path: "/einsatz/edit/" + id, query: queryParam})
  }


  destroyed() {
    bus.$off(Event.einsatzFilterChanged, this.clb)
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  @Watch('request', {deep: true})
  public watchValue(newValue) {
    this.onChangeDebounced()
  }
}

