
import {Component, Prop, Vue} from 'vue-property-decorator';
import 'vue-pdf-embed/src/styles/text-layer.css'
import PdfView from "@/components/PdfView.vue";

@Component({
  components: {PdfView},
})
export default class PdfViewModal extends Vue {
  @Prop({default: false}) value: boolean;
  @Prop() source;
  @Prop() filename;

  get showModal() {
    return this.value;
  }
  set showModal(val) {
    this.$emit('input', val);
  }
}
