

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import {Module} from "@/model/Constants";
import AdminDashboardEmeldStatus from "@/views/adminDashboard/components/modal/AdminDashboardEmeldStatus.vue";
import AdminDashboardDateStatus from "@/views/adminDashboard/components/modal/AdminDashboardDateStatus.vue";
import AdminDashboardFileStatus from "@/views/adminDashboard/components/modal/AdminDashboardFileStatus.vue";
import {Permission, PermissionModul} from "@/model/dto";
import {ReportRequest} from "@/model/Emeld";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    AdminDashboardFileStatus,
    AdminDashboardDateStatus,
    AdminDashboardEmeldStatus
  }
})
export default class AdminDashboardMedProdukteCard extends Vue {
  @Prop() chain!: boolean
  dateDanger = 0
  dateWarning = 0
  fileDanger = 0
  maengelmeldung = 0
  data = null
  moduleId = Module.MEDPRODUKT
  loading = true

  created() {
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    this.data = await DashboardAdminApi.getDasboardData(this.moduleId, this.chain)
    this.dateWarning = this.data.filter(d => d.typ === 1 && d.schweregrad === 2).map(d => d.count).reduce((a, b) => a + b, 0)
    this.dateDanger = this.data.filter(d => d.typ === 1 && d.schweregrad === 3).map(d => d.count).reduce((a, b) => a + b, 0)
    this.fileDanger = this.data.filter(d => d.typ === 2 && d.schweregrad === 2).map(d => d.count).reduce((a, b) => a + b, 0)
    this.maengelmeldung = this.data.filter(d => d.typ === 3).map(d => d.count).reduce((a, b) => a + b, 0)
    this.loading = false
  }

  get showBadgeContainer() {
    return (this.dateWarning && !this.dateDanger) ||
        (this.dateDanger && !this.dateWarning) ||
        (this.dateWarning && this.dateDanger);
  }

  openDateModal() {
    (this.$refs.termsModal as any).show();
  }

  openFileModal() {
    (this.$refs.fileModal as any).show();
  }

  openEmldModal() {
    this.$router.push({
      name: "reportlist",
      params: {
        externalParams: ReportRequest.buildEntityReportRequest(ReportRequest.SPECIAL_MEDP, this.chain)
      }
    })
  }


  get medproduktReportPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Overview)
  }

  get medproduktDatePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Dates, Permission.Overview)
  }

  get medproduktFilePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Files, Permission.Overview)
  }

}

