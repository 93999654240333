

import {Route} from "vue-router";
import {Component, Vue} from "vue-property-decorator";
import EwmpgNavComponent from "@/views/ewmpg/components/EwmpgNavComponent.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import EwoList from "@/views/ewmpg/EwoList.vue";

@Component(
    {
      components: {
        DatatableButtonHeader,
        EwmpgNavComponent,
        EwoList
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)

export default class EwmpgTables extends Vue {

  crud = false
  showTable1 = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name === 'ewolist') {
      this.showTable1 = true
    } else {
      this.showTable1 = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}

