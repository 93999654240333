import { render, staticRenderFns } from "./MaterialKlasseView.vue?vue&type=template&id=0bd38b48&scoped=true&"
import script from "./MaterialKlasseView.vue?vue&type=script&lang=ts&"
export * from "./MaterialKlasseView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd38b48",
  null
  
)

export default component.exports