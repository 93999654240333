
import {Component, Prop, Vue} from 'vue-property-decorator';

const DataProps = Vue.extend({
  props: {}
})
@Component({
  components: {},
})
export default class DatatableButtonHeader extends DataProps {
  @Prop() private hideAddButton;
  @Prop({default: false}) private withExportButton;

  @Prop() private selectedItem;

  newObject() {
    this.$emit("new");
  }

  edit() {
    this.$emit("edit");
  }

  exportEvent() {
    this.$emit("export");
  }


  onFilter(e) {
    this.$emit("filter", e);
  }
}
