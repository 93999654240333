
import {Component, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import UserListView from "@/views/user/UserListView.vue";
import UserClothingListView from "@/views/user/UserClothingListView.vue";
import UserQualificationListView from "@/views/user/UserQualificationListView.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import UserNavComponent from "@/views/user/components/UserNavComponent.vue";
import UserPhoneListView from "@/views/user/UserPhoneListView.vue";
import UserPersonenkreisList from "@/views/user/UserPersonenkreisList.vue";


@Component(
    {
      components: {
        UserPersonenkreisList,
        UserPhoneListView,
        UserNavComponent,
        DatatableButtonHeader,
        UserQualificationListView, UserClothingListView, UserListView},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class UserTables extends Vue {

  showTable1 = false
  showTable2 = false
  showTable3 = false
  showPhoneList = false
  showTable5 = false
  crud = false

  handleRoute(route: Route) {
    if (route.name == 'useredit' || route.name == 'usercreate' || route.name == 'userpersonenkreislistedit' || route.name == 'userpersonenkreislistcreate') {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'userclothinglist') {
      this.showTable2 = true
      this.showTable1 = false
      this.showTable3 = false
      this.showPhoneList = false
      this.showTable5 = false
    }
    else if (route.name == 'userqualificationlist') {
      this.showTable3 = true
      this.showTable2 = false
      this.showTable1 = false
      this.showPhoneList = false
      this.showTable5 = false
    }
    else if (route.name == 'phonelist') {
      this.showTable3 = false
      this.showTable2 = false
      this.showTable1 = false
      this.showPhoneList = true
      this.showTable5 = false
    }
    else if (route.name == 'userpersonenkreislist') {
      this.showTable5 = true
      this.showTable3 = false
      this.showTable2 = false
      this.showTable1 = false
      this.showPhoneList = false
    }
    else {
      this.showTable1 = true
      this.showTable2 = false
      this.showTable3 = false
      this.showPhoneList = false
      this.showTable5 = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
