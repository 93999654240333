
import {Vue, Component, Watch, Prop} from "vue-property-decorator";
import {FobiRequestDTO} from "@/model/dto";
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import AdminDashboardFobiUnconfirmedStatus from "@/views/adminDashboard/components/modal/AdminDashboardFobiUnconfirmedStatus.vue";

@Component({
  components: {AdminDashboardFobiUnconfirmedStatus}
})
export default class AdminDashboardFobiCard extends Vue {
  @Prop() chain!: boolean
  openFobis = 0
  data = null
  loading = true

  created() {
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    const params: FobiRequestDTO = {
      userId: null,
      ouId: this.$store.getters.getLocation.id,
      statusId: 1,
      dateFrom: null,
      dateTo: null,
      archive: null,
      ouChain: this.chain,
      countOnly: true
    };
    this.data = await DashboardAdminApi.getDashboardFobiData(params);
    this.openFobis = this.data.count;
    this.loading = false
  }

  routeFobi() {
    this.$router.push("/fobi/fobitable")
  }
}
