
import {Component, Prop, Vue} from 'vue-property-decorator';
import {FobiKlasseDTO, FobiZeitraumDTO, FobiZeitraumThemaKlasseDTO, FobiZeitraumUserRowDTO} from "@/model/dto";

@Component({
  components: {},
})
export default class FobiUserOverviewComponent extends Vue {
  @Prop() userOverview;
  @Prop() zeitraum: FobiZeitraumDTO;
  @Prop({default: true}) fixed: boolean;

  klassen = []

  created() {
    //Klassen extrahieren
    const map = new Map();
    for (const klasse of this.zeitraum.klassen) {
      if (!map.has(klasse.klasse.id)) {
        map.set(klasse.klasse.id, true);
        this.klassen.push({
          id: klasse.klasse.id,
          name: klasse.klasse.name
        });
      }
    }
  }

  getGesamt(user: FobiZeitraumUserRowDTO) {
    return user.haben.find(haben => haben.zuordnung.typ == 2)
  }

  getGesamtOverAll(user: FobiZeitraumUserRowDTO) {
    return user.haben.find(haben => haben.zuordnung.typ == 99)
  }

  getThema(user: FobiZeitraumUserRowDTO, thema: FobiZeitraumThemaKlasseDTO) {
    return user.haben.find(haben => haben.zuordnung.id == thema.id)
  }

  getKlasse(user: FobiZeitraumUserRowDTO, klasse: FobiKlasseDTO) {
    return user.haben.find(haben => haben.zuordnung.klasse && haben.zuordnung.klasse.id == klasse.id)
  }


  getUserAusschluss(userId, zeitraum: FobiZeitraumDTO) {
    const userAusschluss = zeitraum.ausschluss.find(a => a.user.id == userId)
    return userAusschluss ? (userAusschluss.grund ?  ("Grund: "+userAusschluss.grund) : 'X' ) : '-'
  }
  get sortedUser() {
    return this.userOverview.sort((a, b) => a.user.fromattedLong.localeCompare(b.user.fromattedLong))
  }

}
