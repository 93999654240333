

import {Component, Vue} from "vue-property-decorator";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {Route} from "vue-router";
import TagebuchNavComponent from "@/views/tagebuch/components/TagebuchNavComponent.vue";
import TagebuchEintragList from "@/views/tagebuch/TagebuchEintragList.vue";

@Component({
  components: {
    TagebuchEintragList,
    TagebuchNavComponent,
    DatatableButtonHeader}
})
export default class TagebuchTables extends Vue {
  showTable1 = false
  crud = false

  handleRoute(route: Route) {
    if (route.name == 'tagebucheintraglist') {
      this.showTable1 = true
    }
    else {
      this.showTable1 = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
