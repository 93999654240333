
import {Component, Prop, Vue} from 'vue-property-decorator';
import '@/scripts/dataTables_German'
import {ReportDTO} from "@/model/dto";
import {DateHelper} from "@/model/Date";
import {EmeldApi} from "@/services/EmeldApi";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    },
    DateHelper() {
      return DateHelper
    }
  },
  components: {},
})


export default class EmeldReportCards extends Vue {
  @Prop() list: ReportDTO[]
  @Prop() userDashboard: boolean
  files: any[] = []
  modalTitle = ''

  notizen(item) {
    this.$emit('notizen', item)
  }

  async openFilesModal(item) {
    await this.getFilesForSelectedTask(item.id).then(() => {
      this.modalTitle = 'Dateien für ' + item.level1.name
      this.$bvModal.show('report-files-modal')
    }).catch(() => {
      this.$alert("Fehler beim Laden der Dateien", "Fehler", "error")
    })
  }

  async getFilesForSelectedTask(reportId) {
    const response = await EmeldApi.getReport(reportId)
    this.files = response.data.files
  }
}
