import {OrgUnitTreeDTO} from "@/model/dto";
import {v4 as uuid} from 'uuid';

export class OrgUnitSelectorItemLiquor {
    public text: string;
    public children = [];
    public parent: OrgUnitSelectorItemLiquor
    public isLeaf = false;
    public id: number;
    public disabled = true;
    public selected = false;
    public state = {expanded: false,selected: false, dto: null}
    constructor(elem: OrgUnitTreeDTO, selectId: number, parent = null, expanded = false) {
        this.isLeaf = elem.childs.length < 1
        this.state.dto = elem.dto
        this.text = elem.name
        this.id = elem.id > 0 ? elem.id :  uuid()
        this.parent = parent
        if (expanded) {
            this.state.expanded = true
        }

        if (elem.id == selectId) {
            let worker = parent
            while(worker != null) {
                worker.state.expanded = true
                worker = worker.parent
            }
            this.state.selected = true
            this.state.expanded = true
        }
        this.children = elem.childs.map((child: OrgUnitTreeDTO) => {
                return new OrgUnitSelectorItemLiquor(child, selectId, this, expanded);
            }
        ).sort((a, b) => {
            return a.text.localeCompare(b.text)
        });
    }
}

