
import {Component, Prop} from "vue-property-decorator";
import {FormApi} from "@/services/FormApi";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {UpsertForm, UpsertFormError} from "@/model/FormVersion";
import FormDataView from "@/views/form/components/FormDataView.vue";
import FormVersionDataView from "@/views/form/FormVersionDataView.vue";


@Component(
    {
      components: {FormVersionDataView, FormDataView}
    }
)
export default class FormCreateModal extends mixins<GenericMixin<UpsertForm, UpsertFormError>>(GenericMixin) {
  data = new UpsertForm();
  error = new UpsertFormError();

  @Prop() private value;
  @Prop() private modul;

  created() {
    this.data.rows = []
  }


  get showModal() {
    return this.value
  }
  set showModal(v) {
    this.$emit("input", v);
  }

  onSubmit(goBack: boolean) {
    const p = FormApi.putForm(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, false).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        bus.$emit(Event.formCreated, new ObjectEvent(data.data.id, data.data));

        const pRelease = FormApi.releaseFormVersion(data.data.id)
        this.handleApiRequest(pRelease, true, false).then((data) => {
          if (data instanceof ApiResponse && data.data != undefined) {
            bus.$emit(Event.formTakeOver, new ObjectEvent(data.data.id, data.data));
            this.goBack()
          }
        })
      }
    })
  }


  goBack() {
    this.showModal = false
  }


}
