

import {Component, Vue} from "vue-property-decorator";
import {DateHelper} from "@/model/Date";
import {UsersApi} from "@/services/UserApi";
import jquery from "jquery";

@Component
export default class UserDashboardQualiCard extends Vue {
  qualis = []
  dateHelper = DateHelper;
  donePromise;

  async created() {
    this.donePromise = new Promise((resolve, reject) => {
      let userId = this.$store.getters.getUser.id
      UsersApi.getQualificationOfUser(userId).then(data => {
        this.qualis = data

        this.$nextTick(() => {
          jquery('#qualicontent').children().appendTo("#myCards");
          resolve(this.qualiData.length)
        });
      })

    })
  }

  getPromise() {
    return this.donePromise
  }
  get qualiData() {
    return this.qualis.filter((quali) => quali.statusNumeric == 2 || quali.statusNumeric == 3).sort((a, b) => {
      return b.statusNumeric - a.statusNumeric
    })
  }
}
