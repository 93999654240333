

import {Component, Prop, Vue} from "vue-property-decorator";
import {CheckinDTO, GenericError, UserResponseDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {UserId} from "@/model/Constants";

@Component({
  computed: {
    UserId() {
      return UserId
    }
  },
  components: {Multiselect}})
export default class CheckinPersonalComponent extends Vue {
  @Prop() value: CheckinDTO;
  @Prop() error: GenericError;

  userOpts: UserResponseDTO[] = []

  created() {
    this.init()
  }

  async init() {
    this.userOpts = [...await this.$store.dispatch("user/fetchUser"),
      ...[
        {id: UserId.FREITEXT, formatted: 'Freitext'},
        {
          id: UserId.UNBESETZT,
          formatted: 'Nicht besetzt'
        }]]
  }

  set workingValue(value) {
    this.$emit("input", value)
    this.$emit("change", value)
  }

  get workingValue() {
    return this.value
  }

}

