
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import '@/scripts/dataTables_German'
import FileUpload from "@/components/FileUpload.vue";
import {UsersApi} from "@/services/UserApi";
import {UserEditDTO} from "@/model/dto";


@Component({
  components: {FileUpload}
})
export default class UserImportView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  rowData: UserEditDTO[] = []
  errorData = []

  fileId = null;
  uploadKey = 0
  processing = false;

  success(uploadData) {
    UsersApi.getImportData(uploadData.id).then((response) => {
      this.fileId = uploadData.id
      this.rowData = response.rowData
      this.errorData = response.errorData
    }).catch((error) => {
      this.$alert(error.error ? error.error : 'Unbekannter Fehler!', 'Fehler', 'error');
    })
    this.uploadKey += 1
  }

  reset() {
    this.rowData = []
    this.errorData = []
    this.fileId = null
  }

  process() {
    this.processing = true;
    UsersApi.processImportData(this.fileId).then((response) => {
      this.$alert('Import erfolgreich abgeschlossen!', 'Erfolg', 'success');
      this.processing = false;
      this.fileId = null;
    }).catch((error) => {
      this.processing = false;
      this.$alert(error.error ? error.error : 'Unbekannter Fehler!', 'Fehler', 'error');
    })
  }

  get backendPath() {
    return process.env.VUE_APP_REWIS
  }
}
