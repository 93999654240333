
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {GenericError, OutsourcingDTO, Permission, PermissionModul} from "@/model/dto";
import OutsourcingDataView from "@/views/outsourcing/OutsourcingDataView.vue";
import {OutsourcingEdit} from "@/model/Outsourcing";
import {OutsourcingApi} from "@/services/OutsourcingApi";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  components: {
    OutsourcingDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class OutsourcingEditView extends mixins<GenericMixin<OutsourcingDTO, GenericError>>(GenericMixin, DataProps) {
  data = new OutsourcingEdit();
  error = new GenericError();
  showTabs = false
  tabIndex = 0
  errorTabs = []

  basePath = process.env.VUE_APP_REWIS

  created() {
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')


    const p = OutsourcingApi.getOutsourcing(this.$props.id);

    this.handleApiRequest(p)
        .finally(() => {
          this.$root.$emit('loadingDone');
          this.showTabs = true
        })
  }

  archiv() {
    this.$confirm(' Wirklich ' + this.archivTxt.toLowerCase() + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      OutsourcingApi.archivOutsourcing(this.data.id)
          .then(() => {
            this.data.archive = !this.data.archive
            bus.$emit(Event.outsourcingArchived, new ObjectEvent(this.$props.id, this.data));
            this.goBack()
          })
          .catch(() => {
            this.$alert("Beim " + this.archivTxt + " der Firma trat ein Fehler auf!");
          })
    });
  }

  onSubmit(goBack: boolean) {
    this.errorTabs = []
    const p = OutsourcingApi.updateOutsourcing(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      jquery("#outsourcingtabs .tab-pane").each(
          function (index) {
            if (jquery(this).find('.is-invalid').length > 0) {
              self.errorTabs.push(parseInt(this.getAttribute('tab')));
            }
          }
      );

      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send outsourcingChanged event')
        bus.$emit(Event.outsourcingChanged, new ObjectEvent(this.$props.id, data.data));

        if (goBack) {
         this.goBack()
        }
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/outsourcing/overview", query: queryParam})
  }


  get archivTxt() {
    if (!this.$store.getters.hasPermission(PermissionModul.Outsourcing, Permission.Archive)) {
      return null
    }
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Outsourcing, Permission.Update)
  }

}
