
import {Component, Prop} from "vue-property-decorator";
import {Route} from "vue-router";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {Module} from "@/model/Constants";


@Component(
    {
      components: {},
      watch: {
        '$route'(val: Route) {
          this.init()
        }
      }
    }
)
export default class FileView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop() private id;

  mounted() {
    this.init()
  }

  init() {
    console.log(decodeURI(this.id))
    const decryptedText = this.$CryptoJS.AES.decrypt(decodeURIComponent(this.id), process.env.VUE_APP_CryptoKey).toString(this.$CryptoJS.enc.Utf8)
    if (!decryptedText || decryptedText === '') {
      this.$alert("Datei konnte nicht entschlüsselt werden", "Fehler", "error")
      return
    }
    this.download(decryptedText, Module.FileLex)
  }
}


