
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {KfzDateDTO, Permission, PermissionModul} from "@/model/dto";
import KfzDateEditModal from "@/views/kfz/modal/KfzDateEditModal.vue";
import {DateApi} from "@/services/DateApi";
import moment from "moment/moment";
import {DateHelper} from "@/model/Date";
import {v4 as uuid} from 'uuid';



@Component(
    {
      components: {KfzDateEditModal},
    }
)
export default class KfzDateKfzView extends Vue {
  @Prop() private id: string;
  data: KfzDateDTO | null = null
  dates: KfzDateDTO[] = []
  dateHelper = DateHelper
  modalId = 'kfz-date-edit-modal'

  edit(rowdata) {
    if (!this.editPermission) {
      return;
    }

    this.data = rowdata
    this.$bvModal.show(this.modalId)
    this.modalId = 'kfz-date-edit-modal' + uuid()
  }

  updateTable(data) {
    this.$nextTick(() => {
      this.dates = this.dates.map((d) => {
        if (d.dateTyp.id == data.dateTyp.id) {
          return data
        } else {
          return d
        }
      })
    });
  }


  toMoment(d) {
    return moment(d)
  }


  async created() {
    this.$root.$emit('loadingStart')
    this.dates = await DateApi.getKfzDatesByKfz(this.$props.id);
    this.$root.$emit('loadingDone')
  }

  get datesData() {
    return this.dates.sort((a, b) => {
      return b.statusNumeric - a.statusNumeric
    })
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz_Dates, Permission.Update)
  }
}
