
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";
import {AuktionDTO, SuchenDTO} from "@/model/dto";
import {DateHelper} from "../../../model/Date";


@Component({
  computed: {
    DateHelper() {
      return DateHelper
    }
  }
})
export default class DivaSuche extends mixins(NavMixin) {
  @Prop() suche: SuchenDTO
  @Prop() auktion: AuktionDTO


}
