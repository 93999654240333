
import {Component, Vue, Watch} from "vue-property-decorator";
import {UserProfile} from "@/model/User";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {Permission, PermissionModul} from "@/model/dto";

const DataProps = Vue.extend({
  props: {
    value: UserProfile,
    error: UserProfile
  }
})

@Component({})
export default class UserProfileDressSizeView extends mixins<GenericMixin<UserProfile, UserProfile>>(GenericMixin, DataProps) {
  data = this.$props.value;

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

  get editPermission() {
    if (this.$route.name == "profile") {
      return !this.data.lockProfile;
    }
    return this.$store.getters.hasPermission(PermissionModul.User_Clothes, Permission.Update)
  }
}
