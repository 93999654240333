
import {Component, Prop, Vue} from 'vue-property-decorator';
import Multiselect from "@/libs/multiselect";
import {DateHelper} from "@/model/Date";

@Component({
  components: {Multiselect}
})
export default class ManualFileForm extends Vue {
  @Prop() private id: string;
  @Prop() private value: string;
  @Prop() private error;

  dateHelper = DateHelper
  manualFileOpts = []

  async created() {
    this.manualFileOpts = await this.$store.dispatch("manual/fetchManuals")
  }

  get manualFormValue() {
    return structuredClone(this.value)
  }

  set manualFormValue(d) {
    this.$emit("input", d);
    this.$emit("change", d);
  }


}
