

import {Vue} from "vue-property-decorator";
import {Component} from "vue-property-decorator";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import ManualNavComponent from "@/views/manual/components/ManualNavComponent.vue";
import ManualFileList from "@/views/manual/ManualFileList.vue";
import {Route} from "vue-router";

@Component({
  components: {
    ManualFileList,
    ManualNavComponent,
    DatatableButtonHeader}
})
export default class ManualTables extends Vue {
  showTable1 = false
  crud = false

  handleRoute(route: Route) {
    if (route.name == 'manuallist') {
      this.showTable1 = true
    }
    else {
      this.showTable1 = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
