import Vue from 'vue'
import Vuex from 'vuex'
import {OrgUnitApi} from "@/services/OrgUnitApi";
import {CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";
import {StoreHelper} from "@/store/StoreHelper";
import {StandortApi} from "@/services/StandortApi";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        checkin: null,
        dienste: [],
        abfragezeiten: [],
        medProdukte: [],
        material: [],
        user: [],
    },
    mutations: {
        SET_CHECKIN(state: any, data: any) {
            state.checkin = data
        },
        SET_DIENSTE(state: any, data: any) {
            state.dienste = data
        },
        SET_MED_PRODUKTE(state: any, data: any) {
            state.medProdukte = data.map(d => d.dto)
        },
        SET_MATERIAL(state: any, data: any) {
            state.material = data.map(d => d.dto)
        },
        SET_ABFRAGEZEITEN(state: any, data: any) {
            state.abfragezeiten = data
        },
        RESET_BESTAND(state: any, data: any) {
            state.medProdukte = []
            state.material = []
        },
        resetLocationState(state) {
            state.dienste = []
            state.abfragezeiten = []
            state.checkin = null
            state.material = []
            state.medProdukte = []
            state.user = []
        },
    },
    actions: {
        bestandUpdate({commit, state}: any) {
            commit('RESET_BESTAND')
        },
        setCheckin({commit, state, dispatch}: any, checkin: any) {
            const oldCheckinId = state.checkin ? state.checkin.id : 0
            commit('SET_CHECKIN', checkin)

            if (checkin && checkin.checkinUsers) {
                const users = checkin.checkinUsers.map(u => u.user).filter(u => u.originalId > 0)
                state.user = [...new Map(users.map(item => [item['id'], item])).values()]
            }

            if (!checkin || oldCheckinId != checkin.id) {
                state.medProdukte = []
                state.material = []

                dispatch("fetchMedProdukte")
                dispatch("fetchMaterial")
            }

        },
        async fetchMedProdukte({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.medProdukte && state.medProdukte.length > 0) {
                    resolve(state.medProdukte)
                } else {
                    if (!state.checkin || !state.checkin.kfz) {
                        return []
                    }

                    StandortApi.getKfzLocationItems(state.checkin.kfz.originalId, 'medProdukt')
                        .then(data => {
                            commit('SET_MED_PRODUKTE', data['standort'])
                            resolve(state.medProdukte)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchMaterial({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.material && state.material.length > 0) {
                    resolve(state.material)
                } else {
                    if (!state.checkin || !state.checkin.kfz) {
                        return []
                    }

                    StandortApi.getKfzLocationItems(state.checkin.kfz.originalId, 'material')
                        .then(data => {
                            commit('SET_MATERIAL', data['standort'])
                            resolve(state.material)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDienste({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dienste && state.dienste.length > 0) {
                    resolve(state.dienste)
                } else {
                    OrgUnitApi.getDienste()
                        .then(data => {
                            commit('SET_DIENSTE', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchAbfragezeiten({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.abfragezeiten && state.abfragezeiten.length > 0) {
                    resolve(state.abfragezeiten)
                } else {
                    OrgUnitApi.getAbfragezeiten()
                        .then(data => {
                            commit('SET_ABFRAGEZEITEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.abfragezeitenUpdate:
                    StoreHelper.update("SET_ABFRAGEZEITEN", state.abfragezeiten, event, commit)
                    break;
                case Event.dienstUpdate:
                    StoreHelper.update("SET_DIENSTE", state.dienste, event, commit)
                    break;
            }
        },
    },
    getters: {
        getCheckin: (state: any) => {
            return state.checkin
        },
        getUsers: (state: any) => {
            return state.user
        }
    },
    modules: {}
}
