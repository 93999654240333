<template>
  <div>
    <div id="layoutSidenav_nav">
      <div class="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
        <div class="sb-sidenav-menu my-4">
          <div class="nav text-truncate">

            <div @click="$router.push('/dashboard')" class="nav-link">
              <div class="sb-nav-link-icon mr-3 mr-lg-1">
                <div class="icon-wrapper">
                  <i class="fas fa-home"></i>
                </div>
              </div>Dashboard
            </div>

            <div class="d-block d-lg-none">
              <div class="sb-sidenav-menu-heading">Schnellzugriff</div>
              <div @click="pushCheckin" v-if="hasCheckinCreatePermission" class="nav-link">
                <div class="sb-nav-link-icon mr-3">
                  <div class="icon-wrapper">
                    <i class="fas fa-truck-arrow-right"></i>
                  </div>
                </div>Check-In
              </div>

              <div @click="pushEinsatz" v-if="hasEinsatzCreatePermission" class="nav-link">
                <div class="sb-nav-link-icon mr-3">
                  <div class="icon-wrapper">
                    <i class="fas fa-light-emergency-on"></i>
                  </div>
                </div>Einsatz
              </div>

              <div @click="pushHygiene" v-if="hasHygieneCreatePermission" class="nav-link">
                <div class="sb-nav-link-icon mr-3">
                  <div class="icon-wrapper">
                    <i class="fas fa-spray-can-sparkles"></i>
                  </div>
                </div>Hygienemaßnahme
              </div>

              <div @click="pushEmeld" v-if="hasEmeldCreatePermission" class="nav-link">
                <div class="sb-nav-link-icon mr-3">
                  <div class="icon-wrapper">
                    <i class="fas fa-hand-point-up"></i>
                  </div>
                </div>Ereignismeldung
              </div>
            </div>
            <SideMenuItem v-for="(elem,idx) in getMenu" :key="idx+ random()" :item="elem"></SideMenuItem>
            <div class="nav-link">
              <FehlerAnregung></FehlerAnregung>
            </div>
          </div>
        </div>
        <div @click="onClickLocation"
             role="button"
             class="sb-sidenav-footer d-flex flex-column justify-content-center"
             style="height: 4rem;">
          <div class="d-flex flex-row justify-content-center">
            {{ getLocationName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenuItem from './SideMenuItem'
import {v4 as uuid} from 'uuid';
import FehlerAnregung from "@/components/FehlerAnregung";
import {Permission, PermissionModul} from "@/model/dto";
import {Checkin} from "@/model/Checkin";

export default {
  name: 'SideMenu',
  components: {
    FehlerAnregung,
    SideMenuItem
  },
  data() {
    return {
      menu: [],
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    random() {
      return uuid()
    },
    onClickLocation() {
      this.$router.push("/ouselect")
    },
    pushEinsatz() {
      this.$router.push('/einsatz/create/')
      this.toggleSideNav()
    },
    pushHygiene() {
      this.$router.push('/hygiene/desi/create/')
      this.toggleSideNav()
    },
    pushCheckin() {
      this.$store.dispatch('checkin/setCheckin', new Checkin())
      this.$router.push('/checkin/')
      this.toggleSideNav()
    },
    pushEmeld() {
      this.$router.push('/emeld/report/create/')
      this.toggleSideNav()
    },
    toggleSideNav() {
      this.$store.dispatch('toggleSidebar')
    },
  },
  computed: {
    getMenu() {
      return this.$store.getters['menu/getMenu']
    },
    getLocationName() {
      return (this.$store.getters.getLocation !== undefined) ? this.$store.getters.getLocation.sName : ''
    },
    hasCheckinCreatePermission() {
      return this.$store.getters.hasPermission(PermissionModul.Checkin, Permission.Create)
    },
    hasEinsatzCreatePermission() {
      return this.$store.getters.hasPermission(PermissionModul.Einsatz, Permission.Create)
    },
    hasHygieneCreatePermission() {
      return this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Create)
    },
    hasEmeldCreatePermission() {
      return this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Create)
    }
  },
  async created() {
    await this.$store.dispatch('menu/fetchMenu')
  }
}
</script>

<style scoped>

</style>
