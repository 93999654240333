
import {Component, Prop} from "vue-property-decorator";
import store from '@/store';
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse} from "@/model/AbstractClasses";
import {UserPasswordDTO} from "@/model/dto";
import {UsersApi} from "@/services/UserApi";

@Component({
  components: {},
})
export default class UserChangePasswordView extends mixins<GenericMixin<UserPasswordDTO, UserPasswordDTO>>(GenericMixin) {
  @Prop({ default: false }) force
  data: UserPasswordDTO = {
    passwordOld: '',
    password: '',
    passwordConfirm: '',
    id: store.getters.getUser.id
  }
  error: UserPasswordDTO = {
    passwordOld: '',
    password: '',
    passwordConfirm: '',
    id: 0
  }
  showPassword = false;

  created() {
    if (this.force) {
      this.$alert('Bitte ändern Sie Ihr Passwort', 'Passwortänderung erforderlich', 'info');
    }
  }

  onSubmit() {
    const p = UsersApi.updatePassword(this.data);

    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        this.$router.push({ name: 'profile' });
      }
    });
  }
}
