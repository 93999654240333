
import {Component, Prop, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import FormNavComponent from "@/views/form/components/FormNavComponent.vue";
import FormVersionListView from "@/views/form/FormVersionListView.vue";
import FormListView from "@/views/form/FormListView.vue";
import AbfragezeitenView from "@/views/ou/AbfragezeitenView.vue";
import DiensteView from "@/views/ou/DiensteView.vue";


@Component(
    {
      components: {
        DiensteView,
        AbfragezeitenView,
        FormListView,
        FormVersionListView,
        FormNavComponent,
        DatatableButtonHeader,
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class FormTables extends Vue {
  @Prop() id: number;

  showTable1 = false
  showVersionTable = false
  showTable3 = false
  showAbfragezeitenTable = false
  showDiensteTable = false

  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'formversiontable') {
      this.showTable1 = false
      this.showVersionTable = true
      this.showTable3 = false
      this.showAbfragezeitenTable = false
      this.showDiensteTable = false
    } else if (route.name == 'abfragezeiten') {
      this.showTable1 = false
      this.showVersionTable = false
      this.showTable3 = false
      this.showAbfragezeitenTable = true
      this.showDiensteTable = false
    } else if (route.name == 'dienste') {
      this.showTable1 = false
      this.showVersionTable = false
      this.showTable3 = false
      this.showAbfragezeitenTable = false
      this.showDiensteTable = true
    } else {
      this.showTable1 = true
      this.showVersionTable = false
      this.showTable3 = false
      this.showAbfragezeitenTable = false
      this.showDiensteTable = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
