
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {MaterialArtDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {MaterialArt} from "@/model/Material";
import {MaterialApi} from "@/services/MaterialApi";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})
@Component({
  components: {ChainIconTooltipTableCell, SettingsTooltip, SimpletableButtonHeader}
})
export default class MaterialKlasseView extends mixins<SimpleViewMixin<MaterialArtDTO, MaterialArtDTO>>(SimpleViewMixin, DataProps) {
  data: MaterialArtDTO = new MaterialArt()
  error: MaterialArtDTO = new MaterialArt()
  chainKategorie: MaterialArtDTO[] = []

  async created() {
    this.data.klasse = true
    await this.init(MaterialArt, MaterialApi.getMaterialKlasseList, (a) => MaterialApi.putMaterialArt(a), (b) => MaterialApi.archivMaterialArt(b))

    this.chainKategorie = await this.$store.dispatch("material/fetchMaterialArt")
    this.chainKategorie = this.chainKategorie.filter(cat => cat.klasse && cat.orgUnit.id != this.$store.getters.getLocation.id)
    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  newObject() {
    this.data = new MaterialArt();
    this.data.klasse = true
    this.createErrorDto()
    this.showModal = true
  }

}
