import { render, staticRenderFns } from "./SuchenComponent.vue?vue&type=template&id=259d1706&scoped=true&"
import script from "./SuchenComponent.vue?vue&type=script&lang=ts&"
export * from "./SuchenComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "259d1706",
  null
  
)

export default component.exports