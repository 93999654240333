
import {Component, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import MaterialListServersideView from "@/views/material/MaterialListServersideView.vue";
import MaterialKategorieListView from "@/views/material/MaterialKategorieListView.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import EinweisungNavComponent from "@/views/einweisung/components/EinweisungNavComponent.vue";
import EinweisungListView from "@/views/einweisung/EinweisungListView.vue";
import EinweisungConfigListView from "@/views/einweisung/EinweisungConfigListView.vue";


@Component(
    {
      components: {
        EinweisungConfigListView,
        EinweisungListView,
        EinweisungNavComponent,
        DatatableButtonHeader,
        MaterialKategorieListView,
        MaterialListServersideView
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class EinweisungTables extends Vue {

  showTable1 = false
  showTable2 = false
  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'einweisungconfigoverview') {
      this.showTable2 = true
      this.showTable1 = false
    } else {
      this.showTable1 = true
      this.showTable2 = false

    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
