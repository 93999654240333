
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DesiAdviceDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {DesiAdvice} from "@/model/Hygiene";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {HygieneApi} from "@/services/HygieneApi";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import Multiselect from '@/libs/multiselect'

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {Multiselect, ListItemComponent, ChainIconTooltipTableCell, SimpletableButtonHeader}
})
export default class HygieneDesiAdviceView extends mixins<SimpleViewMixin<DesiAdviceDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: DesiAdviceDTO = new DesiAdvice()
  error: GenericError = new GenericError()

  diseaseOpts = []
  ampelOpts = []
  disinfecantOpts = []
  concentrationOpts = []
  einwirkZeitOpts = []
  scopeOpts = []
  protectionOpts = []

  async created() {
    await this.init(DesiAdvice,
        HygieneApi.getDesiAdvice,
        (a) => HygieneApi.putDesiAdvice(a),
        (b) => HygieneApi.removeDesiAdvice(b)
    )

    this.diseaseOpts = await this.$store.dispatch("hygiene/fetchDisease")
    this.ampelOpts = await this.$store.dispatch("hygiene/fetchAmpel")
    this.disinfecantOpts = await this.$store.dispatch("hygiene/fetchDisinfectant")
    this.concentrationOpts = await this.$store.dispatch("hygiene/fetchConcentration")
    this.einwirkZeitOpts = await this.$store.dispatch("hygiene/fetchEinwirkZeit")
    this.scopeOpts = await this.$store.dispatch("hygiene/fetchScopes")
    this.protectionOpts = await this.$store.dispatch("hygiene/fetchProtection")
  }

  get archivTxt() {
    return "Löschen"
  }

}
