
import {Component, Prop, Vue} from 'vue-property-decorator';
import VueEditor from "@/libs/editor/components/VueEditor.vue";
import Quill from "quill";
import ImageResize from '@/libs/image-resize-vue.min';

@Component({
  components: {
    VueEditor,
  }
})
export default class HtmlEditor extends Vue {
  @Prop() private value: string;
  @Prop() private id: string;
  @Prop({default: null}) private foreignKey: string;


  created() {
    Quill.register('modules/imageResize', ImageResize);
  }

  mounted() {
    const qlEditor = document.querySelectorAll('.ql-editor');
  }

  customToolbar = {
    container: [
      [{header: [false, 1, 2, 3, 4, 5, 6]}],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [
        {align: ""},
        {align: "center"},
        {align: "right"}
      ],
      [{list: "ordered"}, {list: "bullet"}],
      [{indent: "-1"}, {indent: "+1"}], // outdent/indent
      [{color: []}, {background: []}], // dropdown with defaults from theme
      ["link", "image", /*"file"*/],
      ["clean"],
    ],
    handlers: {
      file: () => { // just give your custom elements a class like "ql-customThingy" and the handlers will just work
        console.log("file button was clicked");
        //this.$refs.quillEditor.quill.insertText(this.$refs.quillEditor.quill.getSelection( true ).index, '<a href="https://google.de">google</a>');
        const quill = this.$refs.quillEditor.quill;
        const index = this.$refs.quillEditor.quill.getSelection(true).index
        const text = "text"
        quill.insertText(index, text)
        quill.setSelection(index, text.length);
        quill.theme.tooltip.edit('link', 'http://google.de');
        quill.theme.tooltip.save();
      }
    },
  }

  moduleSettings = {
    modules: {
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      },
      toolbar: this.customToolbar,
      "better-table": {
        operationMenu: {
          items: {
            insertColumnRight: {
              text: "Spalte rechts einfügen"
            },
            insertColumnLeft: {
              text: "Spalte links einfügen"
            },
            insertRowUp: {
              text: "Zeile oberhalb einfügen"
            },
            insertRowDown: {
              text: "Zeile unterhalb einfügen"
            },
            mergeCells: {
              text: "Zusammenführen"
            },
            unmergeCells: {
              text: "Trennen"
            },
            deleteColumn: {
              text: "Spalte löschen"
            },
            deleteRow: {
              text: "Zeile löschen"
            },
            deleteTable: {
              text: "Tabelle löschen"
            },
          },
          color: {
            colors: ['black', 'white', 'blue', 'red', 'yellow', 'green'],  // colors in operationMenu
            text: 'Farben'  // subtitle
          }
        }
      },
    }
  }

  handleImageAdded(id) {
    this.$emit("image-added", id);
  }
  handleImageRemoved(id) {
    this.$emit("image-removed", id);
    console.log("image removed", id)
  }

  get htmlData() {
    return structuredClone(this.value)
  }

  set htmlData(d) {
    this.$emit("input", d);
    this.$emit("change", d);
  }
}
