import {apiClient} from './AbstractHTTPService'
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {EinsatzDTO, EinsatzRequestDTO, EinsatzVorlageDTO} from "@/model/dto";
import {Actions} from "@/model/Actions";
import store from "@/store";
import {Event} from "@/model/Constants";


export abstract class EinsatzApi {
    static async getEinsaetze(request: EinsatzRequestDTO): Promise<EinsatzDTO[]> {
        const response = await apiClient.post('/einsatz/einsatz/', request);
        return response.data;
    }

    static async putEinsatz(einsatz: EinsatzDTO, action = Actions.SAVE): Promise<ApiResponse<EinsatzDTO>> {
        const response = await apiClient.put('/einsatz/einsatz/' + einsatz.id + '/', einsatz, {
            headers: {
                'X-Action': action
            }
        });
        return new ApiResponse(response);
    }

    static async getEinsatz(id): Promise<ApiResponse<EinsatzDTO>> {
        const response = await apiClient.get('/einsatz/einsatz/' + id);
        return new ApiResponse(response);
    }

    static async removeEinsatz(id): Promise<any> {
        const response = await apiClient.delete('/einsatz/einsatz/' + id);
        return response.data;
    }

    static async getVorlagenOuChain(): Promise<EinsatzVorlageDTO[]> {
        const response = await apiClient.get('/einsatz/vorlage/chain');
        return response.data.data;
    }

    static async getVorlagen(): Promise<EinsatzVorlageDTO[]> {
        const response = await apiClient.get('/einsatz/vorlage');
        return response.data.data;
    }

    static async putVorlage(q: EinsatzVorlageDTO): Promise<EinsatzVorlageDTO> {
        const response = await apiClient.put('/einsatz/vorlage', q);
        store.dispatch("crudEvent", new CrudEvent(Event.einsatzVorlageUpdate, response.data.data));
        return response.data;
    }

    static async removeVorlage(id: number): Promise<EinsatzVorlageDTO> {
        const response = await apiClient.delete('/einsatz/vorlage' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.einsatzVorlageUpdate, response.data.data));
        return response.data;
    }
}