
import {Component, Vue} from 'vue-property-decorator';
import UserService from '@/services/UserService';

@Component({
  components: {},
})


export default class PwVergessenView extends Vue {
  sEmail = ''
  modus = 0


  onSubmit() {
    var payload = {sEmail: this.sEmail,}
    UserService.sendPasswordForgotten(payload)
        .then(response => this.modus = 1)
        .catch(e => {
          this.$alert('E-Mail Adresse konnte nicht zugeordnet werden!', 'Fehler', 'error')
        })
  }
}
