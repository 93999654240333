
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import Multiselect from "@/libs/multiselect";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import DateForm from "@/components/DateForm.vue";
import {_} from 'vue-underscore';
import {HistoryEvent} from "@/model/Shared";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {DateForm, Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class EmeldNavComponent extends mixins(NavMixin) {
  routeName = null
  levelTree = []
  levelKlasseOptions = []
  onChangeDebounced = null

  navBar = []
  navBarSettings = []

  async created() {
    this.fillNavbar(
        ['reportlist', 'reportlistown', 'myreports', 'reportlistpublic'],
        this.navBar)

    this.fillNavbar(
        ['levelou', 'emeldformlist'], this.navBarSettings)


    this.handleRoute()
    this.levelTree = await this.$store.dispatch("emeld/fetchLevelTreeOu")
    this.levelKlasseOptions = await this.$store.dispatch("emeld/fetchLevelKlassen")

    this.onChangeDebounced = _.debounce(() => {
      bus.$emit(Event.emeldReportFilterChanged)
    }, 1500)
  }


  handleRoute() {
    this.routeName = this.$route.name
  }

  get level1Options() {
    return this.levelTree.map(node => node.dto.level).filter(l => !this.klasse || (this.klasse && l.klasse && l.klasse.id == this.klasse.id))
  }

  get doneUndone() {
    return this.$store.state.emeld.requestFilter.doneUndone
  }

  set doneUndone(val) {
    this.$store.state.emeld.requestFilter.doneUndone = val
    this.onChangeDebounced()
  }

  get level() {
    return this.$store.state.emeld.requestFilter.level
  }

  set level(val) {
    this.$store.state.emeld.requestFilter.level = val
    this.onChangeDebounced()
  }

  get klasse() {
    return this.$store.state.emeld.requestFilter.klasse
  }

  set klasse(val) {
    this.$store.state.emeld.requestFilter.level = null
    this.$store.state.emeld.requestFilter.klasse = val
    this.onChangeDebounced()
  }

  get datefrom() {
    return this.$store.state.emeld.requestFilter.dateFrom
  }

  set datefrom(val) {
    this.$store.state.emeld.requestFilter.dateFrom = val
    this.onChangeDebounced()
  }

  get dateto() {
    return this.$store.state.emeld.requestFilter.dateTo
  }

  set dateto(val) {
    this.$store.state.emeld.requestFilter.dateTo = val
    this.onChangeDebounced()
  }

  get reportRoute() {
    return (this.routeName == "myreports"
        || this.routeName == "myreportstable"
        || this.routeName == "reportlist"
        || this.routeName == "reportlistpublic"
        || this.routeName == "reportlistown")
  }

  get crudRoute() {
    return (!this.routeName.includes("levelou") && (this.routeName.includes("edit") || this.routeName.includes("create")))
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/emeld/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Emeld_Settings, Permission.History)
  }

}
