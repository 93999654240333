
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {FileCategoryDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {FileApi} from "@/services/FileApi";
import {FileCategory} from "@/model/File";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, LabelComponent, SimpletableButtonHeader, SettingsTooltip}
})
export default class FileCategoryCatalogView extends mixins<SimpleViewMixin<FileCategoryDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: FileCategoryDTO = new FileCategory()
  error: GenericError = new GenericError()

  chainKatalog: FileCategoryDTO[] = []
  getParam = null

  async created() {
    if (this.$route.name.includes("user")) {
      this.getParam = 'user'
    } else if (this.$route.name.includes("kfz")) {
      this.getParam = 'kfz'
    } else if (this.$route.name.includes("medprodukt")) {
      this.getParam = 'mpg'
    } else if (this.$route.name.includes("material")) {
      this.getParam = 'material'
    }

    await this.init(FileCategory,
        FileApi.getFileCategory,
        (a) => FileApi.putFileCategory(a),
        (b) => FileApi.removeFileCategory(b),
        this.getParam
    )

    this.chainKatalog = this.getObjects.filter(cat => (!cat.orgUnit || cat.orgUnit.id != this.$store.getters.getLocation.id))
    this.$root.$emit('loadingDone')
  }

  newObject() {
    this.data = structuredClone(this.newT);
    if (this.getParam) {
      this.data.typString = this.getParam
    }
    this.createErrorDto()
    this.showModal = true
  }


  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  get archivTxt() {
    return "Löschen"
  }


  get getOrgUnitObjects() {
    return this.getObjects.filter(cat => (cat.orgUnit && cat.orgUnit.id == this.$store.getters.getLocation.id))
  }

}
