import { render, staticRenderFns } from "./GegenstandListItemComponent.vue?vue&type=template&id=b63d5a5a&"
import script from "./GegenstandListItemComponent.vue?vue&type=script&lang=ts&"
export * from "./GegenstandListItemComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports