import * as $ from 'jquery';
import moment from "moment";
import "datetime-moment"
import 'datatables.net-datetime'
import 'datatables.net-datetime/css/dataTables.dateTime.scss'
import 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-responsive-bs4'
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';
import jsZip from 'jszip';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'jquery-contextmenu/dist/jquery.contextMenu';
import 'jquery-contextmenu/dist/jquery.contextMenu.css';
import 'pdfmake/build/vfs_fonts'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons/js/buttons.colVis.min'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css'
import 'datatables.net-searchbuilder-bs4'
import 'datatables.net-searchbuilder-bs4/css/searchBuilder.bootstrap4.min.css'
import 'datatables.net-select-bs4'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import 'datatables.net-fixedheader'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

$(document).ready(function () {
    window.moment = moment
    window.JSZip = jsZip;
    $.fn.dataTable.ext.errMode = 'none'
    $.fn.dataTable.moment('DD.MM.YYYY HH:mm')

    $.fn.dataTable.moment('DD.MM.YYYY')
    $.fn.dataTable.moment('HH:mm')


    $.extend(true, $.fn.dataTable.DateTime.defaults, {
        i18n: {
            previous: '',
            next: '',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            hours: 'Stunde',
            minutes: 'Minute'
        }
    })

    $.extend(true, $.fn.dataTable.defaults, {
        dom: '<"datatable-buttons-left"B><"searchBuilderButton mt-2"Q>rtip', // BUTTON/FIND/PROCESSING/TABLE/INFO/PAGING
        paging: false,
        lengthChange: true,
        order: [[0, 'asc']],
        select: 'single',
        stateSave: true,
        stateSaveCallback: function (settings, data) {
            localStorage.setItem('DataTables_' + window.location.pathname, JSON.stringify(data))
        },
        stateLoadCallback: function (settings) {
            return JSON.parse(localStorage.getItem('DataTables_' + window.location.pathname))
        }
    })

    $.extend(true, $.fn.dataTable.defaults.oLanguage, {
        sEmptyTable: 'Keine Daten vorhanden',
        sInfo: '_START_ bis _END_ von _TOTAL_ Einträgen',
        sInfoEmpty: '0 bis 0 von 0 Einträgen',
        sInfoFiltered: '(gefiltert von _MAX_ Einträgen)',
        sInfoPostFix: '',
        sInfoThousands: '.',
        sLengthMenu: '_MENU_ Einträge anzeigen',
        sLoadingRecords: 'Wird geladen...',
        sProcessing: 'Bitte warten...',
        sSearch: 'Suchen',
        sZeroRecords: 'Keine Einträge vorhanden.',
        select: {
            rows: {
                _: '',
                0: '',
                1: ''
            }
        },
        buttons: {
            collection: 'Exportieren',
            colvis: 'Sichtbare Spalten',
            colvisRestore: 'zurücksetzen',
            print: 'Drucken',
            copy: 'Kopieren',
            copyTitle: 'Kopieren in Zwischenablage',
            copySuccess: {
                _: '%d Zeilen kopiert',
                1: '1 Zeile kopiert'
            }
        },
        oPaginate: {
            sFirst: 'Erste',
            sPrevious: 'Zurück',
            sNext: 'Nächste',
            sLast: 'Letzte'
        },
        oAria: {
            sSortAscending: ': aktivieren, um Spalte aufsteigend zu sortieren',
            sSortDescending: ': aktivieren, um Spalte absteigend zu sortieren'
        },
        searchBuilder: {
            add: '<i class="fas fa-search-plus" />',

            condition: 'Bedingung',
            conditions: {
                array: {
                    contains: 'Enthält',
                    empty: 'Leer',
                    equals: 'Gleich',
                    not: 'Nicht',
                    notEmpty: 'Nicht Leer',
                    without: 'Ohne'
                },
                date: {
                    after: 'Nach',
                    before: 'Vor',
                    between: 'Zwischen',
                    empty: 'Leer',
                    equals: 'Gleich',
                    not: 'Nicht',
                    notBetween: 'Nicht Zwischen',
                    notEmpty: 'Nicht Leer'
                },
                moment: {
                    after: 'Nach',
                    before: 'Vor',
                    between: 'Zwischen',
                    empty: 'Leer',
                    equals: 'Gleich',
                    not: 'Nicht',
                    notBetween: 'Nicht Zwischen',
                    notEmpty: 'Nicht Leer'
                },
                number: {
                    between: 'Zwischen',
                    empty: 'Leer',
                    equals: 'Gleich',
                    gt: 'Größer',
                    gte: 'Größer gleich',
                    lt: 'Kleiner',
                    lte: 'Kleiner gleich',
                    not: 'Nicht',
                    notBetween: 'Nicht Zwischen',
                    notEmpty: 'Nicht Leer'
                },
                string: {
                    contains: 'Enthält',
                    empty: 'Leer',
                    endsWith: 'Endet mit',
                    equals: 'Gleich',
                    not: 'Nicht',
                    notEmpty: 'Nicht Leer',
                    startsWith: 'Startet mit',
                    notContains: 'Enthält nicht',
                    notEndsWith: 'Endet nicht mit',
                    notStartsWith: 'Startet nicht mit'
                }
            },
            clearAll: 'Reset',
            deleteTitle: 'Löschen',
            data: 'Spalte',
            leftTitle: 'Links',
            logicAnd: 'und',
            logicOr: 'oder',
            rightTitle: 'Rechts',
            title: {
                0: '',
                _: ''
            },
            value: 'Option',
            valueJoiner: 'und'
        }
    })
})
