
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, TagebuchDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {Tagebuch} from "@/model/Tagebuch";
import {TagebuchApi} from "@/services/TagebuchApi";
import Multiselect from "@/libs/multiselect";
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";
import {OrgUnit} from "@/model/OrgUnit";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {PersonenkreisChooser, OrgUnitSelector, ChainIconTooltipTableCell, SimpletableButtonHeader, Multiselect}
})
export default class TagebuchSettingsView extends mixins<SimpleViewMixin<TagebuchDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: TagebuchDTO = new Tagebuch()
  error: GenericError = new GenericError()

  personenkreisOptions = []

  async created() {
    await this.init(Tagebuch,
        TagebuchApi.getOrgUnitTagebuecher,
        (a) => TagebuchApi.putTagebuch(a),
        (b) => null
    )
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.$root.$emit("loadingDone")
  }


  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  addTag(value) {
    this.data.themen.push(value)
  }


  selectedLocationChanged(locationIds) {
    this.data.targetOus = locationIds.map(id => {
      let orgUnit = new OrgUnit()
      orgUnit.id = id
      return orgUnit
    })
  }

  get preselectedLocation() {
    return this.data.targetOus.map(ou => ou.id)
  }


}
