
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, Module} from "@/model/Constants";
import {EntityTaskTemplateDTO, GenericError} from "@/model/dto";
import TaskDataView from "@/views/task/TaskDataView.vue";
import {TaskApi} from "@/services/TaskApi";
import {EntityTaskTemplate} from "@/model/Task";
import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import MaterialDataView from "@/views/material/MaterialDataView.vue";
import EinweisungOverviewTable from "@/views/einweisung/EinweisungOverviewTable.vue";
import TaskModal from "@/views/task/components/TaskModal.vue";
import TaskChatModal from "@/views/task/components/TaskChatModal.vue";
import TaskNotizModal from "@/views/task/components/TaskNotizModal.vue";
import TaskReportView from "@/views/task/TaskReportView.vue";
import EntityTaskTemplateDataView from "@/views/task/entity/EntityTaskTemplateDataView.vue";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    EntityTaskTemplateDataView,
    TaskReportView,
    TaskNotizModal,
    TaskChatModal, TaskModal,
    EinweisungOverviewTable, MaterialDataView,
    OrgUnitSelectorLiquor,
    TaskDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class EntityTaskTempalteEditView extends mixins<GenericMixin<EntityTaskTemplateDTO, GenericError>>(GenericMixin, DataProps) {
  data = new EntityTaskTemplate();
  error = new GenericError();

  showTabs = false
  tabIndex = 0
  @Prop({default: 0}) idx: number

  modul;
  modulPrefix = "";

  async created() {
    this.modul = this.$router.currentRoute.meta['module'];
    this.modulPrefix = this.$router.currentRoute.meta['entityKey'];

    this.init();
  }

  init = function () {
    this.tabIndex = Number(this.idx)

    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = TaskApi.getEntityTaskTemplate(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }


  onSubmit(goBack: boolean) {
    const p = TaskApi.putEntityTaskTemplate(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send taskChanged event')
        bus.$emit(Event.taskChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    const from = this.$store.state.route.from.fullPath
    if (from &&
        this.$store.state.route.from.name != this.$route.name && from.includes(this.modulPrefix)) {
      this.$router.push(from)
    } else {
      let queryParam = {}
      if (this.$route.query.archive !== undefined) {
        queryParam = {archive: 1}
      }
      this.$router.push({path: "/" + this.modulPrefix + "/tasktemplatelist", query: queryParam})
    }
  }

  archiv() {
    bus.$emit(Event.taskArchiveRequest, new ObjectEvent(this.data.id, this.data));
  }

  get archivTxt() {
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  @Watch('tabIndex')
  public watchValue(newValue, oldvalue) {
    const newRoute = "/" + this.modulPrefix + "/task/edit/" + this.$props.id + "/" + this.tabIndex
    if (this.$route.fullPath != newRoute) {
      this.$router.replace(newRoute)
    }
  }

  get foreignTask() {
    return this.data && this.$store.getters.getLocation && this.data.orgUnit.id != this.$store.getters.getLocation.id
  }

}
