import { render, staticRenderFns } from "./OutsourcingView.vue?vue&type=template&id=865ff976&"
import script from "./OutsourcingView.vue?vue&type=script&lang=ts&"
export * from "./OutsourcingView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports