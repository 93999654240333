
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import moment from "moment/moment";


@Component(
    {
      components: {},
    }
)
export default class UserPhoneListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null

  getUrl() {
    return '/user/userlist/list/phone';
  }

  created() {
    this.archive = false
  }


  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      responsive: false,
      rowId: "id",
      order: [[0, 'asc']],
      columns: [
        {
          class: '',
          data: 'lastname'
        },
        {
          class: '',
          data: 'firstname'
        },
        {
          class: '',
          data: 'emailBusiness'
        },
        {
          class: '',
          data: 'dateOfBirth',
          render: function (data, type) {
            return data ? moment(data).format('DD.MM.YYYY') : "";
          },
        },
        {
          class: '',
          data: 'mobile'
        },
        {
          class: '',
          data: 'phone'
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons([])
    })
    self.table = table
    func.datatableInitComplete(table)
  }


}
