
import {Component, Prop, Vue} from 'vue-property-decorator';
import UserService from '@/services/UserService';

@Component({
  components: {},
})


export default class PwResetView extends Vue {
  @Prop() token;
  password = ''
  pwConfirmed = ''
  modus = 0

  errorData = {
    passwordConfirm: '',
    password: ''
  }

  onSubmit() {
    this.errorData = {passwordConfirm: '', password: ''}
    var payload = {token: this.token, sPassword: this.password, sPasswordConfirm: this.pwConfirmed}
    UserService.passwordReset(payload)
        .then(response => this.modus = 1)
        .catch(e => {
          if (e.fieldErrors) {
            e.fieldErrors.forEach(element => {
              this.errorData[element.name] = element.status
            })
          } else {
            this.$alert('Link ungültig! Bitte erstellen Sie einen neuen Passwort-Reset-Link.', 'Fehler', 'error')
          }
        })
  }
}
