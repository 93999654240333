
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {Permission, PermissionModul, ReportDTO, ReportNotizDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {DateHelper} from "../../model/Date";
import {ReportNotiz} from "@/model/Emeld";
import {EmeldApi} from "@/services/EmeldApi";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    }
  },
  components: {SimpletableButtonHeader}
})
export default class ReportNotizView extends mixins<SimpleViewMixin<ReportNotizDTO, ReportNotizDTO>>(SimpleViewMixin, DataProps) {
  @Prop() private report: ReportDTO;
  id = null;
  data: ReportNotizDTO = new ReportNotiz()
  error: ReportNotizDTO = new ReportNotiz()

  async created() {
    this.id = this.report.id
    await this.init(ReportNotiz,
        EmeldApi.getNotizen,
        (a) => EmeldApi.putNotiz(this.id, a),
        null,
        this.id,
    )
  }

  sort(objects) {
    return objects.sort((a,b) => a.id < b.id ? 1 : -1)
  }

  get editPermission() {
    return (this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update))
        || this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update_Responsible);
  }

}
