import EinweisungView from "@/views/einweisung/EinweisungView.vue";
import EinweisungTables from "@/views/einweisung/EinweisungTables.vue";
import EinweisungCreateView from "@/views/einweisung/EinweisungCreateView.vue";
import EinweisungEditView from "@/views/einweisung/EinweisungEditView.vue";
import EinweisungConfigCrudView from "@/views/einweisung/EinweisungConfigCrudView.vue";
import EinweisungOverview from "@/views/einweisung/EinweisungOverview.vue";
import {Permission, PermissionModul} from "@/model/dto";


export const einweisungRoutes = [
    {
        path: 'einweisung',
        name: 'einweisung',
        component: EinweisungView,
        children: [
            {
                path: 'overview',
                name: 'einweisungoverview',
                component: EinweisungOverview,
                meta: {
                    loading: false,
                    title: 'Übersicht',
                    permission: [PermissionModul.Einweisung, Permission.Overview_Chain]
                }
            },
            {
                path: 'einweisungtable',
                name: 'einweisungtable',
                component: EinweisungTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Einweisungen',
                    permission: [PermissionModul.Einweisung, Permission.Overview],
                    activeRoutes: ['einweisungtable', 'einweisungcreate', 'einweisungedit']
                }
            },
            {
                path: 'create',
                name: 'einweisungcreate',
                components: {
                    default: EinweisungTables,
                    CRUD: EinweisungCreateView
                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Einweisung, Permission.Create]
                }
            },
            {
                path: 'edit/:id',
                name: 'einweisungedit',
                components: {
                    default: EinweisungTables,
                    CRUD: EinweisungEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Einweisung, Permission.Overview]
                }
            },
            {
                path: 'configoverview',
                name: 'einweisungconfigoverview',
                component: EinweisungTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Einweisungskonfigurationen',
                    permission: [PermissionModul.Einweisung, Permission.Config]
                }
            },
            {
                path: 'config/create',
                name: 'einweisungconfigcreate',
                components: {
                    default: EinweisungTables,
                    CRUD: EinweisungConfigCrudView
                },
                meta: {
                    permission: [PermissionModul.Einweisung, Permission.Config]
                }
            },
            {
                path: 'config/edit/:id',
                name: 'einweisungconfigedit',
                components: {
                    default: EinweisungTables,
                    CRUD: EinweisungConfigCrudView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Einweisung, Permission.Config]
                }
            }
        ]
    }
]
