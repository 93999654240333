
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { TaskApi } from "@/services/TaskApi";
import { TaskStorno } from "@/model/Task";
import { ApiResponse, ObjectEvent } from "@/model/AbstractClasses";
import { bus } from "@/main";
import { Event } from "@/model/Constants";
import {GenericError, TaskDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

@Component
export default class TaskStornoModal extends mixins<GenericMixin<TaskDTO, GenericError>>(GenericMixin, Vue) {
  @Prop({ default: false }) value!: boolean;

  @Prop({ default: null }) taskId!: number;

  error = new GenericError();
  stornoReason = ''


  submitStorno() {
    const payload = new TaskStorno();
    payload.id = this.taskId;
    payload.grund = this.stornoReason;

    const p = TaskApi.stornoTask(payload);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        this.data.storno = true
        this.data.status = data.data.status

        console.log('send taskChanged event')
        bus.$emit(Event.taskChanged, new ObjectEvent(data.data.id, this.data));
        this.showStornoModal = false;
        this.stornoReason = '';
        this.$emit('storno', true)
      }

    }).catch((error) => {
      if (error.response && error.response.data && error.response.data.fieldErrors) {
        this.error = {};
        error.response.data.fieldErrors.forEach((fe) => {
          this.error[fe.name] = fe.status;
        });
      }
    });

  }

  get showStornoModal() {
    return this.value
  }

  set showStornoModal(value) {
    this.$emit('input', value)
  }

}
