import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";
import {HygieneApi} from "@/services/HygieneApi";
import {Desi, HygieneRequest} from "@/model/Hygiene";
import {StoreHelper} from "@/store/StoreHelper";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        requestFilter: new HygieneRequest(),
        ampel: [],
        concentration: [],
        types: [],
        scopes: [],
        protection: [],
        einwirkZeit: [],
        disinfectant: [],
        disease: [],
        desiAdvice: [],
        desiRoutines: [],
        desiCategory: [
            {text: 'Infektionseinsatz', value: Desi.CATEGORY_INFECTION},
            {text: 'Routinemaßnahme', value: Desi.CATEGORY_ROUTINE},
        ],
        doneUndoneOptions: [
            {text: 'Nur offene', value: true},
            {text: 'Alle', value: null}
        ]
    },
    mutations: {
        SET_AMPEL(state: any, data: any) {
            state.ampel = data
        },
        SET_CONCENTRATION(state: any, data: any) {
            state.concentration = data
        },
        SET_TYPES(state: any, data: any) {
            state.types = data
        },
        SET_SCOPES(state: any, data: any) {
            state.scopes = data
        },
        SET_PROTECTION(state: any, data: any) {
            state.protection = data
        },
        SET_EINWIRKZEIT(state: any, data: any) {
            state.einwirkZeit = data
        },
        SET_DISINFECTANT(state: any, data: any) {
            state.disinfectant = data
        },
        SET_DISEASE(state: any, data: any) {
            state.disease = data
        },
        SET_DESI_ADVICE(state: any, data: any) {
            state.desiAdvice = data
        },
        SET_DESI_ROUTINES(state: any, data: any) {
            state.desiRoutines = data
        },
        resetLocationState(state) {
            state.ampel = []
            state.concentration = []
            state.types = []
            state.scopes = []
            state.protection = []
            state.einwirkZeit = []
            state.disinfectant = []
            state.disease = []
            state.desiAdvice = []
            state.desiRoutines = []
        },
    },
    actions: {
        async fetchAmpel({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.ampel && state.ampel.length > 0) {
                    resolve(state.ampel)
                } else {
                    HygieneApi.getAmpel()
                        .then(data => {
                            commit('SET_AMPEL', data)
                            resolve(state.ampel)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchConcentration({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.concentration && state.concentration.length > 0) {
                    resolve(state.concentration)
                } else {
                    HygieneApi.getConcentration()
                        .then(data => {
                            commit('SET_CONCENTRATION', data)
                            resolve(state.concentration)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchTypes({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.types && state.types.length > 0) {
                    resolve(state.types)
                } else {
                    HygieneApi.getType()
                        .then(data => {
                            commit('SET_TYPES', data)
                            resolve(state.types)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchScopes({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.scopes && state.scopes.length > 0) {
                    resolve(state.scopes)
                } else {
                    HygieneApi.getScopes()
                        .then(data => {
                            commit('SET_SCOPES', data)
                            resolve(state.scopes)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchProtection({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.protection && state.protection.length > 0) {
                    resolve(state.protection)
                } else {
                    HygieneApi.getProtection()
                        .then(data => {
                            commit('SET_PROTECTION', data)
                            resolve(state.protection)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchEinwirkZeit({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.einwirkZeit && state.einwirkZeit.length > 0) {
                    resolve(state.einwirkZeit)
                } else {
                    HygieneApi.getEinwirkzeit()
                        .then(data => {
                            commit('SET_EINWIRKZEIT', data)
                            resolve(state.einwirkZeit)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDisinfectant({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.disinfectant && state.disinfectant.length > 0) {
                    resolve(state.disinfectant)
                } else {
                    HygieneApi.getDisinfectant()
                        .then(data => {
                            commit('SET_DISINFECTANT', data)
                            resolve(state.disinfectant)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDisease({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.disease && state.disease.length > 0) {
                    resolve(state.disease)
                } else {
                    HygieneApi.getDisease()
                        .then(data => {
                            commit('SET_DISEASE', data)
                            resolve(state.disease)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDesiAdvice({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.desiAdvice && state.desiAdvice.length > 0) {
                    resolve(state.desiAdvice)
                } else {
                    HygieneApi.getDesiAdvice()
                        .then(data => {
                            commit('SET_DESI_ADVICE', data)
                            resolve(state.desiAdvice)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDesiRoutines({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.desiRoutines && state.desiRoutines.length > 0) {
                    resolve(state.desiRoutines)
                } else {
                    HygieneApi.getDesiRoutines()
                        .then(data => {
                            commit('SET_DESI_ROUTINES', data)
                            resolve(state.desiRoutines)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.hygieneAmpelUpdate:
                    StoreHelper.update("SET_AMPEL", state.ampel, event, commit)
                    break;
                case Event.hygieneConcentrationUpdate:
                    StoreHelper.update("SET_CONCENTRATION", state.concentration, event, commit)
                    break;
                case Event.hygieneTypeUpdate:
                    StoreHelper.update("SET_TYPES", state.types, event, commit)
                    break;
                case Event.hygieneScopeUpdate:
                    StoreHelper.update("SET_SCOPES", state.scopes, event, commit)
                    break;
                case Event.hygieneProtectionUpdate:
                    StoreHelper.update("SET_PROTECTION", state.protection, event, commit)
                    break
                case Event.hygieneEinwirkzeitUpdate:
                    StoreHelper.update("SET_EINWIRKZEIT", state.einwirkZeit, event, commit)
                    break;
                case Event.hygieneDisinfectantUpdate:
                    StoreHelper.update("SET_DISINFECTANT", state.disinfectant, event, commit)
                    break;
                case Event.hygieneDiseaseUpdate:
                    StoreHelper.update("SET_DISEASE", state.disease, event, commit)
                    break;
                case Event.hygieneDesiAdviceUpdate:
                    StoreHelper.update("SET_DESI_ADVICE", state.desiAdvice, event, commit)
                    break;
                case Event.hygieneDesiRoutineUpdate:
                    StoreHelper.update("SET_DESI_ROUTINES", state.desiRoutines, event, commit)
                    break;
                case Event.hygieneDesiInheritanceUpdate:
                    commit('resetLocationState')
                    break;
            }
        },
    },
    getters: {
        getDoneUndoneOptions: (state: any) => {
            return state.doneUndoneOptions;
        },
    },
    modules: {}
}