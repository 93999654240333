
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {MedProduktDateDTO, Permission, PermissionModul} from "@/model/dto";
import MedProduktDateEditModal from "@/views/medprodukt/modal/MedProduktDateEditModal.vue";
import {DateApi} from "@/services/DateApi";
import moment from "moment/moment";
import {MedProduktHelper} from "@/model/MedProdukt";
import {DateHelper} from "@/model/Date";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {v4 as uuid} from 'uuid';


@Component(
    {
      components: {ListItemComponent, MedProduktDateEditModal},
    }
)
export default class MedProduktDateMedProduktView extends Vue {
  @Prop() private id: string;
  @Prop() private holder: boolean;

  data: MedProduktDateDTO | null = null
  dates: MedProduktDateDTO[] = []

  helper = MedProduktHelper;
  dateHelper = DateHelper
  modalId = 'medprodukt-date-edit-modal'

  edit(rowdata) {
    if (!this.editPermission) {
      return;
    }

    if (rowdata.locked) return;
    this.data = rowdata
    this.$bvModal.show(this.modalId)
    this.modalId = 'medprodukt-date-edit-modal' + uuid()
  }

  updateTable(data) {
    this.$nextTick(() => {
      let toUpdate = [data, ...data.childs]
      toUpdate.forEach(data =>
          this.dates = this.dates.map((tableData) => {
            if (tableData.medProdukt.id == data.medProdukt.id) {
              if ((tableData.dateTyp && data.dateTyp && tableData.dateTyp.id == data.dateTyp.id)) {
                return data
              } else if (tableData.ewmpgDateTyp && data.ewmpgDateTyp && tableData.ewmpgDateTyp.id == data.ewmpgDateTyp.id) {
                return data
              }
            }

            return tableData
          })
      )
    });
  }


  toMoment(d) {
    return moment(d)
  }


  async created() {
    this.$root.$emit('loadingStart')
    this.dates = await DateApi.getMedProduktDatesByMedProdukt(this.$props.id);
    this.$root.$emit('loadingDone')
  }

  get datesData() {
    return this.dates.sort((a, b) => {
      return b.statusNumeric - a.statusNumeric
    })
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Dates, Permission.Update)
  }
}
