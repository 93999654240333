
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import {HygieneApi} from "@/services/HygieneApi";
import HygieneNavComponent from "@/views/hygiene/components/HygieneNavComponent.vue";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {HygieneNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class HygieneView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'hygiene'
        && this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Overview)) {
      this.$router.push("/hygiene/desioverview")
    }
  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.desiRemoveRequest, (payload: ObjectEvent<any>) => this.removeDesi(payload.data))

  }

  destroyed() {
    bus.$off(Event.desiRemoveRequest)

  }

  removeDesi(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie diese Desi ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      HygieneApi.removeDesi(data.id)
          .then(() => {
            bus.$emit(Event.desiArchived, new ObjectEvent(data.id, {id: data.id, archive: !data.archive}));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

}
