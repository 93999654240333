
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import moment from "moment/moment";

import {EwoDTO, MedProduktDTO, Permission, PermissionModul} from "@/model/dto";
import {ObjectEvent} from "@/model/AbstractClasses";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {MedProduktHelper, notAllowedText as txt} from "@/model/MedProdukt";
import MedProduktChooser from "@/components/MedProduktChooser.vue";
import DateForm from "@/components/DateForm.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";


@Component({components: {LabelComponent, DateForm, ListItemComponent, MedProduktChooser, NumberForm, Multiselect}})
export default class MedProduktChildDataView extends mixins<GenericMixin<MedProduktDTO, MedProduktDTO>>(GenericMixin) {
  @Prop() value: MedProduktDTO;
  @Prop() error: MedProduktDTO;
  data: MedProduktDTO = this.$props.value;
  screenLg = this.$screen.lg
  medproduktOpts: MedProduktDTO[] = []

  noCommercialUse = txt

  ewoOpts: EwoDTO[] = []
  showMore: string[] = []

  helper = MedProduktHelper

  toRoman(num: number): string {
    const roman: { [key: string]: number } = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1
    };
    let str = '';
    for (let i of Object.keys(roman)) {
      const q = Math.floor(num / roman[i]);
      num -= q * roman[i];
      str += i.repeat(q);
    }
    return str;
  }

  async created() {
    this.ewoOpts = await this.$store.dispatch("medprodukt/fetchEwos")
    this.ewoOpts = this.ewoOpts.filter(ewo => ewo.kombiTyp != 1)

    this.medproduktOpts = await this.$store.dispatch("medprodukt/fetchMedizinprodukte")
    this.medproduktOpts = this.medproduktOpts
        .filter(mpg => (!mpg.parent || mpg.parent.id != this.data.id) && (mpg.ewo && mpg.ewo.kombiTyp != 1))

    bus.$on(Event.medProduktInbetriebnahmeChange, (payload: ObjectEvent<MedProduktDTO>) => {
      if (payload.id == this.value.id) {
        this.data.childs.forEach((e, idx) => this.inbetriebnahmeChanged(idx))
      }
    })
  }

  destroyed() {
    bus.$off(Event.medProduktInbetriebnahmeChange)
  }

  inbetriebnahmeChanged(idx) {
    if (this.data.childs[idx].inbetriebnahmeDatum && this.data.childs[idx].ewo && this.data.childs[idx].ewo.maxNutzung) {
      var fm = moment(this.data.childs[idx].inbetriebnahmeDatum).add(this.data.childs[idx].ewo.maxNutzung, 'M');
      this.data.childs[idx].ausserbetriebnahmeDatum = fm.format("YYYY-MM-DD")
    } else {
      this.data.childs[idx].ausserbetriebnahmeDatum = null
    }
    this.fieldChanged('inbetriebnahmeDatum')
    this.fieldChanged('ausserbetriebnahmeDatum')
  }


  ewoChanged(idx) {
    if (this.data.childs[idx].ewo && this.data.childs[idx].ewo.softwareversionen.length > 0) {
      this.data.childs[idx].softwareVersion = this.data.childs[idx].ewo.softwareversionen[0]
    } else {
      this.data.childs[idx].softwareVersion = null
    }
  }

  fieldChanged(field: string) {
    bus.$emit(Event.medProduktChildDataChange, new ObjectEvent(this.value.id, field));
  }

  showMoreClick(i) {
    this.showMore.push(i)
  }

  showLessClick(i) {
    this.showMore = this.showMore.filter(element => element != i)
  }

  removeChild(idx) {
    let self = this.data

    if (this.data.childs[idx].id) {
      this.$fire({
        title: ' Einzelgerät archivieren oder als eigenständiges Gerät führen?',
        icon: "question",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Archivieren`,
        denyButtonText: `eigenständiges Gerät`,
        cancelButtonText: `Abbrechen`,
        denyButtonColor: "#766D66",
        focusCancel: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$prompt('Bitte geben Sie einen Grund an.', '', 'Archivieren').then((reason) => {
            if (!reason) return;
            self.childs[idx].archiveReason = reason
            self.childs[idx].parent = null
            self.childs[idx].archive = true


            self.deleteChilds.push(self.childs[idx])
            self.childs.splice(idx, 1)

            this.data.differentChilds.forEach(diff => this.fieldChanged(diff))
          });

        } else if (result.isDenied) {
          self.childs[idx].parent = null

          self.independentChilds.push(self.childs[idx].id)
        }
      })
    } else {
      self.childs.splice(idx, 1)
      this.data.differentChilds.forEach(diff => this.fieldChanged(diff))
    }
  }

  isDisabled(idx) {
    return this.data.childs[idx].id !== undefined && this.data.independentChilds.includes(this.data.childs[idx].id)
  }

  isExpanded(idx) {
    return (this.error.childs[idx]) || this.showMore.includes(idx)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

  @Watch('$screen.width')
  public watchScreen(newValue) {
    this.screenLg = this.$screen.lg
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Update)
  }

}
