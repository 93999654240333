
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import AdminDashboardEmeldStatus from "@/views/adminDashboard/components/modal/AdminDashboardEmeldStatus.vue";

@Component({
  components: {AdminDashboardEmeldStatus}
})
export default class AdminDashboardReportCard extends Vue {
  @Prop() chain!: boolean;
  data: Array<{ name: string | null; count: number }> = [];
  loading = true;

  params = null;

  created() {
    this.init();
  }

  @Watch("chain")
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    this.params = {
      ouId: this.$store.getters.getLocation.id,
      groupCount: true,
      ouChain: this.chain
    };

    this.data = await DashboardAdminApi.getReportsDashboardData(this.params);
    this.loading = false;
  }

  openEmldModal(item) {
    // (this.$refs.emldModal as any).show();
    this.$router.push({
      name: "reportlist",
      params: {
        externalParams: {...this.params, klasse: item.dto}
      }
    })
  }
}
