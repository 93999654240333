
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {AdditionalField, ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {MaterialArtDTO} from "@/model/dto";
import {MaterialApi} from "@/services/MaterialApi";
import {MaterialArt} from "@/model/Material";
import MaterialKategorieDataView from "@/views/material/MaterialKategorieDataView.vue";


@Component({
  components: {
    MaterialKategorieDataView
  }
})
export default class MaterialKategorieCreateView extends mixins<GenericMixin<MaterialArtDTO, MaterialArtDTO>>(GenericMixin) {
  data = new MaterialArt();
  error = new MaterialArt();

  created() {
    this.data.typField = new AdditionalField();
    this.data.typField.fieldTyp= 8
    this.data.typField.options = []
    this.data.typField.name = "Unterkategorie"
    this.data.passOnChilds = true

    this.data.additionalFields = []
    this.error.additionalFields = []
  }

  onSubmit(goBack: boolean) {
    const p = MaterialApi.putMaterialArt(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send materialArtCreated event')
        bus.$emit(Event.materialArtCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "../", query: queryParam})
  }

}
