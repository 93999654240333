
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {OrgUnitTreeDTO} from "@/model/dto";
import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import {Route} from "vue-router";
import {FileApi} from "@/services/FileApi";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component(
    {
      components: {OrgUnitSelectorLiquor},
      watch: {
        '$route'(val: Route) {
         this.handleRoute()
        }
      }
    }
)
export default class FileLexTreeConfig extends mixins<GenericMixin<null, null>>(GenericMixin, DataProps) {
  selectedId = null
  tree: OrgUnitTreeDTO[] = []
  treeChangedCallback = null

  key=0


  async created() {
    this.init()
    this.treeChangedCallback = (data) => {
      this.init()
    }

    bus.$on(Event.treeChanged, this.treeChangedCallback)
  }

  destroyed() {
    bus.$off(Event.treeChanged, this.treeChangedCallback)
  }

  async init() {
    this.tree = await FileApi.getFileLexTree()
    this.key++
    this.handleRoute()
  }

  handleRoute() {
    if (this.$props.id) {
      this.selectedId = this.$props.id
    } else {
      this.selectedId = null
    }
  }

  treeClicked(dto) {
    this.selectedId = dto.id
    this.edit(dto.id)
  }

  create() {
    this.selectedId = null
    this.$router.push({path: "/docs/tree/create"})
  }

  edit(levelId) {
    this.$router.push({path: "/docs/tree/edit/" + levelId})
  }

}
