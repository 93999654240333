
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {PersonenkreisDataDTO, QualificationDTO, SkillDTO, UserResponseDTO} from "@/model/dto";
import {PersonenkreisData} from "@/model/Personenkreis";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {PersonenkreisApi} from "@/services/PersonenkreisApi";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import Multiselect from "@/libs/multiselect";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";

const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  components: {LabelComponent, SettingsTooltip, Multiselect},
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class PersonenkreisDataView extends mixins<GenericMixin<PersonenkreisDataDTO, PersonenkreisDataDTO>>(GenericMixin, DataProps) {
  data = new PersonenkreisData();
  error = new PersonenkreisData();
  bCreateMode = false;
  skills: SkillDTO[] = []
  qualis: QualificationDTO[] = []
  users: UserResponseDTO[] = []

  async created() {
    await this.init();
  }

  async init() {
    this.skills = await this.$store.dispatch("user/fetchSkills")
    this.qualis = await this.$store.dispatch("user/fetchQualis");
    this.users = await this.$store.dispatch("user/fetchUser");


    if (this.$props.id) {
      this.bCreateMode = false;
      this.$root.$emit('loadingStart')

      const p = PersonenkreisApi.getPersonenkreis(this.$props.id);
      this.handleApiRequest(p)
      p.finally(() => {
        this.$root.$emit('loadingDone');
      })
    } else {
      this.bCreateMode = true;
      this.data = new PersonenkreisData();
      this.error = new PersonenkreisData();
    }

  }


  onSubmit(goBack: boolean) {
    const p = PersonenkreisApi.update(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {

        if (this.bCreateMode) {
          console.log('send personenkreisCreated event')
          bus.$emit(Event.personenkreisCreated, new ObjectEvent(this.data.id, data.data));
        } else {
          console.log('send personenKreisChanged event')
          bus.$emit(Event.personenkreisChanged, new ObjectEvent(this.$props.id, data.data));
        }

        if (goBack) {
          this.goBack()
        }
      }
    })
  }

  archiv() {
    this.$confirm(' Wirklich ' + this.archivTxt.toLowerCase() + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      PersonenkreisApi.archive(this.data.id)
          .then(() => {
            this.data.archive = !this.data.archive
            bus.$emit(Event.personenkreisArchived, new ObjectEvent(this.$props.id, this.data));
            this.goBack()
          })
          .catch(() => {
            this.$alert("Beim " + this.archivTxt + " der Firma trat ein Fehler auf!");
          })
    });
  }


  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "../", query: queryParam})
  }

  get archivTxt() {
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }


}
