
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {GenericError, TaskDTO} from "@/model/dto";
import {TaskApi} from "@/services/TaskApi";
import TaskDataView from "@/views/task/TaskDataView.vue";
import {Task} from "@/model/Task";


@Component({
  components: {
    TaskDataView,
  }
})
export default class TaskCreateView extends mixins<GenericMixin<TaskDTO, GenericError>>(GenericMixin) {
  @Prop({default:true}) standalone;
  data = new Task();
  error = new GenericError();

  created() {
    this.data.typ = 1
    this.data.zModus = 0
    this.data.files = []
  }
  onSubmit(goBack: boolean) {
    const p = TaskApi.putTask(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send taskCreated event')
        bus.$emit(Event.taskCreated, new ObjectEvent(data.data.id, data.data));
        if (this.standalone) {
          this.goBack()
        } else {
          this.$emit("created", data.data)
        }
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }

    this.$router.push({path: "/task/tasktable", query: queryParam})
  }

}
