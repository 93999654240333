
import {Component, Prop, Watch} from 'vue-property-decorator';
import {GenericError, Permission, PermissionModul, ResponseDTO, UserFileCategoryDTO, UserFileDTO, UserResponseDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {UserFile} from "@/model/File";
import Multiselect from "@/libs/multiselect";
import {ExistingFiles, RewisFile} from "@/model/AbstractClasses";
import FileUpload from "@/components/FileUpload.vue";
import {FileApi} from "@/services/FileApi";
import DateForm from "@/components/DateForm.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SkipSupervisionForm from "@/views/shared/components/SkipSupervisionForm.vue";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {SkipSupervisionForm, LabelComponent, DateForm, FileUpload, Multiselect},
})
export default class UserDateEditModal extends mixins<GenericMixin<UserFileDTO, GenericError>>(GenericMixin) {
  data: UserFileDTO = new UserFile()
  error: GenericError = new GenericError()
  userList: UserResponseDTO[] = []
  fileCategories: UserFileCategoryDTO[] = []
  existingFile = null;
  showModal = false
  uploadKey = 0
  @Prop() private value!: UserFileDTO;
  @Prop() private id!: string;

  init(newValue) {
    this.data = new UserFile()
    this.existingFile = null

    if (newValue != null) {
      this.data = structuredClone(newValue)
      this.error = new GenericError()

      if (this.data.file) {
        this.existingFile = new ExistingFiles([this.data.file])
      }
    }
  }


  async created() {
    this.init(this.$props.value)
    this.fileCategories = await this.$store.dispatch('user/fetchDateiKategorienKatalog')
    this.userList = await this.$store.dispatch('user/fetchUser')
  }

  fileUploadSuccess(d) {
    this.data.file = new RewisFile(d)
    this.$emit('input', this.data)
  }

  fileDeleted(d) {
    this.data.file = null
    this.existingFile = null
    this.$emit('input', this.data)
  }


  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
    this.uploadKey++
  }
  @Watch('id')
  public watchId() {
    this.init(this.value)
  }

  close() {
    this.$bvModal.hide(this.$props.id)
  }


  save() {
    const p = FileApi.putUserFile(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('input', this.data)
        this.$bvModal.hide(this.$props.id)
        this.$emit('change', this.data)
      }
    });
  }

  archiv() {
    this.$confirm(' Wirklich ' + (this.data.archive ? 'reaktivieren' : 'archivieren') + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      this.data.archive = !this.data.archive
      this.save();
    });
  }

  get userOptList() {
    return this.userList.sort((a, b) => a.fromattedLong.localeCompare(b.fromattedLong))
  }

  get removePermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Files, Permission.Delete)
  }
  get archivePermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Files, Permission.Archive)
  }

  get downloadPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Files, (this.data.fileCategories && this.data.fileCategories.find(c => c.confidential))
        ? Permission.Access_Vertraulich : Permission.Access)
  }
}
