
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {EinweisungDTO} from "@/model/dto";
import {Einweisung, EinweisungGegenstand, EinweisungUser} from "@/model/Einweisung";
import {EinweisungApi} from "@/services/EinweisungApi";
import EinweisungDataView from "@/views/einweisung/EinweisungDataView.vue";


@Component({
  components: {
    EinweisungDataView,
  }
})
export default class EinweisungCreateView extends mixins<GenericMixin<EinweisungDTO, EinweisungDTO>>(GenericMixin) {
  @Prop() user;
  @Prop() gegenstand;

  data = new Einweisung();
  error = new Einweisung();

  created() {
    if (this.gegenstand) {
      this.gegenstand.id = null
    }
    this.data.gegenstaende = this.gegenstand ? [this.gegenstand]: [new EinweisungGegenstand()];
    this.data.user = this.user ? [new EinweisungUser(this.user)] : [];
    this.data.nachweise = [];
  }


  onSubmit(goBack: boolean) {
    const p = EinweisungApi.putEinweisung(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send einweisungCreated event')
        bus.$emit(Event.einweisungCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }

    let fromRoute = this.$store.state.route.from
    if (fromRoute.name == 'einweisungoverview') {
      this.$router.push({name: 'einweisungoverview'})
    } else {
      this.$router.push({path: "/einweisung/einweisungtable", query: queryParam})
    }
  }

}
