
import {Component} from "vue-property-decorator";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {HistoryEvent} from "@/model/Shared";
import {Permission, PermissionModul} from "@/model/dto";
import NavMixin from "@/views/NavMixin.vue";
import {mixins} from "vue-class-component";


@Component(
    {
      components: {},
    }
)
export default class OutsourcingNavComponent extends mixins(NavMixin) {
  navBar = []
  navBarSettings = []

  created() {
    this.fillNavbar(
        ['outsourcinglist'],
        this.navBar)

    this.fillNavbar(
        ['outsourcingservices'], this.navBarSettings)

  }

    get routeName() {
    return this.$router.currentRoute.name;
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/outsourcing/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Outsourcing_Settings, Permission.History)
  }
}
