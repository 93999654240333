

import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import {Module} from "@/model/Constants";
import AdminDashboardUserQualiStatus from "@/views/adminDashboard/components/modal/AdminDashboardUserQualiStatus.vue";
import AdminDashboardDateStatus from "@/views/adminDashboard/components/modal/AdminDashboardDateStatus.vue";
import AdminDashboardFileStatus from "@/views/adminDashboard/components/modal/AdminDashboardFileStatus.vue";
import {Permission, PermissionModul} from "@/model/dto";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    AdminDashboardFileStatus,
    AdminDashboardDateStatus,
    AdminDashboardUserQualiStatus,
  }
})
export default class AdminDashboardUserCard extends Vue {
  @Prop() chain!: boolean
  dateDanger = 0
  dateWarning = 0
  fileDanger = 0
  userQualiDanger = 0
  userQualiWarning = 0
  data = null
  moduleId = Module.USER
  loading = true
  showModal = false

  created() {
    if (!(this.userQualiPermission || this.userDatePermission || this.userFilePermission)) {
      return;
    }
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    this.data = await DashboardAdminApi.getDasboardData(this.moduleId, this.userChain)
    this.dateWarning = this.data.filter(d => d.typ === 1 && d.schweregrad === 2).map(d => d.count).reduce((a, b) => a + b, 0)
    this.dateDanger = this.data.filter(d => d.typ === 1 && d.schweregrad === 3).map(d => d.count).reduce((a, b) => a + b, 0)
    this.fileDanger = this.data.filter(d => d.typ === 2 && d.schweregrad === 2).map(d => d.count).reduce((a, b) => a + b, 0)
    this.userQualiDanger = this.data.filter(d => d.typ === 4 && d.schweregrad === 3).map(d => d.count).reduce((a, b) => a + b, 0)
    this.userQualiWarning = this.data.filter(d => d.typ === 4 && d.schweregrad === 2).map(d => d.count).reduce((a, b) => a + b, 0)
    this.loading = false
  }

  get showBadgeContainer() {
    return (this.dateWarning && !this.dateDanger) ||
        (this.dateDanger && !this.dateWarning) ||
        (this.dateWarning && this.dateDanger);
  }

  openDateModal() {
    (this.$refs.termsModal as any).show();
  }

  openQualiModal() {
    (this.$refs.qualiModal as any).show();
  }

  openFileModal() {
    (this.$refs.fileModal as any).show();
  }

  get userQualiPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User, Permission.Overview)
  }

  get userDatePermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Dates, Permission.Overview)
  }

  get userFilePermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Files, Permission.Overview)
  }

  get userChain() {
    return this.chain && this.$store.getters.hasPermission(PermissionModul.User, Permission.Overview_Chain)
  }
}

