
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import EwmpgNavComponent from "@/views/ewmpg/components/EwmpgNavComponent.vue";


@Component(
    {
      components: {
        EwmpgNavComponent,
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class EwmpgView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
  }

  created() {
    this.handleRoute(this.$router.currentRoute)
  }
}
