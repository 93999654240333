
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FormDTO, GenericError, OrgUnitMerkmalDTO, TaskDTO, TaskTemplateDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import DateForm from "@/components/DateForm.vue";
import {Task, TaskTemplate, TaskZModus} from "@/model/Task";
import LabelComponent from "@/components/LabelComponent.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import {EntityFile, ExistingFiles, RewisFile} from "@/model/AbstractClasses";
import {bus} from "@/main";
import {Event, Module} from "@/model/Constants";
import FileUpload from "@/components/FileUpload.vue";
import HtmlEditor from "@/components/HtmlEditor.vue";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";
import {OrgUnit} from "@/model/OrgUnit";

@Component({
  computed: {
    Task() {
      return Task
    },
    Module() {
      return Module
    },
    TaskZModus() {
      return TaskZModus
    }
  },
  components: {
    OrgUnitSelector,
    SettingsTooltip,
    PersonenkreisChooser,
    HtmlEditor,
    FileUpload,
    LabelComponent,
    DateForm, Multiselect
  }
})
export default class TaskDataView extends mixins<GenericMixin<TaskDTO | TaskTemplateDTO, GenericError>>(GenericMixin) {
  @Prop() value: Task | TaskTemplate;
  @Prop() error: GenericError;
  @Prop({default: true}) private editPermission;

  data: TaskDTO = this.$props.value;
  existingFiles = []
  cleanText = ""
  personenkreisOptions = []
  preSelectedOrgUnits = []
  loadingComplete = false
  showOrgUnitModal = false
  tempSelectedOrgUnits: []
  showPublic = true
  disablePublic = false
  formOptions: FormDTO[] = []
  desiRoutines = []
  scopeOptions = []
  uploadKey = 0


  orgUnitMerkmale: OrgUnitMerkmalDTO[] = []

  async created() {
    window.scrollTo(0, 0);
    this.init()
    this.tempSelectedOrgUnits = [...this.preSelectedOrgUnits]
  }


  async init() {
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.scopeOptions = await this.$store.dispatch("hygiene/fetchScopes")
    this.desiRoutines = await this.$store.dispatch("hygiene/fetchDesiRoutines")
    if (this.data.template) {
      this.orgUnitMerkmale = await this.$store.dispatch("selectize/fetchOrgUnitMerkmale")
    }

    this.formOptions = await this.$store.dispatch("form/fetchForms")
    this.formOptions = this.formOptions.filter(form => !form.typ || form.typ == Module.TASK)


    this.cleanText = this.$sanitize(this.data.text)
    if (this.data.orgUnits) {
      this.preSelectedOrgUnits = this.data.orgUnits.map(o => o.id)
    }
    if (this.data.files) {
      this.existingFiles = new ExistingFiles(this.data.files.map(n => {
        n.file.foreignId = n.id;
        return n.file
      }));
    }
    this.loadingComplete = true
    this.$root.$emit('loadingDone');
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
    this.uploadKey++
  }


  changeFile(id) {
    const idx = this.data.files.findIndex(nachweis => nachweis.file.identifier == id || (nachweis.file.id == id))
    this.$prompt("Beschreibung", this.data.files[idx].comment).then((r: string) => {
      this.data.files[idx].comment = r
      this.data.files[idx].file.comment = r
      bus.$emit(Event.uploadDescription, this.data.files.map(f => f.file))
    });


    this.$emit('input', this.data)
  }

  fileUploadSuccess(d) {
    let file = new RewisFile(d);


    let nachweis = new EntityFile()
    nachweis.file = file
    this.data.files.push(nachweis)

    this.$emit('input', this.data)
  }

  fileDeleted(d) {
    this.data.files = this.data.files.filter(nachweis => (nachweis.id != d.file.foreignId))
    //  this.existingFile = null
    this.$emit('input', this.data)
  }

  fileRemoved(d) {
    this.data.files = this.data.files.filter(nachweis => !nachweis.file.identifier || (nachweis.file.identifier != d.id))
    //  this.existingFile = null
    this.$emit('input', this.data)
  }

  passOnChildsChanged($event) {
    if (this.data.passOnChilds) {
      this.toggleShowOrgUnitModal()
      if (!this.data.template) {
        this.preSelectedOrgUnits = this.$store.getters.getChildChain
      }
      this.selectedLocationChanged(this.preSelectedOrgUnits)
    } else {
      this.preSelectedOrgUnits = []
      this.data.orgUnits = []
    }
  }

  toggleShowOrgUnitModal() {
    this.tempSelectedOrgUnits = [...this.preSelectedOrgUnits];
    this.showOrgUnitModal = !this.showOrgUnitModal
  }

  selectedLocationChanged(locationIds) {
    this.tempSelectedOrgUnits = locationIds;
  }

  saveAndCloseOrgUnitModal() {
    this.preSelectedOrgUnits = [...this.tempSelectedOrgUnits];
    this.data.orgUnits = this.tempSelectedOrgUnits.map(id => {
      let orgUnit = new OrgUnit()
      orgUnit.id = id
      return orgUnit
    })
    this.toggleShowOrgUnitModal();
  }

  typChanged($event) {
    if (this.data.typ == 2) { //Massnahme
      if (this.data.zModus == 2) { //alle
        this.data.zModus = null
      }
    }
  }

  checkPublic() {
    if (this.data.typ == 4) {
      this.data.public = false
      this.showPublic = false
      this.disablePublic = false
    } else if (this.data.typ == 2) {
      this.data.public = true
      this.showPublic = true
      this.disablePublic = true
    } else {
      this.data.public = false
      this.showPublic = true
      this.disablePublic = false
    }
  }

  zModusChanged() {
    this.data.zustaendig = []
  }

  get zModusOptions() {
    return this.$store.getters['task/getTaskZmodus'].filter(option => (option.value != 2 && this.data.typ == 2) || this.data.typ != 2)
  }

  get passOnChildWarnText() {
    if (this.data.zModus == 0 && this.data.betroffen && this.data.betroffen.length > 0) {
      return "Betroffene werden auf gewählte Standorte gefiltert"
    }

    if (this.data.zModus == 2 && this.data.zustaendig && this.data.zustaendig.length > 0) {
      return "Zuständige Personen werden auf gewählte Standorte gefiltert"
    }
    return "";
  }

}
