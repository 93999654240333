
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {AmpelDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {Ampel} from "@/model/Hygiene";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {HygieneApi} from "@/services/HygieneApi";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader}
})
export default class HygieneAmpelView extends mixins<SimpleViewMixin<AmpelDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: AmpelDTO = new Ampel()
  error: GenericError = new GenericError()

  chainCategories: AmpelDTO[] = []

  async created() {
    await this.init(Ampel,
        HygieneApi.getAmpel,
        (a) => HygieneApi.putAmpel(a),
        (b) => HygieneApi.removeAmpel(b)
    )
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
