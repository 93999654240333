
import {Component, Prop, Vue} from 'vue-property-decorator';
import {v4 as uuid} from 'uuid';

@Component
export default class LabelComponent extends Vue {
  @Prop() id: string
  @Prop() tooltip: string

  uuid = uuid()

  cleanText = ""

  created() {
    this.cleanText = this.$sanitize(this.tooltip)

  }

  tooltipClicked() {
    this.$alert(this.cleanText, "", "info", true)
  }

}
