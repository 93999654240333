
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {MaterialArtDTO} from "@/model/dto";
import {MaterialArt} from "@/model/Material";
import {MaterialApi} from "@/services/MaterialApi";
import MaterialKategorieDataView from "@/views/material/MaterialKategorieDataView.vue";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  components: {
    MaterialKategorieDataView
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class MaterialKategorieEditView extends mixins<GenericMixin<MaterialArtDTO, MaterialArtDTO>>(GenericMixin, DataProps) {
  data = new MaterialArt();
  error = new MaterialArt();
  showTabs = false
  basePath = process.env.VUE_APP_REWIS

  created() {
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = MaterialApi.getMaterialArt(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  archiv() {
    this.$confirm(' Wirklich ' + this.archivTxt.toLowerCase() + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      MaterialApi.archivMaterialArt(this.data.id)
          .then(() => {
            this.data.archive = !this.data.archive
            bus.$emit(Event.materialArtArchived, new ObjectEvent(this.$props.id, this.data));
            this.goBack()
          })
          .catch(() => {
            this.$alert("Beim " + this.archivTxt + " der Firma trat ein Fehler auf!");
          })
    });
  }

  onSubmit(goBack: boolean) {
    const p = MaterialApi.putMaterialArt(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send materialArtChanged event')
        bus.$emit(Event.materialArtChanged, new ObjectEvent(this.$props.id, data.data));

        if (goBack) {
         this.goBack()
        }
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "../", query: queryParam})
  }


  get archivTxt() {
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }
}
