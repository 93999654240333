
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import DivaNavComponent from "@/views/diva/components/DivaNavComponent.vue";
import {DivaApi} from "@/services/DivaApi";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {DivaNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class DivaView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];

    if (this.$route.name == 'diva'
        && this.$store.getters.hasPermission(PermissionModul.Diva, Permission.Overview)) {
      this.$router.push("/diva/auktionlist")
    } else if (this.$route.name == 'diva'
        && this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Overview_Public)) {
      this.$router.push("/diva/portal")
    }


  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.divaArchiveRequest, (payload: ObjectEvent<any>) => this.removeAutkion(payload.data))

  }

  destroyed() {
    bus.$off(Event.divaArchiveRequest)

  }

  removeAutkion(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie diese Auktion ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then((response) => {
      DivaApi.removeAuktion(data.id)
          .then(() => {
            bus.$emit(Event.divaArchived, new ObjectEvent(data.id, {id: data.id, archive: !data.archive}));
            data.archive = !data.archive;
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }
}
