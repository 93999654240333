

import {Component, Vue} from "vue-property-decorator";
import UserDashboardDateCard from "@/views/userDashboard/components/UserDashboardDateCard.vue";
import UserDashboardFileCard from "@/views/userDashboard/components/UserDashboardFileCard.vue";
import UserDashboardEinweisungenCard from "@/views/userDashboard/components/UserDashboardEinweisungenCard.vue";
import UserDashboardMaterialCard from "@/views/userDashboard/components/UserDashboardMaterialCard.vue";
import UserDashboardQualiCard from "@/views/userDashboard/components/UserDashboardQualiCard.vue";
import MyTask from "@/views/task/MyTask.vue";
import MyReportsView from "@/views/emeld/MyReportsView.vue";
import UserDashboardNoTasksCard from "@/views/userDashboard/components/UserDashboardNoTasksCard.vue";
import UserDashboardCampusCard from "@/views/userDashboard/components/UserDashboardCampusCard.vue";
import UserDashboardEvdCard from "@/views/userDashboard/components/UserDashboardEvdCard.vue";

@Component({
  components: {
    UserDashboardEvdCard,
    UserDashboardCampusCard,
    UserDashboardNoTasksCard,
    MyReportsView,
    MyTask,
    UserDashboardQualiCard,
    UserDashboardMaterialCard,
    UserDashboardEinweisungenCard,
    UserDashboardFileCard,
    UserDashboardDateCard
  }
})
export default class UserDashboard extends Vue {
  loadingDone = false
  noDataInModules = false

  mounted() {
    this.$root.$emit('loadingDone')
    Promise.all([
      this.$refs.dateCards.getPromise(),
      this.$refs.qualiCards.getPromise(),
      this.$refs.fileCards.getPromise(),
      this.$refs.einweisungCards.getPromise(),
      this.$refs.taskCards.getPromise(),
      this.$refs.reportCards.getPromise(),
      this.$refs.materialCards.getPromise(),
      this.$refs.campusCards.getPromise(),
      this.$refs.evdCard.getPromise()
    ]).then((results) => {
      this.loadingDone = true
      this.noDataInModules = results.every(result => result === 0);
    })
  }

}
