
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, Module} from "@/model/Constants";
import {GenericError} from "@/model/dto";
import {TaskApi} from "@/services/TaskApi";
import {EntityTaskTemplate} from "@/model/Task";
import EntityTaskTemplateDataView from "@/views/task/entity/EntityTaskTemplateDataView.vue";


@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    EntityTaskTemplateDataView,
  }
})
export default class EntityTaskTemplateCreateView extends mixins<GenericMixin<EntityTaskTemplate, GenericError>>(GenericMixin) {
  @Prop({default:true}) standalone;

  modul;
  modulPrefix = "";

  data = new EntityTaskTemplate();
  error = new GenericError();

  created() {
    this.modul = this.$router.currentRoute.meta['module'];
    this.modulPrefix = this.$router.currentRoute.meta['entityKey'];
  }

  onSubmit(goBack: boolean) {
    this.data.modul = this.modul
    const p = TaskApi.putEntityTaskTemplate(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send taskCreated event')
        bus.$emit(Event.taskCreated, new ObjectEvent(data.data.id, data.data));
        if (this.standalone) {
          this.goBack()
        } else {
          this.$emit("created", data.data)
        }
      }
    })
  }

  goBack() {
    const from = this.$store.state.route.from.fullPath
    if (from &&
        this.$store.state.route.from.name != this.$route.name && from.includes(this.modulPrefix)) {
      this.$router.push(from)
    } else {
      let queryParam = {}
      if (this.$route.query.archive !== undefined) {
        queryParam = {archive: 1}
      }
      this.$router.push({path: "/"+this.modulPrefix+"/tasktemplatelist", query: queryParam})
    }
  }


}
