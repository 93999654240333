
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {SuchenDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import NumberForm from "@/components/NumberForm.vue";
import {Suche} from "@/model/Diva";

@Component({
  computed: {
    Suche() {
      return Suche
    }
  },
  components: {NumberForm, PersonenkreisChooser, Multiselect}
})
export default class SuchenComponent extends Vue {
  @Prop() private id: string;
  @Prop() private value: SuchenDTO;
  @Prop() private error: any;
  personenkreisOptions = []

  data: SuchenDTO = null

  async created() {
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.init(this.value)
  }

  init(newValue) {
    this.data = structuredClone(newValue)
  }

  changed() {
    this.$emit("input", this.data);
    this.$emit("change", this.data);
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

}
