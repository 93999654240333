
import {Component, Prop, Vue} from 'vue-property-decorator';
import {GenericError} from "@/model/dto";
import DateForm from "@/components/DateForm.vue";
import LabelComponent from "@/components/LabelComponent.vue";

@Component({
  components: {LabelComponent, DateForm}
})
export default class SkipSupervisionForm extends Vue {
  @Prop() private value;
  @Prop() private error: GenericError;
  @Prop() private existingFile;

  get workingValue() {
    return this.value
  }

  set workingValue(d) {
    this.$emit("input", d);
    this.$emit("change", d);
  }
}
