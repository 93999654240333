
import {Component, Vue} from 'vue-property-decorator';
import '@/scripts/dataTables_German'

@Component({
  components: {},
})


export default class EmeldEmpty extends Vue {

}
