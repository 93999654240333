
import {Component, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import MaterialListServersideView from "@/views/material/MaterialListServersideView.vue";
import MaterialKategorieListView from "@/views/material/MaterialKategorieListView.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import EinweisungConfigListView from "@/views/einweisung/EinweisungConfigListView.vue";
import FobiNavComponent from "@/views/fobi/components/FobiNavComponent.vue";
import FobiListView from "@/views/fobi/FobiListView.vue";
import FobiKombiThemaView from "@/views/fobi/FobiKombiThemaView.vue";
import FobiZeitraumView from "@/views/fobi/FobiZeitraumView.vue";
import FobiZeitraumKummulativView from "@/views/fobi/FobiZeitraumKummulativView.vue";
import FobiMyFobiView from "@/views/fobi/FobiMyFobiView.vue";


@Component(
    {
      components: {
        FobiMyFobiView,
        FobiZeitraumKummulativView,
        FobiZeitraumView,
        FobiKombiThemaView,
        FobiListView,
        FobiNavComponent,
        EinweisungConfigListView,
        DatatableButtonHeader,
        MaterialKategorieListView,
        MaterialListServersideView
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class FobiTables extends Vue {

  showTable1 = false
  showTable2 = false
  showTable3 = false
  showTable4 = false
  showTable5 = false

  crud = false

  handleRoute(route: Route) {
    if (route.name.toLowerCase().includes('create') || route.name.toLowerCase().includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'fobizeitraumtable') {
      this.showTable1 = false
      this.showTable2 = false
      this.showTable3 = true
      this.showTable4 = false
      this.showTable5 = false
    } else if (route.name == 'fobikombithematable') {
      this.showTable1 = false
      this.showTable2 = true
      this.showTable3 = false
      this.showTable4 = false
      this.showTable5 = false
    } else if (route.name == 'zeitraumkummulativ') {
      this.showTable1 = false
      this.showTable2 = false
      this.showTable3 = false
      this.showTable4 = true
      this.showTable5 = false
    } else if (route.name == 'fobimyfobi') {
      this.showTable1 = false
      this.showTable2 = false
      this.showTable3 = false
      this.showTable4 = false
      this.showTable5 = true
    } else {
      this.showTable1 = true
      this.showTable2 = false
      this.showTable3 = false
      this.showTable4 = false
      this.showTable5 = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
