
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, VivendiOuSyncDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import {VivendiApi} from "@/services/VivendiApi";
import {ApiResponse} from "@/model/AbstractClasses";
import VivendiDienstOuMapList from "@/views/diva/components/VivendiDienstOuMapList.vue";

@Component({
  computed: {},
  components: {
    VivendiDienstOuMapList,
    LabelComponent,
  }
})
export default class VivendiView extends mixins<SimpleViewMixin<null, GenericError>>(SimpleViewMixin) {
  syncDto: VivendiOuSyncDTO = null;
  error: GenericError = new GenericError()

  key = 0
  hasData = false

  async created() {
    this.syncDto = await VivendiApi.getSyncDto()
    this.initMap()
    this.$root.$emit('loadingDone')
  }

  initMap() {
    if (this.syncDto && this.syncDto.vivendiBereich) {
      this.hasData = true
    } else {
      this.hasData = false
    }
    this.key++
  }
  async saveSyncDto() {
    const promise = VivendiApi.saveSyncDto(this.syncDto)
    this.handleApiRequest(promise, true).then((data) => {
      if (data instanceof ApiResponse && data.data !== undefined) {
        this.syncDto = data.data
        this.initMap()

      }
    })

  }


}
