
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {EntityTaskTemplateDTO, EntityTaskTemplateObjectDTO, GenericError} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import LabelComponent from "@/components/LabelComponent.vue";
import FileUpload from "@/components/FileUpload.vue";
import HtmlEditor from "@/components/HtmlEditor.vue";
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import {Module} from "@/model/Constants";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import TaskTemplateDataView from "@/views/task/TaskTemplateDataView.vue";
import TaskRhythmusComponent from "@/views/task/components/TaskRhythmusComponent.vue";
import {EntityTaskTemplateModus, Task, TaskZModus} from "@/model/Task";
import NumberForm from "@/components/NumberForm.vue";
import MedProduktChooser from "@/components/MedProduktChooser.vue";

@Component({
  computed: {
    Module() {
      return Module
    },
    EntityTaskTemplateModus() {
      return EntityTaskTemplateModus
    },
    TaskZModus() {
      return TaskZModus
    }
  },
  components: {
    MedProduktChooser,
    NumberForm,
    TaskRhythmusComponent: TaskRhythmusComponent,
    TaskTemplateDataView,
    ListItemComponent,
    PersonenkreisChooser,
    OrgUnitSelector,
    HtmlEditor,
    FileUpload,
    LabelComponent,
    Multiselect
  }
})
export default class EntityTaskTemplateEntityData extends mixins<GenericMixin<EntityTaskTemplateObjectDTO, GenericError>>(GenericMixin, DropzoneMixin) {
  @Prop() value: EntityTaskTemplateObjectDTO;
  @Prop() error: GenericError;
  @Prop() taskKategorie;

  @Prop({default: EntityTaskTemplateModus.TEMPLATE}) modus
  modul = Module.KFZ

  data: EntityTaskTemplateObjectDTO = this.$props.value;

  personenkreisChooserId = 0
  personenkreisOptions = []
  formOptions = []
  entityTaskTemplates: EntityTaskTemplateDTO[] = []

  scopeOptions = []
  desiRoutines = []
  uploadKey = 0
  kfzOpts = []
  kfzCategoryOpts = []

  medproduktOpts = []
  geraeteartOpts = []

  materialOpts = []

  expand = false

  async created() {
    this.templateChanged()
    this.modul = this.$router.currentRoute.meta['module'];

    this.formOptions = await this.$store.dispatch("form/fetchForms")
    this.formOptions = this.formOptions.filter(form => !form.typ || form.typ == this.modul)
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.scopeOptions = await this.$store.dispatch("hygiene/fetchScopes")
    this.desiRoutines = await this.$store.dispatch("hygiene/fetchDesiRoutines")


    this.entityTaskTemplates = await this.$store.dispatch("task/fetchEntityTaskTemplates")
    this.entityTaskTemplates = this.entityTaskTemplates.filter(item => item.modul == this.modul)

    if (this.modul == Module.KFZ) {
      this.kfzOpts = await this.$store.dispatch("kfz/fetchKfz")
      this.kfzCategoryOpts = await this.$store.dispatch("kfz/fetchKategorien")
    }
    if (this.modul == Module.MEDPRODUKT) {
      this.medproduktOpts = await this.$store.dispatch("medprodukt/fetchMedizinprodukte")
      this.geraeteartOpts = await this.$store.dispatch('medprodukt/fetchGeraeteart')
    }
    if (this.modul == Module.MATERIAL) {
      this.materialOpts = await this.$store.dispatch("material/fetchMaterial")
      this.geraeteartOpts = this.$store.getters['material/getMaterialArts']
    }
  }

  zModusChanged() {
    this.data.zustaendig = []
    if (this.data.zModus == TaskZModus.CHECKIN || this.data.zModus == TaskZModus.MANUELL) {
      this.data.template = false
      this.templateChanged()
    }


    if (this.data.zModus == TaskZModus.SCHICHT) { //Schichtaufgabe
      this.data.template = true
      this.templateChanged()
    } else if (this.data.zModus == TaskZModus.EINE_PERSON) { //Schichtaufgabe
      this.data.template = true
      this.templateChanged()
    }

    this.data.checkinBezug = (this.data.zModus == TaskZModus.SCHICHT)

    this.personenkreisChooserId++
  }

  templateChanged() {
    if (this.data.template && !this.data.rrule_freq) {
      this.data.rrule_freq = 'DAILY'
      this.data.rrule_interval = 1
      this.data.rrule_daysBefore = 0
    } else if (!this.data.template) {
      this.data.rrule_freq = null;
    }
  }

  get workValue() {
    return this.value
  }

  set workValue(val) {
    this.$emit("input", val)
  }

  get zModusOptions() {
    return this.$store.getters['task/getEntityTaskTemplateZmodus']
  }

  get entityTaskTemplateOptions() {
    return this.entityTaskTemplates.filter(item => item.modul == this.modul)
  }

  get isHygienTask() {
    return (this.taskKategorie && this.taskKategorie == Task.TASK_CAT_HYGIENE) || (this.data.master && this.data.master.kategorie == Task.TASK_CAT_HYGIENE)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.uploadKey++
  }
}
