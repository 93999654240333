
import {Component, Vue} from 'vue-property-decorator';
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";

@Component({components: {OrgUnitSelector}})
export default class OrgUnitSelectorView extends Vue {
  async changeLocation({id}) {
    await this.$store.dispatch('changeLocation', {id: id})
    this.$router.push('/dashboard')
  }
}
