
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, WachenCloudDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import {WachenCloud} from "@/model/File";
import {FileApi} from "@/services/FileApi";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {PersonenkreisChooser, SimpletableButtonHeader, Multiselect}
})
export default class CloudSettings extends mixins<SimpleViewMixin<WachenCloudDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: WachenCloudDTO = new WachenCloud()
  error: GenericError = new GenericError()

  personenkreisOptions = []

  async created() {
    await this.init(WachenCloud,
        // eslint-disable-next-line no-undef
        FileApi.getWachenClouds,
        (a) => FileApi.putWachenCloud(a),
        (b) => null
    )
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.$root.$emit("loadingDone")
  }


  sort(objects) {
    return objects.sort((a, b) => a.bezeichnung.localeCompare(b.bezeichnung))
  }
}
