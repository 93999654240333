

import {Component, Vue} from "vue-property-decorator";
import {OrgUnitApi} from "@/services/OrgUnitApi";
import jquery from "jquery";

@Component
export default class UserDashboardEvdCard extends Vue {
  evd = null
  donePromise;

  async created() {
    //this.evd = await OrgUnitApi.getDisplayEvd()
    this.init()
  }

  init() {
    this.donePromise = new Promise((resolve, reject) => {
      OrgUnitApi.getDisplayEvd().then(data => {
        this.evd = data

        this.$nextTick(() => {
          if (this.evdWithContent) {
            jquery('#evdcontent').children().prependTo("#myCards");
            resolve(this.evd)
          } else {
            resolve(0)
          }
        })
      })
    })
  }

  get evdWithContent() {
    return this.evd && (this.evd.nummer || this.evd.user)
  }
  getPromise() {
    return this.donePromise
  }


}
