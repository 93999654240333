
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {EntityStandortDTO, EwoHerstellerDTO, MaterialArtDTO, MaterialDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import AdditionalFieldForm from "@/components/AdditionalFieldForm.vue";
import AdditionalFieldResultForm from "@/components/AdditionalFieldResultForm.vue";
import {MaterialApi} from "@/services/MaterialApi";
import {AdditionalFieldResult} from "@/model/AbstractClasses";
import LocationChooser from "@/components/LocationChooser.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import DateForm from "@/components/DateForm.vue";
import LabelComponent from "@/components/LabelComponent.vue";


@Component({
  components: {
    LabelComponent,
    DateForm,
    LocationChooser, AdditionalFieldResultForm, AdditionalFieldForm, NumberForm, Multiselect
  }
})
export default class MaterialDataView extends mixins<GenericMixin<MaterialDTO, MaterialDTO>>(GenericMixin) {
  @Prop() value: MaterialDTO;
  @Prop() error: MaterialDTO;
  data: MaterialDTO = this.$props.value;

  materialArtOpts: MaterialArtDTO[] = []
  materialKlasseOpts: MaterialArtDTO[] = []

  locationsOpts: EntityStandortDTO[] = []
  herstellerOpts: EwoHerstellerDTO[] = []


  async created() {
    window.scrollTo(0, 0);
    this.herstellerOpts = await this.$store.dispatch('medprodukt/fetchHersteller')
    this.materialArtOpts = await this.$store.dispatch("material/fetchMaterialArt")
    this.materialArtOpts = this.materialArtOpts.filter(opt => !opt.klasse)

    this.materialKlasseOpts = await this.$store.dispatch("material/fetchMaterialArt")
    this.materialKlasseOpts = this.materialKlasseOpts.filter(opt => opt.klasse)

  }

  async mounted() {
    this.locationsOpts = await this.$store.dispatch('material/fetchStandorte')
  }

  async materialArtChanged(event) {
    this.data.typ = null
    this.data.additionalFieldResults = []

    if (this.data.art) {
      const tmp = await MaterialApi.getMaterialArt(this.data.art.id);
      const materialExtended = tmp.data

      if (materialExtended.typField) {
        this.data.typ = new AdditionalFieldResult(materialExtended.typField)
      }

      materialExtended.additionalFields.forEach(addField => this.data.additionalFieldResults.push(new AdditionalFieldResult(addField)))
    }
  }

  locationChanged() {
    if (this.data.id) {
      bus.$emit(Event.materialStandortChanged, this.data)
    }
  }

  get verwaltungsStandortOptions() {
    return this.locationsOpts.filter(location => location.orgUnit != null && !location.customLocation).map(location => location.orgUnit)
  }

  get herstellerOptions() {
    return this.herstellerOpts.sort((a, b) => a.name.localeCompare(b.name))

  }

  get primStandortOpts() {
    return this.locationsOpts.filter(location => !location.abstract)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }


}
