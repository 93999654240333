
import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {DateHelper} from "@/model/Date";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import {KfzApi} from "@/services/KfzApi";
import {HistoryEvent} from "@/model/Shared";
import {MaterialRequest} from "@/model/Material";
import {_} from 'vue-underscore';
import {Permission, PermissionModul} from "@/model/dto";

@Component(
    {
      components: {DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("kfzlist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class KfzListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  table = null
  windowWith = null;
  thresholdExceeded = true;

  showTable = true
  archive = false
  includeChilds = false //childOrgUnits

  getUrl() {
    return '/kfz/kfzlist/';
  }

  created() {
    this.archive = this.$route.query.archive !== undefined
  }
  onResizeDebounced = _.debounce(() => {
    this.init();
  }, 500);

  mounted() {
    this.checkWith(false)
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    const request = new MaterialRequest();
    request.archive = this.archive
    request.includeChildOus = this.includeChilds //child orgUnits

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }


    this.registerEvents([Event.kfzCreated, Event.kfzChanged, Event.kfzArchived])
    const dateHelper = DateHelper


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: this.getUrl(),
        type: 'POST',
        data: function () {
          return JSON.stringify(request);
        },
      }),
      fixedHeader: {
        header: true,
        headerOffset: this.changeOffset(),
      },
      responsive: false,
      rowId: "id",
      columns: [
        {
          class: '',
          data: 'licensePlate'
        },
        {
          data: null,
          class: 'text-center',
          render(data, type, row) {
            return self.kfzStatus(data.status)
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return func.getDashboardStatusIcon(data.statusFile, 'file')
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return func.getDashboardStatusIcon(data.statusDate, 'date')
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return func.getDashboardStatusIcon(data.statusEmeldReport, 'report')
          }
        },
        {
          class: '',
          data: 'name'
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.location ? data.location.icon + " " + (data.locationText ? data.locationText : data.location.text) : ""
          }
        },
        {
          class: '',
          title: 'verwaltender Standort',
          data: null,
          render: function (data, type) {
            return data.orgUnit ? data.orgUnit.name : ""
          },
        },
        {
          data: null,
          render: function (data, type, row) {
            return self.$store.getters['selectize/getReifenLabelFromId'](data.tire)
          }
        },
        {
          class: '',
          data: 'typ'
        },
        {
          class: '',
          data: 'herstellerModell.name'
        },
        {
          class: '',
          data: 'kennung.name'
        },
        /*{
          class: '',
          data: 'category[0].name'
        },*/
        {
          class: '',
          data: null,
          render: function (data, type, row) {
            let categories = data.category.map(function (item) {
              return item.name
            })
            return categories.join(', ')
          }
        },
        {
          class: 'cw-150',
          data: 'chassisNumber'
        },
        {
          class: 'cw-100',
          data: 'investNummer'
        },
        {
          class: '',
          data: 'aufbauer.name'
        },
        {
          class: '',
          data: 'builderNumber'
        },
        {
          /*class: '',
          data: 'firstRegistrationDate'*/
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.firstRegistrationDate)
          }
        },
        {
          class: '',
          data: 'constructionYear'
        },
        {
          class: '',
          data: 'comment'
        },
        {
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.outOfServiceDate)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.repInsurance) {
              return 'Ja';
            } else {
              return 'Nein';
            }
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.fremdeigentum) {
              return 'Ja';
            } else {
              return 'Nein';
            }
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.mpgLocation) {
              return 'Ja';
            } else {
              return 'Nein';
            }
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.materialLocation) {
              return 'Ja';
            } else {
              return 'Nein';
            }
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            if (data.caseMoved) {
              return data.caseMovedDate ? dateHelper.parseDate(data.caseMovedDate) : 'Ja';
            } else {
              return 'Nein';
            }
          }
        },
        {
          class: '',
          data: 'quali.name'
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons),
    })
    func.datatableInitComplete(table)


    self.table = table
    self.initContextMenu(contextMenuButtons)
    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      if (!self.$store.getters.hasPermission(PermissionModul.Kfz, Permission.Overview)) {
        if (!self.$store.getters.hasPermission(PermissionModul.Kfz, Permission.Overview_Public)) {
          return;
        }
      }
      let rowId = jquery(this).attr('id')
      self.editKfz(rowId)
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Kfz, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.createKfz()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Kfz, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.editKfz(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.editKfz(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Kfz, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/kfz/kfzdata', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/kfz/kfzdata', tr.attr('id')))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Kfz, Permission.Archive,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }, contextMenuButtons
    )

    this.addButton(
        PermissionModul.Kfz, Permission.Overview,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.includeChilds ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.includeChilds ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.includeChilds = !self.includeChilds
            self.init()
          },
        }, myButtons)
  }
  

  kfzStatus(statusId) {
    const tmp = this.$store.getters['selectize/getKfzStatus'].find(status => status.value == statusId);
    if (tmp) {
      return func.getIcon(tmp.type, statusId, tmp.text);
    }

    return "";
  }

  editKfz(id) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/kfz/edit/" + id, query: queryParam})
  }

  createKfz() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/kfz/create/", query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "kfzlist", query: queryParam})
  }

  archiv(data) {
    this.$confirm('Sind sie Sicher ?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      KfzApi.archivKfz(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.kfzUmzugRequest, data);
            bus.$emit(Event.kfzArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

  initContextMenu(myContextButtons) {
    if (myContextButtons.length == 0) return
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: myContextButtons,
    });
  }

  changeOffset() {
    if(this.thresholdExceeded) {
      return this.$DesktopHeaderOffset;
    } else {
      return this.$MobilHeaderOffset;
    }
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }
  @Watch('$screen.width')
  onWidthChange() {
    this.checkWith()
  }

  checkWith(reload= true) {
    this.windowWith = this.$screen.width;
    const exceedsThreshold = this.windowWith > this.$navBarUmbruch;

    if (exceedsThreshold != this.thresholdExceeded) {
      this.thresholdExceeded = exceedsThreshold;
      if (reload) this.onResizeDebounced();
    }
  }
}
