
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import '@/scripts/dataTables_German'
import {FormApi} from "@/services/FormApi";
import {FormVersionDTO} from "@/model/dto";
import {DateHelper} from "../../model/Date";


@Component(
    {
      computed: {
        DateHelper() {
          return DateHelper
        }
      },
      components: {},
    }
)
export default class FormVersionListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop() id: number;
  versions: FormVersionDTO[] = []

  async created() {
    this.$root.$emit('loadingStart')
    this.versions = await FormApi.getFormVersions(this.id)
    this.$root.$emit('loadingDone')
  }

  open(version) {
    this.$router.push({path: "/form/" + version.form.id + "/version/edit/" + version.id})
  }


}
