import { render, staticRenderFns } from "./UserDateSettingsView.vue?vue&type=template&id=f32ec296&scoped=true&"
import script from "./UserDateSettingsView.vue?vue&type=script&lang=ts&"
export * from "./UserDateSettingsView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f32ec296",
  null
  
)

export default component.exports