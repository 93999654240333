
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {LevelOuDTO} from "@/model/dto";
import EmeldLevelDataView from "@/views/emeld/data/EmeldLevelDataView.vue";
import {Level, LevelOu} from "@/model/Emeld";
import {EmeldApi} from "@/services/EmeldApi";
import {Route} from "vue-router";
import EmeldLevelOuDataView from "@/views/emeld/data/EmeldLevelOuDataView.vue";


@Component({
  components: {
    EmeldLevelOuDataView,
    EmeldLevelDataView,
  },
  watch: {
    async '$route'(val: Route) {
      await this.init()
      this.$root.$emit('loadingDone')
    }
  }
})
export default class EmeldLevelOuCreateView extends mixins<GenericMixin<LevelOuDTO, LevelOuDTO>>(GenericMixin) {
  data = new LevelOu();
  error = new LevelOu();
  @Prop() id


  async created() {
    await this.init()
    this.$root.$emit('loadingDone')
  }

  init() {
    let level = new Level()
    this.error.level = {}

    level.status = []
    level.bewertungType = 0
    level.tags = []
    level.name = ''
    if (this.id) {
      level.parent = {id: this.id}
    } else {
      level.parent = null
      level.status = [{id: null, name: 'Erfasst', archive: false}]
    }

    let levelOu = new LevelOu()
    levelOu.level = level
    levelOu.own = true
    levelOu.tagsOu = []
    levelOu.info = []
    this.data = levelOu
  }

  onSubmit(goBack: boolean) {
    const p = EmeldApi.putLevelOu(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send emeldLevelOuChanged event')
        bus.$emit(Event.emeldLevelOuChanged, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push({path: "/emeld/levelou/edit/" + this.data.level.id})

    //do nothing
  }

  zustaendigkeitChanged(val) {
    this.data.zustaendig = val
  }

}
