
import {Component, Prop, Vue} from 'vue-property-decorator';
import 'vue-pdf-embed/src/styles/text-layer.css'
import PdfView from "@/components/PdfView.vue";
import HistoryView from "@/views/shared/HistoryView.vue";
import {HistoryEvent} from "@/model/Shared";

@Component({
  components: {HistoryView, PdfView},
})
export default class HistoryViewModal extends Vue {
  @Prop({default: false}) value: boolean;
  @Prop() payload: HistoryEvent;

  get showModal() {
    console.log(this.payload)
    return this.value;
  }

  set showModal(val) {
    this.$emit('input', val);
  }
}
