
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {FileCategoryDTO, GenericError, MaterialArtDTO, MaterialFileCategoryDTO} from "@/model/dto";
import Multiselect from '@/libs/multiselect'
import {MaterialFileCategory} from "@/model/File";
import {FileApi} from "@/services/FileApi";
import PseudoTag from "@/components/PseudoTag.vue";
import MaterialView from "@/views/material/MaterialView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {
    ChainIconTooltipTableCell,
    LabelComponent,
    ListItemComponent, SimpletableButtonHeader, SettingsTooltip, MaterialView, PseudoTag, Multiselect}
})
export default class MaterialFileCategoriesView extends mixins<SimpleViewMixin<MaterialFileCategoryDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: MaterialFileCategoryDTO = new MaterialFileCategory()
  error: GenericError = new GenericError()

  fileCategories: FileCategoryDTO[] = []
  orgUnitFileCategories: MaterialFileCategoryDTO[] = []

  materialCategoryOpts: MaterialArtDTO[] = []

  chainCategories: MaterialFileCategoryDTO[] = []


  async created() {
    await this.init(MaterialFileCategory,
        FileApi.getMaterialFileCategoryOu,
        (a) => FileApi.putMaterialFileCategoryOu(a),
        (b) => FileApi.removeMaterialFileCategoryOu(b))

    this.fileCategories = await this.$store.dispatch("material/fetchDateiKategorienKatalog")
    this.orgUnitFileCategories = await FileApi.getMaterialFileCategoryOu()

    this.materialCategoryOpts = await this.$store.dispatch("material/fetchMaterialArt")

    this.chainCategories = await this.$store.dispatch('material/fetchDateiKategorienOu');
    this.chainCategories = this.chainCategories.filter(cat => (!cat.orgUnit || cat.orgUnit.id != this.$store.getters.getLocation.id))
    this.$root.$emit('loadingDone')
  }

  get getCategories() {
    return this.fileCategories.sort((a, b) => a.name.localeCompare(b.name))
  }
  get archivTxt() {
    return "Löschen"
  }

  removeElement(id) {
    this.data.materialArt = this.data.materialArt.filter((e) => e.id != id);
  }
}
