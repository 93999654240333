
import {Component, Prop, Watch} from 'vue-property-decorator';
import {GenericError, MaterialDTO, MaterialFileDTO, MaterialOutsourcingDTO, OutsourcingCategoryDTO, OutsourcingDTO, ResponseDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import Multiselect from "@/libs/multiselect";
import {MaterialOutsourcing} from "@/model/Outsourcing";
import {OutsourcingApi} from "@/services/OutsourcingApi";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

@Component({
  components: {ListItemComponent, Multiselect},
})
export default class MaterialOutsourcingEditModal extends mixins<GenericMixin<MaterialOutsourcingDTO, GenericError>>(GenericMixin) {
  data: MaterialOutsourcingDTO = new MaterialOutsourcing()
  error: GenericError = new GenericError()

  materialList: MaterialDTO[] = []
  categories: OutsourcingCategoryDTO[] = []
  outsourcingOpts: OutsourcingDTO[] = []

  @Prop() private value!: MaterialFileDTO;
  @Prop() private id!: string;

  init(newValue) {
    this.data = new MaterialOutsourcing()

    if (newValue != null) {
      this.data = structuredClone(newValue)
    }
  }


  async created() {
    this.init(this.$props.value)
    this.categories = await this.$store.dispatch('outsourcing/fetchKategorien')
    this.materialList = await this.$store.dispatch("material/fetchMaterial")
    this.outsourcingOpts = await this.$store.dispatch('material/fetchOutsourcing')

  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

  close() {
    this.$bvModal.hide(this.$props.id)
  }


  save() {
    const p = OutsourcingApi.putMaterialOutsourcing(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('input', this.data)
        this.$bvModal.hide(this.$props.id)
        this.$emit('change', this.data)
      }
    });
  }

  get materialOptList() {
    return this.materialList.sort((a, b) => a.searchText.localeCompare(b.searchText))
  }


  get categorieOpts() {
    return this.categories
        //.filter(cat => cat.typ == 1)
  }

}
