
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EntityTaskTemplateObjectDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import Multiselect from '@/libs/multiselect'
import PseudoTag from "@/components/PseudoTag.vue";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {EntityTaskTemplateModus, EntityTaskTemplateObject} from "@/model/Task";
import {TaskApi} from "@/services/TaskApi";
import EntityTaskTemplateEntityData from "@/views/task/entity/EntityTaskTemplateEntityData.vue";
import {DateHelper} from "../../../model/Date";
import {Module} from "@/model/Constants";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    },
    EntityTaskTemplateModus() {
      return EntityTaskTemplateModus
    }
  },
  components: {
    EntityTaskTemplateEntityData,
    SimpletableButtonHeader, SettingsTooltip, KfzView, PseudoTag, Multiselect
  }
})
export default class EntityTaskTemplateEntityView extends mixins<SimpleViewMixin<EntityTaskTemplateObjectDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: EntityTaskTemplateObjectDTO = new EntityTaskTemplateObject()
  error: GenericError = new GenericError()

  @Prop() entity;


  modul;
  vorlagePath = null

  async created() {
    this.$root.$emit('loadingStart')
    this.modul = this.$router.currentRoute.meta['module'];

    this.vorlagePath = "/" + this.$router.currentRoute.meta['entityKey']?.toLowerCase() + "/tasktemplate/create"

    await this.init(EntityTaskTemplateObject,
        TaskApi.getEntityTaskTemplateObject,
        (a) => TaskApi.putEntityTaskTemplateObject(a),
        (b) => TaskApi.archivEntityTaskTemplateObject(b),
        this.modul + "/" + this.entity.id
    )
    this.$root.$emit('loadingDone')
  }

  newObject() {
    this.data = new EntityTaskTemplateObject();
    this.data[this.$router.currentRoute.meta['entityKey']] = this.entity

    this.createErrorDto()
    this.showModal = true
  }

  newVorlage() {

    if (this.vorlagePath) {
      this.$router.push({path: this.vorlagePath})
    }
  }

  getArt(q: EntityTaskTemplateObjectDTO) {
    const obj = this.$store.getters['task/getEntityTaskTemplateZmodus'].find(item => item.value === q.zModus)
    return obj ? obj.text : ''
  }


  get archivTxt() {
    return "Löschen"
  }

  get permissionModul() {
    if (!this.modul) return null;

    let permissionModul = PermissionModul.Kfz_Task_Plan;
    if (this.modul == Module.MEDPRODUKT) permissionModul = PermissionModul.Medprodukt_Task_Plan;
    if (this.modul == Module.MATERIAL) permissionModul = PermissionModul.Material_Task_Plan;

    return permissionModul
  }

  get editPermission() {
    return this.$store.getters.hasPermission(this.permissionModul, Permission.Update)
  }
}
