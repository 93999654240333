import {apiClient} from './AbstractHTTPService'
import store from '../store'

import Axios from "axios";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {EwoDTO, FileDTO, KfzStandortDTO, MedProduktDTO, MedProduktStandortDTO, OutsourcingDTO} from "@/model/dto";
import {Event} from "@/model/Constants";


export abstract class MedProduktApi {
    private static usersAxios = Axios.create();

    static async getMedProduteList(): Promise<EwoDTO[]> {
        const response = await apiClient.get('/medprodukt/medproduktelist/list/');
        return response.data.data;
    }

    static async getMedProdukt(id): Promise<ApiResponse<MedProduktDTO>> {
        const response = await apiClient.get('/medprodukt/medproduktdata/'+id);
        return response.data;
    }

    static async putMedProdukt(obj: MedProduktDTO): Promise<ApiResponse<MedProduktDTO>> {
        const response = await apiClient.put('/medprodukt/medproduktdata/' + obj.id + '/', obj);
        store.dispatch("crudEvent", new CrudEvent(Event.medproduktChanged, response.data.data)); //TODO dispatch childs!
        return new ApiResponse(response);
    }

    static async archiveMedProdukt(id: number, dto): Promise<ApiResponse<any>> {
        const response = await apiClient.delete('/medprodukt/medproduktdata/' + id, {data: dto});
        store.dispatch("crudEvent", new CrudEvent(Event.medproduktChanged, response.data.data));
        return new ApiResponse(response);
    }


    static async updatePhoto(medProduktId: number, dto): Promise<FileDTO> {
        const response = await apiClient.put('/medprodukt/medproduktphoto/' + medProduktId + '/', dto);
        return response.data.data;
    }

    static async removePhoto(medProduktId: number): Promise<any> {
        const response = await apiClient.delete('/medprodukt/medproduktphoto/' + medProduktId + '/');
        return response.data;
    }


    static async getLocations(): Promise<KfzStandortDTO[]> {
        const response = await apiClient.get('/medprodukt/medproduktstandort/chain');
        return response.data.data;
    }

    static async getOutsourcing(): Promise<OutsourcingDTO[]> {
        const response = await apiClient.get('/outsourcing/outsourcinglist/chain/mpg');
        return response.data.data;
    }

    static async getOrgUnitStandorte(): Promise<MedProduktStandortDTO[]> {
        const response = await apiClient.get('/medprodukt/medproduktstandort');
        return response.data.data;
    }

    static async putStandort(q: MedProduktStandortDTO): Promise<MedProduktStandortDTO> {
        const response = await apiClient.put('/medprodukt/medproduktstandort', q);
        store.dispatch("crudEvent", new CrudEvent(Event.entityStandortUpdate, response.data.data));
        return response.data;
    }

    static async removeStandort(id:number): Promise<MedProduktStandortDTO> {
        const response = await apiClient.delete('/medprodukt/medproduktstandort'+id);
        store.dispatch("crudEvent", new CrudEvent(Event.entityStandortUpdate, response.data.data));
        return response.data;
    }

    static async getImportData(id): Promise<any> {
        const response = await apiClient.get('/medprodukt/import/' + encodeURIComponent(id));
        return response.data.data;
    }

    static async processImportData(id): Promise<any> {
        const response = await apiClient.post('/medprodukt/import/' + encodeURIComponent(id));
        return response.data.data;
    }


}