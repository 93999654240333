
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {MedProduktDTO} from "@/model/dto";
import MedProduktDataView from "@/views/medprodukt/MedProduktDataView.vue";
import {MedProduktEdit} from "@/model/MedProdukt";
import {MedProduktApi} from "@/services/MedProduktApi";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from "@/main";
import {Event} from "@/model/Constants";


@Component({
  components: {
    MedProduktDataView,
  },
  watch: {}
})
export default class MedProduktCreateView extends mixins<GenericMixin<MedProduktDTO, MedProduktDTO>>(GenericMixin) {
  data = new MedProduktEdit();
  error = new MedProduktEdit();
  errorTabs = []

  created() {
    this.init();
  }

  init = function () {
    this.data.qrCode = {code: '', id: null}
    this.data.differentChilds = []
    this.data.orgUnit = this.$store.getters.getLocation
  }


  onSubmit(goBack: boolean) {
    this.errorTabs = []
    const p = MedProduktApi.putMedProdukt(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      let queryParam = {}
      if (this.$route.query.archive !== undefined) {
        queryParam = {archive: 1}
      }


      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send medproduktCreated event')
        bus.$emit(Event.medproduktCreated, new ObjectEvent(data.data.id, data.data));

        if (this.$store.state.route.from.name === 'medproduktlist') {
          data.data.childs.forEach(child => {
            bus.$emit(Event.medproduktCreated, new ObjectEvent(child.id, child));
          })
        }


        this.$router.push({
          name: "medproduktedit",
          params: {
            id: "" + data.data.id,
            from: this.$store.state.route.from.fullPath
          }
        })
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }

    let from = this.$store.state.route.from.fullPath;
    if (!from.includes("medprodukt")) {
      console.log("Fallback redirect")
      from = "/medprodukt/"
    }

    this.$router.push({path: from})
  }

}
