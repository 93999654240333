
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FobiZeitraumDTO, FobiZeitraumUserRowDTO} from "@/model/dto";
import {FobiApi} from "@/services/FobiApi";
import FobiUserOverviewComponent from "@/views/fobi/components/FobiUserOverviewComponent.vue";


@Component({
  components: {FobiUserOverviewComponent}
})
export default class FobiZeitraumOverview extends mixins<GenericMixin<FobiZeitraumDTO, null>>(GenericMixin) {
  @Prop() value: FobiZeitraumDTO;
  data: FobiZeitraumDTO = this.$props.value;
  userOverview: FobiZeitraumUserRowDTO[] = [];
  loadingComplete = false

  async created() {
    this.loadingComplete = false

    this.userOverview = await FobiApi.getZeitraumOverview(this.value)
    this.loadingComplete = true
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }
}
