
import {Component, Prop, Vue} from "vue-property-decorator";
import {FobiZeitraumDTO, FobiZeitraumUserRowDTO} from "@/model/dto";
import {DateHelper} from "@/model/Date";
import {FobiApi} from "@/services/FobiApi";
import {FobiUserOverview} from "@/model/Fobi";
import FobiUserOverviewComponent from "@/views/fobi/components/FobiUserOverviewComponent.vue";


@Component(
    {
      computed: {
        DateHelper() {
          return DateHelper
        }
      },
      components: {FobiUserOverviewComponent}
    }
)
export default class FobiUserOverviewTable extends Vue {
  @Prop() private userId;
  @Prop() userProfile;
  completeZeitraeume: FobiZeitraumDTO[] = []
  uncompleteZeitraeume: FobiZeitraumDTO[] = []

  overviews: FobiZeitraumUserRowDTO[] = []

  dialogZeitraum = null
  dialogOverview = []
  showDialog = false

  async created() {
    await this.init()
  }

  async init() {
    let userZeitraum = new FobiUserOverview(this.userId)
    this.completeZeitraeume = []
    this.uncompleteZeitraeume = []
    this.overviews = []

    const chainZeitraum = await FobiApi.getZeitraumeWithUser(this.userId)
    for (const zeitraum of chainZeitraum) {
      FobiApi.getZeitraumOverview(zeitraum).then(overviewData => {
        zeitraum.overviewData = overviewData
        zeitraum.userAusschluss = this.getAusschluss(zeitraum)

        overviewData.forEach(userRow => {
          if (userRow.gesamtStatus == 1) {
            this.completeZeitraeume.push(zeitraum)
          } else {
            this.uncompleteZeitraeume.push(zeitraum)
          }
        })
      });
    }
  }

  zeitraumClick(zeitraum) {
    this.dialogZeitraum = zeitraum

    this.dialogZeitraum = zeitraum;
    this.dialogOverview = zeitraum.overviewData;
    this.showDialog = true;
  }

  ausschluss(zeitraum) {
    this.$prompt('Bitte geben Sie einen Grund an.', '', 'Ausschließen von Zeitraum ' + zeitraum.name).then((reason) => {
      FobiApi.putZeitraumAusschluss({grund: reason, userId: this.userId, zeitraumId: zeitraum.id})
          .then((response) => {
            this.init()
          })
          .catch((e) => {
            this.$alert(e.error, 'Fehler', 'error');
          })
    });
  }

  getAusschluss(zeitraum) {
    return zeitraum.ausschluss.find(a => a.user.id == this.userId)
  }
}
