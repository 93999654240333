
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {ApiResponse, ExistingFiles, ObjectEvent, RewisFile} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, Module} from "@/model/Constants";
import {EwoDTO} from "@/model/dto";
import EwoDataView from "@/views/ewmpg/EwoDataView.vue";
import {EwoEdit} from "@/model/Ewmpg";
import {EwmpgApi} from "@/services/EwmpgApi";
import EwoFileEwoView from "@/views/ewmpg/EwoFileEwoView.vue";
import EwoDateEwoView from "@/views/ewmpg/EwoDateEwoView.vue";
import EwoFileCategoryEwoView from "@/views/ewmpg/EwoFileCategoryEwoView.vue";
import FileUpload from "@/components/FileUpload.vue";
import {FileApi} from "@/services/FileApi";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  computed: {
    Module() {
      return Module
    },
    FileApi() {
      return FileApi
    }
  },
  components: {
    FileUpload,
    EwoFileEwoView,
    EwoFileCategoryEwoView,
    EwoDateEwoView,
    EwoDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class EwoEditView extends mixins<GenericMixin<EwoDTO, EwoDTO>>(GenericMixin, DataProps) {
  data = new EwoEdit();
  error = new EwoEdit();
  showTabs = false
  tabIndex = 0
  errorTabs = []

  basePath = process.env.VUE_APP_REWIS
  showProfilePhotoUploadModal = false
  existingProfilePhoto = null
  uploadKey = 0

  created() {
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')


    const p = EwmpgApi.getEwo(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true

      if (this.data.profilePhoto) this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
    })
    this.uploadKey += 1
  }

  editProfilePhoto() {
    this.showProfilePhotoUploadModal = true
  }

  profilePhotoFileUploadSuccess(d) {
    let file = new RewisFile(d);

    EwmpgApi.updatePhoto(this.data.id, file)
        .then((photo) => {
          this.showProfilePhotoUploadModal = false
          this.data.profilePhoto = photo
          this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
        })
        .catch((e) => {
          this.$alert("Beim Upload des Bildes trat ein Fehler auf!");
        });
  }

  profilePhotoFileDelete(d) {
    EwmpgApi.removePhoto(this.data.id)
        .then((photo) => {
          this.data.profilePhoto = null
          this.existingProfilePhoto = null
        })
        .catch((e) => {
          this.$alert("Beim Löschen des Profilbiles trat ein Fehler auf!");
        });
  }


  archiv() {
    this.$confirm(' Wirklich ' + this.archivTxt.toLowerCase() + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      EwmpgApi.archiveEwo(this.data.id)
          .then(() => {
            this.data.archive = !this.data.archive
            bus.$emit(Event.ewoArchived, new ObjectEvent(this.$props.id, this.data));
            this.goBack()
          })
          .catch(() => {
            this.$alert("Beim " + this.archivTxt + " des Fahrzeugs trat ein Fehler auf!");
          })
    });
  }

  onSubmit(goBack: boolean) {
    this.errorTabs = []
    const p = EwmpgApi.putEwo(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      jquery("#ewotabs .tab-pane").each(
          function (index) {
            if (jquery(this).find('.is-invalid').length > 0) {
              self.errorTabs.push(parseInt(this.getAttribute('tab')));
            }
          }
      );

      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send ewoChanged event')
        bus.$emit(Event.ewoChanged, new ObjectEvent(this.$props.id, data.data));

        if (goBack) {
          this.goBack()
        }
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "../", query: queryParam})
  }

  get archivTxt() {
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }
}
