
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DateTypDTO, FileCategoryDTO, GenericError, PersonenkreisDTO, ResponseDTO, UserDateTypDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect/";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import {UserDateTyp} from "@/model/Date";
import {DateApi} from "@/services/DateApi";
import NumberForm from "@/components/NumberForm.vue";
import UserView from "@/views/user/UserView.vue";
import SimpleViewMixin from "../SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {
    ChainIconTooltipTableCell,
    LabelComponent,
    ListItemComponent,
    SimpletableButtonHeader,
    SettingsTooltip, UserView, NumberForm, PersonenkreisTag, PersonenkreisChooser, Multiselect}
})
export default class UserDateSettingsView extends mixins<SimpleViewMixin<UserDateTyp, GenericError>>(SimpleViewMixin, DataProps) {
  data: UserDateTypDTO = new UserDateTyp()
  error: GenericError = new GenericError()

  dateTypes: DateTypDTO[] = []
  orgUnitDateTypes: UserDateTypDTO[] = []
  chainDateTypes: UserDateTypDTO[] = []

  personenkreisOptions: PersonenkreisDTO[] = []
  fileCategories: FileCategoryDTO[] = []
  k = 0

  async created() {
    await this.init(UserDateTyp,
      DateApi.getUserDateTypesOu,
      (a) => DateApi.putUserDateTypOu(a),
      (b) => DateApi.removeUserDateTypOu(b),
      '',
      (data: ResponseDTO) => {
        if (data.data != undefined) {
          this.updatePersonenkreise(data.data.personenkeis);
        }
      })

    this.orgUnitDateTypes = await DateApi.getUserDateTypesOu()
    this.dateTypes = await this.$store.dispatch("user/fetchDateTypCatalog")

    this.chainDateTypes = await this.$store.dispatch("user/fetchDateTypesOu")

    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.fileCategories = await this.$store.dispatch('user/fetchDateiKategorienKatalog');

    let lftNr = 0
    this.personenkreisOptions
        .map((pK: PersonenkreisDTO) => {
          if (pK.temporary) {
            pK.id = (-1) * ++lftNr
          }
          return pK;
        })
    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects ? objects.sort((a, b) => a.formatted.localeCompare(b.formatted)) : []
  }

  handleNumber(event) {
    console.log(event)
  }

  updatePersonenkreise(data: PersonenkreisDTO[]) {
    data.forEach((updatePersonenkreis) => {
      let opt = this.personenkreisOptions.find(o => updatePersonenkreis.frontendRef == o.id)
      if (opt != undefined) {
        opt.id = updatePersonenkreis.id
        opt.temporary = false
      }
    })
  }

  get getDateTypes() {
    return this.dateTypes.sort((a, b) => a.name.localeCompare(b.name))
  }

  get getOrgUnitCategories() {
    return this.sort(this.orgUnitDateTypes)
  }

  get getChainCategories() {
    return this.sort(this.chainDateTypes.filter(dateType => dateType.orgUnit.id != this.$store.getters.getLocation.id))
  }


  get getPersonenkreisOptions() {
    return this.personenkreisOptions
  }

  get archivTxt() {
    return "Löschen"
  }

}
