
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {LevelDTO} from "@/model/dto";
import EmeldLevelDataView from "@/views/emeld/data/EmeldLevelDataView.vue";
import {Level} from "@/model/Emeld";
import {EmeldApi} from "@/services/EmeldApi";
import {Route} from "vue-router";


@Component({
  components: {
    EmeldLevelDataView,
  },
  watch: {
    '$route'(val: Route) {
      this.init()
    }
  }
})
export default class EmeldLevelCreateView extends mixins<GenericMixin<LevelDTO, LevelDTO>>(GenericMixin) {
  data = new Level();
  error = new Level();
  @Prop() id


  async created() {
    this.init()
  }

  init() {
    this.data = new Level()
    this.data.status = []
    this.data.bewertungType = 0
    this.data.tags = []
    if (this.id) {
      this.data.parent = {id: this.id}
    } else {
      this.data.parent = null
      this.data.status = [{id: null, name: 'Erfasst', archive: false}]
    }
  }

  onSubmit(goBack: boolean) {
    const p = EmeldApi.putLevel(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send emeldLevelChanged event')
        bus.$emit(Event.emeldLevelChanged, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    if (this.id) {
      this.$router.push({path: "/emeld/level/edit/" + this.id})
    } else {
      this.$router.push({path: "/emeld/level/edit/" + this.data.id})
    }
    //do nothing
  }

}
