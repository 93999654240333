
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {FobiKlasseDTO} from "@/model/dto";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {FobiApi} from "@/services/FobiApi";
import {FobiKlasse} from "@/model/Fobi";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader, SettingsTooltip, KfzView}
})
export default class FobiKlasseView extends mixins<SimpleViewMixin<FobiKlasseDTO, FobiKlasseDTO>>(SimpleViewMixin, DataProps) {
  data: FobiKlasseDTO = new FobiKlasse()
  error: FobiKlasseDTO = new FobiKlasse()

  chainThemen: FobiKlasse[] = []

  async created() {
    await this.init(FobiKlasse,
       FobiApi.getKlassen,
        (a) => FobiApi.putKlasse(a),
        (b) => FobiApi.archivKlasse(b),
        true
    )

    this.chainThemen = await this.$store.dispatch('fobi/fetchKlassen')
    this.chainThemen = this.sort(this.chainThemen.filter(cat => (cat.orgUnit && cat.orgUnit.id != this.$store.getters.getLocation.id)))
    this.$root.$emit('loadingDone')
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
