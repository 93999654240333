import {apiClient} from './AbstractHTTPService'

const PATH = '/user/list/'

export default {

    login(payload) {
        return apiClient.post('/login/', payload)
    },

    sendPasswordForgotten(payload) {
        return apiClient.post('/user/passwordforgotten', payload)
    },

    passwordReset(payload) {
        return apiClient.put('/user/passwordforgotten', payload)
    },

    checkQrLogin(qr) {
        return apiClient.get('/login/qrlogin/' + qr + '/')
    },

    checkPinLogin(id) {
        return apiClient.get('/login/pinlogin/' + id + '/')
    }
}
