import {apiClient} from './AbstractHTTPService'

import Axios from "axios";
import {EntityStandortDTO, GegenstandListItemDTO, OrgUnitTreeDTO, StandortwechselDTO} from "@/model/dto";
import store from "@/store";
import {CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";


export abstract class StandortApi {
    private static usersAxios = Axios.create();

    static async getOrgUnitandorte(param: string): Promise<EntityStandortDTO[]> {
        const path = "/entitystandort/chain/" + param;

        const response = await apiClient.get(path);
        return response.data.data;
    }

    static async getStandorte(typ): Promise<EntityStandortDTO[]> {
        const response = await apiClient.get('/entitystandort/' + typ);
        return response.data.data;
    }

    static async getStandorteTree(typ): Promise<OrgUnitTreeDTO[]> {
        const response = await apiClient.get('/entitystandort/' + typ + "/tree");
        return response.data.data;
    }

    static async putStandort(q: EntityStandortDTO): Promise<EntityStandortDTO> {
        const response = await apiClient.put('/entitystandort/', q);

        store.dispatch("crudEvent", new CrudEvent(Event.entityStandortUpdate, response.data.data));
        return response.data;
    }

    static async archivStandort(id): Promise<any> {
        const response = await apiClient.delete('/entitystandort/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.entityStandortUpdate, response.data.data));
        return response.data;
    }

    static async getKfzLocationItems(kfz: number, type: string): Promise<GegenstandListItemDTO[]> {
        const path = "/entitystandort/kfzlocationitems/" + kfz + '/' + type;

        const response = await apiClient.get(path);
        return response.data.data;
    }

    static async changeStandort(q: StandortwechselDTO): Promise<any> {
        const response = await apiClient.post('/entitystandort/', q);
        return response.data;
    }
}