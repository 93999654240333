
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EinwirkzeitDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {Einwirkzeit} from "@/model/Hygiene";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {HygieneApi} from "@/services/HygieneApi";
import NumberForm from "@/components/NumberForm.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {NumberForm, ChainIconTooltipTableCell, SimpletableButtonHeader}
})
export default class HygieneEinwirkzeitView extends mixins<SimpleViewMixin<EinwirkzeitDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: EinwirkzeitDTO = new Einwirkzeit()
  error: GenericError = new GenericError()

  chainCategories: EinwirkzeitDTO[] = []

  async created() {
    await this.init(Einwirkzeit,
        HygieneApi.getEinwirkzeit,
        (a) => HygieneApi.putEinwirkzeit(a),
        (b) => HygieneApi.removeEinwirkzeit(b)
    )
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
