
import {Component, Prop} from 'vue-property-decorator';
import {FobiStatusDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import FileUpload from "@/components/FileUpload.vue";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import {mixins} from "vue-class-component";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {FileUpload, Multiselect},
})
export default class FobiUserComponent extends mixins(DropzoneMixin) {
  @Prop() private id: string;
  @Prop({default: true}) private editPermission;

  @Prop() value

  @Prop() private error: any;
  userList = []
  uploadKey = 0
  statusOpts: FobiStatusDTO[] = []

  async created() {
    this.statusOpts = await this.$store.dispatch("fobi/fetchStatus");
    this.userList = await this.$store.dispatch('user/fetchUser')
  }

  get workValue() {
    return this.value
  }

  set workValue(val) {
    this.uploadKey++
    this.$emit("input", val)
  }

}
