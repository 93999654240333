
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DesiInheritanceDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {DesiInheritance} from "@/model/Hygiene";
import {HygieneApi} from "@/services/HygieneApi";
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {OrgUnitSelector}
})
export default class HygieneInheritanceView extends mixins<SimpleViewMixin<DesiInheritanceDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: DesiInheritanceDTO = new DesiInheritance()
  error: GenericError = new GenericError()

  async created() {
    await this.init()
  }

  async init() {
    this.data = await HygieneApi.getDesiInheritance()
    this.$root.$emit('loadingDone')
  }

  selectedLocationChanged(selection) {
    this.data.selection = selection.map(i => ({id: i, name: ''}))
  }

  get selectedIds() {
    return [...this.data.selection.map(o => o.id), ...[this.$store.getters.getLocation.id]]
  }


  onSubmit(goBack: boolean) {
    const p = HygieneApi.putDesiInheritance(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data: DesiInheritance) => {
      this.init()
    })
  }

}
