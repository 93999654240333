
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {EwTragweiteBewertungDTO, GenericError, ReportDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import {EmeldApi} from "@/services/EmeldApi";

@Component({
  components: {
    PersonenkreisChooser,
    Multiselect
  }
})
export default class ReportProcessView extends mixins<GenericMixin<ReportDTO, GenericError>>(GenericMixin) {
  @Prop() value: ReportDTO;
  @Prop() error: GenericError;
  loadingComplete = true
  personenkreisOptions = []
  initProcess = false

  eskalieren = false
  eskalierenFix = false

  zustaendigkeitSave = null
  eskalationStufeSave = 0
  eskalation1 = null

  bewertungen = []
  ewTragweiteBewertung: EwTragweiteBewertungDTO = null


  async created() {
    this.$root.$emit('loadingStart')
    this.data = this.value
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.ewTragweiteBewertung = await this.$store.dispatch("emeld/fetchEwTragweiteBewertung")

    window.scrollTo(0, 0);
    await this.init()
    this.$root.$emit('loadingDone')
  }

  async init() {
    console.log("init!!!")
    if (!this.loadingComplete) {
      return;
    }
    this.loadingComplete = false

    this.zustaendigkeitSave = structuredClone(this.workingValue.zustaendigkeit)
    this.eskalationStufeSave = this.workingValue.eskalationsStufe
    this.calcBewertungen()
    EmeldApi.getLevelOuByLevelId(this.getMaxLevelId()).then(beLevel => {
      if (beLevel.data.eskalation) {
        this.eskalation1 = beLevel.data.eskalation
      }

      this.loadingComplete = true
    })
  }

  eskalierenChanged() {
    if (this.eskalieren) {
      this.eskalierenFix = false
      if (this.workingValue.eskalationsStufe == 0 && this.eskalation1) {
        this.workingValue.zustaendigkeit = this.eskalation1
        this.eskalierenFix = true
      } else {
        this.workingValue.zustaendigkeit = null
      }

      this.workingValue.eskalationsStufe = this.eskalationStufeSave + 1
    } else {
      this.workingValue.eskalationsStufe = this.eskalationStufeSave
      this.workingValue.zustaendigkeit = this.zustaendigkeitSave
    }
  }


  getMaxLevelId() {
    for (let i = 5; i >= 1; i--) {
      if (this.workingValue['level' + i]) return this.workingValue['level' + i].id;
    }

    return null
  }

  calcBewertungen() {
    this.bewertungen = []
    for (let i = 5; i >= 1; i--) {
      if (this.workingValue['level' + i]) {
        if (this.workingValue['level' + i].bewertungType) {
          this.bewertungen.push(this.workingValue['level' + i].bewertungType)
        }
      }
    }

    this.workingValue.tags.forEach(tag => {
      if (tag.bewertungType) {
        this.bewertungen.push(tag.bewertungType)
      }
    })
  }

  get ewRisikoOptions() {
    return this.ewTragweiteBewertung.eintrittswahrscheinlichkeiten.filter(o => o.type == 1).sort((a, b) => a.name.localeCompare(b.name))
  }

  get risikoTragweiteOptions() {
    return this.ewTragweiteBewertung.tragweite.filter(o => o.type == 1).sort((a, b) => a.name.localeCompare(b.name))
  }

  get bewertungOptions() {
    return this.ewTragweiteBewertung.bewertungen.sort((a, b) => a.name.localeCompare(b.name))
  }

  get workingValue() {
    return this.value;
  }

  set workingValue(v) {
    this.$emit("input", v);
  }
}
