
import {Component, Vue} from 'vue-property-decorator';

const DataProps = Vue.extend({
  props: {
    text: String,
    group: String,
    ouText: String
  }
})
@Component({
  components: {},
})
export default class PseudoTag extends DataProps {
}
