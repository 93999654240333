
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {KfzDTO} from "@/model/dto";
import KfzDataView from "@/views/kfz/KfzDataView.vue";
import {KfzEdit} from "@/model/Kfz";
import {KfzApi} from "@/services/KfzApi";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from "@/main";
import {Event} from "@/model/Constants";


@Component({
  components: {
    KfzDataView,
  },
  watch: {
  }
})
export default class KfzCreateView extends mixins<GenericMixin<KfzDTO, KfzDTO>>(GenericMixin) {
  data = new KfzEdit();
  error = new KfzEdit();
  errorTabs = []

  created() {
    this.init();
  }

  init = function () {
    this.data.rfid = { publicId: '', id: null}
    this.data.status = 1
    this.data.orgUnit  = this.$store.getters.getLocation
  }


  onSubmit(goBack: boolean) {
    this.errorTabs = []
    const p = KfzApi.updateKfz(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      let queryParam = {}
      if (this.$route.query.archive !== undefined) {
        queryParam = {archive: 1}
      }


      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send kfzCreated event')
        bus.$emit(Event.kfzCreated, new ObjectEvent(data.data.id, data.data));
        this.$router.push({path: "/kfz/edit/" + data.data.id, query: queryParam})
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/kfz/overview", query: queryParam})
  }

}
