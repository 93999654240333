
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DateTypDTO, FileCategoryDTO, GenericError, KfzCategoryDTO, KfzDateTypDTO, KfzResponseDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect/";
import {KfzDateTyp} from "@/model/Date";
import {DateApi} from "@/services/DateApi";
import NumberForm from "@/components/NumberForm.vue";
import PseudoTag from "@/components/PseudoTag.vue";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {
    ChainIconTooltipTableCell,
    LabelComponent, SimpletableButtonHeader, SettingsTooltip, KfzView, PseudoTag, NumberForm, Multiselect
  }
})
export default class KfzDateSettingsView extends mixins<SimpleViewMixin<KfzDateTyp, GenericError>>(SimpleViewMixin, DataProps) {
  data: KfzDateTypDTO = new KfzDateTyp()
  error: GenericError = new GenericError()

  showDateTypModal = false
  dateTypes: DateTypDTO[] = []
  orgUnitDateTypes: KfzDateTypDTO[] = []
  chainDateTypes: KfzDateTypDTO[] = []

  kfzOpts: KfzResponseDTO[] = []
  kfzCategoryOpts: KfzCategoryDTO[] = []
  fileCategories: FileCategoryDTO[] = []

  async created() {
    await this.init(KfzDateTyp,
        DateApi.getKfzDateTypesOu,
        (a) => DateApi.putKfzDateTypOu(a),
        (b) => DateApi.removeKfzDateTypOu(b))

    this.fileCategories = await this.$store.dispatch('kfz/fetchDateiKategorienKatalog');

    this.dateTypes = await this.$store.dispatch("kfz/fetchDateTypCatalog")
    this.orgUnitDateTypes = await DateApi.getKfzDateTypesOu()
    this.kfzOpts = await this.$store.dispatch("kfz/fetchKfz")
    this.kfzCategoryOpts = await this.$store.dispatch("kfz/fetchKategorien")
    this.chainDateTypes = await this.$store.dispatch("kfz/fetchDateTypesOu")
    this.$root.$emit('loadingDone')

  }

  handleNumber(event) {
    console.log(event)
  }

  get getDateTypes() {
    return this.dateTypes.sort((a, b) => a.name.localeCompare(b.name))
  }


  get getOrgUnitCategories() {
    return this.sort(this.orgUnitDateTypes)
  }

  get getChainCategories() {
    return this.sort(this.chainDateTypes
        .filter(dateType => dateType.orgUnit.id != this.$store.getters.getLocation.id))
  }

  sort(objects) {
    return objects ? objects.sort((a, b) => a.formatted.localeCompare(b.formatted)) : []
  }

  get archivTxt() {
    return "Löschen"
  }

}
