
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EwoGeraeteartDTO} from "@/model/dto";
import {EwoBaseDataGeneric} from "@/model/Ewmpg";
import {EwmpgApi} from "@/services/EwmpgApi";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";

const DataProps = Vue.extend({
  props: {}
})
@Component({
  components: {SimpletableButtonHeader}
})
export default class EwoGeraeteartView extends mixins<SimpleViewMixin<EwoGeraeteartDTO, EwoGeraeteartDTO>>(SimpleViewMixin, DataProps) {
  data: EwoGeraeteartDTO = new EwoBaseDataGeneric()
  error: EwoGeraeteartDTO = new EwoBaseDataGeneric()

  async created() {
    await this.init(EwoBaseDataGeneric, EwmpgApi.getGeraeteartList, (a) => EwmpgApi.putGeraeteart(a), (b) => EwmpgApi.removeGeraeteart(b))
    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
