
import {Component, Vue} from "vue-property-decorator";
import {CampusResultDTO} from "@/model/dto";
import {DateHelper} from "@/model/Date";
import {FobiApi} from "@/services/FobiApi";


@Component(
    {
      computed: {
        DateHelper() {
          return DateHelper
        }
      },
      components: {}
    }
)
export default class CampusOverviewTable extends Vue {
  data: CampusResultDTO = null;

  async created() {
    this.data = await FobiApi.getCampusOverview()
    this.$root.$emit('loadingDone')
  }
}
