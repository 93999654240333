import { render, staticRenderFns } from "./PortalView.vue?vue&type=template&id=6532aafb&scoped=true&"
import script from "./PortalView.vue?vue&type=script&lang=ts&"
export * from "./PortalView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6532aafb",
  null
  
)

export default component.exports