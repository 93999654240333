
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, ScopeDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {Scope} from "@/model/Hygiene";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {HygieneApi} from "@/services/HygieneApi";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader}
})
export default class HygieneScopeView extends mixins<SimpleViewMixin<ScopeDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: ScopeDTO = new Scope()
  error: GenericError = new GenericError()

  chainCategories: ScopeDTO[] = []

  async created() {
    await this.init(Scope,
        HygieneApi.getScopes,
        (a) => HygieneApi.putScope(a),
        (b) => HygieneApi.removeScope(b)
    )
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
