
import {Component, Vue} from 'vue-property-decorator';
import StartView from "@/template/StartView.vue";
import {ConstantsDTO} from "@/model/dto";

@Component({
  components: {StartView}
})
export default class FehlerAnregung extends Vue {
  constants: ConstantsDTO | null;

  created() {
    this.constants = this.$store.getters.getConstants;
  }
}
