
import {Component, Vue} from "vue-property-decorator";


@Component(
    {
      components: {},
    }
)
export default class FileLexTreeView extends Vue{
  data = null;
  error = null;

  treeDto = null;

}
