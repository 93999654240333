import { render, staticRenderFns } from "./VueEditor.vue?vue&type=template&id=265d5416&"
import script from "./VueEditor.vue?vue&type=script&lang=js&"
export * from "./VueEditor.vue?vue&type=script&lang=js&"
import style0 from "quill/dist/quill.snow.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "../assets/vue2-editor.scss?vue&type=style&index=1&prod&lang=scss&"
import style2 from "../assets/quill-better-table.css?vue&type=style&index=2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports