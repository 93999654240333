

import {Component, Prop} from 'vue-property-decorator';
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {ReportDTO} from "@/model/dto";
import {EmeldApi} from "@/services/EmeldApi";
import jquery from "jquery";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {mixins} from "vue-class-component";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import ReportNotizModal from "@/views/emeld/components/ReportNotizModal.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import GenericMixin from "@/views/GenericMixin.vue";
import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    }
  },
  components: {DatatableButtonHeader, ReportNotizModal},
  watch: {
    '$route'(val: Route) {
      if (val.name.includes("myreportstable")) {
        this.table.fixedHeader.adjust();
        this.table.fixedHeader.enable()
        this.$root.$emit('loadingDone');
      } else {
        this.table.fixedHeader.disable()
      }
      this.handleDatableRoute(true);
    }
  }
})
export default class EmeldReportTable extends mixins<SimpleViewMixin, GenericMixin<null, null>>(SimpleViewMixin, GenericMixin) {
  @Prop() list: ReportDTO[]
  files: any[] = []
  modalTitle = ''
  showNotizModal = false
  selectedItem: ReportDTO = null
  tableId = 'dTable_' + uuid();
  request = null
  table = null

  clb = () => {
    this.$root.$emit("loadingStart")
    this.reloadTable()
  }

  async created() {
    bus.$on(Event.emeldReportFilterChanged, this.clb)
  }

  mounted() {
    this.init()
  }

  getUrl() {
    this.request = this.$store.state.emeld.requestFilter.formDataToRequest()
    this.request.userId = this.$store.getters.getUser.id

    return '/emeld/report'
  }

  async init() {
    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    let table = jquery('#' + this.tableId).DataTable({

      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        contentType: 'application/json',
        data: function() {
          return JSON.stringify(self.request);
        },
        error: function(xhr, error, thrown) {
          console.error('Error loading data:', error, thrown);
        },
        dataSrc: function(response) {
          if (response && response.rows) {
            return response.rows;
          } else {
            console.warn('Unexpected response format');
            return [];
          }
        }
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      stateSave: false,
      responsive: false,
      rowId: 'id',
      columns: [
        {data: 'level1.name'},
        {data: 'level2.name'},
        {data: 'level3.name'},
        {data: 'orgUnit.name'},
        {
          data: null,
          class: '',
          render: function (data) {
            return DateHelper.parseDate(data.ereignisDatum)
          }
        },
        {data: 'status.name'},
        {
          data: null,
          class: 'text-center',
          render: function (data) {
            return `
                <button class="btn tableEditOrRemoveButton btn-open" data-id="${data.id}" title="Öffnen">
                    <i class="fas fa-pen"></i>
                </button>
                <button class="btn tableEditOrRemoveButton btn-note" data-id="${data.id}" title="Notizen">
                    <i class="fas fa-sticky-note"></i>
                </button>
                <button class="btn tableEditOrRemoveButton btn-files" data-id="${data.id}" title="Dateien">
                    <i class="fas fa-paperclip"></i>
                </button>`;
          }
        },
      ],
      select: true,
      order: [0, 'asc'],
      buttons: func.addDefaultButtons(),
      initComplete: function() {
        self.$root.$emit('loadingDone')
      }
    });
    self.table = table
    func.datatableInitComplete(table)

    jquery('#' + this.tableId).on('click', '.btn-open', function() {
      const id = jquery(this).data('id');
      self.reportSelected(id);
    });

    jquery('#' + this.tableId).on('click', '.btn-note', function() {
      const id = jquery(this).data('id');
      self.reportNotizSelected({ id });
    });

    jquery('#' + this.tableId + ' tbody').on('click', '.btn-files', function(e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.openFilesModal(item);
    });
  }

  destroyed() {
    this.table.fixedHeader.disable()

    this.unregisterEvents()
  }

  async openFilesModal(item) {
    try {
      await this.getFilesForSelectedTask(item.id)
      this.modalTitle = 'Dateien für ' + item.level1.name
      this.$bvModal.show('files-modal')
    } catch (error) {
      console.error('Error loading files:', error)
      await this.$alert("Fehler beim Laden der Dateien", "Fehler", "error")
    }
  }

  reportSelected(id) {
    this.$router.push('/emeld/myreports/edit/' + id)
  }

  reportNotizSelected(item) {
    this.selectedItem = item
    this.showNotizModal = true
  }

  cardView() {
    this.$router.push('/emeld/myreports')
  }

  async getFilesForSelectedTask(reportId) {
    const response = await EmeldApi.getReport(reportId)
    this.files = response.data.files
  }
}
