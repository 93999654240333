
import {Component, Prop, Watch} from 'vue-property-decorator';
import {
  KfzFileDTO,
  KfzOutsourcingDTO,
  KfzResponseDTO,
  OutsourcingCategoryDTO,
  OutsourcingDTO,
  ResponseDTO
} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import Multiselect from "@/libs/multiselect";
import {KfzOutsourcing} from "@/model/Outsourcing";
import {OutsourcingApi} from "@/services/OutsourcingApi";

@Component({
  components: {Multiselect},
})
export default class KfzOutsourcingEditModal extends mixins<GenericMixin<KfzOutsourcingDTO, KfzOutsourcingDTO>>(GenericMixin) {
  data: KfzOutsourcingDTO = new KfzOutsourcing()
  error: KfzOutsourcingDTO = new KfzOutsourcing()

  kfzList: KfzResponseDTO[] = []
  categories: OutsourcingCategoryDTO[] = []
  outsourcingOpts: OutsourcingDTO[] = []

  @Prop() private value!: KfzFileDTO;
  @Prop() private id!: string;

  init(newValue) {
    this.data = new KfzOutsourcing()

    if (newValue != null) {
      this.data = structuredClone(newValue)
    }
  }


  async created() {
    this.init(this.$props.value)
    this.categories = await this.$store.dispatch('outsourcing/fetchKategorien')
    this.kfzList = await this.$store.dispatch("kfz/fetchKfz")
    this.outsourcingOpts = await this.$store.dispatch('kfz/fetchOutsourcing')

  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

  close() {
    this.$bvModal.hide(this.$props.id)
  }


  save() {
    const p = OutsourcingApi.putKfzOutsourcing(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('input', this.data)
        this.$bvModal.hide(this.$props.id)
        this.$emit('change', this.data)
      }
    });
  }

  get kfzOptList() {
    return this.kfzList.sort((a, b) => a.licensePlate.localeCompare(b.licensePlate))
  }


  get categorieOpts() {
    return this.categories
        //.filter(cat => cat.typ == 1)
  }

}
