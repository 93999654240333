
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {FobiKlasseDTO, FobiThemaDTO, FobiThemaStundenDTO, PersonenkreisDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import GegenstandComponent from "@/views/einweisung/components/GegenstandComponent.vue";
import NumberForm from "@/components/NumberForm.vue";
import {FobiZeitraumThemaKlasse} from "@/model/Fobi";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";

@Component({
  components: {PersonenkreisChooser, NumberForm, GegenstandComponent, Multiselect}
})
export default class FobiZeitraumThemaKlasseStundenComponent extends Vue {
  @Prop() private id: string;
  @Prop() private value: FobiThemaStundenDTO;
  @Prop() private error: any;
  @Prop() private disabled: boolean;
  @Prop() isFirstInstance: boolean;
  @Prop({default: false}) noTn: boolean;

  data: FobiZeitraumThemaKlasse = null
  themen: FobiThemaDTO[] = []
  klassen: FobiKlasseDTO[] = []
  personenkreisOptions = []

  async created() {
    this.themen = await this.$store.dispatch("fobi/fetchThemen");
    this.klassen = await this.$store.dispatch("fobi/fetchKlassen");
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    let lftNr = 0
    this.personenkreisOptions
        .map((pK: PersonenkreisDTO) => {
          if (pK.temporary) {
            pK.id = (-1) * ++lftNr
          }
          return pK;
        })

    this.init(this.value)
  }

  init(newValue) {
    this.data = structuredClone(newValue)
  }

  changed() {
    this.$emit("input", this.data);
    this.$emit("change", this.data);
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

}
