
import {Component, Prop} from 'vue-property-decorator';
import DateForm from "@/components/DateForm.vue";
import ReportListServersideView from "@/views/emeld/ReportListServersideView.vue";
import {TaskReportMapDTO} from "@/model/dto";
import {ReportRequest} from "@/model/Emeld";
import Multiselect from "@/libs/multiselect";
import {mixins} from "vue-class-component";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";

@Component({
  components: {ReportListServersideView, DateForm, Multiselect}
})
export default class AdminDashboardEmeldStatus extends mixins<SimpleViewMixin<TaskReportMapDTO, TaskReportMapDTO>>(SimpleViewMixin) {
  @Prop() chain!: boolean;
  id = null;
  requestDTO = null
  levelTree = []
  levelKlasseOptions = []
  includeDone = false
  includeOpen = true

  async created() {
    this.levelTree = await this.$store.dispatch("emeld/fetchLevelTreeOu")
    this.levelKlasseOptions = await this.$store.dispatch("emeld/fetchLevelKlassen")
    this.requestDTO = new ReportRequest()
    this.requestDTO.includeOpen = this.includeOpen
    this.requestDTO.includeDone = this.includeDone
    this.requestDTO.dateFrom = null
    this.requestDTO.dateTo = null
    this.requestDTO.ouChain = this.chain
  }


  get level1Options() {
    return this.levelTree.map(node => node.dto.level).filter(l => !this.requestDTO.klasse || (this.requestDTO.klasse && l.klasse && l.klasse.id == this.requestDTO.klasse.id))
  }
}
