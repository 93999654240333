import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {FileApi} from "@/services/FileApi";

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        manuals: [],
        dateiKategorien: [],
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        SET_MANUALS(state: any, data: any) {
            state.manuals = data
        },
        SET_DATEI_KATEGORIEN(state: any, data: any) {
            state.dateiKategorien = data
        },
        resetState(state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {
        async fetchManuals({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.manuals && state.manuals.length > 0) {
                    resolve(state.manuals)
                } else {
                    FileApi.getManualFiles()
                        .then(data => {
                            commit('SET_MANUALS', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateiKategorien({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateiKategorien && state.dateiKategorien.length > 0) {
                    resolve(state.dateiKategorien)
                } else {
                    FileApi.getFileCategory('manual')
                        .then(data => {
                            commit('SET_DATEI_KATEGORIEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
    },
    getters: {},
    modules: {}
}
