<template>
  <b-modal ref="genderAlertModal" title="Genderhinweis" size="l">
    <template #modal-header>
      <h5 class="modal-title">Genderhinweis</h5>
    </template>
    <div>
      <p>
        In Rew.iS verwenden wir konsequent die maskuline Form. Dies hat rein praktische Gründe und soll keinesfalls eine Präferenz für ein bestimmtes Geschlecht zum Ausdruck bringen oder die Existenz anderer Geschlechter ignorieren.
        Die Verwendung der maskulinen Form hilft uns, die Klarheit und Eindeutigkeit unserer Texte und Codes zu gewährleisten. Unsere Erfahrung zeigt, dass eine inklusive Schreibweise oft den Lesefluss beeinträchtigt und in manchen Fällen zu Verwirrung führen kann. Darüber hinaus können bestimmte Sonderzeichen, die zur geschlechtsneutralen Schreibweise verwendet werden, zu technischen Problemen in unseren Codes führen.
        Wir möchten betonen, dass unsere Entscheidung, die maskuline Form zu verwenden, in keiner Weise die Bedeutung oder Wichtigkeit anderer Geschlechtsidentitäten schmälern soll. Vielmehr ist sie ein pragmatischer Ansatz, um sicherzustellen, dass unser System so effizient und nutzerfreundlich wie möglich funktioniert. Auch wenn wir die maskuline Form verwenden, sind alle Geschlechter gemeint.
        Wir danken Ihnen für Ihr Verständnis und schätzen Ihre Unterstützung, während wir ständig daran arbeiten, unser System zu verbessern und zugänglicher zu gestalten.
      </p>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="primary"
            class="float-right"
            @click="hideModal">
          Schließen
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  methods: {
    hideModal() {
      this.$refs.genderAlertModal.hide();
    },
    showModal() {
      this.$refs.genderAlertModal.show();
    }
  }
}
</script>
