
import {Component, Vue} from 'vue-property-decorator';

const DataProps = Vue.extend({
  props: {
    personenkreis:{}
  }
})
@Component({
  components: {},
})
export default class PersonenkreisTag extends DataProps {
}
