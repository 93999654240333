
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {FileLexCategoryDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {FileLexCategory} from "@/model/File";
import {FileApi} from "@/services/FileApi";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader}
})
export default class FileLexCategoryView extends mixins<SimpleViewMixin<FileLexCategoryDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: FileLexCategoryDTO = new FileLexCategory()
  error: GenericError = new GenericError()

  async created() {
    await this.init(FileLexCategory,
        FileApi.getFileLexCategory,
        (a) => FileApi.putFileLexCategory(a),
        (b) => FileApi.removeFileLexCategory(b)
    )

    this.$root.$emit("loadingDone")
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
