
import {Component, Vue} from "vue-property-decorator";
import {OrgUnitTreeDTO} from "@/model/dto";


@Component(
    {
      components: {},
    }
)
export default class EmeldLevel extends Vue{
  data = null;
  error = null;
  tree: OrgUnitTreeDTO[] = []

  async created() {
    //todo
  }


}
