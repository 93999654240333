
import UserRegisterService from '@/services/UserRegisterService'
import {Component, Vue} from "vue-property-decorator";
import {func} from '@/scripts/scripts'
import store from '@/store'
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {UserProfile} from "@/model/User";
import {UserPinDTO} from "@/model/dto";
import {UsersApi} from "@/services/UserApi";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import jquery from "jquery";

@Component({
  components: {},
})
export default class UserChangePinView extends mixins<GenericMixin<UserPinDTO, UserPinDTO>>(GenericMixin) {
  data: UserPinDTO = {
    pin: '',
    pinConfirm: '',
    id: store.getters.getUser.id
  }
  error: UserPinDTO = {
    pin: '',
    pinConfirm: '',
    id: 0
  }

  showPassword = false;

  onSubmit() {
    const p = UsersApi.updatePin(this.data);

    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        this.$router.push({ name: 'profile' });
      }
    });
  }



}
