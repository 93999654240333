import { render, staticRenderFns } from "./FobiKombiThemaDataView.vue?vue&type=template&id=6bad1d70&scoped=true&"
import script from "./FobiKombiThemaDataView.vue?vue&type=script&lang=ts&"
export * from "./FobiKombiThemaDataView.vue?vue&type=script&lang=ts&"
import style0 from "./FobiKombiThemaDataView.vue?vue&type=style&index=0&id=6bad1d70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bad1d70",
  null
  
)

export default component.exports