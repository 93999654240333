
import {Component, Prop, Watch} from "vue-property-decorator";
import {FileDTO, GenericError, KfzDateDTO, ResponseDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {DateApi} from "@/services/DateApi";
import {DateHelper, KfzDate} from "@/model/Date";
import LabelComponent from "@/components/LabelComponent.vue";
import DateForm from "@/components/DateForm.vue";
import FileUpload from "@/components/FileUpload.vue";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module;
    },
    DateHelper() {
      return DateHelper;
    }
  },
  components: { FileUpload, DateForm, LabelComponent }
})
export default class KfzDateEditModal extends mixins<GenericMixin<KfzDateDTO, GenericError>>(GenericMixin, DropzoneMixin) {
  data: KfzDateDTO = new KfzDate();
  error: GenericError = new GenericError();
  @Prop() private value!: GenericError;
  @Prop() private id;

  uploadKey = 0;
  fileSimple = true;
  fileField = "file";
  dateFiles: FileDTO[] = [];

  async created() {
    if (this.$props.value != null) {
      await this.init(this.$props.value);
    }
  }

  initInProgess = false;
  async init(newValue) {
    if (this.initInProgess) {
      return;
    }
    this.initInProgess = true;
    this.dateFiles = [];
    this.error = new GenericError();

    if (this.data != null) {
      if (newValue.dateTyp) {
        this.dateFiles = await DateApi.getFilesByKfzDate(newValue.id);
      }

      this.data = structuredClone(newValue);
    } else {
      this.data = new KfzDate();
    }

    this.initInProgess = false;
  }

  get getDateTypIntervall() {
    if (this.data.dateTyp && this.data.dateTyp.intervallTyp !== null) {
      if (this.data.dateTyp.intervallTyp === 0) {
        return "Timer-Variante: Datum des letzten Ereignisses (z.B. Prüfung)";
      } else if (this.data.dateTyp.intervallTyp === 1) {
        return "Vorwarn-Variante: Datum des nächsten Ereignisses (z.B. Prüfung)";
      }
    }
    return "";
  }

  @Watch("value")
  public watchValue(newValue) {
    this.init(newValue);
    this.uploadKey++;
  }

  @Watch("id")
  public watchId() {
    this.init(this.value);
  }

  close() {
    this.$bvModal.hide(this.id);
  }

  checkAndSave() {
    const dateType = this.data.dateTyp
    const dateToCheck = DateHelper.getSqlDate(this.data.date)

    if (dateType && dateType.intervallTyp === 1 && dateToCheck) {
      const selectedDate = new Date(dateToCheck); // Format: 'YYYY-MM-DD'
      const today = new Date();
      if (selectedDate <= today) {
        this.$confirm(
            'Achtung, dieser Termin ist eine Vorwarn-Termin. Sie haben ein Datum in der Gegenwart oder Vergangenheit erfasst. Bitte prüfen Sie das Datum',
            DateHelper.parseDate(dateToCheck),
            'question',
            {
              confirmButtonText: 'geprüft',
              cancelButtonText: 'prüfen'
            }
        ).then(() => {
          this.save();
        });
      } else {
        this.save();
      }
    } else {
      this.save();
    }
  }




  save() {
    const p = DateApi.putKfzDate(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit("input", this.data);
        this.close();
        this.$emit("change", this.data);
      }
    });
  }

  get workValue() {
    return this.data;
  }

  set workValue(val) {
    this.$emit("input", this.data);
  }
}
