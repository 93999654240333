

import {Component, Vue} from 'vue-property-decorator';
import AdminDashboardUserCard from "@/views/adminDashboard/components/AdminDashboardUserCard.vue";
import AdminDashboardKfzCard from "@/views/adminDashboard/components/AdminDashboardKfzCard.vue";
import AdminDashboardMedProdukteCard from "@/views/adminDashboard/components/AdminDashboardMedProdukteCard.vue";
import AdminDashboardMaterialCard from "@/views/adminDashboard/components/AdminDashboardMaterialCard.vue";
import AdminDashboardCheckinCard from "@/views/adminDashboard/components/AdminDashboardCheckinCard.vue";
import AdminDashboardFobiCard from "@/views/adminDashboard/components/AdminDashboardFobiCard.vue";
import AdminDashboardTaskCard from "@/views/adminDashboard/components/AdminDashboardTaskCard.vue";
import AdminDashboardHygieneCard from "@/views/adminDashboard/components/AdminDashboardHygieneCard.vue";
import AdminDashboardReportCard from "@/views/adminDashboard/components/AdminDashboardReportCard.vue";
import {Permission, PermissionModul} from "@/model/dto";
import AdminDashboardBtMCard from "@/views/adminDashboard/components/AdminDashboardBtMCard.vue";

@Component({
  components: {
    AdminDashboardBtMCard,
    AdminDashboardReportCard,
    AdminDashboardTaskCard,
    AdminDashboardFobiCard,
    AdminDashboardCheckinCard,
    AdminDashboardMaterialCard,
    AdminDashboardMedProdukteCard,
    AdminDashboardKfzCard,
    AdminDashboardUserCard,
    AdminDashboardHygieneCard
  }
})

export default class AdminDashboard extends Vue {
  chain = false

  created() {
    this.loadChainStatus();
  }

  get checkinPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Checkin, Permission.Overview)
  }

  get reportPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Overview)
  }

  get taskPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview)
  }

  get fobiPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Overview)
  }


  loadChainStatus() {
    const savedChain = localStorage.getItem('chainStatus');
    if (savedChain !== null) {
      this.chain = JSON.parse(savedChain);
    }
  }

  saveChainStatus() {
    localStorage.setItem('chainStatus', JSON.stringify(this.chain));
  }


  get taskKfzPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz_Task, Permission.Overview)
  }

  get taskMedProduktPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Task, Permission.Overview)
  }

  get taskMaterialPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Material_Task, Permission.Overview)
  }

  get desiPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Validate)
  }

  get hygPermission() {
    return this.taskKfzPermission || this.taskMedProduktPermission || this.taskMaterialPermission || this.desiPermission || this.taskPermission
  }


}
