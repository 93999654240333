
import {Component, Prop} from "vue-property-decorator";
import {EntityTaskDTO, GenericError, TaskDTO, TaskProcessDTO} from "@/model/dto";
import {TaskApi} from "@/services/TaskApi";
import {mixins} from "vue-class-component";
import {Task, TaskProcess, TaskRequest} from "@/model/Task";
import {ApiResponse} from "@/model/AbstractClasses";
import RewisChat from "@/components/RewisChat.vue";
import TaskChatView from "@/views/task/TaskChatView.vue";
import TaskModal from "@/views/task/components/TaskModal.vue";
import TaskChatModal from "@/views/task/components/TaskChatModal.vue";
import TaskNotizModal from "@/views/task/components/TaskNotizModal.vue";
import {DateHelper} from "@/model/Date";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import TaskCardView from "@/views/task/TaskCardView.vue";
import TaskTableView from "@/views/task/TaskTableView.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import {FormApi} from "@/services/FormApi";
import TaskNotizView from "@/views/task/TaskNotizView.vue";
import DesiCreateView from "@/views/hygiene/DesiCreateView.vue";
import {Module} from "@/model/Constants";

@Component(
    {
      computed: {
        Module() {
          return Module
        }
      },
      components: {
        DesiCreateView,
        TaskNotizView,
        FormInput,
        TaskTableView, TaskCardView, TaskNotizModal, TaskChatModal, TaskModal, TaskChatView, RewisChat
      }
    }
)
export default class CheckinTaskView extends mixins<SimpleViewMixin<TaskDTO, GenericError>>(SimpleViewMixin) {
  @Prop() id
  @Prop({default: 'card'}) modus
  @Prop({default: Task.STATUS_DONE}) newStatus
  showModal = false
  showProcessModal = false
  showNotizModal = false
  showHygieneModal = false
  isLoading = false

  cleanText = ""
  dateHelper = DateHelper

  selectedItem: TaskDTO | EntityTaskDTO = null;
  data: TaskProcessDTO = new TaskProcess();
  error: GenericError = new GenericError();

  notesVisible = false
  notesButtonText = "Notizen "
  noteCount: number | null = null;


  taskStatusOptions = []
  formRows = []
  loadingDone = false

  reloadFunction = () => {
    this.init();
  }

  async created() {
    await this.init();
    this.taskStatusOptions = await this.$store.dispatch("task/fetchStatus")
    this.$root.$emit('loadingDone')
  }




  async init() {
    this.initFilter()
    let request = new TaskRequest();
    request.archive = false

    const filter = {...this.$store.getters['task/getTaskRequestFilter']};
    if (filter.statusId) {
      request.statusId = filter.statusId
    }
    this.objects = await TaskApi.getCheckinTasks(this.id);
    this.loadingDone = true
  }


  openModal(item) {
    this.selectedItem = item
    this.showModal = true
  }

  notizen(item) {
    this.selectedItem = item
    this.showNotizModal = true
  }
  async notesCounter(id) {
    try {
      const notes = await TaskApi.getNotizen(id);
      this.noteCount = notes.length;
    } catch (error) {
      console.error("Fehler beim Laden der Notizen:", error);
    }
  }

  hygiene(item) {
    this.selectedItem = item
    this.cleanText = this.$sanitize(item.text);
    this.showHygieneModal = true
  }


  hygCreated(data) {
    this.selectedItem.status = data.task.status
    this.showHygieneModal = false
  }

  async process(item: TaskDTO) {
    const taskResposne = await TaskApi.getTask(item.id)

    this.selectedItem = taskResposne.data
    this.data = new TaskProcess()
    this.error = new GenericError()

    if (item.formVersion) {
      this.formRows = await FormApi.getFormRows({versions: [item.formVersion]});
    } else if (item.form) {
      this.formRows = await FormApi.getFormRows({forms: [item.form]});
    } else {
      this.formRows = []
    }


    this.data.id = item.id
    this.data.addFields = item.addFields
    this.data.statusId = (this.newStatus) ? this.newStatus : item.status?.id

    this.cleanText = this.$sanitize(item.text)
    this.showProcessModal = true
    this.notesCounter(item.id);
  }

  submit() {
    this.isLoading = true;

    const p = TaskApi.putMyTask(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        this.selectedItem = this.data.task;
        let obj = this.objects.find(item => item.id == this.data.id)
        if (obj) {
          obj.status = this.data.task.status
          obj.addFields = this.data.task.addFields
        }
        this.showProcessModal = false
      }
    })
  }


  sort(objects) {
    return objects.sort((a, b) => a.status.sortOrder < b.status.sortOrder ? -1 : 1)
  }

  get filteredStatusOptions() {
    let filtered = this.taskStatusOptions
        .filter(option => option.value != Task.STATUS_RE && option.value != 4 && option.value != 98) //storno, nichtabgerufen filtern

    if (this.selectedItem.typ == 2) { //Maßnahme
      filtered = filtered.filter(option => option.value != Task.STATUS_DONE)
    } else {
      filtered = filtered.filter(option => option.value != Task.STATUS_DONE_RE
          && option.value != Task.STATUS_DONE_WIRKSAM
          && option.value != Task.STATUS_DONE_UNWIRKSAM)
    }

    return filtered
  }

}
