
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import {MaterialApi} from "@/services/MaterialApi";
import {DateHelper} from "@/model/Date";
import {ListItemHelper} from "@/model/Shared";
import {MaterialAusgabeDTO, MaterialDTO} from "@/model/dto";

@Component(
    {
      components: {DatatableButtonHeader},
    }
)
export default class UserMaterialAusgabeUserView extends Vue {
  @Prop() private id;
  tableId = 'dTable_' + uuid();

  updateTable() {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
  }

  mounted() {
    this.$root.$emit('loadingStart')
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    const dateHelper = DateHelper

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/material/materialausgabe/user/' + self.id,
        type: 'GET'
      }),
      rowId: "id",
      order: [[1, 'asc']],
      "rowCallback": function (row, data) {
        if (data && data.hasChilds) {
          jquery('td:eq(0)', row).addClass('dt-control');
        }
      },
      stateSave: false,
      columns: [
        {
          className: 'not-export-col',
          orderable: false,
          data: null,
          title: '&#9654;',
          render: function (data, type) {
            return data ? "" : "";
          },
        },
        {
          class: '',
          data: 'id'
        },
        {
          data: null,
          render: function (data, type, row) {
            return ListItemHelper.getTag(data.material)
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return (data.parent ? data.parent.empfangConfirmed : data.empfangConfirmed)  ? "<i class='fas fa-2x fa-hexagon-check text-success' title='Bereits bestätigt'></i>" :
                (self.id == self.$store.getters.getUserId ? "<i class='far fa-2x fa-hexagon text-danger dt-check' title='Bestätigen'></i>" : "-"
            )
          }
        },

        {
          data: null,
          render: function (data, type, row) {
            return data.parent ? dateHelper.parseDate(data.parent.dateAusgabe) : dateHelper.parseDate(data.dateAusgabe)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return data.parent ? data.parent.ausgeber.formatted : data.ausgeber.formatted
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return !data.dateRuecknahme && data.parent ? dateHelper.parseDate(data.parent.dateRuecknahme) : dateHelper.parseDate(data.dateRuecknahme)
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            const ruecknehmer = data.parent ? data.parent.ruecknehmer : data.ruecknehmer
            return ruecknehmer ? ruecknehmer.formatted : ''
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            const verlust = data.parent ? data.parent.verlust : data.verlust
            return verlust ? 'X' : ''
          }
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit('loadingDone')
      },
      buttons: func.addDefaultButtons([], true),

    })


    func.datatableInitComplete(table, this.tableId)

    // Add event listener for opening and closing details
    jquery('#' + this.tableId).on('click', 'td.dt-control', (e) => {
      let tr = e.target.closest('tr');
      let row = jquery('#' + this.tableId).DataTable().row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        const p = openChildContent(row.data()).then((childRow) => {
          row.child(childRow).show();
        });
      }
    });

    jquery('#' + self.tableId).on('click', '.dt-check', function (e) {
      e.preventDefault()
      let tr = jquery(this).closest('tr')
      let row = jquery('#' + self.tableId).DataTable().row(tr)

      let ausgabeId = row.data().id

      self.$confirm('Wollen Sie den erhalt der Ausgabe-ID ' + ausgabeId + ' wirklich bestätigen?', '', 'question', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein'
      }).then(() => {
        MaterialApi.setMaterialAusgabeConfirmed(ausgabeId).then((d) => {
          self.updateTable()
        }).catch(() => {
          self.$alert("Zugriff verweigert!", "Fehler", "error");
        })
      });
    })

  }
}

async function openChildContent(d: MaterialDTO) {
  return new Promise(function (resolve, reject) {
    MaterialApi.getMaterialAusgabenByParent(d.id).then(childResponse => {
      if (childResponse.length == 0) {
        let str = '<table id="childtableNoItems" class="table-striped table">'
            + '<thead><tr>'
            + '<th>Keine Unterprodukte zugeordnet</th>';

        str += '</tr></thead><tbody>'
        return resolve(str);
      }


      let str = '<table id="childtable" class="table table-striped">'
          + '<thead><tr>'
          + '<th>ID</th><th>Material</th><th>Rückgabe</th><th class="text-center">Verlust</th>'
          + '</tr></thead><tbody>';

      childResponse.forEach(child => str += getChildRow(child, d.id));

      str += '</tbody></table>';
      return resolve(str);
    });
  });
}

function getChildRow(d: MaterialAusgabeDTO, parentRowId) {
  let str =
      '<tr class="childrow" id="' + d.id + '_' + parentRowId + '">' +
      '<td>' + d.id + '</td>' +
      '<td>' + ListItemHelper.getTag(d.material) + '</td>' +
      '<td>' + (d.dateRuecknahme ? moment(d.dateRuecknahme).format("DD.MM.YYYY") : 'siehe oben') + '</td>' +
      '<td class="text-center">' + (d.verlust || d.parent.verlust ? 'X' : '') + '</td>';
  str += '</tr>';
  return str;
}
