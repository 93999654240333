

import {Component, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import ManualNavComponent from "@/views/manual/components/ManualNavComponent.vue";

@Component(
    {
      components: {ManualNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)

export default class ManualView extends Vue {
  tableRoute = true

  handleRoute() {
    this.tableRoute = this.$route.meta['datatable'];
  }

  created() {
    this.handleRoute()
  }
}
