export const sortMixin = {
    props: {
        useCustomSort: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        sortedOptions() {
            // Eine Funktion, um das Attribut für die Sortierung zu bestimmen
            const getSortAttribute = (option) => {
                if (option.name) {
                    return option.name;
                }
                else if (option.text) {
                    return option.text;
                } else {
                    const keys = Object.keys(option);
                    const firstStringKey = keys.find(key => typeof option[key] === 'string' && option[key]);
                    return option[firstStringKey] || '';
                }
            };

            if (this.useCustomSort) {
                // Keine Sortierung, nur Filterung der Optionen
                return this.options.slice().filter(option => option && getSortAttribute(option));
            } else {
                // Standard-Sortierung für alle anderen Multiselect-Komponenten
                return this.options
                    .slice()
                    .filter(option => option && getSortAttribute(option))
                    .sort((a, b) => getSortAttribute(a).localeCompare(getSortAttribute(b)));
            }
        }
    }
};