
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {MaterialDateTypDTO} from "@/model/dto";
import MaterialDateView from "@/views/material/MaterialDateView.vue";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import PseudoTag from "@/components/PseudoTag.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import InstructionText from "@/components/InstructionText.vue";


@Component(
    {
      components: {ListItemComponent, PseudoTag, PersonenkreisTag, MaterialDateView, InstructionText},
    }
)
export default class MaterialDateList extends Vue {
  dateTypes: MaterialDateTypDTO[] = []
  showAllTags = false
  maxTags: number;
  selectedElem = null
  key = 0
  clb = null
  showModal = false

  async created() {
    this.maxTags = this.$store.getters['tags/getMaxTags']
    this.dateTypes = await this.$store.dispatch("material/fetchDateTypesOu")
    this.selectedElem = this.$store.state.material.filter.dateType
    this.clb = (dateType) => {
      this.selectedElem = dateType
      this.key++
    }
    bus.$on(Event.materialDateCategoryFilter, this.clb)
  }

  destroyed() {
    bus.$off(Event.materialDateCategoryFilter, this.clb)
  }

  get dateTypeTabs() {
    return this.dateTypes
  }

  openModal() {
    this.showModal = true
  }

}
