
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {UpsertFormDTO} from "@/model/dto";
import {FormApi} from "@/services/FormApi";
import {FormVersion, UpsertForm, UpsertFormError} from "@/model/FormVersion";
import FormVersionDataView from "@/views/form/FormVersionDataView.vue";
import FormDataView from "@/views/form/components/FormDataView.vue";
import FormInput from "@/views/form/components/FormInput.vue";


@Component({
  components: {
    FormInput,
    FormDataView,
    FormVersionDataView
  }
})
export default class FormVersionCreateView extends mixins<GenericMixin<UpsertFormDTO, UpsertFormError>>(GenericMixin) {
  data = new UpsertForm();
  error = new UpsertFormError();


  loadingComplete = false
  origin = null
  showTabs = false

  async created() {
    this.data.rows = []

    if (this.$route.params['formVersion']) {
      this.data = await FormVersion.copy(this.data, this.$route.params['formVersion'])
    }
    this.loadingComplete = true
    this.showTabs = true
  }

  onSubmit(goBack: boolean) {
    const p = FormApi.putFormVersion(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send formCreated event')
        bus.$emit(Event.formCreated, new ObjectEvent(data.data.id, data.data));

        /*if (this.origin) {
          this.origin.form = data.data.form
          bus.$emit(Event.formChanged, new ObjectEvent(this.fromVersion, this.origin));
        }*/
        this.goBack()
      }
    })
  }

  goBack() {
    let fromRoute = this.$store.state.route.from
    if (this.$route.params['fromRoute']) {
      fromRoute = this.$route.params['fromRoute']
    }
    const fromName =  fromRoute.name
    const from =  fromRoute.fullPath

    if (!(fromName && from)) {
      this.$router.push({name: "formtable"})
      return
    }

    if (fromName != this.$route.name && (fromName.includes("table") || fromName.includes("list"))) {
      this.$router.push(from)
    } else {
      this.$router.push({name: "formtable"})
    }
  }

}
