
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EwmpgFileCategoryDTO, EwoDTO, FileCategoryDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {EwmpgFileCategory} from "@/model/File";
import {FileApi} from "@/services/FileApi";
import NumberForm from "@/components/NumberForm.vue";
import Multiselect from "@/libs/multiselect";
import PseudoTag from "@/components/PseudoTag.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ListItemComponent, SimpletableButtonHeader, PseudoTag, NumberForm, Multiselect}
})
export default class EwoFileCategoryView extends mixins<SimpleViewMixin<EwmpgFileCategoryDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: EwmpgFileCategoryDTO = new EwmpgFileCategory()
  error: GenericError = new GenericError()
  categoryOpts: FileCategoryDTO[] = []
  ewoOpts: EwoDTO[] = []

  async created() {
    this.categoryOpts = await this.$store.dispatch("medprodukt/fetchDateiKategorienKatalog")
    this.ewoOpts = await this.$store.dispatch("medprodukt/fetchEwos")

    await this.init(EwmpgFileCategory, FileApi.getEwoFileCategories, (a) => FileApi.putEwoFileCategory(a), (b) => FileApi.removeEwoFileCategory(b))
    this.$root.$emit('loadingDone')
  }

  removeEwoSelection(ewoId) {
    this.data.ewo = this.data.ewo.filter(e => e.id != ewoId)
  }

  sort(objects) {
    return objects.sort((a, b) => a.fileCategory.name.localeCompare(b.fileCategory.name))
  }

  get archivTxt() {
    return "Löschen"
  }

}
