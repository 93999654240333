
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {MaterialArtDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import AdditionalFieldForm from "@/components/AdditionalFieldForm.vue";
import {AdditionalField} from "@/model/AbstractClasses";
import draggable from 'vuedraggable'


@Component({components: {AdditionalFieldForm, NumberForm, Multiselect, draggable}})
export default class MaterialKategorieDataView extends mixins<GenericMixin<MaterialArtDTO, MaterialArtDTO>>(GenericMixin) {
  @Prop() value: MaterialArtDTO;
  @Prop() error: MaterialArtDTO;
  data: MaterialArtDTO = this.$props.value;
  materialArtOpts: MaterialArtDTO[] = []

  async created() {
    this.materialArtOpts = await this.$store.dispatch("material/fetchMaterialArt")
    this.materialArtOpts = this.materialArtOpts.filter(opt => opt.klasse)
  }

  addField() {
    this.data.additionalFields.push(new AdditionalField());
    this.reorder()
  }

  removeField(idx) {
    this.data.additionalFields.splice(idx, 1)
  }

  onChange(event) {
    this.reorder()
  }

  reorder() {
    this.data.additionalFields.forEach((item, index) => (item.sortOrder = index))
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

}
