
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, Module} from "@/model/Constants";
import {FormApi} from "@/services/FormApi";
import {UpsertForm, UpsertFormError} from "@/model/FormVersion";
import FormVersionDataView from "@/views/form/FormVersionDataView.vue";
import FormVersionListView from "@/views/form/FormVersionListView.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import FormDataView from "@/views/form/components/FormDataView.vue";
import {Permission, PermissionModul} from "@/model/dto";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component({
  components: {
    FormDataView,
    FormInput,
    FormVersionListView,
    FormVersionDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class FormVersionEditView extends mixins<GenericMixin<UpsertForm, UpsertFormError>>(GenericMixin, DataProps) {
  data = new UpsertForm();
  error = new UpsertFormError();
  showTabs = false

  @Prop() form: number

  async created() {
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = FormApi.getFormVersion(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean, callback = null) {
    const p = FormApi.putFormVersion(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send formChanged event')
        bus.$emit(Event.formChanged, new ObjectEvent(data.data.id, data.data));
        // bus.$emit(Event.formChanged, new ObjectEvent(this.data.form.activeVersionId, {form: data.data.form}));

        if (callback) {
          callback()
        }

        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }

    let fromRoute = this.$store.state.route.from
    if (this.$route.params['fromRoute']) {
      fromRoute = this.$route.params['fromRoute']
    }
    const fromName = fromRoute.name
    const from = fromRoute.fullPath


    if (!(fromName && from)) {
      this.$router.push({name: "formtable"})
      return
    }


    if (from && fromName != this.$route.name &&
        (fromName.includes("table") || fromName.includes("list"))) {
      this.$router.push(from)
    } else {
      this.$router.push({name: "formtable", query: queryParam})
    }
  }

  remove() {
    bus.$emit(Event.formVersionRemoveRequest, new ObjectEvent(this.data.id, this.data));
    this.goBack()
  }

  release() {
    let activeVersion = this.data.form.activeVersionId
    let draftVersion = this.data.form.draftVersionId

    let text = 'Wollen Sie die Formular wirklich freigeben und verwenden? Es ist danach nicht mehr bearbeitbar.';
    if (this.data.released) {
      text = 'Wollen Sie dieses Formular als aktive Version verwenden?'
    }

    this.$confirm(text, '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      this.onSubmit(true, () => {
        FormApi.releaseFormVersion(this.data.id).then(
            (releaseData) => {
              if (releaseData.data) {
                if (activeVersion) bus.$emit(Event.formVersionArchived, new ObjectEvent(activeVersion, {
                  archive: true,
                  id: activeVersion
                }));

                if (draftVersion == releaseData.data.id) {
                  bus.$emit(Event.formChanged, new ObjectEvent(releaseData.data.id, releaseData.data));
                } else {
                  bus.$emit(Event.formCreated, new ObjectEvent(releaseData.data.id, releaseData.data));
                }
              }
            }
        )
      });
    })
  }

  newVersion() {
    this.$router.push({
      name: "formversioncreate", params: {
        form: this.$props.form,
        formVersion: "" + this.data.id,
        fromRoute: this.$store.state.route.from
      }
    })
  }

  copy() {
    this.$router.push({
      name: "formcreate", params: {
        formVersion: "" + this.data.id,
        fromRoute: this.$store.state.route.from
      }
    })
  }

  get archivTxt() {
    return this.data.form['archive'] ? "Reaktivieren" : "Archivieren"
  }

  archiv() {
    bus.$emit(Event.formArchiveRequest, new ObjectEvent(this.data.id, this.data));
    this.goBack()
  }

  get permissionModul() {
    if (!this.data.typ) return null;

    let typ = this.data.typ;

    if (typ == Module.KFZ) return PermissionModul.Kfz_Settings;
    else if (typ == Module.MEDPRODUKT) return PermissionModul.Medprodukt_Settings;
    else if (typ == Module.MATERIAL) return PermissionModul.Material_Settings;
    else if (typ == Module.EINSATZ) return PermissionModul.Einsatz_Settings;
    else if (typ == Module.REPORT) return PermissionModul.Emeld_Settings;
    else if (typ == Module.TASK) return PermissionModul.Task_Settings;

    return null;
  }

  get editPermission() {
    if (this.data.form.orgUnit.id != this.$store.getters.getLocation.id) {
      return false
    }

    const modul = this.permissionModul;
    if (!modul) {
      return false;
    }

    return this.$store.getters.hasPermission(modul, Permission.Formulare)
  }

  get releasePermission() {
    if (this.data.form.orgUnit.id != this.$store.getters.getLocation.id) {
      return false
    }

    const modul = this.permissionModul;
    if (!modul) {
      return false;
    }

    return this.$store.getters.hasPermission(modul, Permission.Formulare_Release)
  }
}
