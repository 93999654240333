
import {Component, Prop, Watch} from 'vue-property-decorator';
import {GenericError, ResponseDTO, TagebuchDTO, TagebuchEintragDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import Multiselect from '@/libs/multiselect'
import FileUpload from "@/components/FileUpload.vue";
import {TagebuchEintrag} from "@/model/Tagebuch";
import {TagebuchApi} from "@/services/TagebuchApi";
import DateForm from "@/components/DateForm.vue";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";

@Component({
  components: {PersonenkreisChooser, DateForm, FileUpload, Multiselect},
})
export default class CheckinBuchEintragModal extends mixins<GenericMixin<TagebuchEintragDTO, GenericError>>(GenericMixin) {
  @Prop() private value;
  @Prop() private id;

  data: TagebuchEintragDTO = new TagebuchEintrag()
  error: GenericError = new GenericError()


  existingFile = null;
  showModal = false

  personenkreisOptions = []
  tagebuchOptions = []
  tagebuch: TagebuchDTO = null;

  init(newValue) {
    this.data = newValue
    this.tagebuch = null
  }


  async created() {
    this.init(this.$props.value)
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
  }


  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

  close() {
    this.$bvModal.hide(this.$props.id)
  }


  save() {
    const p = TagebuchApi.putEintrag(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('input', this.data)
        this.$bvModal.hide(this.$props.id)
        this.$emit('change', this.data)
      }
    });
  }

  get disabled() {
    return this.data.id || this.data.id > 0
  }

}
