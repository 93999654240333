
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {FobiThemaDTO} from "@/model/dto";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {FobiApi} from "@/services/FobiApi";
import {FobiThema} from "@/model/Fobi";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader, SettingsTooltip, KfzView}
})
export default class FobiThemaView extends mixins<SimpleViewMixin<FobiThemaDTO, FobiThemaDTO>>(SimpleViewMixin, DataProps) {
  data: FobiThemaDTO = new FobiThema()
  error: FobiThemaDTO = new FobiThema()

  chainThemen: FobiThema[] = []

  async created() {
    await this.init(FobiThema,
        FobiApi.getThema,
        (a) => FobiApi.putThema(a),
        (b) => FobiApi.archiveThema(b),
        true
    )

    this.chainThemen = await this.$store.dispatch('fobi/fetchThemen')
    this.chainThemen = this.sort(this.chainThemen.filter(cat => (cat.orgUnit && cat.orgUnit.id != this.$store.getters.getLocation.id)))
    this.$root.$emit('loadingDone')
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
