
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, QualificationDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {Qualification} from "@/model/User";
import {UsersApi} from "@/services/UserApi";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import NumberForm from "@/components/NumberForm.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {NumberForm, ChainIconTooltipTableCell, LabelComponent, SimpletableButtonHeader, SettingsTooltip}
})
export default class QualificationCatalogView extends mixins<SimpleViewMixin<QualificationDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: QualificationDTO = new Qualification()
  error: GenericError = new GenericError()

  chainKatalog: QualificationDTO[] = []
  getParam = null

  async created() {

    await this.init(Qualification,
        UsersApi.getQualifications,
        (a) => UsersApi.putQualification(a),
        (b) => UsersApi.removeQualification(b)
    )

    if (this.$route.name.includes('kfz')) {
      this.objects = this.getObjects.filter(cat => cat.typ == 1)
    }

    this.chainKatalog = this.getObjects.filter(cat => (!cat.orgUnit || cat.orgUnit.id != this.$store.getters.getLocation.id))

    this.$root.$emit('loadingDone')
  }


  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

  get archivTxt() {
    return "Löschen"
  }


  get getOrgUnitObjects() {
    return this.getObjects.filter(cat => (cat.orgUnit && cat.orgUnit.id == this.$store.getters.getLocation.id))
  }

  get importRoute() {
    return this.$route.name.includes('import')
  }

}
