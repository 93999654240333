
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {Permission, PermissionModul, ReportDTO, TaskReportMapDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {TaskApi} from "@/services/TaskApi";
import {ReportRequest} from "@/model/Emeld";
import ReportListServersideView from "@/views/emeld/ReportListServersideView.vue";
import DateForm from "@/components/DateForm.vue";
import Multiselect from "@/libs/multiselect";
import {TaskReportMap} from "@/model/Task";
import TaskCreateView from "@/views/task/TaskCreateView.vue";
import {EmeldApi} from "@/services/EmeldApi";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {TaskCreateView, DateForm, ReportListServersideView, SimpletableButtonHeader, Multiselect}
})
export default class ReportTaskView extends mixins<SimpleViewMixin<TaskReportMapDTO, TaskReportMapDTO>>(SimpleViewMixin, DataProps) {
  @Prop() private report: ReportDTO;
  id = null;
  data: TaskReportMapDTO = new TaskReportMap()
  error: TaskReportMapDTO = new TaskReportMap()
  requestDTO = new ReportRequest()
  archivSubText = 'Es wird nur die Verknüpfung, nicht aber die Aufgabe selbst gelöscht.'

  async created() {
    this.$root.$emit('loadingStart')
    this.id = this.report.id
    await this.init(TaskReportMap,
        EmeldApi.getTasks,
        (a) => TaskApi.putReport(this.id, a),
        (b) => TaskApi.removeReport(b),
        this.id
    )
    this.$root.$emit('loadingDone')
  }

  taskCreated(task) {
    let dto = new TaskReportMap()
    dto.task = task
    dto.report = this.report;
    this.data = dto;
    this.saveObject();
  }

  edit(map) {
    this.$router.push("/task/edit/" + map.task.id)
  }

  get archivTxt() {
    return "löschen"
  }

  taskTyp(taskTypValue) {
    const taskTypes = this.$store.getters['task/getTaskTyp'];
    const typeObject = taskTypes.find(type => type.value === taskTypValue);
    return typeObject ? typeObject.text : '';
  }

  isPublic(taskPublic) {
    return taskPublic == 0 ? 'X' : ''
  }

  get editPermission() {
    return (this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update))
        || this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update_Responsible);
  }

  get taskOverviewPermission() {
    return (this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview));
  }

  get createPermission() {
    return (this.$store.getters.hasPermission(PermissionModul.Task, Permission.Create) && this.editPermission)
  }


}
