
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {GenericError, TaskTemplateDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import DateForm from "@/components/DateForm.vue";
import TaskDataView from "@/views/task/TaskDataView.vue";
import NumberForm from "@/components/NumberForm.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import TaskRhythmusComponent from "@/views/task/components/TaskRhythmusComponent.vue";
import {TaskZModus} from "@/model/Task";

@Component({
  computed: {
    TaskZModus() {
      return TaskZModus
    }
  },
  components: {
    TaskRhythmusComponent: TaskRhythmusComponent,
    LabelComponent,
    NumberForm,
    TaskDataView,
    DateForm, Multiselect
  }
})
export default class TaskTemplateDataView extends mixins<GenericMixin<TaskTemplateDTO, GenericError>>(GenericMixin) {
  @Prop() value: TaskTemplateDTO;
  @Prop() error: GenericError;
  @Prop({default: true}) private editPermission;

  data: TaskTemplateDTO = this.$props.value;
  loadingComplete = false


  async created() {
    window.scrollTo(0, 0);
    this.init()
  }


  async init() {
    this.loadingComplete = true
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }


}
