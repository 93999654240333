
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import moment from "moment";

import {GegenstandListItem, HistoryEvent} from "@/model/Shared";
import '@/scripts/dataTables_German'
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("einweisungtable")) {
            console.log('enable fixedheader')
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit("loadingDone")
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()
        }
      }
    }
)
export default class EinweisungListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false

  getUrl() {
    return '/ewmpg/einweisungen/' + ((this.archive) ? 'archive' : '');
  }

  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    this.registerEvents([Event.einweisungCreated, Event.einweisungArchived, Event.einweisungChanged]);

    var p = jquery(".dt-button-bar").last();
    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      stateSave: false,
      responsive: false,
      rowId: "id",
      columns: [
        {
          class: '',
          data: 'id'
        },
        {
          class: '',
          data: 'datum',
          render: function (data, type) {
            return data ? moment(data).format('DD.MM.YYYY') : "";
          },
        },
        {
          class: '',
          data: 'art'
        },
        {
          class: 'text-center',
          data: 'multiplikator',
          render: function (data, type) {
            return data ? "X" : "";
          },
        },
        {
          class: 'cw-400',
          data: 'gegenstaende',
          render: function (data, type) {
            let arr = []

            data.forEach(item => {
              if (item.kfz) {
                arr.push(new GegenstandListItem(item.kfz))
              } else if (item.medProdukt) {
                arr.push(new GegenstandListItem(item.medProdukt))
              } else if (item.material) {
                arr.push(new GegenstandListItem(item.material))
              } else if (item.kfzCategory) {
                arr.push(new GegenstandListItem(item.kfzCategory))
              } else if (item.materialKlasse) {
                arr.push(new GegenstandListItem(item.materialKlasse))
              }
            });

            return arr.map(a => a.text).join("; <br>")
          },
        },
        {
          class: 'cw-200',
          data: 'gegenstaende',
          render: function (data, type) {
            return data.map(a => a.einweiser ? a.einweiser.formattedShort : (a.einweiserTxt ? a.einweiserTxt : '?')).join("; <br>")
          },
        },
        {
          class: '',
          data: 'user',
          render: function (data, type) {
            return data.map(a => a.user ? a.user.formattedShort : '?').join("; ")
          },
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons)
    })
    self.table = table
    self.initContextMenu(contextMenuButtons)
    func.datatableInitComplete(table)


    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Einweisung, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Einweisung, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Einweisung, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/ewmpg/einweisungen', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/ewmpg/einweisungen', tr.attr('id')))
          }
        }, contextMenuButtons)
  }


  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/einweisung/create/", query: queryParam})
  }

  edit(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.Overview)) {
      return;
    }

    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/einweisung/edit/" + id, query: queryParam})
  }

  destroyed() {
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
    this.unregisterEvents()
  }

  archiv(data) {
    bus.$emit(Event.einweisungArchiveRequest, new ObjectEvent(data.id, data));
  }

}
