import {
    AdditionalFieldResultDTO,
    ChatDTO,
    DesiRoutineDTO,
    EntityFileDTO,
    EntityTaskDTO,
    EntityTaskFormRequestDTO,
    EntityTaskTemplateDTO,
    EntityTaskTemplateObjectDTO,
    EntityTtoCheckinTaskDTO,
    EwoGeraeteartDTO,
    FormDTO,
    FormRowDTO,
    FormVersionDTO,
    KfzCategoryDTO,
    KfzResponseDTO,
    MaterialArtDTO,
    MaterialDTO,
    MedProduktDTO,
    OrgUnitDTO,
    OrgUnitMerkmalDTO,
    PersonenkreisDTO,
    ReportDTO,
    ScopeDTO,
    TaskChatDTO,
    TaskDTO,
    TaskFormRequestDTO,
    TaskNotizDTO,
    TaskProcessDTO,
    TaskReportMapDTO,
    TaskRequestDTO,
    TaskStatusDTO,
    TaskStornoDTO,
    TaskTemplateDTO,
    UserResponseDTO
} from "@/model/dto";
import moment from "moment";
import {DateHelper} from "@/model/Date";
import {AbstractClass, AbstractEntityTaskFormRequest} from "@/model/AbstractClasses";
import {ReportRequest} from "@/model/Emeld";
import {EinsatzRequest} from "@/model/Einsatz";

export class EntityTaskTemplateModus {
    static readonly TEMPLATE = 'template'
    static readonly ENTITY = 'entity'

    static readonly KFZ_TASK = 'kfztask'
    static readonly MATERIAL_TASK = 'materialtask'
    static readonly MEDPRODUKT_TASK = 'medprodukttask'
    static readonly EWO_TASK = 'ewotask'

}

export class TaskWeitergabeModus {
    static readonly KEINE = 0
    static readonly AKTIV = 1
    static readonly DIENST = 2
}


export class TaskZModus {
    static readonly EINE_PERSON = 0
    static readonly GRUPPE = 1
    static readonly ALLE = 2
    static readonly SCHICHT = 3
    static readonly CHECKIN = 4
    static readonly MANUELL = 5
    static readonly SCHICHT_ALLE = 6
}

export class Task extends AbstractClass implements TaskDTO {
    static readonly STATUS_DONE = 2
    static readonly STATUS_RE = 8

    static readonly STATUS_DONE_RE = 9
    static readonly STATUS_DONE_WIRKSAM = 10
    static readonly STATUS_DONE_UNWIRKSAM = 11

    static readonly TASK_TYP_LOGIN = 21
    static readonly TASK_CAT_HYGIENE = 1

    archive: boolean;
    betroffen: PersonenkreisDTO[];
    chance: string | null;
    createTS: string | null;
    faelligkeit: string | null;
    files: EntityFileDTO[] = [];
    gueltigAb: string | null;
    gueltigBis: string | null;
    massnahme: boolean | null;
    master: TaskDTO | null;
    orgUnit: OrgUnitDTO | null;
    orgUnits: OrgUnitDTO[];
    passOnChilds: boolean;
    public: boolean | null;
    quelle: string | null;
    risiken: string | null;
    status: TaskStatusDTO | null;
    storno: boolean | null;
    template: boolean | null;
    text: string | null;
    title: string | null;
    typ: number | null;
    typText: string | null;
    verantwortlich: PersonenkreisDTO[];
    zModus: number | null;
    zustaendig: PersonenkreisDTO[];
    addFields: AdditionalFieldResultDTO[] = [];
    rows: FormRowDTO[];
    form: FormDTO | null;
    weitergabeModus: number | null = TaskWeitergabeModus.KEINE;
    user: UserResponseDTO | null;
    scopes: ScopeDTO[] = [];
    desiRoutine: DesiRoutineDTO | null;
    kategorie: number | null = 0;
    alternativeDaysBefore: number | null = null;
    alternativeDesiAllowed: number | null = null;
    zurueckBis: string | null;
    formVersion: FormVersionDTO | null;
}

export class TaskTemplate extends Task implements TaskTemplateDTO {
    rrule_bymonth: string | null;
    rrule_bymonthday: number | null;
    rrule_bysetpos: string | null;
    rrule_byweekday: string | null;
    rrule_dynamik: string | null;
    rrule_freq: string | null;
    rrule_interval: number | null;
    rrule_daytype: string | null;
    rrule_daysBefore: number | null;
    rrule_lastFaellig: string | null;
    rrule_lastGen: string | null;
    rrule_nextFaellig: string | null;
    rrule_nextGen: string | null;
    rrule_prevFaellig: string | null;
    rrule_prevGen: string | null;
    rrule_text: string | null;
    ouMerkmals: OrgUnitMerkmalDTO[];
}

export class TaskRequest extends AbstractClass implements TaskRequestDTO {
    archive: boolean;
    masterId: number | null;
    ouId: number | null;
    statusId: number | null;
    typId: number | null;
    userId: number | null;
    template: boolean;
    onlyPublic: boolean;
    countOnly: boolean | null;
    groupCount: boolean | null;
    hygieneOnly: boolean | null;
    ouChain: boolean;
    taskId: number | null;
    kategorie: number | null;
}

export class TaskProcess extends AbstractClass implements TaskProcessDTO {

    task: TaskDTO | null;
    text: string | null;
    statusId: number | null;
    addFields: AdditionalFieldResultDTO[] = [];
    rows: FormRowDTO[];
    zurueckBis: string | null;
}

export class TaskChat extends AbstractClass implements TaskChatDTO {

    orgUnits: OrgUnitDTO[];
    personenkreis: PersonenkreisDTO[];
    taskId: number | null;
    title: string | null;
    chat: ChatDTO | null;
}

export class TaskNotiz extends AbstractClass implements TaskNotizDTO {
    createTS: string | null;
    creator: UserResponseDTO | null;

    taskId: number | null;
    text: string | null;
}

export class TaskReportMap extends AbstractClass implements TaskReportMapDTO {

    report: ReportDTO | null;
    task: TaskDTO | null;
}

export class EntityTaskTemplate extends AbstractClass implements EntityTaskTemplateDTO {
    archive: boolean;
    files: EntityFileDTO[] = [];

    modul: number | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;
    text: string | null;
    title: string | null;
    form: FormDTO | null;
    entityTasks: EntityTaskTemplateObjectDTO[] = [];
    scopes: ScopeDTO[] = [];
    desiRoutine: DesiRoutineDTO | null;
    kategorie: number | null = 0;
    alternativeDaysBefore: number | null = 0;
    alternativeDesiAllowed: number | null = 0;
}

export class EntityTaskTemplateObject extends TaskTemplate implements EntityTaskTemplateObjectDTO {
    kfz: KfzResponseDTO | null;
    checkinBezug: boolean;

    constructor() {
        super();
        this.zustaendig = []
        this.files = []
        this.zModus = 0
        this.template = true
    }

    medProduktArt: EwoGeraeteartDTO;
    materialArt: MaterialArtDTO;

    material: MaterialDTO | null;
    medProdukt: MedProduktDTO | null;
    kfzCategory: KfzCategoryDTO | null;


}

export class EntityTask extends Task implements EntityTaskDTO {
    kfz: KfzResponseDTO | null;
    checkinBezug: boolean | null;

    constructor() {
        super();
        this.zustaendig = []
        this.files = []
        this.zModus = 0
        this.gueltigAb = moment().format(moment.HTML5_FMT.DATE);
        this.faelligkeit = moment().format(moment.HTML5_FMT.DATE);
    }

    material: MaterialDTO | null;
    medProdukt: MedProduktDTO | null;

}

export class TaskFormRequet extends AbstractEntityTaskFormRequest implements  TaskFormRequestDTO {
    formId: number | null;
    masterId: number | null;
}

export class TaskStorno extends AbstractClass implements TaskStornoDTO {
    grund: string | null;
}

export class EntityTtoCheckinTask extends AbstractClass implements EntityTtoCheckinTaskDTO {
    checkinId: number | null;
    entityTto: EntityTaskTemplateObjectDTO | null;


    constructor(checkinId: number | null, entityTto: EntityTaskTemplateObjectDTO | null) {
        super();
        this.checkinId = checkinId;
        this.entityTto = entityTto;
    }

    medProdukt: MedProduktDTO;
    material: MaterialDTO;
    kfz: KfzResponseDTO;
}

export class EntityTaskFormRequest extends AbstractEntityTaskFormRequest implements EntityTaskFormRequestDTO {
    anyKfz: boolean | null = false;

    modulId: number = null;
    emeldLevelId: number;
    einsatzVorlageId: number;
    formId: number;


    constructor(statusId = null) {
        super()
        this.dateFrom = moment().subtract(2, 'weeks').format(moment.HTML5_FMT.DATE);
        this.dateTo = moment().format(moment.HTML5_FMT.DATE);
        this.statusId = statusId;
    }

    anyMedProdukt: boolean;
    anyMaterial: boolean;

    hygieneOnly: boolean;
    statusId: number;
    ouChain: boolean;


    formDataToRequest(self: any = new EntityTaskFormRequest()) {
        self.dateFrom = DateHelper.getSqlDate(this.dateFrom)
        self.dateTo = DateHelper.getSqlDate(this.dateTo)
        self.kfzId = this.kfzId
        self.medProduktId = this.medProduktId
        self.materialId = this.materialId

        self.hygieneOnly = this.hygieneOnly
        self.statusId = this.statusId
        self.ouChain = this.ouChain

        self.archive = this.archive

        if (self instanceof EntityTaskFormRequest) {
            self.anyKfz = this.anyKfz
            self.anyMedProdukt = this.anyMedProdukt
            self.anyMaterial = this.anyMaterial

            self.modulId = this.modulId
            self.emeldLevelId = this.emeldLevelId
            self.einsatzVorlageId = this.einsatzVorlageId
            self.formId = this.formId
        } else if (self instanceof ReportRequest) {
            self.levelId = this.emeldLevelId
        }  else if (self instanceof EinsatzRequest) {
            self.vorlageId = this.einsatzVorlageId
        }  else if (self instanceof TaskFormRequet) {
            self.formId = this.formId
        }


        return self
    }
}