
import {Component, Vue} from "vue-property-decorator";
import OutsourcingNavComponent from "@/views/outsourcing/components/OutsourcingNavComponent.vue";
import {Route} from "vue-router";
import {Permission, PermissionModul} from "@/model/dto";

@Component(
    {
      components: {OutsourcingNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class OutsourcingView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'outsourcing' && this.$store.getters.hasPermission(PermissionModul.Outsourcing, Permission.Overview)) {
      this.$router.push("/outsourcing/overview")
    }
  }

  created() {
    this.handleRoute(this.$router.currentRoute)
  }
}
