import {
    AdditionalFieldDTO,
    AdditionalFieldResultDTO,
    EntityStandortDTO,
    EwoHerstellerDTO,
    FileDTO,
    MaterialArtDTO,
    MaterialAusgabeConfirmedDTO,
    MaterialAusgabeDTO,
    MaterialDateTypDTO,
    MaterialDTO,
    MaterialRequestDTO,
    MedProduktQrCodeDTO,
    OrgUnitDTO,
    UserResponseDTO
} from "@/model/dto";
import {AbstractClass, AbstractListItem, AdditionalField} from "@/model/AbstractClasses";


export class MaterialArt extends AbstractListItem implements MaterialArtDTO {
    additionalFields: AdditionalFieldDTO[];
    archive: boolean;
    name: string | null;
    typField: AdditionalFieldDTO = new AdditionalField();
    klasse: boolean | null = false;
    klassen: MaterialArtDTO[];
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;
    inEinweisung: boolean | null;
    dateTypes: MaterialDateTypDTO[];
}


export class Material extends AbstractListItem implements MaterialDTO {
    grandStandort: EntityStandortDTO;
    einweisungPflicht: boolean;
    primStandort: EntityStandortDTO;
    locationText: string;
    additionalInfos: string;
    klassen: MaterialArtDTO[];
    additionalFieldResults: AdditionalFieldResultDTO[];
    anschaffungsDatum: string | null;
    archive: boolean;
    art: MaterialArtDTO | null;
    childs: MaterialDTO[];
    inventarNummer: string | null;
    kommentar: string | null;
    location: EntityStandortDTO | null;
    name: string | null;
    orgUnit: OrgUnitDTO;
    parent: MaterialDTO | null;
    profilePhoto: FileDTO | null;
    qrCode: MedProduktQrCodeDTO | null;
    serienNummer: string | null;
    temporary: boolean | null;
    typ: AdditionalFieldResultDTO | null;
    toString: string;
    medProduktStandort = false;
    materialStandort = false;
    investNummer: string | null;
    modell: string | null;
    hersteller: EwoHerstellerDTO | null;
    ausgegeben: boolean | null;
}

export class MaterialRequest extends AbstractClass implements MaterialRequestDTO {
    archive: boolean | null = false;
    materialStandortFilter: boolean | null = false;
    medProduktStandortFilter: boolean | null = false;
    onlyIds: boolean | null = false;
    withChilds: boolean | null = true;
    ouId: number | null;
   
    materialArtId_arr: [];
    materialKlasseId_arr: [];
    parentId: number | null;
    includeChildOus: boolean | null = false;
    ouIds: [] | null;
}

export class MaterialAusgabe extends AbstractClass implements MaterialAusgabeDTO {
    ausgeber: UserResponseDTO | null;
    dateAusgabe: string | null;
    dateRuecknahme: string | null;
    empfaenger: UserResponseDTO | null;
    empfangConfirmed: boolean | null;
    hasChilds: boolean | null;
   
    kommentar: string | null;
    material: MaterialDTO | null;
    parent: MaterialAusgabeDTO | null;
    ruecknehmer: UserResponseDTO | null;
    verlust: boolean | null;
    empfaengerText: string | null;
    location: EntityStandortDTO | null;
    locationText: string | null;
}

export class MaterialAusgabeConfirmed extends AbstractClass implements MaterialAusgabeConfirmedDTO {
    empfangConfirmed: boolean | null;
   
}