
import {Component, Vue} from "vue-property-decorator";
import {EntityFile, ExistingFiles, RewisFile} from "@/model/AbstractClasses";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {v4 as uuid} from 'uuid';

@Component
export default class DropzoneMixin extends Vue {
  fileField = 'files'
  dropZoneUuid = uuid()
  fileSimple = false


  fileUploadSuccess(d) {
    let file = new RewisFile(d);



    if (this.fileSimple) {
      this.workValue[this.fileField] = file
    } else {
      let nachweis = new EntityFile()
      nachweis.file = file
      this.workValue[this.fileField].push(nachweis)
    }
  }

  fileDeleted(d) {
    if (this.fileSimple) {
      this.workValue[this.fileField] = null
    } else {
      this.workValue[this.fileField] = this.workValue[this.fileField].filter(nachweis => (nachweis.id != d.file.foreignId))
    }
  }

  fileRemoved(d) {
    if (this.fileSimple) {
      this.workValue[this.fileField] = null
    } else {
      this.workValue[this.fileField] = this.workValue[this.fileField].filter(nachweis => !nachweis.file.identifier || (nachweis.file.identifier != d.id))
    }
  }

  get existingFiles() {
    if (this.fileSimple) {
      let tmpArr = []

      if (this.workValue[this.fileField]) {
        tmpArr = [this.workValue[this.fileField]].filter(a => a != null)
      }

      return new ExistingFiles(tmpArr.map(n => {
        n["foreignId"] = n.id
        return n
      }));
    } else {
      return new ExistingFiles(this.workValue[this.fileField].map(n => {
        n.file["foreignId"] = n.id
        return n.file
      }));
    }
  }

  changeFile(id, uuid) {
    if (this.fileSimple) return;

    const idx = this.workValue[this.fileField].findIndex(file => file.file.identifier == id || (file.file.id == id))
    this.$prompt("Beschreibung", this.workValue[this.fileField][idx].comment).then((r) => {
      this.workValue[this.fileField][idx].comment = r
      this.workValue[this.fileField][idx].file.comment = r
      bus.$emit(Event.uploadDescription, {
        uuid: this.dropZoneUuid,
        files: this.workValue[this.fileField].map(f => f.file)
      })
    }).catch(e => {
      //nothing
    });
  }

  downloadFileById(id, module = null) {
    return this.download(id, module)
  }


}
