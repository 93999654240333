import {
    AbstractDTO,
    AbstractGegenstandDTO,
    EinweisungConfigDTO,
    EinweisungDTO,
    EinweisungFileDTO,
    EinweisungGegenstandDTO,
    EinweisungOverviewRequestDTO,
    EinweisungUserDTO,
    FileDTO,
    KfzCategoryDTO,
    KfzDTO,
    MaterialArtDTO,
    MaterialDTO,
    MedProduktDTO,
    OrgUnitDTO,
    PersonenkreisDTO,
    QualificationDTO,
    UserResponseDTO
} from "@/model/dto";
import {AbstractClass, AbstractListItem} from "@/model/AbstractClasses";


export class Einweisung extends AbstractListItem  implements EinweisungDTO {
    datum: string | null;
    gegenstaende: EinweisungGegenstandDTO[];
    multiplikator: boolean;
    nachweis: FileDTO | null;
    user: EinweisungUserDTO[];
    archive: boolean | null;
    art: string | null;
    nachweise: EinweisungFileDTO[];
    orgUnit: OrgUnitDTO | null;
    locked: boolean | null;
}

export class EinweisungConfig extends AbstractClass  implements EinweisungConfigDTO {
    gegenstaende: AbstractGegenstandDTO[];
    name: string | null;
    orgUnit: OrgUnitDTO;
    passOnChilds: boolean;
    personenkeis: PersonenkreisDTO[];
    archive: boolean | null;
    requestDto: EinweisungOverviewRequestDTO | null;
    optionDto: AbstractDTO | null;
}


export class EinweisungGegenstand extends AbstractClass  implements EinweisungGegenstandDTO {
    confirmed: boolean;
    einweiser: UserResponseDTO | null;
    einweiserTxt: string | null;
    kfz: KfzDTO | null;
    material: MaterialDTO | null;
    medProdukt: MedProduktDTO | null;
    kfzCategory: KfzCategoryDTO | null;
    materialKlasse: MaterialArtDTO | null;
    qualifikation: QualificationDTO | null;
}

export class EinweisungConfigGegenstand extends AbstractClass  implements AbstractGegenstandDTO {
    kfz: KfzDTO | null;
    material: MaterialDTO | null;
    medProdukt: MedProduktDTO | null;
    kfzCategory: KfzCategoryDTO | null;
    materialKlasse: MaterialArtDTO | null;
    qualifikation: QualificationDTO | null;
}

export class EinweisungFile extends AbstractClass  implements EinweisungFileDTO {
    file: FileDTO | null;
    frontendRef: number | null;
    comment: string | null;
}

export class EinweisungUser  extends AbstractClass implements EinweisungUserDTO {
    confirmed: boolean;
    user: UserResponseDTO | null;


    constructor(user: UserResponseDTO | null) {
        super();
        this.user = user;
        this.confirmed = false;
    }
}

export class EinweisungOverviewRequest extends AbstractClass  implements EinweisungOverviewRequestDTO {
    configDTO: EinweisungConfigDTO | null;
    kfzId: number | null;
    medProduktId: number | null;
    materialId: number | null;
    ewoId: number | null;
    orgUnitId: number | null;
    standortId: number | null;
}

