import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {CrudEvent} from "@/model/AbstractClasses";
import {EmeldApi} from "@/services/EmeldApi";
import {ReportRequest} from "@/model/Emeld";
import {Event} from "@/model/Constants";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        requestFilter: new ReportRequest(),
        emeldStatus: [],
        klassen: [],
        levels: [],
        levelTreeOu: [],
        bewertungen: null,
        bewertungType: [
            {text: 'Keine', value: 0},
            {text: 'Risiko-Bewertung', value: 1},
            {text: 'Chancen-Bewertung', value: 2},
            {text: 'Risiko- und Chancen-Bewertung', value: 3}

        ],
        doneUndoneOptions: [
            {text: 'Nur offene', value: 0},
            {text: 'Nur erledigte', value: 1},
            {text: 'Alle', value: 2}
        ]
    },
    mutations: {
        SET_REQUEST_FILTER(state: any, data: any) {
            state.requestFilter = data
        },
        SET_STATUS(state: any, data: any) {
            state.emeldStatus = data
        },
        SET_KLASSEN(state: any, data: any) {
            state.klassen = data
        },
        SET_LEVELS(state: any, data: any) {
            state.levels = data
        },
        SET_LEVEL_TREE_OU(state: any, data: any) {
            state.levelTreeOu = data
        },
        SET_BEWERTUNGEN(state: any, data: any) {
            state.bewertungen = data
        },
        resetLocationState(state) {
            state.levels = []
            state.levelTreeOu = []
        },
    },
    actions: {
        emeldRequestFilterUpdate({commit, state}, value: any) {
            commit('SET_REQUEST_FILTER', value)
        },
        async fetchStatus({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.emeldStatus && state.emeldStatus.length > 0) {
                    resolve(state.emeldStatus)
                } else {
                    EmeldApi.getStatus()
                        .then(data => {
                            commit('SET_STATUS', data)
                            resolve(state.emeldStatus)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchLevelKlassen({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.klassen && state.klassen.length > 0) {
                    resolve(state.klassen)
                } else {
                    EmeldApi.getLevelKlassen()
                        .then(data => {
                            commit('SET_KLASSEN', data)
                            resolve(state.klassen)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchLevelList({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.levels && state.levels.length > 0) {
                    resolve(state.levels)
                } else {
                    EmeldApi.getLevelList()
                        .then(data => {
                            commit('SET_LEVELS', data)
                            resolve(state.levels)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchLevelTreeOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.levelTreeOu && state.levelTreeOu.length > 0) {
                    resolve(state.levelTreeOu)
                } else {
                    EmeldApi.getLevelOuTree()
                        .then(data => {
                            commit('SET_LEVEL_TREE_OU', data)
                            resolve(state.levelTreeOu)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchEwTragweiteBewertung({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.bewertungen) {
                    resolve(state.bewertungen)
                } else {
                    EmeldApi.getEwTragweiteBewertung()
                        .then(data => {
                            commit('SET_BEWERTUNGEN', data)
                            resolve(state.bewertungen)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.emeldLevelChanged:
                    state.levelTreeOu = []
                    break;
            }
        },
    },
    getters: {
        getBewertungTypes: (state: any) => {
            return state.bewertungType;
        },
        getDoneUndoneOptions: (state: any) => {
            return state.doneUndoneOptions;
        },
    },
    modules: {}
}