
import {Component, Prop, Vue} from 'vue-property-decorator';
import moment from "moment/moment";
import {DateHelper} from "@/model/Date";

@Component
export default class DateForm extends Vue {
  @Prop() private id: string;
  @Prop() private value: string;
  @Prop() private error: string;
  @Prop() disabled;

  de = {
    labelPrevDecade: 'Vorheriges Jahrzehnt',
    labelPrevYear: 'Vorheriges Jahr',
    labelPrevMonth: 'Vorheriger Monat',
    labelCurrentMonth: 'Aktueller Monat',
    labelNextMonth: 'Nächster Monat',
    labelNextYear: 'Nächstes Jahr',
    labelNextDecade: 'Nächstes Jahrzehnt',
    labelToday: 'Heute',
    labelSelected: 'Ausgewähltes Datum',
    labelNoDateSelected: 'Kein Datum gewählt',
    labelCalendar: 'Kalender',
    labelNav: 'Kalendernavigation',
    labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
  }

  valueYMD = null

  datePickerInput(ctx) {
    this.formattedValue = ctx
  }


  get formattedValue() {
    this.valueYMD = this.formatYMD(this.value)

    if (DateHelper.isValidSqlDate(this.value)) {
      let tmpMoment = moment(this.value, "YYYY-MM-DD")
      return tmpMoment.isValid() ? tmpMoment.format("DD.MM.YYYY") : structuredClone(this.value)
    }


    return structuredClone(this.value)
  }

  set formattedValue(d) {
    this.valueYMD = this.formatYMD(d)
    this.$emit("input", d);
    this.$emit("change", d);
  }

  formatYMD(d) {
    if (DateHelper.isValidSqlDate(d)) {
      return d
    }

    let tmpMoment = DateHelper.parseGermanDate(d);
    return tmpMoment && tmpMoment.isValid() ? tmpMoment.format("YYYY-MM-DD") : null
  }


  autoCompleteDate() {
    if (this.formattedValue.match(/^\d{2}\d{2}\d{4}$/) != null) {
      let tmp = moment(this.formattedValue, "DDMMYYYY")
      if (tmp.isValid()) this.formattedValue = tmp.format("DD.MM.YYYY")
      return tmp
    } else if (this.formattedValue.match(/^\d{1,2}\.\d{1,2}\.\d{2}$/) != null) {
      let tmp = moment(this.formattedValue, "D.M.YY")
      if (tmp.isValid()) this.formattedValue = tmp.format("DD.MM.YYYY")
    }
  }

}
