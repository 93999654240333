import {apiClient} from './AbstractHTTPService'
import store from '../store'

import Axios from "axios";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {FileDTO, KfzAufbauerDTO, KfzCategoryDTO, KfzDTO, KfzKennungDTO, KfzResponseDTO, KfzStandortDTO, OutsourcingDTO} from "@/model/dto";
import {Event} from "@/model/Constants";


export abstract class KfzApi {
    private static usersAxios = Axios.create();

    static async getKfzList(): Promise<KfzResponseDTO[]> {
        const response = await apiClient.get('/kfz/kfzlist/list');
        return response.data.data;
    }

    static async getKennungenOuChain(): Promise<KfzKennungDTO[]> {
        const response = await apiClient.get('/kfz/kfzkennung/chain');
        return response.data.data;
    }

    static async getKennungen(): Promise<KfzKennungDTO[]> {
        const response = await apiClient.get('/kfz/kfzkennung');
        return response.data.data;
    }

    static async getCategories(param = ''): Promise<KfzCategoryDTO[]> {
        const response = await apiClient.get('/kfz/kfzcategory/' + param);
        return response.data.data;
    }

    static async putCategory(q: KfzStandortDTO): Promise<KfzCategoryDTO> {
        const response = await apiClient.put('/kfz/kfzcategory', q);
        store.dispatch("crudEvent", new CrudEvent(Event.kfzCategoryUpdate, response.data.data));
        return response.data;
    }

    static async removeCategory(id: number): Promise<KfzCategoryDTO> {
        const response = await apiClient.delete('/kfz/kfzcategory' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.kfzCategoryUpdate, response.data.data, id));
        return response.data;
    }

    static async getLocations(): Promise<KfzStandortDTO[]> {
        const response = await apiClient.get('/entitystandort/kfzstandort/chain');
        return response.data.data;
    }

    static async getOutsourcing(): Promise<OutsourcingDTO[]> {
        const response = await apiClient.get('/outsourcing/outsourcinglist/chain/kfz');
        return response.data.data;
    }


    static async updateKfz(kfz: KfzDTO): Promise<ApiResponse<KfzDTO>> {
        const response = await apiClient.put('/kfz/kfzdata/' + kfz.id + '/', kfz);

        store.dispatch("crudEvent", new CrudEvent(Event.kfzChanged, response.data.data));

        return new ApiResponse(response);
    }

    static async updatePhoto(kfzId: number, dto): Promise<FileDTO> {
        const response = await apiClient.put('/kfz/kfzphoto/' + kfzId + '/', dto);
        return response.data.data;
    }

    static async removePhoto(kfzId: number): Promise<any> {
        const response = await apiClient.delete('/kfz/kfzphoto/' + kfzId + '/');
        return response.data;
    }


    static async getKfz(id): Promise<ApiResponse<KfzDTO>> {
        const response = await apiClient.get('/kfz/kfzdata/' + id);
        return new ApiResponse(response);
    }


    static async getOrgUnitStandorte(): Promise<KfzStandortDTO[]> {
        const response = await apiClient.get('/kfz/kfzstandort');
        return response.data.data;
    }

    static async putStandort(q: KfzStandortDTO): Promise<KfzStandortDTO> {
        const response = await apiClient.put('/kfz/kfzstandort', q);
        store.dispatch("kfz/standortUpdate")
        return response.data;
    }

    static async removeStandort(id: number): Promise<KfzStandortDTO> {
        const response = await apiClient.delete('/kfz/kfzstandort' + id);
        store.dispatch("kfz/standortUpdate")
        return response.data;
    }

    static async archivKfz(id): Promise<any> {
        const response = await apiClient.delete('/kfz/kfzdata/' + id);

        store.dispatch("crudEvent", new CrudEvent(Event.kfzChanged, response.data.data));
        return response.data;
    }


    static async putKennung(q: KfzKennungDTO): Promise<KfzKennungDTO> {
        const response = await apiClient.put('/kfz/kfzkennung', q);
        store.dispatch("crudEvent", new CrudEvent(Event.kfzKennungUpdate, response.data.data));
        return response.data;
    }

    static async removeKennung(id: number): Promise<KfzKennungDTO> {
        const response = await apiClient.delete('/kfz/kfzkennung/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.kfzKennungUpdate, response.data.data));
        return response.data;
    }

    static async getAufbauer(): Promise<KfzAufbauerDTO[]> {
        const response = await apiClient.get('/kfz/kfzaufbauer/aufbauer');
        return response.data.data;
    }

    static async getHersteller(): Promise<KfzAufbauerDTO[]> {
        const response = await apiClient.get('/kfz/kfzaufbauer/hersteller');
        return response.data.data;
    }

    static async getImportData(id): Promise<any> {
        const response = await apiClient.get('/kfz/import/' + encodeURIComponent(id));
        return response.data.data;
    }

    static async processImportData(id): Promise<any> {
        const response = await apiClient.post('/kfz/import/' + encodeURIComponent(id));
        return response.data.data;
    }
}