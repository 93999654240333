import {apiClient} from './AbstractHTTPService'
import {
    AmpelDTO,
    ConcentrationDTO,
    DesiAdviceDTO,
    DesiDTO,
    DesiInheritanceDTO,
    DesiRoutineDTO,
    DiseaseDTO,
    DisinfectantDTO,
    EinwirkzeitDTO,
    KfzStandortDTO,
    ProtectionDTO,
    ScopeDTO,
    TypeDTO
} from "@/model/dto";
import {Actions} from "@/model/Actions";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import store from "@/store";
import {Event} from "@/model/Constants";


export abstract class HygieneApi {

    static async getAmpel(param = ''): Promise<AmpelDTO[]> {
        const response = await apiClient.get('/hygiene/ampel/' + param);
        return response.data.data;
    }
    static async putAmpel(q: AmpelDTO): Promise<AmpelDTO> {
        const response = await apiClient.put('/hygiene/ampel', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneAmpelUpdate, response.data.data));
        return response.data;
    }
    static async removeAmpel(id: number): Promise<AmpelDTO> {
        const response = await apiClient.delete('/hygiene/ampel' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneAmpelUpdate, response.data.data, id));
        return response.data;
    }

    static async getConcentration(param = ''): Promise<ConcentrationDTO[]> {
        const response = await apiClient.get('/hygiene/concentration/' + param);
        return response.data.data;
    }
    static async putConcentration(q: ConcentrationDTO): Promise<ConcentrationDTO> {
        const response = await apiClient.put('/hygiene/concentration', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneConcentrationUpdate, response.data.data));
        return response.data;
    }
    static async removeConcentration(id: number): Promise<ConcentrationDTO> {
        const response = await apiClient.delete('/hygiene/concentration' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneConcentrationUpdate, response.data.data, id));
        return response.data;
    }

    static async getType(param = ''): Promise<TypeDTO[]> {
        const response = await apiClient.get('/hygiene/type/' + param);
        return response.data.data;
    }
    static async putType(q: TypeDTO): Promise<TypeDTO> {
        const response = await apiClient.put('/hygiene/type', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneTypeUpdate, response.data.data));
        return response.data;
    }
    static async removeType(id: number): Promise<TypeDTO> {
        const response = await apiClient.delete('/hygiene/type' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneTypeUpdate, response.data.data, id));
        return response.data;
    }

    static async getScopes(): Promise<ScopeDTO[]> {
        const response = await apiClient.get('/hygiene/scope');
        return response.data.data;
    }
    static async putScope(q: ScopeDTO): Promise<ScopeDTO> {
        const response = await apiClient.put('/hygiene/scope', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneScopeUpdate, response.data.data));
        return response.data;
    }
    static async removeScope(id: number): Promise<ScopeDTO> {
        const response = await apiClient.delete('/hygiene/scope' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneScopeUpdate, response.data.data, id));
        return response.data;
    }

    static async getProtection(param = ''): Promise<ProtectionDTO[]> {
        const response = await apiClient.get('/hygiene/protection/' + param);
        return response.data.data;
    }
    static async putProtection(q: ProtectionDTO): Promise<ProtectionDTO> {
        const response = await apiClient.put('/hygiene/protection', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneProtectionUpdate, response.data.data));
        return response.data;
    }
    static async removeProtection(id: number): Promise<ProtectionDTO> {
        const response = await apiClient.delete('/hygiene/protection' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneProtectionUpdate, response.data.data, id));
        return response.data;
    }


    static async getEinwirkzeit(param = ''): Promise<EinwirkzeitDTO[]> {
        const response = await apiClient.get('/hygiene/einwirkzeit/' + param);
        return response.data.data;
    }
    static async putEinwirkzeit(q: EinwirkzeitDTO): Promise<EinwirkzeitDTO> {
        const response = await apiClient.put('/hygiene/einwirkzeit', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneEinwirkzeitUpdate, response.data.data));
        return response.data;
    }
    static async removeEinwirkzeit(id: number): Promise<EinwirkzeitDTO> {
        const response = await apiClient.delete('/hygiene/einwirkzeit' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneEinwirkzeitUpdate, response.data.data, id));
        return response.data;
    }


    static async getDisinfectant(param = ''): Promise<DisinfectantDTO[]> {
        const response = await apiClient.get('/hygiene/disinfectant/' + param);
        return response.data.data;
    }
    static async putDisinfectant(q: KfzStandortDTO): Promise<DisinfectantDTO> {
        const response = await apiClient.put('/hygiene/disinfectant', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneDisinfectantUpdate, response.data.data));
        return response.data;
    }
    static async removeDisinfectant(id: number): Promise<DisinfectantDTO> {
        const response = await apiClient.delete('/hygiene/disinfectant' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneDisinfectantUpdate, response.data.data, id));
        return response.data;
    }



    static async getDisease(param = ''): Promise<DiseaseDTO[]> {
        const response = await apiClient.get('/hygiene/disease/' + param);
        return response.data.data;
    }
    static async putDisease(q: DiseaseDTO): Promise<DiseaseDTO> {
        const response = await apiClient.put('/hygiene/disease', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneDiseaseUpdate, response.data.data));
        return response.data;
    }
    static async removeDisease(id: number): Promise<DiseaseDTO> {
        const response = await apiClient.delete('/hygiene/disease' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneDiseaseUpdate, response.data.data, id));
        return response.data;
    }


    static async getDesiAdvice(param = ''): Promise<DesiAdviceDTO[]> {
        const response = await apiClient.get('/hygiene/desiadvice/' + param);
        return response.data.data;
    }
    static async putDesiAdvice(q: DesiAdviceDTO): Promise<DesiAdviceDTO> {
        const response = await apiClient.put('/hygiene/desiadvice', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneDesiAdviceUpdate, response.data.data));
        return response.data;
    }
    static async removeDesiAdvice(id: number): Promise<DesiAdviceDTO> {
        const response = await apiClient.delete('/hygiene/desiadvice' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneDesiAdviceUpdate, response.data.data, id));
        return response.data;
    }




    static async getDesiRoutines(): Promise<DesiRoutineDTO[]> {
        const response = await apiClient.get('/hygiene/desiroutine');
        return response.data.data;
    }
    static async putDesiRoutine(q: DesiRoutineDTO): Promise<DesiRoutineDTO> {
        const response = await apiClient.put('/hygiene/desiroutine', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneDesiRoutineUpdate, response.data.data));
        return response.data;
    }
    static async removeDesiRoutine(id: number): Promise<DesiRoutineDTO> {
        const response = await apiClient.delete('/hygiene/desiroutine' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneDesiRoutineUpdate, response.data.data, id));
        return response.data;
    }





    static async getDesi(id): Promise<ApiResponse<DesiDTO>> {
        const response = await apiClient.get('/hygiene/desi/' + id);
        return new ApiResponse(response);
    }


    static async putDesi(desi: DesiDTO, action = Actions.SEND): Promise<ApiResponse<DesiDTO>> {
        const response = await apiClient.put('/hygiene/desi/' + desi.id + '/', desi, {
            headers: {
                'X-Action': action
            }
        });
        return new ApiResponse(response);
    }

    static async removeDesi(id): Promise<any> {
        const response = await apiClient.delete('/hygiene/desi/' + id);
        return response.data;
    }


    static async getDesiInheritance(param = ''): Promise<DesiInheritanceDTO> {
        const response = await apiClient.get('/hygiene/desiinheritance/' + param);
        return response.data.data;
    }
    static async putDesiInheritance(q: DesiInheritanceDTO): Promise<DesiInheritanceDTO> {
        const response = await apiClient.put('/hygiene/desiinheritance', q);
        store.dispatch("crudEvent", new CrudEvent(Event.hygieneDesiInheritanceUpdate, response.data.data));
        return response.data;
    }

  

}