
import {Component, Prop} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import {Event} from "@/model/Constants";
import {Route} from "vue-router";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {PersonenkreisApi} from "@/services/PersonenkreisApi";
import {ListItemHelper} from "@/model/Shared";


@Component(
    {
      components: {DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("userpersonenkreislist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
          } else {
            this.table.fixedHeader.disable()
          }
          this.handleDatableRoute(true);
          this.$root.$emit('loadingDone')
        },
        '$route.query.archive'(val) {
          this.archive = (this.$route.query.archive !== undefined)
        },
        'archive'(val) {
          jquery('#' + this.tableId).dataTable().api().ajax.url(func.rewisUrl(this.getUrl())).load();
        }
      }
    }
)
export default class UserPersonenkreiseList extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop() private id: string;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null

  getUrl() {
    return '/user/personenkreisdatalist/' + ((this.archive) ? 'archive/' : 'noarchive/') + "chain";
  }
  
  created() {
    this.archive = this.$route.query.archive !== undefined
  }

  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/user/personenkreislist/create/", query: queryParam})
  }

  edit(id) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();
    if (rowdata.orgUnit.id != this.$store.getters.getLocation.id) {
      this.$alert("Sie haben keine Berechtigung")
      return
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/user/personenkreislist/edit/" + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "userpersonenkreislist", query: queryParam})
  }

  destroyed() {
    this.unregisterEvents()
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')
  }

  mounted() {
    this.registerEvents([Event.personenkreisCreated, Event.personenkreisChanged, Event.personenkreisArchived])

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      order: [[0, 'asc']],
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      "rowCallback": function (row, data) {
        if (data && data.orgUnit.id != self.$store.getters.getLocation.id) {
          jquery(row).addClass('foreign-row');

        }
      },
      responsive: true,
      rowId: "id",
      columns: [
        {
          class: 'not-export-col text-center',
          data: null,
          render: function (data, type, row) {
            // if row is foreign-row then return ListItemHelper.getInheritance() else return ''
            return data.orgUnit.id != self.$store.getters.getLocation.id ? ListItemHelper.getInheritance('chain_'+data.id) : ''
          }
        },
        {
          class: 'center',
          data: 'text'
        },
        {
          class: '',
          data: 'orgUnit.name'
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons([
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Bearbeiten',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            self.edit(id)
          }
        }, {
          className: 'text-secondary bg-white',
          titleAttr: 'Archiv',
          text: '<i class="fas fa-archive" />',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }], true)
    })
    self.table = table
    self.initContextMenu()
    func.datatableInitComplete(table)

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }

  initContextMenu() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr:not(.foreign-row)';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: {
        copy: {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        },
        archive: {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }
      }
    });
  }

  archiv(data) {
    if (data.orgUnit.id != this.$store.getters.getLocation.id) {
      this.$alert("Sie haben keine Berechtigung")
      return
    }

    this.$confirm('Sind sie Sicher ?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      PersonenkreisApi.archive(data.id)
          .then(() => {
            data.archive = !data.archive
            this.updateTable()
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

  updateTable() {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
  }
}
