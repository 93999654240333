import {apiClient} from './AbstractHTTPService'
import {ApiResponse} from "@/model/AbstractClasses";
import {AuktionDTO, OrgUnitDTO, OrgUnitTreeDTO, SuchenPersonDTO, SuchenPersonTokenDTO} from "@/model/dto";
import {SuchenPersonToken} from "@/model/Diva";


export abstract class DivaApi {

    static async putAuktion(Auktion: AuktionDTO): Promise<ApiResponse<AuktionDTO>> {
        const response = await apiClient.put('/diva/auktion/' + Auktion.id + '/', Auktion);
        return new ApiResponse(response);
    }

    static async getAuktion(id): Promise<ApiResponse<AuktionDTO>> {
        const response = await apiClient.get('/diva/auktion/' + id);
        return new ApiResponse(response);
    }

    static async getPortal(): Promise<SuchenPersonDTO[]> {
        const response = await apiClient.get('/diva/portal');
        return response.data.data;
    }

    static async removeAuktion(id): Promise<any> {
        const response = await apiClient.delete('/diva/auktion/' + id);
        return response.data;
    }

    static async getDivaOrgUnits(): Promise<OrgUnitDTO[]> {
        const response = await apiClient.get<OrgUnitTreeDTO>('auth/oulist/diva');
        return response.data.data;
    }


    static async getSuchenUser(suchenId): Promise<SuchenPersonDTO[]> {
        const response = await apiClient.get<OrgUnitTreeDTO>('/diva/personen/suche/' + suchenId);
        return response.data.data;
    }


    static async putSuchenPerson(person: SuchenPersonDTO): Promise<ApiResponse<SuchenPersonDTO>> {
        const response = await apiClient.put('/diva/personen/' + person.id + '/', person);
        return new ApiResponse(response);
    }

    static async getByToken(token: string): Promise<SuchenPersonDTO> {
        const response = await apiClient.get('/diva/token/' + token);
        return response.data.data;
    }

    static async answer(payload: SuchenPersonTokenDTO): Promise<ApiResponse<SuchenPersonDTO>> {
        const response = await apiClient.put('/diva/token/' + payload.token, payload);
        return new ApiResponse(response);
    }
    static async answerPortal(payload: SuchenPersonDTO): Promise<ApiResponse<SuchenPersonDTO>> {
        const convertedPayload = new SuchenPersonToken();
        convertedPayload.id = payload.id;
        convertedPayload.status = payload.status;
        convertedPayload.kommentar = payload.antwortKommentar;

        const response = await apiClient.put('/diva/portal/' + convertedPayload.id, convertedPayload);
        return new ApiResponse(response);
    }
}