
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { Material } from "@/model/Material";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import { MaterialApi } from "@/services/MaterialApi";
import Multiselect from "@/libs/multiselect";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {MaterialDTO} from "@/model/dto";

const DataProps = Vue.extend({
  props: {
    id: String
  }
})

@Component({
  components: {
    Multiselect,
    ListItemComponent
  }
})
export default class MaterialModal extends mixins<GenericMixin<MaterialDTO, MaterialDTO>>(GenericMixin, DataProps) {
 // @Prop({ default: () => [] }) materials!: Material[];
  @Prop({ default: false }) isVisible!: boolean;
  @Prop() dataId!: number;

  materials = []
  selectedMaterial = [];

  async created() {
    const material = await this.$store.dispatch('material/fetchMaterial')
    this.materials = material
  }
  closeModal() {
    this.$emit('close');
  }

  async saveSelectedMaterial() {
    const locationOpts = await this.$store.dispatch('material/fetchStandorte');

    const found = locationOpts.find(location => {
      return location.material && location.material.id === this.dataId;
    });
    if (!found) {
      await this.$alert('Es ist ein Fehler aufgetreten.');
      return;
    }

    for (const material of this.selectedMaterial) {
        material.location = found;
        const promise = MaterialApi.putMaterial(material);
        this.handleApiRequest(promise, false, false).then((response) => {
          if (response instanceof ApiResponse && response.data != undefined) {
            bus.$emit(Event.materialChanged, new ObjectEvent(material.id, response.data));
            this.$emit('bestandChanged');
          } else {
            this.$alert('Fehler beim Speichern des Materials: ' + material.text);
            return;
          }
        });

    }
  }

  removeElement(id) {
    this.selectedMaterial = this.selectedMaterial.filter((e) => e.id != id);
  }
}
