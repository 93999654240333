import UserProfileView from "@/views/profile/UserProfileView.vue";
import OrgUnitSelectorView from "@/views/auth/OrgUnitSelectorView.vue";
import AboutView from "@/views/AboutView.vue";
import QrCodeReaderView from "@/views/util/QrCodeReaderView.vue";
import UserChangePinView from "@/views/profile/UserChangePinView.vue";
import UserChangePassword from "@/views/profile/UserChangePasswordView.vue";
import SwitchUserByPinView from "@/views/auth/SwitchUserByPinView.vue";
import MyChatView from "@/views/chat/MyChatView.vue";
import HomeView from "@/views/HomeView.vue";

export const globalRoutes = [
    {
        path: '',
        name: '',
        component: HomeView
    },
    {
        path: 'dashboard',
        name: 'dashboard',
        component: HomeView
    },
    {
        path: 'mydashboard',
        name: 'mydashboard',
        component: HomeView
    },
    {
        path: 'admindashboard',
        name: 'admindashboard',
        component: HomeView
    },
    {
        path: 'wachbuch',
        name: 'wachbuch',
        component: HomeView
    },
    {
        path: 'profile/:force?',
        name: 'profile',
        component: UserProfileView,
        props: true,
        meta: {
            loading: true
        }
    },
    {
        path: 'chat',
        name: 'chat',
        component: MyChatView
    },
    {
        path: 'ouselect',
        name: 'ouselect',
        component: OrgUnitSelectorView
    },
    {
        path: 'info',
        name: 'info',
        component: AboutView
    },
    {
        path: 'qr',
        name: 'qr',
        component: QrCodeReaderView,
    },
    {
        path: 'userpin',
        name: 'userpin',
        component: UserChangePinView,
    },
    {
        path: 'userpassword/:force?',
        name: 'userpassword',
        component: UserChangePassword,
        props: true
    },
    {
        path: 'pinlogin/:user',
        name: 'pinlogin',
        component: SwitchUserByPinView,
        props: true
    },
]
