
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {FobiKombiThemaDTO} from "@/model/dto";
import {FobiKombiThema} from "@/model/Fobi";
import {FobiApi} from "@/services/FobiApi";
import FobiKombiThemaDataView from "@/views/fobi/FobiKombiThemaDataView.vue";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  components: {
    FobiKombiThemaDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class FobiKombiThemaEditView extends mixins<GenericMixin<FobiKombiThemaDTO, FobiKombiThemaDTO>>(GenericMixin, DataProps) {
  data = new FobiKombiThema();
  error = new FobiKombiThema();
  showTabs = false

  created() {
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = FobiApi.getKombiThema(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean) {
    const p = FobiApi.putKombiThema(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send fobiKombiThemaChanged event')
        bus.$emit(Event.fobiKombiThemaChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/fobi/kombithema", query: queryParam})
  }

  archiv() {
    bus.$emit(Event.fobiKombiThemaArchiveRequest, new ObjectEvent(this.data.id, this.data));
  }

  get archivTxt() {
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }
}
