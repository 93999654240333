
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, MaterialDTO, MedProduktDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import MedProduktChooser from "@/components/MedProduktChooser.vue";
import Multiselect from "@/libs/multiselect/";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import LocationChooser from "@/components/LocationChooser.vue";
import {Standortwechsel} from "@/model/Shared";
import {StandortApi} from "@/services/StandortApi";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {LocationChooser, ListItemComponent, MedProduktChooser, Multiselect}
})
export default class UmzugView extends mixins<SimpleViewMixin<null, GenericError>>(SimpleViewMixin, DataProps) {
  @Prop({default: false}) isCheckin;
  @Prop({default: true}) neuerStandort;
  @Prop({default: null}) kfz;

  loading = true;
  done = false;

  data = null
  error: GenericError = new GenericError()

  medProdukt = []
  medproduktOpts = []

  locationsOpts = []
  location = null
  locationText = ''

  material = []
  materialOpts = []

  async created() {
    this.init();
  }

  async init() {
    this.location = null;
    this.medProdukt = []
    this.material = []

    this.loading = true;
    if (this.isCheckin && this.neuerStandort) { //Abgabe von Checkin
      this.medproduktOpts = await this.$store.dispatch("checkin/fetchMedProdukte")
      this.materialOpts = await this.$store.dispatch("checkin/fetchMaterial")
    } else { //Sonstiges (wird später weiter gefiltert)
      this.medproduktOpts = await this.$store.dispatch("medprodukt/fetchMedizinprodukte")
      this.materialOpts = await this.$store.dispatch("material/fetchMaterial")
    }

    this.locationsOpts = await this.$store.dispatch('material/fetchStandorte')

    //Übernahme zu Kfz
    if (!this.neuerStandort && this.kfz) {
      this.location = this.locationsOpts.find((e) => e.kfz && e.kfz.id == this.kfz.originalId)
    } else if (this.kfz) { //Abgabe (abgebendes KFZ filtern)
      this.locationsOpts = this.locationsOpts.filter((e) => !e.kfz || (e.kfz && e.kfz.id && e.kfz.id != this.kfz.originalId))
    }

    //Nur Parents
    this.medproduktOpts = this.medproduktOpts.filter((e:MedProduktDTO) => e.parent == null)
    this.materialOpts = this.materialOpts
        .filter((e:MaterialDTO) => (e.location == null || e.location.material == null))

    //Kfz-Abgabe von Kfz (nicht checkin)
    if (this.neuerStandort && this.kfz && !this.isCheckin) {
      this.medproduktOpts = this.medproduktOpts.filter((e:MedProduktDTO) => e.location && e.location.kfz && e.location.kfz.id == this.kfz.originalId)
      this.materialOpts = this.materialOpts.filter((e:MaterialDTO) => e.location && e.location.kfz && e.location.kfz.id == this.kfz.originalId)
    }

    //Übernahme, Standort gefunden
    if (!this.neuerStandort && this.location) {
      this.medproduktOpts = this.medproduktOpts.filter((e:MedProduktDTO) => !e.location || (e.location && e.location.id != this.location.id))
      this.materialOpts = this.materialOpts.filter((e:MaterialDTO) => !e.location || (e.location && e.location.id != this.location.id))
    }

    this.$root.$emit('loadingDone')
    this.loading = false;
  }
  allMaterial() {
    this.material = this.materialOpts
  }
  allMedProdukt() {
    this.medProdukt = this.medproduktOpts
  }

  remoeMedProdukt(id) {
    this.medProdukt = this.medProdukt.filter((e) => e.id != id);
  }
  removeMaterial(id) {
    this.material = this.material.filter((e) => e.id != id);
  }

  save() {
    const payload = new Standortwechsel()
    payload.medProdukt = this.medProdukt
    payload.material = this.material
    payload.neuerStandort = this.location

    StandortApi.changeStandort(payload).then(() => {
      if (this.isCheckin) {
        this.$store.dispatch('checkin/bestandUpdate')
      }

      if (this.medProdukt.length>0) this.$store.dispatch('medprodukt/bestandUpdate')
      if (this.material.length>0) this.$store.dispatch('material/bestandUpdate')


      this.$alert("Standortwechsel erfolgreich", 'Erfolg', 'success').then(() => {
        this.init();
      })
    }).catch((e) => {
      this.$alert(e.error, 'Fehler', 'error');
    })
  }
}
