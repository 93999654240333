
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {WachenCloudDateDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import DateForm from "@/components/DateForm.vue";

@Component({
  components: {DateForm, Multiselect}
})
export default class CloudDateComponent extends Vue {
  @Prop() private dateTypeOptions: [];
  @Prop() private value: WachenCloudDateDTO;
  @Prop() private error: any;


  data: WachenCloudDateDTO = null

  async created() {
    this.init(this.value)
  }

  init(newValue) {
    this.data = structuredClone(newValue)
  }

  changed() {
    this.$emit("input", this.data);
    this.$emit("change", this.data);
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

}
