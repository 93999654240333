

import {Component} from "vue-property-decorator";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {HistoryEvent} from "@/model/Shared";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";
import {Permission, PermissionModul} from "@/model/dto";
import {Route} from "vue-router";


@Component({
  components: {},
  watch: {
    '$route'(val: Route) {
      this.handleRoute()
    }
  }
})
export default class FileLexNavComponent extends mixins(NavMixin) {
  navBar = []
  navBarSettings = []
  routeName = null

  created() {
    this.fillNavbar(
        ['filelexdocs', 'filecloud'],
        this.navBar)

    this.fillNavbar(
        ['filelextreeconfig', 'filelexcategorycatalog', 'cloudsettings'], this.navBarSettings)

    this.handleRoute()

  }

  handleRoute() {
    this.routeName = this.$route.name
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/file/filelexhistory'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.FILELEX, Permission.History)
  }

  historyCloud() {
    bus.$emit(Event.history, new HistoryEvent('/file/wachencloudhistory'))
  }

  get historyCloudPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Cloud, Permission.History)
  }
}
