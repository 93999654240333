
import {Component, Prop, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import Multiselect from "@/libs/multiselect";
import DivaNavComponent from "@/views/diva/components/DivaNavComponent.vue";
import AuktionList from "@/views/diva/AuktionList.vue";


@Component(
    {
      components: {
        AuktionList,
        DivaNavComponent,
        DatatableButtonHeader,
        Multiselect
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class DivaTables extends Vue {
  @Prop() id: number;

  showAuktionTable = false
  showTable2 = false
  showTable3 = false
  showTableOwn = false

  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'auktionlist') {
      this.showAuktionTable = true
      this.showTable2 = false
      this.showTable3 = false
      this.showTableOwn = false
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
