import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MenuService from '../../services/MenuService'

Vue.use(Vuex)

function handleActiveState(data, path = null) {
    return data.map(x => {
        x.active = false

        if (x.path == '/' && path == '/') {
            x.active = true
        }
        if (path && x.path && path.startsWith(x.path) && x.path != '/') {
            x.active = true
        }

        if (x.submenu !== null && Array.isArray(x.submenu) && x.submenu.length > 0) {
            x.submenu = handleActiveState(x.submenu, path)
        }

        return x
    })
}

export default {
    namespaced: true,
    state: {
        menu: [],
        active: '',
        calledRoute: '',
        loading: false
    },
    mutations: {
        SET_MENU(state : any, menu : any) {
            state.menu = handleActiveState(menu, state.active)
        },
        ADD_MENU(state : any) {
            state.menu.push({
                isHeading: true,
                name: 'test'
            })
        },
        SET_ACTIVE(state : any, path : any) {
            state.active = path
            handleActiveState(state.menu, path)
        },
        SET_CALLEDROUTE(state : any, path : any) {
            state.calledRoute = path
            handleActiveState(state.menu, path)
        },
        SET_LOADING (state : any, loading : any) {
            state.loading = loading
        },
        resetState(state : any) {
            state.menu = []
            state.loading = false
        },
    },
    actions: {
        fetchMenu({ commit } : any) {
            MenuService.getMenu()
                .then(response => {
                    commit('SET_MENU', response.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        addMenu({ commit } : any) {
            commit('ADD_MENU')
        },
        setActive({ commit } : any, data : any) {
            commit('SET_ACTIVE', data.path)
        },
        calledRoute({ commit } : any, data : any) {
            commit('SET_CALLEDROUTE', data.path)
        },
        setLoading ({ commit } : any, data : any) {
            commit('SET_LOADING', data)
        }
    },
    getters: {
        getMenu: (state : any) => {
            return state.menu
        },
        getCalledRoute: (state : any) => {
            return state.calledRoute
        },
        getLoading: (state : any) => {
            return state.loading
        }
    },
    modules: {}
}
