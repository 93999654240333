
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {GenericError, OrgUnitTreeDTO, Permission, PermissionModul, ReportDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import DateForm from "@/components/DateForm.vue";
import {FormApi} from "@/services/FormApi";
import FormInput from "@/views/form/components/FormInput.vue";
import {EntityFile, ExistingFiles, RewisFile} from "@/model/AbstractClasses";
import {bus} from "@/main";
import {Event, Module} from "@/model/Constants";
import FileUpload from "@/components/FileUpload.vue";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    FileUpload,
    FormInput,
    DateForm,
    PersonenkreisChooser,
    Multiselect
  }
})
export default class ReportDataView extends Vue {
  @Prop() value: ReportDTO;
  @Prop() error: GenericError;
  data: ReportDTO = this.$props.value;
  addFieldData = []
  loadingComplete = false
  personenkreisOptions = []
  levelTree: OrgUnitTreeDTO[] = []
  version = 0
  existingFiles = []

  fileUploadKey = 0

  async created() {
    window.scrollTo(0, 0);
    this.levelTree = await this.$store.dispatch("emeld/fetchLevelTreeOu")

    this.init()
  }

  async init() {
    this.existingFiles = new ExistingFiles(this.data.files.map(n => {
      n.file.foreignId = n.id;
      return n.file
    }));

    this.fileUploadKey += 1
    this.loadingComplete = true
  }

  levelChanged(level: number) {
    for (let i = level + 1; i <= 5; i++) {
      this.data['level' + i] = null

      if (this.error) {
        this.data['level' + i] = null
      }
    }

    this.data.tags = this.data.tags.filter(tag => {
      return this.tagOptions.map(option => option.id).includes(tag.id)
    })

    this.recalcForm()
  }

  tagsChanged() {
    this.recalcForm()
  }

  async recalcForm() {
    const dto = {forms: this.getForms}
    this.data.rows = await FormApi.getFormRows(dto);
  }

  get level1Node() {
    return this.data.level1 ? this.levelTree.find(node => node.dto.level.id == this.data.level1.id) : null
  }

  get level2Node() {
    return this.extractChildNode(this.level1Node, this.data.level2)
  }

  get level3Node() {
    return this.extractChildNode(this.level2Node, this.data.level3)
  }

  get level4Node() {
    return this.extractChildNode(this.level3Node, this.data.level4)
  }

  get level5Node() {
    return this.extractChildNode(this.level4Node, this.data.level5)
  }

  get level1Options() {
    return this.extractLevelDto(this.levelTree)
  }

  get level2Options() {
    return this.extractChildLevels(this.level1Node)
  }

  get level3Options() {
    return this.extractChildLevels(this.level2Node)
  }

  get level4Options() {
    return this.extractChildLevels(this.level3Node)
  }

  get level5Options() {
    return this.extractChildLevels(this.level4Node)
  }


  extractLevelDto(list) {
    return list.map(node => node.dto.level)
  }

  extractChildNode(node, level) {
    return node && level ? node.childs.find(node => node.dto.level.id == level.id) : false
  }

  extractChildLevels(node) {
    if (!node) return false;
    const childs = this.extractLevelDto(node.childs);
    return childs.length > 0 ? childs : false
  }

  get tagOptions() {
    return [
      ...this.extractTags(this.level1Node),
      ...this.extractTags(this.level2Node),
      ...this.extractTags(this.level3Node),
      ...this.extractTags(this.level4Node),
      ...this.extractTags(this.level5Node)
    ]
  }

  extractTags(treeLevel: OrgUnitTreeDTO) {
    if (treeLevel && treeLevel.dto && treeLevel.dto.level) return treeLevel.dto.level.tags ? treeLevel.dto.level.tags : []
    return []
  }


  get getForms() {
    const forms = [
      ...this.extractForm(this.level1Node),
      ...this.extractForm(this.level2Node),
      ...this.extractForm(this.level3Node),
      ...this.extractForm(this.level4Node),
      ...this.extractForm(this.level5Node),
      ...this.extractFormsOfTags(this.data.tags)
    ];

    let alreadyUsed = [];
    let uniqueForms = [];
    forms.forEach(form => {
      if (alreadyUsed.includes(form.id)) {
        return;
      }
      alreadyUsed.push(form.id)
      uniqueForms.push(form)
    })

    return uniqueForms;
  }


  extractForm(treeLevel: OrgUnitTreeDTO) {
    if (treeLevel && treeLevel.dto && treeLevel.dto.level && treeLevel.dto.level.form) return [treeLevel.dto.level.form]
    return []
  }

  extractFormsOfTags(tags) {
    let forms = []
    tags.forEach(tag => {
      if (tag.form) {
        forms.push(tag.form)
      }
    })
    return forms
  }

  changeFile(id) {
    const idx = this.data.files.findIndex(nachweis => nachweis.file.identifier == id || (nachweis.file.id == id))
    this.$prompt("Beschreibung", this.data.files[idx].comment).then((r: string) => {
      this.data.files[idx].comment = r
      this.data.files[idx].file.comment = r
      bus.$emit(Event.uploadDescription, this.data.files.map(f => f.file))
    });


    this.$emit('input', this.data)
  }

  fileUploadSuccess(d) {
    let file = new RewisFile(d);


    let nachweis = new EntityFile()
    nachweis.file = file
    this.data.files.push(nachweis)

    this.$emit('input', this.data)
  }

  fileDeleted(d) {
    this.data.files = this.data.files.filter(nachweis => (nachweis.id != d.file.foreignId))
    //  this.existingFile = null
    this.$emit('input', this.data)
  }

  fileRemoved(d) {
    this.data.files = this.data.files.filter(nachweis => !nachweis.file.identifier || (nachweis.file.identifier != d.id))
    //  this.existingFile = null
    this.$emit('input', this.data)
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.version += 1
    this.init();
  }

  get editPermission() {
    if (!this.data.sent) {
      if (this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Create)) {
        return true;
      }
    }

    return (this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update))
        || this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update_Responsible);

  }
}
