import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {UsersApi} from "@/services/UserApi";
import {FileApi} from "@/services/FileApi";
import {DateApi} from "@/services/DateApi";
import {PersonenkreisApi} from "@/services/PersonenkreisApi";
import {CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";
import {StoreHelper} from "@/store/StoreHelper";
import {PersonenkreisDTO} from "@/model/dto";

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        user: [],
        filter: {
            fileCategory: null,
            dateType: null
        },
        dateiKategorien: [],
        dateiKategorienOu: [],
        qualis: [],
        skills: [],
        dateTypes: [],
        dateTypesOu: [],
        personenkreise: [],
        qualiTypes: [{text: 'Auswählen...', value: null, disabled: true},
            {text: 'Medizinische Qualifikation', value: 0},
            {text: 'Führerscheinklasse', value: 1},
            {text: 'Sonstiges Qualifikation', value: 99}
        ],
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        SET_USER(state: any, data: any) {
            state.user = data
        },
        SET_DATEI_KATEGORIEN(state: any, data: any) {
            state.dateiKategorien = data
        },
        SET_DATEI_KATEGORIEN_OU(state: any, data: any) {
            state.dateiKategorienOu = data
        },
        SET_QUALIS(state: any, data: any) {
            state.qualis = data
        },
        SET_SKILLS(state: any, data: any) {
            state.skills = data
        },
        SET_DATE_TYPES(state: any, data: any) {
            state.dateTypes = data
        },
        SET_DATE_TYPES_OU(state: any, data: any) {
            state.dateTypesOu = data
        },
        SET_PERSONENKREISE(state: any, data: any) {
            state.personenkreise = data
        },
        resetState(state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {
        userUpdate({commit, state}: any) {
            commit('SET_USER', [])
        },
        dateTypOuUpdate({commit, state}: any) {
            commit('SET_DATE_TYPES_OU', [])
        },
        fileCategoryOuUpdate({commit, state}: any) {
            commit('SET_DATEI_KATEGORIEN_OU', [])
        },
        personenkreisUpdate({commit, state}: any) {
            commit('SET_PERSONENKREISE', [])
        },
        async fetchUser({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.user && state.user.length > 0) {
                    resolve(state.user)
                } else {
                    UsersApi.getUserList()
                        .then(data => {
                            commit('SET_USER', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateiKategorienKatalog({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateiKategorien && state.dateiKategorien.length > 0) {
                    resolve(state.dateiKategorien)
                } else {
                    FileApi.getFileCategory('user')
                        .then(data => {
                            commit('SET_DATEI_KATEGORIEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateiKategorienOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateiKategorienOu && state.dateiKategorienOu.length > 0) {
                    resolve(state.dateiKategorienOu)
                } else {
                    FileApi.getUserFileCategoryOuChain()
                        .then(data => {
                            commit('SET_DATEI_KATEGORIEN_OU', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchQualis({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.qualis && state.qualis.length > 0) {
                    resolve(state.qualis)
                } else {
                    UsersApi.getQualifications()
                        .then(data => {
                            commit('SET_QUALIS', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchSkills({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.skills && state.skills.length > 0) {
                    resolve(state.skills)
                } else {
                    UsersApi.getSkills()
                        .then(data => {
                            commit('SET_SKILLS', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateTypCatalog({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateTypes && state.dateTypes.length > 0) {
                    resolve(state.dateTypes)
                } else {
                    DateApi.getDateTypes('user')
                        .then(data => {
                            commit('SET_DATE_TYPES', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateTypesOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateTypesOu && state.dateTypesOu.length > 0) {
                    resolve(state.dateTypesOu)
                } else {
                    DateApi.getUserDateTypesOuChain()
                        .then(data => {
                            commit('SET_DATE_TYPES_OU', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchPersonenkreise({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.personenkreise && state.personenkreise.length > 0) {
                    resolve(state.personenkreise)
                } else {
                    PersonenkreisApi.getPersonenkreise()
                        .then(data => {
                            let lftNr = 0
                            data
                                .map((pK: PersonenkreisDTO) => {
                                    if (pK.temporary) {
                                        pK.id = (-1) * ++lftNr
                                    }
                                    return pK;
                                })
                            commit('SET_PERSONENKREISE', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({state, commit}, event: CrudEvent) {
            switch (event.name) {
                case Event.userChanged:
                    StoreHelper.update("SET_USER", state.user, event, commit)
                    commit("SET_PERSONENKREISE", [])
                    break;
                case Event.userDateOuUpdate:
                    StoreHelper.update("SET_DATE_TYPES_OU", state.dateTypesOu, event, commit)
                    break;
                case Event.userFileCategoryOuUpdate:
                    StoreHelper.update("SET_DATEI_KATEGORIEN_OU", state.dateiKategorienOu, event, commit)
                    break;
                case Event.personenkreisChanged:
                    commit("SET_PERSONENKREISE", [])
                    //StoreHelper.update("SET_PERSONENKREISE", state.personenkreise, event, commit)
                    break;
                case Event.fileCategoryUpdate:
                    if (event.dto && event.dto['typ'] == 1) {
                        StoreHelper.update("SET_DATEI_KATEGORIEN", state.dateiKategorien, event, commit)
                    }
                    break;
                case Event.dateTypUpdate:
                    if (event.dto && event.dto['typ'] == 1) {
                        StoreHelper.update("SET_DATE_TYPES", state.dateTypes, event, commit)
                    }
                    break;
                case Event.skillUpdate:
                    StoreHelper.update("SET_SKILLS", state.skills, event, commit)
                    commit("SET_PERSONENKREISE", [])
                    break;
                case Event.qualiUpdate:
                    StoreHelper.update("SET_QUALIS", state.qualis, event, commit)
                    commit("SET_PERSONENKREISE", [])
                    break;
            }
        },
    },
    getters: {
        qualiTypes: (state: any) => {
            return state.qualiTypes
        },
    },
    modules: {}
}
