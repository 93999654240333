
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import TaskNavComponent from "@/views/task/components/TaskNavComponent.vue";
import {TaskApi} from "@/services/TaskApi";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {TaskNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class TaskView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'task' && this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview)) {
      this.$router.push("/task/tasktable")
    } else if (this.$route.name == 'task' && this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview_Own)) {
      this.$router.push("/task/mytask")
    }
  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.taskArchiveRequest, (payload: ObjectEvent<any>) => this.archivTask(payload.data))
  }

  destroyed() {
    bus.$off(Event.taskArchiveRequest)
  }

  archivTask(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie Aufgabe ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      TaskApi.archivTask(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.taskArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

}
