
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {FileLexTreeDTO, GenericError} from "@/model/dto";
import {Route} from "vue-router";
import {FileLexTree} from "@/model/File";
import {FileApi} from "@/services/FileApi";
import FileLexTreeData from "@/views/file/data/FileLexTreeData.vue";


@Component({
  components: {
    FileLexTreeData,
  },
  watch: {
    '$route'(val: Route) {
      this.init()
    }
  }
})
export default class FileLexTreeCreate extends mixins<GenericMixin<FileLexTreeDTO, GenericError>>(GenericMixin) {
  data = new FileLexTree();
  error = new GenericError();
  @Prop() id

  async created() {
    this.init()
  }

  init() {
    this.data = new FileLexTree()
    this.data.childs = []
    if (this.id) {
      this.$root.$emit('loadingStart', true)
      FileApi.getTree(this.id).then((data) => {
        if (data instanceof ApiResponse && data.data != undefined) {
          this.data.parent = data.data
          this.data.passOnChilds = this.data.parent.passOnChilds
          this.$root.$emit('loadingDone', true)
        }
      })
    } else {
      this.data.parent = null
    }
  }

  onSubmit(goBack: boolean) {
    const p = FileApi.putFilelexTree(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        bus.$emit(Event.treeChanged, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    if (this.id) {
      this.$router.push({path: "/docs/tree/edit/" + this.id})
    } else {
      this.$router.push({path: "/docs/tree/edit/" + this.data.id})
    }
    //do nothing
  }

}
