
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {DesiDTO, GenericError, Permission, PermissionModul} from "@/model/dto";
import CheckinTaskView from "@/views/checkin/CheckinTaskView.vue";
import CheckinListItem from "@/views/checkin/components/CheckinListItem.vue";
import DesiDataView from "@/views/hygiene/data/DesiDataView.vue";
import {Desi} from "@/model/Hygiene";
import {HygieneApi} from "@/services/HygieneApi";
import EntityTaskListView from "@/views/task/entity/EntityTaskListView.vue";
import HygieneTaskList from "@/views/hygiene/components/HygieneTaskList.vue";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component({
  components: {HygieneTaskList, EntityTaskListView, DesiDataView, CheckinListItem, CheckinTaskView},
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class DesiEditView extends mixins<GenericMixin<DesiDTO, GenericError>>(GenericMixin, DataProps) {
  data = new Desi();
  error = new GenericError();
  showTabs = false
  tabIndex = 0

  async created() {
    this.init();
  }


  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = HygieneApi.getDesi(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean) {
    const p = HygieneApi.putDesi(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send desiChanged event')
        bus.$emit(Event.desiChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push("/hygiene/desioverview")
  }

  get editPermission() {
    if (this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Validate)) {
      return true;
    }

    if (this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Update_Own) && !this.data.seen) {
      return this.data.creator?.id == this.$store.getters.getUser.id;
    }

    return false;
  }

}
