
import {Component, Prop, Vue} from 'vue-property-decorator';
import {MedProduktDTO} from "@/model/dto";
import Multiselect from '@/libs/multiselect'
import {v4 as uuid} from 'uuid';
import {MedProduktHelper} from "@/model/MedProdukt";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import QrCodeReader from "@/components/QrCodeReader.vue";


@Component({
  components: {QrCodeReader, ListItemComponent, Multiselect},
})
export default class MedProduktChooser extends Vue {
  @Prop() options: []
  @Prop() value: []
  @Prop() multi: boolean
  @Prop({default: null}) max: number

  @Prop() error: any
  @Prop() label: string
  @Prop() disabled

  multiSelect = true
  selectedValue: MedProduktDTO[] | MedProduktDTO = []
  searchTexts = []

  helper = MedProduktHelper
  uuid = uuid()

  showQrScan = false

  removeElement(id) {
    if (Array.isArray(this.selectedValue)) {
      this.selectedValue = this.selectedValue.filter((e) => e.originalId != id)
    }
    this.$emit("input", this.selectedValue)
  }

  created() {
    this.selectedValue = Vue.util.extend(this.$props.value)
    this.multiSelect = this.$props.multi
    //
  }

  sendChange(event) {
    this.$emit("input", this.selectedValue)
  }


  filter(pk: MedProduktDTO) {
    let res = true
    this.searchTexts.forEach((t) => {
      if (pk['filterText'].toLowerCase().includes(t.toLowerCase()) && res) {
        res = true
      } else {
        res = false
      }
    })

    return res
  }

  toggleQrScan() {
    this.showQrScan = !this.showQrScan
  }

  onScan(read) {
    let found = this.getOptions.find((medP:MedProduktDTO) => medP.qrCode && medP.qrCode.code == read)
    if (!found) {
      this.$alert("Kein Medizinprodukt mit diesem QR-Code gefunden!", "Fehler", "error")
    } else {
      this.showQrScan = false
      if (this.multi) {
        if (Array.isArray(this.selectedValue)) {
          if (this.selectedValue.find((e) => e.originalId == found.originalId)) {
            return
          }
          this.selectedValue.push(found)
          this.$emit("input", this.selectedValue)
        } else {
          this.selectedValue = [found]
          this.$emit("input", this.selectedValue)
        }
      } else {
        this.selectedValue = found
        this.$emit("input", this.selectedValue)
      }
    }
  }


  get getOptions() {
    return this.$props.options
  }

  get closeOnSelect() {
    if (this.max !== null) {
      if (this.max > 1) {
        return !this.multi
      }

      return true
    }

    return !this.multi
  }
}
