
import {Component, Prop} from "vue-property-decorator";
import {GenericError} from "@/model/dto";
import AdditionalFieldResultForm from "@/components/AdditionalFieldResultForm.vue";
import FileUpload from "@/components/FileUpload.vue";
import HtmlEditor from "@/components/HtmlEditor.vue";
import AdditionalFieldForm from "@/components/AdditionalFieldForm.vue";
import draggable from "vuedraggable";
import {mixins} from "vue-class-component";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import {FormRow} from "@/model/FormVersion";
import {AdditionalField} from "@/model/AbstractClasses";
import {FormApi} from "@/services/FormApi";

@Component({
  components: {AdditionalFieldForm, HtmlEditor, draggable, FileUpload, AdditionalFieldResultForm}
})
export default class FormRowComponent extends mixins(DropzoneMixin) {
  @Prop() value: FormRow;
  @Prop() error: GenericError;
  @Prop() defaultColor;

  @Prop() rowIndex;
  @Prop() parent;

  MAX_ELEMENTS = 6
  fileField = 'file'
  fileSimple = true

  get workValue() {
    return this.value;
  }

  set workValue(v) {
    this.$emit("input", v);
  }


  get row() {
    return this.value;
  }

  set row(v) {
    this.$emit("input", v);
  }

  removeZeilenueberschrift(row) {
    if (this.workValue.isDescription || this.workValue.isDownload) {
      this.workValue.title = null
    }

    if (!this.workValue.isDescription) {
      this.workValue.description = null
    }
  }


  addField(idx) {

    if (this.workValue.fields.length >= this.MAX_ELEMENTS) {
      this.$alert("Es dürfen maximal " + this.MAX_ELEMENTS + " Felder in einer Zeile sein!");
      return false
    }

    let field = new AdditionalField();
    this.workValue.fields.push(field);
    this.reorder()
  }

  removeField(idx) {
    if (!this.workValue.fields[idx].id) {
      this.workValue.fields.splice(idx, 1)
      this.reorder()
    } else {
      this.$confirm('Wollen Sie das Feld wirklich unwiderruflich sofort löschen?', '', 'question', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein'
      }).then(() => {
        FormApi.removeField(this.workValue.fields[idx].id).then((d) => {
          this.workValue.fields.splice(idx, 1)
          this.reorder()
        }).catch(() => {
          this.$alert("Das Feld kann nicht gelöscht werden!", "Fehler", "error");
        })
      });
    }
  }


  handleImageAdded(id) {
    this.$emit("imageAdded", id);
  }

  handleImageRemoved(id) {
    this.$emit("imageRemoved", id);
  }

  addRow() {
    this.$emit("addRow", null);

  }

  addRowAbove(index: number) {
    this.$emit("addRowAbove", index);
  }

  addRowBelow(index: number) {
    this.$emit("addRowBelow", index);
  }


  colorChanged(val) {
    this.$emit("colorChanged", val);
  }

  removeZeile(rowIndex) {
    this.$emit("removeZeile", rowIndex);
  }

  checkMove(event) {
    this.$emit("checkMove", event);
  }


  reorder() {
    this.$emit("reorder", null);
  }
}
