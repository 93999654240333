
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DiseaseDTO, GenericError} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {Disease} from "@/model/Hygiene";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {HygieneApi} from "@/services/HygieneApi";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader}
})
export default class HygieneDiseaseView extends mixins<SimpleViewMixin<DiseaseDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: DiseaseDTO = new Disease()
  error: GenericError = new GenericError()

  chainCategories: DiseaseDTO[] = []

  async created() {
    await this.init(Disease,
        HygieneApi.getDisease,
        (a) => HygieneApi.putDisease(a),
        (b) => HygieneApi.removeDisease(b)
    )
  }

  get archivTxt() {
    return "Löschen"
  }

  sort(objects) {
    return objects.sort((a, b) => a.name.localeCompare(b.name))
  }

}
