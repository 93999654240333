
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import UserEditView from "@/views/user/UserEditView.vue";
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import {MaterialApi} from "@/services/MaterialApi";


@Component(
    {
      components: {UserEditView},
      watch: {
        '$route'(val: Route) {
          //fillme
        },
        '$route.query.archive'(val) {
          this.archive = (this.$route.query.archive !== undefined)
        },
        'archive'(val) {
          jquery('#' + this.tableId).dataTable().api().ajax.url(func.rewisUrl(this.getUrl())).load();
          this.initContextMenu()

        }
      }
    }
)
export default class MaterialListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null

  getUrl() {
    return '/material/materiallist/overview/' + ((this.archive) ? 'archive' : '');
  }

  created() {
    this.archive = this.$route.query.archive !== undefined
  }


  mounted() {
    this.registerEvents([Event.materialCreated, Event.materialArchived, Event.materialChanged]);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      "scrollX": true,
      responsive: false,
      rowId: "id",
      columns: [
        {
          class: '',
          data: 'id'
        },
        {
          class: '',
          data: 'art.name'
        },
        {
          class: '',
          data: 'typ.fieldValue'
        },
        {
          class: '',
          data: 'serienNummer'
        },
        {
          class: '',
          data: 'inventarNummer'
        },
        {
          class: '',
          data: 'name'
        },
        {
          class: '',
          data: 'location',
          render: function (data, type) {
            return data ? data.name : "";
          },
        }
      ],
      buttons: func.addDefaultButtons([
        {
          className: 'text-secondary bg-primary',
          titleAttr: 'Hinzufügen',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Bearbeiten',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = table.row({selected: true}).id()
            self.edit(id)
          },
        },
        {
          className: 'text-secondary bg-white',
          titleAttr: 'Archiv',
          text: '<i class="fas fa-archive" />',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }
      ])
    })
    self.table = table
    self.initContextMenu()
    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }


  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/material/create/", query: queryParam})
  }

  edit(id) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/material/edit/" + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "materiallist", query: queryParam})
  }

  destroyed() {
    this.unregisterEvents()
  }

  initContextMenu() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this;
    const selector = '#' + this.tableId + ' tbody tr';

    jquery.contextMenu('destroy');
    jquery.contextMenu({
      selector: selector,
      items: {
        copy: {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        },
        archive: {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }
      }
    });
  }

  archiv(data) {
    this.$confirm('Sind sie Sicher ?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      MaterialApi.archivMaterial(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.materialArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

}
