
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {ApiResponse, ExistingFiles, ObjectEvent, RewisFile} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, Module} from "@/model/Constants";
import FileUpload from "@/components/FileUpload";
import {MedProduktDTO, Permission, PermissionModul} from "@/model/dto";
import MedProduktDataView from "@/views/medprodukt/MedProduktDataView.vue";
import {MedProduktEdit} from "@/model/MedProdukt";
import {MedProduktApi} from "@/services/MedProduktApi";
import MedProduktFileMedProduktView from "@/views/medprodukt/MedProduktFileMedProduktView.vue";
import MedProduktDateMedProduktView from "@/views/medprodukt/MedProduktDateMedProduktView.vue";
import MedProduktOutsourcingMedProduktView from "@/views/medprodukt/MedProduktOutsourcingMedProduktView.vue";
import MedProduktChildDataView from "@/views/medprodukt/MedProduktChildDataView.vue";
import EwoFileEwoView from "@/views/ewmpg/EwoFileEwoView.vue";
import {EinweisungOverviewRequest} from "@/model/Einweisung";
import EinweisungOverviewTable from "@/views/einweisung/EinweisungOverviewTable.vue";
import EntityTaskListView from "@/views/task/entity/EntityTaskListView.vue";
import EntityTaskTemplateEntityView from "@/views/task/entity/EntityTaskTemplateEntityView.vue";
import EntityFormListServersideView from "@/views/task/entity/EntityFormListServersideView.vue";
import {FileApi} from "@/services/FileApi";


const DataProps = Vue.extend({
  props: {
    id: String,
    from: String
  }
})
@Component({
  computed: {
    Module() {
      return Module
    },
    FileApi() {
      return FileApi
    }
  },
  components: {
    EntityFormListServersideView,
    EntityTaskTemplateEntityView, EntityTaskListView,
    EinweisungOverviewTable,
    EwoFileEwoView,
    MedProduktChildDataView,
    MedProduktOutsourcingMedProduktView,
    MedProduktDateMedProduktView,
    MedProduktFileMedProduktView,
    MedProduktDataView,
    FileUpload
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class MedProduktEditView extends mixins<GenericMixin<MedProduktDTO, MedProduktDTO>>(GenericMixin, DataProps) {
  data = new MedProduktEdit();
  error = new MedProduktEdit();
  showTabs = false
  tabIndex = 0
  errorTabs = []
  uploadKey = 0
  basePath = process.env.VUE_APP_REWIS
  showProfilePhotoUploadModal = false
  existingProfilePhoto = null
  existingEwoPhoto = null

  einweisungRequest: EinweisungOverviewRequest | null = null

  @Prop({default: 0}) idx: number

  created() {
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')

    this.tabIndex = Number(this.idx)

    const p = MedProduktApi.getMedProdukt(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true

      this.einweisungRequest = new EinweisungOverviewRequest();
      this.einweisungRequest.medProduktId = this.$props.id

      if (this.data.profilePhoto) this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
      if (this.data.ewo.profilePhoto) this.existingEwoPhoto = new ExistingFiles([this.data.ewo.profilePhoto])
    })
    this.uploadKey += 1
  }

  editProfilePhoto() {
    this.showProfilePhotoUploadModal = true
  }

  profilePhotoFileUploadSuccess(d) {
    let file = new RewisFile(d);

    MedProduktApi.updatePhoto(this.data.id, file)
        .then((photo) => {
          this.showProfilePhotoUploadModal = false
          this.data.profilePhoto = photo
          this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
        })
        .catch((e) => {
          this.$alert("Beim Upload des Bildes trat ein Fehler auf!");
        });
  }

  profilePhotoFileDelete(d) {
    MedProduktApi.removePhoto(this.data.id)
        .then((photo) => {
          this.data.profilePhoto = null
          this.existingProfilePhoto = null
        })
        .catch((e) => {
          this.$alert("Beim Löschen des Profilbiles trat ein Fehler auf!");
        });
  }

  archiv() {
    const title = this.data.archive ? 'Reaktivieren' : 'Archivieren';
    this.$prompt('Bitte geben Sie einen Grund an.', '', title).then((reason) => {
      MedProduktApi.archiveMedProdukt(this.data.id, {reason: reason})
          .then(() => {
            this.data.archive = !this.data.archive
            bus.$emit(Event.medproduktArchived, new ObjectEvent(this.$props.id, this.data));

            this.data.childs.forEach(child => {
              child.archive = !child.archive
              bus.$emit(Event.medproduktArchived, new ObjectEvent(child.id, child));
            })

            this.goBack()
          })
          .catch((e) => {
            this.$alert(e.error, 'Fehler', 'error');
          })
    });
  }

  onSubmit(goBack: boolean) {
    this.errorTabs = []

    const dtoCopy = structuredClone(this.data)
    const p = MedProduktApi.putMedProdukt(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      jquery("#medprodukttabs .tab-pane").each(
          function (index) {
            if (jquery(this).find('.is-invalid').length > 0) {
              self.errorTabs.push(parseInt(this.getAttribute('tab')));
            }
          }
      );

      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send medproduktChanged event')
        bus.$emit(Event.medproduktChanged, new ObjectEvent(this.$props.id, data.data));
        const medProduktDto: MedProduktDTO = data.data


        if (this.$store.state.route.from.name === 'medproduktlist') {
          medProduktDto.childs.forEach(child => { //childs updaten, wenn in "Alle Geräte" ansicht
            bus.$emit(Event.medproduktChanged, new ObjectEvent(child.id, child));
          })

          dtoCopy.deleteChilds.forEach(childId => { //child wird archiviert
            bus.$emit(Event.medproduktArchived, new ObjectEvent(childId, {archive: true}));
          })
        } else {
          dtoCopy.existingChilds.forEach(child => { //gerät wird in verbund aufgenommen (aus Übersicht entfernen)
            bus.$emit(Event.medproduktArchived, new ObjectEvent(child.id, {archive: true}));
          })

          dtoCopy.independentChilds.forEach(childId => { //neues eigenständiges gerät (in Übersicht einfügen)
            let tmpIndependent = dtoCopy.childs.find(child => child.id == childId);
            if (tmpIndependent != undefined) {
              bus.$emit(Event.medproduktCreated, new ObjectEvent(childId, tmpIndependent));
            }
          })
        }


        if (goBack) {
          this.goBack()
        }
      }
    })
  }

  goBack() {/*
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }


    this.$router.push({path: "../", query: queryParam})*/


    let from = this.$props.from ? this.$props.from : this.$store.state.route.from.fullPath;
    if (!from.includes("medprodukt")) {
      console.log("Fallback redirect")
      from = "/medprodukt/overview"
    }

    if (from.includes("edit")) {
      from = "/medprodukt/overview"
    }

    this.$router.push({path: from})
  }

  get archivTxt() {
    if (!this.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Archive)) {
      return null
    }
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Update)
  }

  get overviewAllPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Overview)
  }

  addChild() {
    let newMedProdukt = new MedProduktEdit();
    newMedProdukt.anschaffungsJahr = this.data.anschaffungsJahr
    newMedProdukt.ausserbetriebnahmeDatum = this.data.ausserbetriebnahmeDatum
    newMedProdukt.inbetriebnahmeDatum = this.data.inbetriebnahmeDatum
    newMedProdukt.kommentar = this.data.kommentar
    newMedProdukt.temporary = this.data.temporary
    newMedProdukt.qrCode = {code: '', id: null}

    this.data.childs.push(newMedProdukt)
    this.$refs["bottom"].scrollIntoView({behavior: "smooth"})
  }

  addExistingChild() {
    this.data.existingChilds.push(new MedProduktEdit());
    this.$refs["bottom"].scrollIntoView({behavior: "smooth"})

  }

  @Watch('tabIndex')
  public watchValue(newValue, oldvalue) {
    const newRoute = "/medprodukt/edit/" + this.$props.id + "/" + this.tabIndex
    if (this.$route.fullPath != newRoute) {
      this.$router.replace(newRoute)
    }
  }
}
