import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {CrudEvent} from "@/model/AbstractClasses";
import {OutsourcingApi} from "@/services/OutsourcingApi";
import {Event} from "@/model/Constants";
import {StoreHelper} from "@/store/StoreHelper";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        kategorien: []
    },
    mutations: {
        SET_KATEGORIEN(state: any, data: any) {
            state.kategorien = data
        }
    },
    actions: {
        async fetchKategorien({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.kategorien && state.kategorien.length > 0) {
                    resolve(state.kategorien)
                } else {
                    OutsourcingApi.getCategories()
                        .then(data => {
                            commit('SET_KATEGORIEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.outsourcingCategoryChanged:
                    StoreHelper.update("SET_KATEGORIEN", state.kategorien, event, commit)
                    break;
            }
        },
    },
    getters: {},
    modules: {}
}
