
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {OrgUnitTreeDTO} from "@/model/dto";
import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import {FileApi} from "@/services/FileApi";

const DataProps = Vue.extend({
  props: {
    id: String,
  }
});

@Component({
  components: {OrgUnitSelectorLiquor},
})
export default class FileLexTreeDocs extends mixins<GenericMixin<null, null>>(GenericMixin, DataProps) {
  selectedId = null;
  tree: OrgUnitTreeDTO[] = [];
  key = 0;
  loading = true; // Initialisierungswert für das Laden

  async created() {
    this.init();
  }

  async init() {
    try {
      this.tree = await FileApi.getFileLexTree();
    } finally {
      this.loading = false; // Ladeanzeige stoppen, wenn der Baum geladen ist
      this.key++;
    }
  }

  treeClicked(dto) {
    this.selectedId = dto.id;
    this.$parent.treeDto = dto;
    this.$router.push({path: "/docs/tree/docs/" + dto.id});
  }
}
