import _Vue from "vue";
import Swal, {SweetAlertIcon, SweetAlertOptions, SweetAlertResult,} from "sweetalert2";

const primaryColor = "#3085d6"

export class FsSimpleAlert {
    public static globalOptions: SweetAlertOptions;
    public static alert(
        message?: string,
        title?: string,
        type?: SweetAlertIcon,
        html = false,
        options?: SweetAlertOptions
    ): Promise<boolean> {
        return new Promise(resolve => {
            const mixedOptions: SweetAlertOptions = {
                ...FsSimpleAlert.globalOptions,
                ...options
            };

            if (html) mixedOptions.html = message;
            mixedOptions.title = title || mixedOptions.title;
            mixedOptions.text = message || mixedOptions.text;
            mixedOptions.icon = type || mixedOptions.icon;
            Swal.fire(mixedOptions)
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    resolve(true);
                });
        });
    }

    public static confirm(
        message?: string,
        title?: string,
        type?: SweetAlertIcon,
        options?: SweetAlertOptions
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const mixedOptions: SweetAlertOptions = {
                ...FsSimpleAlert.globalOptions,
                ...options
            };
            mixedOptions.title = title || mixedOptions.title;
            mixedOptions.text = message || mixedOptions.text;
            mixedOptions.icon = type || mixedOptions.icon;
            mixedOptions.showCancelButton = true;

            Swal.fire(mixedOptions)
                .then((r: SweetAlertResult) => {
                    if (r.value === true) {
                        // Closed by OK button
                        resolve(true);
                    } else reject();
                })
                .catch(() => reject());
        });
    }

    public static prompt(
        message: string,
        defaultText?: string,
        title?: string,
        type?: SweetAlertIcon,
        options?: SweetAlertOptions
    ): Promise<string> {
        return new Promise((resolve, reject) => {
            const mixedOptions: SweetAlertOptions = {
                ...FsSimpleAlert.globalOptions,
                ...options
            };
            mixedOptions.title = title || mixedOptions.title;
            mixedOptions.inputValue = defaultText;
            mixedOptions.text = message || mixedOptions.text;
            mixedOptions.icon = type || mixedOptions.icon;
            mixedOptions.showCancelButton = true;
            mixedOptions.cancelButtonText = "Abbruch"
            mixedOptions.input = mixedOptions.input || "text";

            Swal.fire(mixedOptions)
                .then(r => {
                    if (r.isConfirmed) {
                        // Closed by OK button
                        resolve(r.value);
                    } else reject();
                })
                .catch(() => {
                    return reject();
                });
        });
    }

    public static fire(options: SweetAlertOptions): Promise<SweetAlertResult> {
        return Swal.fire(options);
    }

    static install(Vue: typeof _Vue, options: SweetAlertOptions): void {
        FsSimpleAlert.globalOptions = options;

        // Global properties
        Vue.alert = FsSimpleAlert.alert;
        Vue.confirm = FsSimpleAlert.confirm;
        Vue.prompt = FsSimpleAlert.prompt;
        Vue.fire = FsSimpleAlert.fire;

        // Instance properties
        Vue.prototype.$alert = FsSimpleAlert.alert;
        Vue.prototype.$confirm = FsSimpleAlert.confirm;
        Vue.prototype.$prompt = FsSimpleAlert.prompt;
        Vue.prototype.$fire = FsSimpleAlert.fire;

    }
}

declare module "vue/types/vue" {
    interface Vue {
        $alert: typeof FsSimpleAlert.alert;
        $confirm: typeof FsSimpleAlert.confirm;
        $prompt: typeof FsSimpleAlert.prompt;
        $fire: typeof FsSimpleAlert.fire;
    }

    interface VueConstructor {
        alert: typeof FsSimpleAlert.alert;
        confirm: typeof FsSimpleAlert.confirm;
        prompt: typeof FsSimpleAlert.prompt;
        fire: typeof FsSimpleAlert.fire;
    }
}

export default FsSimpleAlert;
