
import {Component, Prop, Vue} from "vue-property-decorator";
import Multiselect from "@/libs/multiselect";
import {TaskDTO} from "@/model/dto";
import TaskChatView from "@/views/task/TaskChatView.vue";


@Component(
    {
      components: {TaskChatView, Multiselect}
    }
)
export default class TaskChatModal extends Vue {
  @Prop() private value;
  @Prop() private selectedItem: TaskDTO;
  cleanText = ""


  get showModal() {
    return this.value
  }
  set showModal(v) {
    this.$emit("input", v);
  }
}
