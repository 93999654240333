
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, OrgUnitEvdDTO, UserResponseDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {OrgUnitEvd} from "@/model/OrgUnit";
import {OrgUnitApi} from "@/services/OrgUnitApi";
import Multiselect from "@/libs/multiselect";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {Multiselect}
})
export default class EvdView extends mixins<SimpleViewMixin<OrgUnitEvdDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: OrgUnitEvdDTO = new OrgUnitEvd()
  error: GenericError = new GenericError()

  userOpts: UserResponseDTO[] = []

  async created() {
    this.userOpts = await this.$store.dispatch("user/fetchUser")

    await this.init()
  }

  async init() {
    this.data = await OrgUnitApi.getEvd()
    this.$root.$emit('loadingDone')
  }


  onSubmit(goBack: boolean) {
    const p = OrgUnitApi.putEvd(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      this.init()
    })
  }

}
