
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {EwoDTO} from "@/model/dto";
import EwoDataView from "@/views/ewmpg/EwoDataView.vue";
import {EwoEdit} from "@/model/Ewmpg";
import {EwmpgApi} from "@/services/EwmpgApi";


@Component({
  components: {
    EwoDataView,
  }
})
export default class EwoCreateView extends mixins<GenericMixin<EwoDTO, EwoDTO>>(GenericMixin) {
  data = new EwoEdit();
  error = new EwoEdit();
  errorTabs = []

  mounted() {
    this.data.einweisungsPflicht = 0
  }

  onSubmit(goBack: boolean) {
    this.errorTabs = []
    const p = EwmpgApi.putEwo(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      let queryParam = {}
      if (this.$route.query.archive !== undefined) {
        queryParam = {archive: 1}
      }


      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send ewoCreated event')
        bus.$emit(Event.ewoCreated, new ObjectEvent(data.data.id, data.data));
        this.$router.push({path: "/ewo/edit/" + data.data.id, query: queryParam})
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "../", query: queryParam})
  }

}
