
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {FileCategoryDTO, GenericError, PersonenkreisDTO, UserFileCategoryDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {UserFileCategory} from "@/model/File";
import {FileApi} from "@/services/FileApi";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";


import Column from "primevue/column/Column";
import DataTable from "primevue/datatable/DataTable";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import ContextMenu from "primevue/contextmenu/ContextMenu";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";

@Component({
  components: {
    ListItemComponent,
    ChainIconTooltipTableCell,
    DataTable, Column, ContextMenu,
    SimpletableButtonHeader, SettingsTooltip,
    PersonenkreisTag, PersonenkreisChooser, Multiselect,
  }
})
export default class UserFileCategoriesView extends mixins<SimpleViewMixin<UserFileCategory, GenericError>>(SimpleViewMixin) {
  data: UserFileCategoryDTO = new UserFileCategory()
  error: GenericError = new GenericError()
  archivText = 'Löschen'

  fileCategories: FileCategoryDTO[] = []
  personenkreisOptions: PersonenkreisDTO[] = []

  menuModel = []

  async created() {
    this.menuModel = [
      {label: 'Bearbeiten', icon: 'fas fa-pen', command: () => this.editObject(this.selectedItem)},
      {label: 'Löschen', icon: 'fas fa-trash', command: () => this.removeObject(this.selectedItem)}
    ]

    await this.init(UserFileCategory,
        () => this.$store.dispatch('user/fetchDateiKategorienOu'),
        (a) => FileApi.putUserFileCategoryOu(a),
        (b) => FileApi.removeUserFileCategoryOu(b)
    )

    this.fileCategories = await this.$store.dispatch('user/fetchDateiKategorienKatalog');
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.$root.$emit('loadingDone')
  }


  getTagFromChain(id) {
    let arr = []
    this.objects.filter((ufk) => ufk.fileCategory.id == id).some((ufk: UserFileCategoryDTO) => {
      arr.push(...ufk.personenkeis);
    })
    return arr
  }
}
