<template>
  <div v-if="loadingComplete" style="border: 1px solid black; ">
    <Chat
        style="height: 60vh;"
        :border-style="borderStyle"
        :participants="participants"
        :myself="myself"
        :messages="messages"
        :chat-title="chatTitle"
        :placeholder="placeholder"
        :colors="colors"
        :scroll-bottom="scrollBottom"
        :profile-picture-config="profilePictureConfig"
        :timestamp-config="timestampConfig"
        @onMessageSubmit="onMessageSubmit"
        :hide-close-button="true"
        :submit-icon-size="25"
        :load-more-messages="null"
        :async-mode="true"
        :display-header="true"
        :send-images="false"
    >
      <template v-slot:header>
        <div>
          <span class="text-white" style="font-size: 1.25rem">
            <span v-if="chatDto.referenceType === Module.TASK" @click="$emit('task', chatDto.reference)"  class="mr-2 pointer">
              <i class="fas fa-clipboard"></i>
            </span>
              {{ chatTitle }}
          </span>

          <span class="text-white members" style="font-size: 0.75rem">
            {{ participants.map(p => p.name).join(", ") }}
          </span>
        </div>
      </template>
    </Chat>
  </div>
</template>

<script>
import {Chat} from 'vue-quick-chat';
import 'vue-quick-chat/dist/vue-quick-chat.css';
import {ChatApi} from "@/services/ChatApi";
import {FullChatDTO} from "@/model/dto";
import {ApiResponse} from "@/model/AbstractClasses";
import {Module} from "@/model/Constants";

export default {
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    Chat
  },
  props: ['id'],
  async created() {
    ChatApi.getChat(this.$props.id)
        .then(chat => {
          this.messages = chat.messages
          this.participants = chat.users
          this.chatTitle = chat.name
          this.loadingComplete = true
          this.chatDto = chat
        })

  },
  data() {
    return {
      chatDto: null,
      loadingComplete: false,
      placeholder: 'Nachricht senden',
      colors: {
        header: {
          bg: '#d30303',
          text: '#fff'
        },
        message: {
          myself: {
            bg: '#fff',
            text: 'black'
          },
          others: {
            bg: '#fb4141',
            text: '#fff'
          },
          messagesDisplay: {
            bg: '#f7f3f3'
          }
        },
        submitIcon: '#b91010',
      },
      scrollBottom: {
        messageSent: true,
        messageReceived: false
      },
      profilePictureConfig: {
        others: false,
        myself: false,
      },
      timestampConfig: {
        format: 'dd.MM.yy HH:mm',
        relative: false
      },
      borderStyle: {
        topLeft: "0px",
        topRight: "0px",
        bottomLeft: "0px",
        bottomRight: "0px",
      },
      participants: [],
      myself: {
        name: this.$store.getters.getUser.formatted,
        id: this.$store.getters.getUser.id,
      },
      messages: [],
      chatTitle: '',
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
    }
  },
  methods: {
    onMessageSubmit: async function (message) {
      ChatApi.putMessage(this.$props.id, message)
          .then(data => {
            if (data instanceof ApiResponse && data.data != undefined) {
              this.messages.push(data.data);
            }
          })
          .catch(err => {
            this.$alert("Nachricht konnte nicht gesendet werden!", "Fehler", "error");
          })
    }
  }
}
</script>
<style>
.members {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>