
import {Component, Prop, Vue} from 'vue-property-decorator';
import {AdditionalFieldDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";

@Component({
  components: {NumberForm, Multiselect}
})export default class AdditionalFieldForm extends Vue {
  @Prop() private value: AdditionalFieldDTO;
  @Prop() private error;
  @Prop() private hideLabelField;
  @Prop() private disableFieldTyp;
  @Prop() private idx;

  showOptionModal = false

  fieldTypOptions = []
  identifierOptions = []

  geraeteArt = []
  materialArtOpts = []


  async created() {
    this.fieldTypOptions = [
      {value: 1, text: 'Textfeld'},
      {value: 2, text: 'Mehrzeiliges Textfeld'},
      {value: 3, text: 'Datum'},
      {value: 4, text: 'Uhrzeit'},
      {value: 5, text: 'Checkbox'},
      {value: 6, text: 'Zahl'},
      {value: 7, text: 'Auswahl ja/nein'},
      {value: 8, text: 'Auswahlfeld mit eigenen Werten'},
    ];

    if (this.$route.name.includes("form")) {
      this.fieldTypOptions = [
        ...this.fieldTypOptions, ...
            [
              {value: 20, text: 'Medizinprodukt'},
              {value: 21, text: 'Fahrzeug'},
              {value: 22, text: 'Benutzer'},
              {value: 23, text: 'Material'},
              {value: 24, text: 'Fahrzeug mit Kilometereingabe'},
              {value: 25, text: 'Funkrufname'},
              {value: 26, text: 'Dienst'},
              {value: 27, text: 'Qualifikation'},
              {value: 30, text: 'Datei'},

            ]
      ]
    }


    this.identifierOptions = await this.$store.dispatch("form/fetchFieldIdentifer")
    this.geraeteArt = await this.$store.dispatch('medprodukt/fetchGeraeteart')

    this.materialArtOpts = await this.$store.dispatch("material/fetchMaterialArt")

  }

  addTag(value) {
    this.workingValue.options.push(value)
  }

  addIdentifier(value) {
    this.identifierOptions.push(value)
    this.workingValue.identifier = value
  }


  typChanged(value) {
    this.workingValue.checkinField = null
    this.workingValue.bCheckinBezug = false
    this.workingValue.multi = false
    if (this.workingValue.fieldTyp != 8) {
      this.workingValue.options = []
    }
  }

  get workingValue() {
    return this.value
  }

  set workingValue(value) {
    this.$emit("input", value);
    this.$emit("change", value);
  }

  get checkinFieldOpts() {
    const defaultOpts = [{text: 'Auswählen...', value: null}]


    if (this.workingValue.fieldTyp == 3) { //DATUM
      return [...defaultOpts, {text: 'Startdatum', value: 'datumStart'}, {text: 'Enddatum', value: 'datumEnde'},]
    } else if (this.workingValue.fieldTyp == 4) { //ZEIT
      return [...defaultOpts, {text: 'Startzeit', value: 'zeitStart'}, {text: 'Endzeit', value: 'zeitEnde'},]
    } else if (this.workingValue.fieldTyp == 21 || this.workingValue.fieldTyp == 24) { //kfz
      return [...defaultOpts, {text: 'Fahrzeug', value: 'kfz'}]
    } else if (this.workingValue.fieldTyp == 25) { //kennung
      return [...defaultOpts, {text: 'Kennung', value: 'kennung'}]
    } else if (this.workingValue.fieldTyp == 26) { //dienst
      return [...defaultOpts, {text: 'Dienst', value: 'dienst'}]
    } else if (this.workingValue.fieldTyp == 22) { //user
      return [...defaultOpts, {text: 'Fahrer', value: 'fahrer'}, {text: 'Beifahrer', value: 'beifahrer'}, {text: 'Dritter', value: 'driter'}]
    }

    return []
  }
}
