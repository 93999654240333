
import {Component, Prop, Watch} from 'vue-property-decorator';
import {FileLexDTO, GenericError, ResponseDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FileLex} from "@/model/File";
import Multiselect from '@/libs/multiselect'
import {ExistingFiles, RewisFile} from "@/model/AbstractClasses";
import FileUpload from "@/components/FileUpload.vue";
import {FileApi} from "@/services/FileApi";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {FileUpload, Multiselect},
})
export default class FileLexEditModal extends mixins<GenericMixin<FileLexDTO, GenericError>>(GenericMixin) {
  @Prop() private value;
  @Prop() private id;

  data: FileLexDTO = new FileLex()
  error: GenericError = new GenericError()


  existingFile = null;
  showModal = false
  uploadKey = 0
  fileCategoryOpts = []

  init(newValue) {
    this.data = new FileLex()
    this.existingFile = null

    if (newValue != null) {
      this.data = structuredClone(newValue)

      if (this.data.file) {
        this.existingFile = new ExistingFiles([this.data.file])
      }
    }
  }


  async created() {
    this.init(this.$props.value)
    this.fileCategoryOpts = await this.$store.dispatch("filelex/fetchKategorien")
  }

  fileUploadSuccess(d) {
    let file = new RewisFile(d);

    this.data.file = file
    this.$emit('input', this.data)
  }

  fileDeleted(d) {
    this.data.file = null
    this.existingFile = null
    this.$emit('input', this.data)
  }


  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
    this.uploadKey++
  }

  close() {
    this.$bvModal.hide(this.$props.id)
  }


  save() {
    const p = FileApi.putFilelex(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('input', this.data)
        this.$bvModal.hide(this.$props.id)
        this.$emit('change', this.data)
      }
    });
  }


}
