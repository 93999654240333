
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {GegenstandListItemDTO, KfzDTO} from "@/model/dto";
import {StandortApi} from "@/services/StandortApi";
import GegenstandListItemComponent from "@/views/shared/components/GegenstandListItemComponent.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";


@Component(
    {
      components: {LabelComponent, ListItemComponent, GegenstandListItemComponent},
    }
)
export default class KfzLocationItemsKfzView extends Vue {
  @Prop() private kfz: KfzDTO;
  @Prop() private type: string;
  standortList: GegenstandListItemDTO[] = []
  primStandortList: GegenstandListItemDTO[] = []

  searchQuery = '';
  filteredStandortList: GegenstandListItemDTO[] = [];
  filteredPrimStandortList: GegenstandListItemDTO[] = [];


  async created() {
    this.$root.$emit('loadingStart')
    const response = await StandortApi.getKfzLocationItems(this.kfz.id, this.type)

    this.standortList = response['standort'];
    this.primStandortList = response['primStandort'];

    this.filteredStandortList = this.standortList;
    this.filteredPrimStandortList = this.primStandortList;
    this.$root.$emit('loadingDone')
  }

  gegenstandClicked(item) {
    if (item.id.includes("medProdukt")) {
      this.$router.push({path: "/medprodukt/edit/" + item.dto.id})
    } else if (item.id.includes("material")) {
      this.$router.push({path: "/material/edit/" + item.dto.id})
    }
  }

  filterItems() {
    const searchLower = this.searchQuery.toLowerCase();

    this.filteredStandortList = this.standortList.filter(item => {
      return item.dto.searchText.toLowerCase().includes(searchLower)
    });

    this.filteredPrimStandortList = this.primStandortList.filter(item => {
      return item.dto.searchText.toLowerCase().includes(searchLower)
    });
  }

  get searchPlaceholder(): string {
    if (this.type === 'medProdukt') {
      return 'Suche nach Medizinprodukt';
    } else if (this.type === 'material') {
      return 'Suche nach Material';
    }
    return 'Suche nach Gegenstand';
  }
}
