<template>
  <div>
    <div v-show="!loading" class="quillWrapper" ondragstart="return false;" ondrop="return false;" draggable="false">
      <slot name="toolbar"></slot>
      <div style="min-height: 15rem;" :id="id" ref="quillContainer" ondragstart="return false;" ondrop="return false;" draggable="false"></div>
      <input
          v-if="useCustomImageHandler"
          id="file-upload"
          ref="fileInput"
          type="file"
          accept="image/*"
          style="display: none;"
          @change="emitImageInfo($event)"
      />
    </div>
    <b-spinner class="my-2" v-if="loading"></b-spinner>

  </div>
</template>

<script>
import Quill from "quill";
import defaultToolbar from "../helpers/default-toolbar";
import oldApi from "../helpers/old-api";
import mergeDeep from "../helpers/merge-deep";
import QuillBetterTable from "quill-better-table";
import {QuillToolbarButton} from "../helpers/DynamicQuillTools";
import jquery from "jquery";
import {FileApi} from "@/services/FileApi";

export default {
  name: "VueEditor",
  mixins: [oldApi],
  props: {
    id: {
      type: String,
      default: "quill-container"
    },
    foreignKey: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean
    },
    editorToolbar: {
      type: Array,
      default: () => []
    },
    editorOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    useCustomImageHandler: {
      type: Boolean,
      default: false
    },
    useMarkdownShortcuts: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    quill: null,
    loading: false
  }),

  watch: {
    value(val) {
      if (val != this.quill.root.innerHTML && !this.quill.hasFocus()) {
        this.quill.root.innerHTML = val;
      }
    },
    disabled(status) {
      this.quill.enable(!status);
    }
  },

  mounted() {
    this.registerCustomModules(Quill);
    this.registerPrototypes();
    this.initializeEditor();
  },

  beforeDestroy() {
    this.quill = null;
    delete this.quill;
  },

  methods: {
    initializeEditor() {
      this.setupQuillEditor();
      this.checkForCustomImageHandler();
      this.handleInitialContent();
      this.registerEditorEventListeners();
      this.$emit("ready", this.quill);
    },

    setupQuillEditor() {
      const editorConfig = {
        debug: false,
        modules: this.setModules(),
        theme: "snow",
        placeholder: this.placeholder ? this.placeholder : "",
        readOnly: this.disabled ? this.disabled : false
      };


      this.prepareEditorConfig(editorConfig);
      this.quill = new Quill(this.$refs.quillContainer, editorConfig);
      this.quill.root.addEventListener('dragstart', e => {
        e.preventDefault();
      });

      this.addCustomToolbar();
    },

    setModules() {
      const modules = {
        toolbar: this.editorToolbar.length ? this.editorToolbar : defaultToolbar
      };

      modules["table"] = false;

      modules["history"] = { maxStack: 0, userOnly: true, }


      const Clipboard = Quill.import("modules/clipboard");

      class ExtendedClipboard extends Clipboard {
        constructor(quill, options) {
          quill.root.addEventListener("paste", (args) => {
            console.log("custom_paste_2");
            // cancel on demand;
            args.preventDefault();
          });
          super(quill, options);
          //console.log("create_clipboard");
        }
      }

      /*   Quill.register(
             {
               "modules/clipboard": ExtendedClipboard
             },
             true
         );*/


      // better-table module config
      Quill.register(
          {
            "modules/better-table": QuillBetterTable
          },
          true
      );

      modules["better-table"] = {
        operationMenu: {
          items: {
            unmergeCells: {
              text: "Another unmerge cells name"
            }
          }
        }
      };

      //modules["keyboard"] = {
      // bindings: QuillBetterTable.keyboardBindings
      //};
      // better-table module config end
      return modules;
    },

    addCustomToolbar() {
      const myButton = new QuillToolbarButton({
        icon: `<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill="none" stroke="#000" stroke-width="2" d="M8,5 L8,23 M16,5 L16,23 M1,11 L23,11 M1,5 L23,5 M1,17 L23,17 M1,1 L23,1 L23,23 L1,23 L1,1 Z"/>
</svg>`
      });
      myButton.onClick = function (quill) {
        // adding better vue table
        let tableModule = quill.getModule("better-table");
        tableModule.insertTable(3, 3);
        // For example, get the selected text and convert it to uppercase:
      };
      myButton.attach(this.quill);
    },

    prepareEditorConfig(editorConfig) {
      if (
          Object.keys(this.editorOptions).length > 0 &&
          this.editorOptions.constructor === Object
      ) {
        if (
            this.editorOptions.modules &&
            typeof this.editorOptions.modules.toolbar !== "undefined"
        ) {
          // We don't want to merge default toolbar with provided toolbar.
          delete editorConfig.modules.toolbar;
        }

        mergeDeep(editorConfig, this.editorOptions);
      }
    },

    registerPrototypes() {
      Quill.prototype.getHTML = function () {
        return this.container.querySelector(".ql-editor").innerHTML;
      };
      Quill.prototype.getWordCount = function () {
        return this.container.querySelector(".ql-editor").innerText.length;
      };
    },

    registerEditorEventListeners() {
      this.quill.on("selection-change", this.handleSelectionChange);
      this.listenForEditorEvent("selection-change");
      this.listenForEditorEvent("editor-change");

      this.quill.on('text-change', (delta, oldContents, source) => {
        //Image remove handling
        const deleted = getImgUrls(this.quill.getContents().diff(oldContents));
        if (deleted.length) {
          deleted.forEach(image => {
            if (image.includes('http://') || image.includes('https://')) {
              console.log("http img to delete found: ", image)
              const pieces = image.split("/")
              const last = pieces[pieces.length - 1]
              this.$emit("image-removed", last);
              this.sendChanges(); //force to send change after img delete
            }
          });
        }

        //Image add handling
        for (let index = 0; index < delta['ops'].length; index++) {
          if (source !== 'user') break;

          // eslint-disable-next-line no-prototype-builtins
          if (delta['ops'][index] && delta['ops'][index]['insert'] && delta['ops'][index].insert.hasOwnProperty('image')) {
            if (
                delta['ops'][index]['insert']['image'].includes('http://')
                || delta['ops'][index]['insert']['image'].includes('https://')
            ) {
              console.log('external image ' + delta['ops'][index]['insert']['image'])
              continue;
            }

            this.loading = true

            const formData = new FormData();
            formData.append("image", delta['ops'][index]['insert']['image']);

            // Save current cursor state
            const range = this.quill.getSelection(true);
            // Move cursor to right side of image (easier to continue typing)
            this.quill.setSelection(range.index + 1);

            FileApi.postImage(formData)
                .then(res => {
                  this.quill.setContents(oldContents); //remove inserted image

                  // Insert uploaded image
                  this.quill.insertEmbed(range.index, 'image', res.uri);
                  this.$emit('image-added', res.id);

                  this.loading = false
                  this.sendChanges();
                })
                .catch(e => {
                  this.quill.setContents(oldContents); //remove inserted image

                  alert('Fehler beim Upload');
                  this.loading = false
                });

            break; //nur ein Bild einfügen
          }
        }


        //Send changes
        if (!this.loading) { //wenn kein Bild hochgeladen wird
          this.sendChanges();
        }
      });

      function getImgUrls(delta) {
        return delta.ops.filter(i => i.insert && i.insert.image).map(i => i.insert.image);
      }
    },

    sendChanges() {
      const editorContent =
          this.quill.getHTML() === "<p><br></p>" ? "" : this.quill.getHTML();
      this.$emit("input", editorContent);
    },

    listenForEditorEvent(type) {
      this.quill.on(type, (...args) => {
        this.$emit(type, ...args);
      });
    },

    handleInitialContent() {
      if (this.value) this.quill.root.innerHTML = this.value; // Set initial editor content
    },

    handleSelectionChange(range, oldRange) {
      if (!range && oldRange) this.$emit("blur", this.quill);
      else if (range && !oldRange) this.$emit("focus", this.quill);
    },

    handleImageRemoved(delta, oldContents) {
      const currrentContents = this.quill.getContents();
      const deletedContents = currrentContents.diff(oldContents);
      const operations = deletedContents.ops;

      operations.map(operation => {
        // eslint-disable-next-line no-prototype-builtins
        if (operation.insert && operation.insert.hasOwnProperty("image")) {
          const {image} = operation.insert;
          this.$emit("image-removed", image);
        }
      });
    },
    checkForCustomImageHandler() {
      this.useCustomImageHandler === true ? this.setupCustomImageHandler() : "";
    },

    setupCustomImageHandler() {
      const toolbar = this.quill.getModule("toolbar");
      toolbar.addHandler("image", this.customImageHandler);
    },

    customImageHandler() {
      this.$refs.fileInput.click();
    },

    emitImageInfo($event) {
      const resetUploader = function () {
        var uploader = document.getElementById("file-upload");
        uploader.value = "";
      };
      const file = $event.target.files[0];
      const Editor = this.quill;
      const range = Editor.getSelection();
      const cursorLocation = range.index;
      this.$emit("image-added", file, Editor, cursorLocation, resetUploader);
    }
  }
};
</script>

<style src="quill/dist/quill.snow.css"></style>
<style src="../assets/vue2-editor.scss" lang="scss"></style>
<style src="../assets/quill-better-table.css"></style>
