import { render, staticRenderFns } from "./SuchenPersonListComponent.vue?vue&type=template&id=805aaa64&scoped=true&"
import script from "./SuchenPersonListComponent.vue?vue&type=script&lang=ts&"
export * from "./SuchenPersonListComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805aaa64",
  null
  
)

export default component.exports