
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DateTypDTO, EwmpgDateTypDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {EwmpgDateTyp} from "@/model/Date";
import {DateApi} from "@/services/DateApi";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import LabelComponent from "@/components/LabelComponent.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {LabelComponent, SimpletableButtonHeader, NumberForm, Multiselect}
})
export default class EwoDateEwoView extends mixins<SimpleViewMixin<EwmpgDateTypDTO, EwmpgDateTypDTO>>(SimpleViewMixin, DataProps) {
  data: EwmpgDateTypDTO = new EwmpgDateTyp()
  error: EwmpgDateTypDTO = new EwmpgDateTyp()
  @Prop() private id: string;

  dateTypes: DateTypDTO[] = []

  async created() {
    this.$root.$emit('loadingStart')
    this.dateTypes = await this.$store.dispatch("medprodukt/fetchDateTypCatalog")

    await this.init(EwmpgDateTyp, DateApi.getEwoDatesByEwo, (a) => {
      a.ewo = {id: this.$props.id};
      return DateApi.putEwoDate(a);
    }, (b) => DateApi.removeEwoDate(b), this.$props.id)
    this.$root.$emit('loadingDone')
  }

  sort(objects) {
    return objects.sort((a, b) => a.dateTyp.name.localeCompare(b.dateTyp.name))
  }

  get archivTxt() {
    return "Löschen"
  }


}
