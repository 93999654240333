
import {Component, Prop, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import Multiselect from "@/libs/multiselect";
import EinsatzNavComponent from "@/views/einsatz/components/EinsatzNavComponent.vue";
import EinsatzListServersideView from "@/views/einsatz/EinsatzListServersideView.vue";
import CheckinListView from "@/views/einsatz/CheckinListView.vue";
import FormListView from "@/views/form/FormListView.vue";


@Component(
    {
      components: {
        FormListView,
        CheckinListView,
        EinsatzListServersideView,
        EinsatzNavComponent,
        DatatableButtonHeader,
        Multiselect
      },
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class EinsatzTables extends Vue {
  @Prop() id: number;

  showTable1 = false
  showTable2 = false
  showTable3 = false
  showTableOwn = false

  crud = false

  handleRoute(route: Route) {
    if (route.name.includes('create') || route.name.includes('edit')) {
      this.crud = true
      return;
    }

    this.crud = false

    if (route.name == 'einsatzlist') {
      this.showTable1 = true
      this.showTable2 = false
      this.showTable3 = false
      this.showTableOwn = false
    } else if (route.name == 'checkinoverviewlist') {
      this.showTable1 = false
      this.showTable2 = true
      this.showTable3 = false
      this.showTableOwn = false
    } else if (route.name == 'einsatzformlist') {
      this.showTable1 = false
      this.showTable2 = false
      this.showTable3 = true
      this.showTableOwn = false
    }  if (route.name == 'einsatzlistown') {
      this.showTable1 = false
      this.showTable2 = false
      this.showTable3 = false
      this.showTableOwn = true
    }
  }

  mounted() {
    this.handleRoute(this.$router.currentRoute)
  }
}
