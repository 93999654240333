
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Route} from "vue-router";

const DataProps = Vue.extend({
  props: {}
})
@Component({
  components: {},
  watch: {
    '$route'(val: Route) {
      this.handleRoute()
    }
  }
})
export default class DatatableButtonHeader extends DataProps {
  @Prop() archive: boolean
  @Prop({default: '0'}) id
  @Prop({default: false}) containerShift
  @Prop() ewoList
  isInArchive = false

  created() {
    this.handleRoute()
  }

  handleRoute() {
    if (this.archive !== undefined && this.archive !== null) {
      this.isInArchive = this.archive
    } else if (this.$route.query.archive) {
      this.isInArchive = true
    } else {
      this.isInArchive = false
    }
  }

}
