
import {Component, Prop, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {Permission, PermissionModul, TaskDTO, TaskReportMapDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {TaskApi} from "@/services/TaskApi";
import {ReportRequest} from "@/model/Emeld";
import ReportListServersideView from "@/views/emeld/ReportListServersideView.vue";
import DateForm from "@/components/DateForm.vue";
import Multiselect from "@/libs/multiselect";
import {TaskReportMap} from "@/model/Task";
import {DateHelper} from "@/model/Date";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {DateForm, ReportListServersideView, SimpletableButtonHeader, Multiselect}
})
export default class TaskReportView extends mixins<SimpleViewMixin<TaskReportMapDTO, TaskReportMapDTO>>(SimpleViewMixin, DataProps) {
  @Prop() private task: TaskDTO;
  archivSubText = 'Es wird nur die Verknüpfung, nicht aber die Meldung selbst gelöscht.'

  id = null;
  data: TaskReportMapDTO = new TaskReportMap()
  error: TaskReportMapDTO = new TaskReportMap()
  requestDTO = new ReportRequest()
  dateHelper = DateHelper
  levelTree = []
  levelKlasseOptions = []

  async created() {
    this.$root.$emit('loadingStart')
    this.levelTree = await this.$store.dispatch("emeld/fetchLevelTreeOu")
    this.levelKlasseOptions = await this.$store.dispatch("emeld/fetchLevelKlassen")


    this.id = this.task.id
    await this.init(TaskReportMap,
        TaskApi.getReports,
        (a) => TaskApi.putReport(this.id, a),
        (b) => TaskApi.removeReport(b),
        this.id
    )
    this.$root.$emit('loadingDone')
  }

  reportSelected(report) {
    let dto = new TaskReportMap()
    dto.task = this.task
    dto.report = report;
    this.data = dto;
    this.saveObject();
  }

  edit(map) {
    this.$router.push("/emeld/report/edit/" + map.report.id)
  }

  get level1Options() {
    return this.levelTree.map(node => node.dto.level).filter(l => !this.requestDTO.klasse || (this.requestDTO.klasse && l.klasse && l.klasse.id == this.requestDTO.klasse.id))
  }

  get archivTxt() {
    return "löschen"
  }

  get editPermission() {
    return (this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update))
        || this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update_Responsible);
  }

  get overviewPermission() {
    return (this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Overview))
  }

}
