
import {Component, Vue} from "vue-property-decorator";

@Component
export default class NavMixin extends Vue {
  fillNavbar(routes, navBar) {
    routes.forEach((item) => {
          let route = this.$router.getRoutes().find(r => r.name == item)
          if (!route || !route.meta) {
            return;
          }

          let found = false;
          if (route.meta.permissions === undefined && route.meta.permission === undefined) {
            found = true
          } else if (route.meta.permissions !== undefined) {
            route.meta.permissions.forEach((permission) =>
                found |= this.$store.getters.hasPermission(permission[0], permission[1])
            )
          } else {
            found |= (route.meta.permission !== undefined && this.$store.getters.hasPermission(route.meta.permission[0], route.meta.permission[1]))
          }


          if (found) {
            navBar.push({name: route.name, path: route.path, title: route.meta.title, activeRoutes: (route.meta.activeRoutes ? route.meta.activeRoutes : [])})
          }
        }
    )
  }

  isActive(item) {
    return this.$route.name == item.name || (item.activeRoutes.includes(this.$route.name))
  }

}
